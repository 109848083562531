const Funnels = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30">
    <g id="filter-2--funnel-filter-angle-oil">
      <path
        id="Vector"
        fill="currentColor"
        d="M26.874642857142856 0.9953571428571428H0.9953571428571428l9.95357142857143 13.934999999999999v11.944285714285714l5.972142857142857 -3.9814285714285713v-7.962857142857143l9.95357142857143 -13.934999999999999Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M26.874642857142856 0.9953571428571428H0.9953571428571428l9.95357142857143 13.934999999999999v11.944285714285714l5.972142857142857 -3.9814285714285713v-7.962857142857143l9.95357142857143 -13.934999999999999Z"
        strokeWidth="2.13"
      ></path>
    </g>
  </svg>
);

export default Funnels;