import { TrafficSource } from '@/models/models';
import { FFCol, FFField, FFInput, FFSelect, FFSwitch } from '@/uikit';
import { FFSelectOption } from '@/uikit/types/select';
import { Control, Controller, useWatch } from 'react-hook-form';

const EVENT_NAMES: Array<FFSelectOption> = [
  {
    value: 'AddPaymentInfo',
    label: 'Add Payment Info',
  },
  {
    value: 'AddToCart',
    label: 'Add to Cart',
  },
  {
    value: 'AddToWishlist',
    label: 'Add to Wishlist',
  },
  {
    value: 'ClickButton',
    label: 'Click Button',
  },
  {
    value: 'CompletePayment',
    label: 'Complete Payment',
  },
  {
    value: 'CompleteRegistration',
    label: 'Complete Registration',
  },
  {
    value: 'Contact',
    label: 'Contact',
  },
  {
    value: 'Download',
    label: 'Download',
  },
  {
    value: 'InitiateCheckout',
    label: 'Initiate Checkout',
  },
  {
    value: 'PlaceAnOrder',
    label: 'Place an Order',
  },
  {
    value: 'Search',
    label: 'Search',
  },
  {
    value: 'SubmitForm',
    label: 'Submit Form',
  },
  {
    value: 'Subscribe',
    label: 'Subscribe',
  },
  {
    value: 'ViewContent',
    label: 'View Content',
  },
  {
    value: '{txid}',
    label: 'Custom - Transaction ID Value',
  },
];

const TikTokCustomScenario = ({ triggeringEvent, control }: { triggeringEvent: string; control: Control<TrafficSource> }) => {
  const sendTestEventCode = useWatch({
    control,
    name: `conversionTrackingSettings.${triggeringEvent}.customEventData.sendTestEventCode`,
  });

  return (
    <FFCol gap="12px">
      <Controller
        name={`conversionTrackingSettings.${triggeringEvent}.customEventData.accessToken`}
        control={control}
        render={(opt) => (
          <FFField label="Access Token:" block direction="row">
            <FFInput
              value={opt.field.value}
              onChange={(e) => opt.field.onChange(e.target.value.trim())}
              error={opt.fieldState.error?.message}
              placeholder="Access Token"
              style={{ width: 280 }}
            />
          </FFField>
        )}
      />
      <Controller
        name={`conversionTrackingSettings.${triggeringEvent}.customEventData.pixelID`}
        control={control}
        render={(opt) => (
          <FFField label="Pixel ID:" block direction="row">
            <FFInput
              value={opt.field.value}
              onChange={(e) => opt.field.onChange(e.target.value.trim())}
              error={opt.fieldState.error?.message}
              placeholder="Pixel ID"
              style={{ width: 280 }}
            />
          </FFField>
        )}
      />
      <Controller
        name={`conversionTrackingSettings.${triggeringEvent}.customEventData.eventName`}
        control={control}
        render={(opt) => (
          <FFField label="Event type:" block direction="row">
            <FFSelect
              options={EVENT_NAMES}
              valueGetter={(opt) => opt.value}
              labelGetter={(opt) => opt.label}
              value={opt.field.value}
              onChange={opt.field.onChange}
              error={opt.fieldState.error?.message}
              placeholder="Event name"
              style={{ width: 280 }}
            />
          </FFField>
        )}
      />
      <Controller
        name={`conversionTrackingSettings.${triggeringEvent}.customEventData.sendTestEventCode`}
        control={control}
        render={(opt) => (
          <FFSwitch checked={opt.field.value} onClick={opt.field.onChange}>
            Send an optional test event code (use for testing only)
          </FFSwitch>
        )}
      />
      {sendTestEventCode && (
        <Controller
          name={`conversionTrackingSettings.${triggeringEvent}.customEventData.testEventCode`}
          control={control}
          rules={{ required: 'Required' }}
          render={(opt) => (
            <FFField label="Test Event code:" block direction="row">
              <FFInput
                value={opt.field.value}
                onChange={(e) => opt.field.onChange(e.target.value.trim())}
                error={opt.fieldState.error?.message}
                placeholder="Test Event code"
                style={{ width: 280 }}
              />
            </FFField>
          )}
        />
      )}
    </FFCol>
  );
};

export default TikTokCustomScenario;
