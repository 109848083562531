const Labs = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30">
    <g id="flask-half--entertainment-gaming-flask-half">
      <path
        id="Vector"
        fill="currentColor"
        d="M24.074902285714284 26.874443785714284H3.7951574357142857C2.2488701142857144 26.874443785714284 0.9953571428571428 25.621090071428572 0.9953571428571428 24.074703214285716c0 -0.5834783571428572 0.18232952142857142 -1.1524245 0.5215074214285714 -1.6274089285714286l4.187805921428572 -5.823177707142857h16.506326014285715L26.353075714285712 22.447294285714285c0.3392177142857143 0.4749844285714286 0.5215671428571429 1.0439305714285714 0.5215671428571429 1.6274089285714286 0 1.5463868571428572 -1.2535527857142859 2.7997405714285715 -2.7997405714285715 2.7997405714285715Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Subtract"
        fill="#ffffff"
        fillRule="evenodd"
        d="M10.237925057142856 0.9953571428571428v9.242607728571429L1.5168506292857142 22.447493357142857 5.704670485714286 16.62423602142857h16.4890068L17.631995314285714 10.23796487142857V0.9953571428571428H10.237925057142856Z"
        clipRule="evenodd"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector 570"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.237925057142856 10.23796487142857V0.9953571428571428h7.394090164285714v9.242607728571429L26.353075714285712 22.447493357142857c0.3392177142857143 0.47478535714285713 0.5215671428571429 1.0437315 0.5215671428571429 1.6274089285714286 0 1.5461877857142856 -1.2535527857142859 2.7997405714285715 -2.7997405714285715 2.7997405714285715H3.7951574357142857C2.2488701142857144 26.874642857142856 0.9953571428571428 25.621090071428572 0.9953571428571428 24.074902285714284c0 -0.5836774285714286 0.18232952142857142 -1.1526235714285713 0.5215074214285714 -1.6274089285714286l8.721060492857143 -12.209528485714285Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector 948"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m5.704590857142858 16.624116578571428 16.506405642857143 0"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector 947"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.389447307142857 0.9953571428571428h11.091125292857143"
        strokeWidth="2.13"
      ></path>
    </g>
  </svg>
);

export default Labs;
