import useHttp from '@/hooks/http';
import useHttpNotification from '@/hooks/useHttpError';
import { TrafficSource } from '@/models/trafficSource';
import { useMutation } from '@tanstack/react-query';

export function useTrafficSourceCreateMutation() {
  const { post } = useHttp();
  const { handleAssetNotification } = useHttpNotification();
  return useMutation<TrafficSource, Error, TrafficSource>({
    mutationKey: ['trafficSource', 'create'],
    mutationFn: async (trafficSource: TrafficSource) => {
      const { data } = await post<TrafficSource, TrafficSource>('v1/trafficsource/save/', trafficSource);
      return data;
    },
    onError: (error: any, data) => {
      handleAssetNotification(data, 'added', 'trafficSource', error);
    },
    onSuccess: (_, data) => {
      handleAssetNotification(data, 'added', 'trafficSource');
    },
  });
}

export function useTrafficSourceUpdateMutation() {
  const { put } = useHttp();
  const { handleAssetNotification } = useHttpNotification();
  return useMutation<TrafficSource, Error, TrafficSource>({
    mutationKey: ['trafficSource', 'update'],
    mutationFn: async (trafficSource: TrafficSource) => {
      const { data } = await put<TrafficSource, TrafficSource>('v1/trafficsource/save/', trafficSource);
      return data;
    },
    onError: (error: any, data) => {
      handleAssetNotification(data, 'updated', 'trafficSource', error);
    },
    onSuccess: (_, data) => {
      handleAssetNotification(data, 'updated', 'trafficSource');
    },
  });
}

export function useTrafficSourceDuplicateMutation(previousIdTrafficSource: string) {
  const { post } = useHttp();
  const { handleAssetNotification } = useHttpNotification();
  return useMutation<TrafficSource, Error, TrafficSource>({
    mutationKey: ['trafficSource', 'duplicate'],
    mutationFn: async (trafficSource: TrafficSource) => {
      const { data } = await post<TrafficSource, TrafficSource>(
        `v1/trafficsource/duplicate?idTrafficSource=${previousIdTrafficSource}`,
        trafficSource,
      );
      return data;
    },
    onError: (error: any, data) => {
      handleAssetNotification(data, 'duplicated', 'trafficSource', error);
    },
    onSuccess: (_, data) => {
      handleAssetNotification(data, 'duplicated', 'trafficSource');
    },
  });
}

export function useTrafficSourceDeleteMutation() {
  const { del } = useHttp();
  const { handleAssetNotification } = useHttpNotification();
  return useMutation<null, Error, string>({
    mutationKey: ['trafficSource', 'delete'],
    mutationFn: async (id: string) => {
      await del('v1/trafficsource/delete/', {
        data: { entries: [id] },
      });
      return null;
    },
    onError: (error: any, id) => {
      handleAssetNotification({ idTrafficSource: id }, 'deleted', 'trafficSource', error);
    },
    onSuccess: (_, id) => {
      handleAssetNotification({ idTrafficSource: id }, 'deleted', 'trafficSource');
    },
  });
}

export function useTrafficSourceArchiveMutation() {
  const { put } = useHttp();
  const { handleAssetNotification } = useHttpNotification();
  return useMutation<null, Error, TrafficSource>({
    mutationKey: ['trafficSource', 'archive'],
    mutationFn: async (trafficSource: TrafficSource) => {
      await put<TrafficSource, null>('v1/trafficsource/save/', trafficSource);
      return null;
    },
    onError: (error: any, data) => {
      handleAssetNotification(data, 'archived', 'trafficSource', error);
    },
    onSuccess: (_, data) => {
      handleAssetNotification(data, 'archived', 'trafficSource');
    },
  });
}

export function useTrafficSourceUnArchiveMutation() {
  const { put } = useHttp();
  const { handleAssetNotification } = useHttpNotification();
  return useMutation<null, Error, TrafficSource>({
    mutationKey: ['trafficSource', 'unarchive'],
    mutationFn: async (trafficSource: TrafficSource) => {
      await put<TrafficSource, null>('v1/trafficsource/save/', trafficSource);
      return null;
    },
    onError: (error: any, data) => {
      handleAssetNotification(data, 'unarchived', 'trafficSource', error);
    },
    onSuccess: (_, data) => {
      handleAssetNotification(data, 'unarchived', 'trafficSource');
    },
  });
}
