const Builder = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
        <g id="screensaver-monitor-wallpaper">
            <path id="Vector" fill="#d7e0ff" d="M13 1H1c-0.276142 0 -0.5 0.22386 -0.5 0.5V10c0 0.2761 0.223858 0.5 0.5 0.5h12c0.2761 0 0.5 -0.2239 0.5 -0.5V1.5c0 -0.27614 -0.2239 -0.5 -0.5 -0.5Z" strokeWidth="1"></path>
            <path id="Intersect" fill="#ffffff" fillRule="evenodd" d="M0.515545 8.18006 4.07145 5.61972c0.08974 -0.07483 0.20283 -0.11581 0.31962 -0.11581 0.11678 0 0.22988 0.04098 0.31961 0.11581l5.20375 4.87998V10.5H1.01494c-0.275806 0 -0.499395 -0.2239 -0.499395 -0.5V8.18006Z" clipRule="evenodd" strokeWidth="1"></path>
            <path id="vector" stroke="#4147d5" strokeLinecap="round" strokeLinejoin="round" d="M9.91443 10.4997 4.71068 5.61972c-0.08973 -0.07483 -0.20283 -0.11581 -0.31961 -0.11581 -0.11679 0 -0.22988 0.04098 -0.31962 0.11581L0.499963 8.1875" strokeWidth="1"></path>
            <path id="Vector_2" fill="#ffffff" d="m5 13 1 -2.5h2L9 13H5Z" strokeWidth="1"></path>
            <path id="Vector_3" stroke="#4147d5" strokeLinecap="round" strokeLinejoin="round" d="M13 1H1c-0.276142 0 -0.5 0.22386 -0.5 0.5V10c0 0.2761 0.223858 0.5 0.5 0.5h12c0.2761 0 0.5 -0.2239 0.5 -0.5V1.5c0 -0.27614 -0.2239 -0.5 -0.5 -0.5Z" strokeWidth="1"></path>
            <path id="Vector_4" stroke="#4147d5" strokeLinecap="round" strokeLinejoin="round" d="M6 10.5 5 13" strokeWidth="1"></path>
            <path id="Vector_5" stroke="#4147d5" strokeLinecap="round" strokeLinejoin="round" d="M8 10.5 9 13" strokeWidth="1"></path>
            <path id="Vector_6" stroke="#4147d5" strokeLinecap="round" strokeLinejoin="round" d="M4 13h6" strokeWidth="1"></path>
            <path id="vector_2" fill="#ffffff" d="M9.86434 6c-0.7671 0 -1.38896 -0.6226 -1.38896 -1.39062 0 -0.76803 0.62186 -1.39063 1.38896 -1.39063 0.76706 0 1.38896 0.6226 1.38896 1.39063C11.2533 5.3774 10.6314 6 9.86434 6Z" strokeWidth="1"></path>
            <path id="vector_3" stroke="#4147d5" strokeLinecap="round" strokeLinejoin="round" d="M9.86434 6c-0.7671 0 -1.38896 -0.6226 -1.38896 -1.39062 0 -0.76803 0.62186 -1.39063 1.38896 -1.39063 0.76706 0 1.38896 0.6226 1.38896 1.39063C11.2533 5.3774 10.6314 6 9.86434 6Z" strokeWidth="1"></path>
        </g>
    </svg>
    );
    
export default Builder;