const IntegratedReporting = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30">
    <g id="browser-setting--window-gear-app-code-cog-settings-apps">
      <path
        id="Vector"
        fill="#ffffff"
        d="M26.874642857142856 24.88392857142857v-17.916428571428572H0.9953571428571428v17.916428571428572c0 1.0994715 0.8912726464285714 1.9907142857142857 1.9907142857142857 1.9907142857142857h21.89785714285714c1.0994715 0 1.9907142857142857 -0.8912427857142856 1.9907142857142857 -1.9907142857142857Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_2"
        fill="currentColor"
        d="M26.874642857142856 2.9860714285714285v3.9814285714285713H0.9953571428571428v-3.9814285714285713c0 -1.0994396485714286 0.8912746371428572 -1.9907142857142857 1.9907142857142857 -1.9907142857142857h21.89785714285714c1.0994715 0 1.9907142857142857 0.8912726464285714 1.9907142857142857 1.9907142857142857Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_3"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.935019907142856 20.646096c1.9444500857142857 0 3.520717564285714 -1.5763072928571429 3.520717564285714 -3.5207573785714286 0 -1.9444301785714286 -1.5762674785714286 -3.5206976571428568 -3.520717564285714 -3.5206976571428568 -1.9444301785714286 0 -3.5206976571428568 1.5762674785714286 -3.5206976571428568 3.5206976571428568 0 1.9444500857142857 1.5762674785714286 3.5207573785714286 3.5206976571428568 3.5207573785714286Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_4"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.934999999999999 10.96411772142857v2.64054315"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m8.601119957142856 14.044708264285713 2.288465421428571 1.3202815285714287"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_6"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m8.601119957142856 20.20594907142857 2.288465421428571 -1.3202815285714287"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_7"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.934999999999999 23.28657942857143v-2.6404834285714287"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_8"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m19.26882032142857 20.20594907142857 -2.288445514285714 -1.3202815285714287"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_9"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m19.26882032142857 14.044708264285713 -2.288445514285714 1.3202815285714287"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_10"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M0.9953571428571428 6.954620078571428h25.879285714285714"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_11"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M24.88392857142857 0.9953571428571428h-21.89785714285714c-1.0994416392857143 0 -1.9907142857142857 0.8912726464285714 -1.9907142857142857 1.9907142857142857v21.89785714285714c0 1.0994715 0.8912726464285714 1.9907142857142857 1.9907142857142857 1.9907142857142857h21.89785714285714c1.0994715 0 1.9907142857142857 -0.8912427857142856 1.9907142857142857 -1.9907142857142857v-21.89785714285714c0 -1.0994416392857143 -0.8912427857142856 -1.9907142857142857 -1.9907142857142857 -1.9907142857142857Z"
        strokeWidth="2.13"
      ></path>
    </g>
  </svg>
);

export default IntegratedReporting;