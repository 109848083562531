import AntMessageContext from '@/contexts/antMessage';
import { useContext } from 'react';

interface Params {
  DeletePageGroups: {
    entries: string[];
  };
  DeleteConditions: {
    entries: string[];
  };
  SaveFunnel: {};
  ResetPassword: {
    email: string;
  };
  Login: {
    username: string;
  };
  Offer: {
    name: string;
  };
  Ai: {
    ids: string[];
  };
}

interface NotificationParams<T extends keyof Params = keyof Params> {
  url?: string;
  params?: Params[T];
  error?: any;
  type?: string;
}

const HttpNotificationType = {
  DeletePageGroups: 'DeletePageGroups',
  DeleteConditions: 'DeleteConditions',
  SaveFunnel: 'SaveFunnel',
  ResetPassword: 'ResetPassword',
  Login: 'Login',
  Offer: 'Offer',
  Ai: 'Ai',
} as const;

const notificationHandlers: Record<keyof typeof HttpNotificationType, (params: NotificationParams<any>, messageApi: any) => void> = {
  [HttpNotificationType.DeleteConditions]: (params: NotificationParams<'DeleteConditions'>, messageApi) => {
    if (params.error) {
      const message =
        (params.params?.entries || []).length > 1
          ? `Conditions (${params.params?.entries.join(', ')}) cannot be deleted`
          : `Condition (${params.params?.entries[0]}) cannot be deleted`;
      messageApi.error(`${message} ${params.error.response?.data?.message}`);
    } else {
      const message =
        (params.params?.entries || []).length > 1
          ? `Conditions (${params.params?.entries.join(', ')}) deleted successfully`
          : `Condition (${params.params?.entries[0]}) deleted successfully`;
      messageApi.success(message);
    }
  },
  [HttpNotificationType.DeletePageGroups]: (params: NotificationParams<'DeletePageGroups'>, messageApi) => {
    if (params.error) {
      const message =
        (params.params?.entries || []).length > 1
          ? `Page groups (${params.params?.entries.join(', ')}) cannot be deleted`
          : `Page group (${params.params?.entries[0]}) cannot be deleted`;
      messageApi.error(`${message} ${params.error.response?.data?.message}`);
    } else {
      const message =
        (params.params?.entries || []).length > 1
          ? `Page groups (${params.params?.entries.join(', ')}) deleted successfully`
          : `Page group (${params.params?.entries[0]}) deleted successfully`;
      messageApi.success(message);
    }
  },
  [HttpNotificationType.SaveFunnel]: (params: NotificationParams, messageApi) => {},
  [HttpNotificationType.ResetPassword]: (params: NotificationParams<'ResetPassword'>, messageApi) => {
    if (params.error) {
      messageApi.error(`${params.error.response?.data?.message}`);
    } else {
      messageApi.success(`Success. If your email matches one of our accounts, you will receive a password reset notice shortly.`);
    }
  },
  [HttpNotificationType.Login]: (params: NotificationParams<'Login'>, messageApi) => {
    if (params.error) {
      messageApi.error(`${params.error.response?.data?.error_description}`);
    }
  },
  [HttpNotificationType.Offer]: (params: NotificationParams<'Offer'>, messageApi) => {
    if (params.error) {
      messageApi.error(`${params.error.response?.data?.message}`);
    } else if (params.type === 'OfferCreate') {
      messageApi.success(`Offer (${params.params?.name}) created successfully`);
    } else if (params.type === 'OfferUpdate') {
      messageApi.success(`Offer (${params.params?.name}) updated successfully`);
    } else if (params.type === 'OfferDuplicate') {
      messageApi.success(`Offer (${params.params?.name}) duplicated successfully`);
    } else if (params.type === 'OfferDelete') {
      messageApi.success(`Offer (${params.params?.name}) deleted successfully`);
    } else if (params.type === 'OfferArchive') {
      messageApi.success(`Offer (${params.params?.name}) archived successfully`);
    } else if (params.type === 'OfferUnArchive') {
      messageApi.success(`Offer (${params.params?.name}) unarchived successfully`);
    }
  },
  [HttpNotificationType.Ai]: (params: NotificationParams<'Ai'>, messageApi) => {
    if (params.error) {
      messageApi.error(`${params.error.response?.data?.message}`);
    } else if (params.type === 'AiCreate') {
      messageApi.success(`AI (${params.params?.ids.join(', ')}) created successfully`);
    } else if (params.type === 'AiUpdate') {
      messageApi.success(`AI (${params.params?.ids.join(', ')}) updated successfully`);
    } else if (params.type === 'AiDelete') {
      messageApi.success(`AI (${params.params?.ids.join(', ')}) deleted successfully`);
    }
  },
};
const useHttpNotificationNew = (category: keyof typeof HttpNotificationType, type?: string) => {
  const { messageApi } = useContext(AntMessageContext);
  const showNotification = (params: NotificationParams) => {
    const handler = notificationHandlers[category];
    handler({ ...params, type }, messageApi);
  };

  return { showNotification };
};

export default useHttpNotificationNew;
