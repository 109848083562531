import config from '@/config';
import { FF_ACCESS_TOKEN, FF_REFRESH_TOKEN } from '@/constants/auth';
import { VerifyAuthenticationResponse } from '@/models/authenticate';
import useAuthStore from '@/stores/auth';
import { getCookie } from '@/utils/storage';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLogout } from './logout';

const axiosInstance: AxiosInstance = axios.create({
  baseURL: config.apiUrls.funnelflux,
});

const useHttp = () => {
  const accessToken = getCookie(FF_ACCESS_TOKEN);
  const refreshToken = getCookie(FF_REFRESH_TOKEN);
  const idToken = useAuthStore((state) => state.tokens.id_token);
  const { logout } = useLogout();
  const initializeAppAuth = useAuthStore((state) => state.initializeAppAuth);
  const navigate = useNavigate();

  useEffect(() => {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  }, [accessToken]);

  useEffect(() => {
    axiosInstance.interceptors.response.use(
      (response) => response,
      async (error: AxiosError<any>) => {
        const canRetry =
          !error.config?.url?.includes('/v1/auth/validate') &&
          (error.response?.status === 401 || (error.response?.status === 400 && error.response?.data?.error === 'token is expired'));

        if (error.response && canRetry) {
          try {
            const verifyAuth = await axiosInstance.post<VerifyAuthenticationResponse>('/v1/auth/validate', {
              idToken: idToken,
              refreshToken: refreshToken,
              accessToken: accessToken,
            });
            initializeAppAuth(verifyAuth.data);
          } catch (e) {
            logout();
            navigate('/');
          }
        }
        return Promise.reject(error);
      },
    );
  }, [accessToken, idToken, refreshToken, initializeAppAuth, logout, navigate]);

  function get<T>(url: string, options?: AxiosRequestConfig<T>) {
    return axiosInstance.get<T>(url, options);
  }

  function post<T, R>(url: string, body: T, options?: AxiosRequestConfig<T>) {
    return axiosInstance.post<T, AxiosResponse<R>>(url, body, options);
  }

  function put<T, R = T>(url: string, body: T, options?: AxiosRequestConfig<T>) {
    return axiosInstance.put<T, AxiosResponse<R>>(url, body, options);
  }

  function del<T>(url: string, options?: AxiosRequestConfig<T>) {
    return axiosInstance.delete(url, options);
  }

  return { get, post, put, del };
};

export default useHttp;
