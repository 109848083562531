/**
 * FunnelFlux Stats API
 * API endpoints to manage stats and run statistic reports
 *
 * OpenAPI spec version: 2.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CostSegment { 
    cost: string;
    costType?: CostSegment.CostTypeEnum;
    /**
     * If set, then update cost of traffic that comes from this device type only
     */
    restrictToDeviceType?: CostSegment.RestrictToDeviceTypeEnum;
    /**
     * If set, then update cost of traffic that comes from this connection type only
     */
    restrictToConnectionType?: CostSegment.RestrictToConnectionTypeEnum;
    /**
     * If set, then update cost of traffic that comes from this browser only
     */
    restrictToBrowser?: string;
    /**
     * If set, then update cost of traffic that comes from this country only
     */
    restrictToCountryCode?: string;
    /**
     * If set, then update cost of traffic that have these tracking fields' keys/values only.
     */
    restrictToTrackingFields?: { [key: string]: Array<string>; };
}
export namespace CostSegment {
    export type CostTypeEnum = 'wholeSegment' | 'perEntrance';
    export const CostTypeEnum = {
        WholeSegment: 'wholeSegment' as CostTypeEnum,
        PerEntrance: 'perEntrance' as CostTypeEnum
    };
    export type RestrictToDeviceTypeEnum = 'Unknown' | 'Console' | 'Desktop' | 'EReader' | 'MediaHub' | 'Mobile' | 'SmallScreen' | 'SmartPhone' | 'SmartWatch' | 'Tablet' | 'TV';
    export const RestrictToDeviceTypeEnum = {
        Unknown: 'Unknown' as RestrictToDeviceTypeEnum,
        Console: 'Console' as RestrictToDeviceTypeEnum,
        Desktop: 'Desktop' as RestrictToDeviceTypeEnum,
        EReader: 'EReader' as RestrictToDeviceTypeEnum,
        MediaHub: 'MediaHub' as RestrictToDeviceTypeEnum,
        Mobile: 'Mobile' as RestrictToDeviceTypeEnum,
        SmallScreen: 'SmallScreen' as RestrictToDeviceTypeEnum,
        SmartPhone: 'SmartPhone' as RestrictToDeviceTypeEnum,
        SmartWatch: 'SmartWatch' as RestrictToDeviceTypeEnum,
        Tablet: 'Tablet' as RestrictToDeviceTypeEnum,
        TV: 'TV' as RestrictToDeviceTypeEnum
    };
    export type RestrictToConnectionTypeEnum = 'Unknown' | 'Mobile' | 'Wifi' | 'Wired' | 'Dialup' | 'Satellite';
    export const RestrictToConnectionTypeEnum = {
        Unknown: 'Unknown' as RestrictToConnectionTypeEnum,
        Mobile: 'Mobile' as RestrictToConnectionTypeEnum,
        Wifi: 'Wifi' as RestrictToConnectionTypeEnum,
        Wired: 'Wired' as RestrictToConnectionTypeEnum,
        Dialup: 'Dialup' as RestrictToConnectionTypeEnum,
        Satellite: 'Satellite' as RestrictToConnectionTypeEnum
    };
}
