const Marketplace = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30">
    <g id="gift-2--reward-box-social-present-gift-media-rating-bow">
      <path
        id="Vector"
        fill="#ffffff"
        d="M26.153805214285715 9.539044992857141v1.1960410499999998c0 1.0994316857142856 -0.8912427857142856 1.9907142857142857 -1.9907142857142857 1.9907142857142857H3.706829442857143c-1.0994316857142856 0 -1.9907122949999998 -0.8912826 -1.9907122949999998 -1.9907142857142857v-1.1960410499999998c0 -1.0994316857142856 0.8912806092857142 -1.9907142857142857 1.9907122949999998 -1.9907142857142857H24.163090928571428c1.0994715 0 1.9907142857142857 0.8912826 1.9907142857142857 1.9907142857142857Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_2"
        fill="currentColor"
        d="M24.437212285714285 24.35539392857143V12.725800328571427H3.4322303142857145v11.6295936c0 1.0994715 0.8912826 1.9907142857142857 1.9907142857142857 1.9907142857142857h17.023553399999997c1.0994715 0 1.9907142857142857 -0.8912427857142856 1.9907142857142857 -1.9907142857142857Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_3"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M24.437212285714285 24.35539392857143V12.725800328571427H3.4322303142857145v11.6295936c0 1.0994715 0.8912826 1.9907142857142857 1.9907142857142857 1.9907142857142857h17.023553399999997c1.0994715 0 1.9907142857142857 -0.8912427857142856 1.9907142857142857 -1.9907142857142857Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector 2239"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.934999999999999 26.345909142857145V12.721898528571428"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_4"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M26.153805214285715 9.539044992857141v1.1960410499999998c0 1.0994316857142856 -0.8912427857142856 1.9907142857142857 -1.9907142857142857 1.9907142857142857H3.706829442857143c-1.0994316857142856 0 -1.9907122949999998 -0.8912826 -1.9907122949999998 -1.9907142857142857v-1.1960410499999998c0 -1.0994316857142856 0.8912806092857142 -1.9907142857142857 1.9907122949999998 -1.9907142857142857H24.163090928571428c1.0994715 0 1.9907142857142857 0.8912826 1.9907142857142857 1.9907142857142857Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.935159257142857 7.548131635714285C13.935159257142857 5.806256635714285 11.902978392857143 1.7418749999999998 8.854697142857143 1.7418749999999998c-4.959088264285714 0 -3.1526145857142858 5.806256635714285 -0.7790660357142857 5.806256635714285"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_6"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.934999999999999 7.548131635714285C13.934999999999999 5.806256635714285 15.967180864285714 1.7418749999999998 19.015462114285715 1.7418749999999998c4.959108171428571 0 3.152534957142857 5.806256635714285 0.7790660357142857 5.806256635714285"
        strokeWidth="2.13"
      ></path>
    </g>
  </svg>
);

export default Marketplace;