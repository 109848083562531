import { RouterProvider } from 'react-router-dom';
import { protectedRoutes } from './protected';
import { publicRoutes } from './public';
import useAuthStore from '@/stores/auth';
import { useMemo } from 'react';

export const AppRoutes = () => {
  const isLoggedIn = useAuthStore(state => state.isLoggedIn);

  const routes = useMemo(() => (isLoggedIn ? protectedRoutes : publicRoutes), [isLoggedIn]);
  return <RouterProvider router={routes} />;
};
