import { TrackingTokenType } from '@/types';
import { OfferSourceTemplate } from '@/types/offerSource';
import { TrafficSourceTemplate } from '@/types/trafficsource';
import { defined } from '@/utils/define';
import { FFSelectOption } from '@/uikit/types/select';

export const TRAFFIC_SOURCE_TEMPLATE: TrafficSourceTemplate[] = [
  {
    id: 'traffic_source_1',
    name: 'RTX Platform (Intext)',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '{campaign_id}' },
      external: { name: 'external', value: '{click_id}' },
      c1: { name: 'keyword', value: '{keyword}' },
      c2: { name: 'keyword_id', value: '{keyword_id}' },
      c3: { name: 'campaign_id', value: '{campaign_id}' },
      c4: { name: 'country', value: '{country}' }
    },
    typeCost: 'cpe',
    defaultCost: '{bid}',
    trackingType: 'postbackURL',
    tracking: {
      code: 'https://www.trkjmp.com/conv?v=REPLACE_ME&c={external}&r={payout}'
    },
    imgSrc: 'trafficsources/rtx-intext.png'
  },
  {
    id: 'traffic_source_2',
    name: 'RTX Platform (Pops)',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '{campaign_id}' },
      external: { name: 'external', value: '{click_id}' },
      c1: { name: 'channel', value: '{channel}{target}' },
      c2: { name: 'target', value: '{target}' },
      c3: { name: 'domain', value: '{domain}' },
      c4: { name: 'domain_id', value: '{domain_id}' },
      c5: { name: 'country', value: '{country}' }
    },
    typeCost: 'cpe',
    defaultCost: '{bid}',
    trackingType: 'postbackURL',
    tracking: {
      code: 'https://p.trkjmp.com/conv?v=REPLACE_ME&c={external}&r={payout}'
    },
    imgSrc: 'trafficsources/rtx-pops.png'
  },
  {
    id: 'traffic_source_3',
    name: 'RTX Platform (Search)',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '{campaign_id}' },
      external: { name: 'external', value: '{click_id}' },
      c1: { name: 'keyword', value: '{keyword}' },
      c2: { name: 'keyword_id', value: '{keyword_id}' },
      c3: { name: 'adgroup_id', value: '{adgroup_id}' },
      c4: { name: 'ad_id', value: '{ad_id}' },
      c5: { name: 'country', value: '{country}' }
    },
    typeCost: 'cpe',
    defaultCost: '{bid}',
    trackingType: 'postbackURL',
    tracking: {
      code: 'https://s.trkjmp.com/conv?v=REPLACE_ME&c={external}&r={payout}'
    },
    imgSrc: 'trafficsources/rtx-search.png'
  },
  {
    id: 'traffic_source_6',
    name: 'AdCash',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '[campaign]' },
      external: { name: 'external', value: '[clickid]' },
      c1: { name: 'country', value: '[country]' },
      c2: { name: 'lang', value: '[lang]' },
      c3: { name: 'zone', value: '[zone]' },
      c4: { name: 'time', value: '[time]' }
    },
    typeCost: 'cpe',
    defaultCost: '[redirection_cost]',
    trackingHelpLink: 'https://support.adcash.com/en/articles/243-tracking-101',
    trackingType: 'postbackURL',
    tracking: {
      code: 'https://adcash.com/event.php?type=Registration&advertiser=REPLACE_ME&cid={external}'
    },
    imgSrc: 'trafficsources/adcash.png'
  },
  {
    id: 'traffic_source_7',
    name: 'Adroll',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '' },
      external: { name: 'external', value: '' },
      c1: { name: 'banner', value: '#REPLACE#' },
      c2: { name: 'size', value: '#REPLACE#' }
    },
    typeCost: 'cpe',
    defaultCost: '',
    trackingType: 'none',
    tracking: { code: '' },
    imgSrc: 'trafficsources/adroll.png'
  },
  {
    id: 'traffic_source_9',
    name: 'Avazu',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '' },
      external: { name: 'external', value: '{subid}' },
      c1: { name: 'bundle_id', value: '{bundle_id}' },
      c2: { name: 'category', value: '{category}' },
      c3: { name: 'exchange', value: '{exchange}' },
      c4: { name: 'media', value: '{media}' },
      c5: { name: 'creative_id', value: '{creative_id}' },
      c6: { name: 'publisher_name', value: '{publisher_name}' },
      c7: { name: 'source_name', value: '{source_name}' }
    },
    typeCost: 'cpe',
    defaultCost: '',
    trackingType: 'postbackURL',
    tracking: {
      code: 'https://mdsp.avazutracking.net/tracking/s2s.php?subid={external}'
    },
    imgSrc: 'trafficsources/avazu.png'
  },
  {
    id: 'traffic_source_10',
    name: 'Microsoft Ads',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '{CampaignId}' },
      external: { name: 'msclkid', value: '', previousName: 'external' },
      c1: { name: 'keyword', value: '{keyword}' },
      c2: { name: 'query', value: '{QueryString}' },
      c3: { name: 'adgroup', value: '{AdGroupId}' },
      c4: { name: 'ad', value: '{AdId}' },
      c5: { name: 'bidmatchtype', value: '{BidMatchType}' },
      c6: { name: 'matchtype', value: '{MatchType}' },
      c7: { name: 'network', value: '{Network}' }
    },
    typeCost: 'cpe',
    defaultCost: '',
    trackingType: 'postbackURL',
    conversionTrackingSettings: {
      Conversions: {
        isCustomScenario: true,
        customEventType: 'MicrosoftAds',
        customEventData: {},
        postbackType: 'postbackURL',
        postbackURL: ''
      }
    },
    tracking: { code: '' },
    imgSrc: 'trafficsources/msads.png'
  },
  {
    id: 'traffic_source_11',
    name: 'Meta (Facebook) Ads',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '{{campaign.id}}' },
      external: { name: 'fbclid', value: '', previousName: 'external' },
      c1: { name: 'ad_id', value: '{{ad.id}}' },
      c2: { name: 'adset_id', value: '{{adset.id}}' },
      c3: { name: 'placement', value: '{{placement}}' },
      c4: { name: 'site_source', value: '{{site_source_name}}' }
    },
    typeCost: 'cpe',
    defaultCost: '',
    trackingType: 'postbackURL',
    conversionTrackingSettings: {
      Conversions: {
        isCustomScenario: true,
        customEventType: 'Facebook',
        customEventData: {},
        postbackType: 'postbackURL',
        postbackURL: ''
      }
    },
    tracking: { code: '' },
    imgSrc: 'trafficsources/meta.png'
  },
  {
    id: 'traffic_source_13',
    name: 'Google Ads',
    trackingFieldSlots: {
      campaign: {
        name: 'utm_campaign',
        value: '{campaignid}',
        previousName: 'campaign'
      },
      external: { name: 'gclid', value: '', previousName: 'external' },
      c1: { name: 'adgroup', value: '{adgroupid}' },
      c2: { name: 'ad', value: '{creative}' },
      c3: { name: 'matchtype', value: '{matchtype}' },
      c4: { name: 'network', value: '{network}' },
      c5: { name: 'kw', value: '{keyword}' },
      c6: { name: 'placement', value: '{placement}' },
      c7: { name: 'target', value: '{target}' },
      c8: { name: 'adposition', value: '{adposition}' },
      c9: { name: 'gbraid', value: '' },
      c10: { name: 'wbraid', value: '' }
    },
    typeCost: 'cpe',
    defaultCost: '',
    trackingType: 'postbackURL',
    conversionTrackingSettings: {
      Conversions: {
        isCustomScenario: true,
        customEventType: 'GoogleAds',
        customEventData: {},
        postbackType: 'postbackURL',
        postbackURL: ''
      }
    },
    tracking: { code: '' },
    imgSrc: 'trafficsources/googleads.png'
  },
  {
    id: 'traffic_source_14',
    name: 'Leadbolt',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '[CAMP_ID]' },
      external: { name: 'external', value: '[CLK_ID]' },
      c1: { name: 'trackid', value: '[TRACK_ID]' },
      c2: { name: 'category_id', value: '[CAT_ID]' },
      c3: { name: 'site_id', value: '[SITE_ID]' },
      c4: { name: 'adgroup', value: '[AD_GROUP_ID]' },
      c5: { name: 'ad', value: '[AD_ID]' },
      c6: { name: 'os', value: '[PLATFORM]' }
    },
    typeCost: 'cpe',
    defaultCost: '',
    trackingType: 'postbackURL',
    tracking: {
      code: 'https://ad.leadbolt.net/conv/?track_id={data-trackid}&clk_id={external}'
    },
    imgSrc: 'trafficsources/leadbolt.png'
  },
  {
    id: 'traffic_source_15',
    name: 'MGID',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '{campaign_id}' },
      external: { name: 'external', value: '{click_id}' },
      c1: { name: 'publisher', value: '{widget_id}' },
      c2: { name: 'ad', value: '{teaser_id}' }
    },
    typeCost: 'cpe',
    defaultCost: '',
    trackingType: 'postbackURL',
    tracking: {
      code: 'https://a.mgid.com/postback?c={external}&r={payout}&e=conversion'
    },
    imgSrc: 'trafficsources/mgid.png'
  },
  {
    id: 'traffic_source_16',
    name: 'PopAds',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '[CAMPAIGNID]' },
      external: { name: 'external', value: '[IMPRESSIONID]' },
      c1: { name: 'site_id', value: '[WEBSITEID]' },
      c2: { name: 'category', value: '[CATEGORYNAME]' },
      c3: { name: 'category_id', value: '[CATEGORYID]' },
      c4: { name: 'formfactor', value: '[FORMFACTORNAME]' },
      c5: { name: 'quality_score', value: '[QUALITY]' }
    },
    typeCost: 'cpe',
    defaultCost: '[BID]',
    trackingType: 'postbackURL',
    tracking: {
      code: 'https://serve.popads.net/cpixel.php?s2s=1&aid=REPLACE_ME&id={external}&value={payout}'
    },
    imgSrc: 'trafficsources/popads.png'
  },
  {
    id: 'traffic_source_17',
    name: 'PopCash',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '[campaignid]' },
      external: { name: 'external', value: '[clickid]' },
      c1: { name: 'site_id', value: '[siteid]' },
      c2: { name: 'category', value: '[category]' },
      c3: { name: 'cc', value: '[cc]' },
      c4: { name: 'carrier', value: '[carrier]' }
    },
    typeCost: 'cpe',
    defaultCost: '[bid]',
    trackingType: 'none',
    tracking: {
      code: 'https://ct.popcash.net/click?aid=REPLACE_ME&clickid={external}&payout={payout}'
    },
    imgSrc: 'trafficsources/popcash.png'
  },
  {
    id: 'traffic_source_18',
    name: 'PropellerAds',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '{campaignid}' },
      external: { name: 'external', value: '${SUBID}' },
      c1: { name: 'zone', value: '{zoneid}' },
      c2: { name: 'country', value: '{country}' },
      c3: { name: 'region', value: '{region}' },
      c4: { name: 'isp', value: '{isp}' },
      c5: { name: 'carrier', value: '{carrier}' }
    },
    typeCost: 'cpe',
    defaultCost: '{cost}',
    trackingType: 'postbackURL',
    tracking: {
      code: 'https://ad.propellerads.com/conversion.php?aid=REPLACE_ME&tid=REPLACE_ME&visitor_id={external}&payout={payout}'
    },
    imgSrc: 'trafficsources/propellerads.png'
  },
  {
    id: 'traffic_source_20',
    name: 'RevContent',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '{boost_id}' },
      external: { name: 'rc_uuid', value: '', previousName: 'external' },
      c1: { name: 'target', value: '{adv_targets}' },
      c2: { name: 'content', value: '{content_id}' },
      c3: { name: 'widget', value: '{widget_id}' }
    },
    typeCost: 'cpe',
    defaultCost: '',
    trackingType: 'postbackURL',
    tracking: {
      code: 'https://trends.revcontent.com/api/v1/conversion.php?api_key=REPLACE_ME&rc_uuid={external}&amount={payout}'
    },
    imgSrc: 'trafficsources/revcontent.png'
  },
  {
    id: 'traffic_source_21',
    name: 'SelfAdvertiser',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '@@CAMPAIGN-KEYWORD@@' },
      external: { name: 'external', value: '@@CLICK-ID@@' },
      c1: { name: 'source', value: '@@SOURCE@@' },
      c2: { name: 'campaign_kw', value: '@@CAMPAIGN-KEYWORD@@' }
    },
    typeCost: 'cpe',
    defaultCost: '',
    trackingType: 'postbackURL',
    tracking: {
      code: 'https://clkpback3.com/adServe/ssapixel?subid={external}&price={payout}'
    },
    imgSrc: 'trafficsources/selfadvertiser.png'
  },
  {
    id: 'traffic_source_23',
    name: 'Start.io',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: 'campaign_id' },
      external: { name: 'external', value: 'startapp_click_id_placeholder' },
      c1: { name: 'category', value: 'startapp_publisher_category' },
      c2: { name: 'publisher', value: 'startapp_pub_id' },
      c3: { name: 'appid', value: 'startapp_app_id' },
      c4: { name: 'creative', value: 'creative_id' },
      c5: { name: 'bid_type', value: 'bid_type' },
      c6: { name: 'connection_type', value: 'startapp_connection_type' },
      c7: { name: 'user_id', value: 'user_id' }
    },
    typeCost: 'cpe',
    defaultCost: 'bid_cost',
    trackingType: 'postbackURL',
    tracking: {
      code: 'https://www.startappinstalls.com/trackinstall/selfservice?d={external}'
    },
    imgSrc: 'trafficsources/startio.png'
  },
  {
    id: 'traffic_source_24',
    name: 'Taboola',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '{campaign_id}' },
      external: { name: 'click_id', value: '{click_id}', previousName: 'external' },
      c1: { name: 'publisher', value: '{site}' },
      c2: { name: 'ad_title', value: '{title}' },
      c3: { name: 'creative', value: '{thumbnail}' },
      c4: { name: 'site_id', value: '{site_id}' },
      c5: { name: 'sitedomain', value: '{site_domain}' }
    },
    typeCost: 'cpe',
    defaultCost: '',
    trackingType: 'postbackURL',
    tracking: {
      code: `https://trc.taboola.com/actions-handler/log/3/s2s-action?click-id={external}&name=EVENT_NAME&revenue={payout}`
    },
    imgSrc: 'trafficsources/taboola.png'
  },
  {
    id: 'traffic_source_25',
    name: 'TrafficStars',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '{campaign_id}' },
      external: { name: 'external', value: '{click_id}' },
      c1: { name: 'category_id', value: '{category_id}' },
      c2: { name: 'adspot_id', value: '{adspot_id}' },
      c3: { name: 'creative_id', value: '{creative_id}' },
      c4: { name: 'keywords', value: '{keywords}' },
      c5: { name: 'referrer', value: '{referrer}' },
      c6: { name: 'site_host', value: '{site_host}' },
      c7: { name: 'site_id', value: '{site_id}' },
      c8: { name: 'carrier', value: '{carrier}' }
    },
    typeCost: 'cpe',
    defaultCost: '{cost}',
    trackingType: 'postbackURL',
    tracking: {
      code: 'https://tsyndicate.com/api/v1/cpa/action?key=REPLACE_ME&clickid={external}&value={payout}'
    },
    imgSrc: 'trafficsources/trafficstars.png'
  },
  {
    id: 'traffic_source_26',
    name: 'WigetMedia',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '{campaignId}' },
      external: { name: 'external', value: '{transactionId}' },
      c1: { name: 'advertiser', value: '{advertiserId}' },
      c2: { name: 'campaign_group', value: '{campaignGroupId}' },
      c3: { name: 'ad', value: '{adId}' },
      c4: { name: 'dimension', value: '{width}x{height}' },
      c5: { name: 'placement', value: '{pid}' }
    },
    typeCost: 'cpe',
    defaultCost: '',
    trackingType: 'none',
    tracking: { code: '' },
    imgSrc: 'trafficsources/wiget.png'
  },
  {
    id: 'traffic_source_27',
    name: 'ZeroPark',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '{campaign_id}' },
      external: { name: 'external', value: '{cid}' },
      c1: { name: 'source', value: '{source}' },
      c2: { name: 'target', value: '{target}' },
      c3: { name: 'keyword', value: '{keyword}' },
      c4: { name: 'match', value: '{match}' },
      c5: { name: 'traffic_type', value: '{traffic_type}' },
      c6: { name: 'visitor_type', value: '{visitor_type}' },
      c7: { name: 'adcopy', value: '{ad_copy_name}' },
      c8: { name: 'push_type', value: '{push_type}' },
      c9: { name: 'creative_num', value: '{creative_number}' }
    },
    typeCost: 'cpe',
    defaultCost: 'visit_cost',
    trackingType: 'postbackURL',
    tracking: {
      code: 'https://zp-postback.com/zppostback/REPLACE_ME?cid={external}&payout={payout}'
    },
    imgSrc: 'trafficsources/zeropark.png'
  },
  {
    id: 'traffic_source_28',
    name: 'RollerAds',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '{campaignId}' },
      external: { name: 'external', value: '{clickId}' },
      c1: { name: 'zone', value: '{zoneId}' },
      c2: { name: 'creative', value: '{creativeId}' },
      c3: { name: 'age', value: '{ageGroup}' },
      c4: { name: 'device', value: '{device}' },
      c5: { name: 'country', value: '{country}' }
    },
    typeCost: 'cpe',
    defaultCost: '{costPerClick}',
    trackingType: 'postbackURL',
    tracking: {
      code: 'https://eu.rollerads.com/conversion/{external}/aid/##ACCOUNT_NUMBER##/##API_KEY##'
    },
    imgSrc: 'trafficsources/rollerads.png'
  },
  {
    id: 'traffic_source_29',
    name: 'TwinRed',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '{campaignId}' },
      external: { name: 'external', value: '{s2sparam}' },
      c1: { name: 'site_id', value: '{siteid}' },
      c2: { name: 'placement', value: '{placementId}' },
      c3: { name: 'channel', value: '{channel}' },
      c4: { name: 'subchannel', value: '{subchannel}' },
      c5: { name: 'media', value: '{mediaId}' },
      c6: { name: 'size', value: '{size}' },
      c7: { name: 'subsite', value: '{subsiteid}' },
      c8: { name: 'domain', value: '{domainId}' },
      c9: { name: 'keyword', value: '{keyword}' }
    },
    typeCost: 'cpe',
    defaultCost: '{cpv}',
    trackingType: 'postbackURL',
    tracking: {
      code: 'https://twinrdsrv.com/conversion.engine?s2sparam={external}&apikey=REPLACE_ME&amount={payout}'
    },
    imgSrc: 'trafficsources/twinred.png'
  },
  {
    id: 'traffic_source_30',
    name: 'Clickadu',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '{campaignid}' },
      external: { name: 'external', value: '${SUBID}' },
      c1: { name: 'zone', value: '{zoneid}' },
      c2: { name: 'pn_type', value: '{pn_type}' },
      c3: { name: 'os', value: '{os}' },
      c4: { name: 'device', value: '{device}' },
      c5: { name: 'browser', value: '{browser}' },
      c6: { name: 'con_type', value: '{connection_type}' },
      c7: { name: 'carrier', value: '{carrier}' },
      c8: { name: 'cohort', value: '{cohort}' }
    },
    typeCost: 'cpe',
    defaultCost: '{cost}',
    trackingType: 'postbackURL',
    tracking: {
      code: 'https://sconvtrk.com/conversion/REPLACE_ME/?aid=REPLACE_ME&visitor_id={external}'
    },
    imgSrc: 'trafficsources/clickadu.png'
  },
  {
    id: 'traffic_source_31',
    name: 'HasTraffic',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '{cid}' },
      external: { name: 'external', value: '{clickid}' },
      c1: { name: 'category', value: '{category}' },
      c2: { name: 'keyword', value: '{keyword}' },
      c3: { name: 'sid', value: '{sid}' },
      c4: { name: 'target', value: '{target}' }
    },
    typeCost: 'cpe',
    defaultCost: '{cpv}',
    trackingType: 'postbackURL',
    tracking: {
      code: 'https://hastraffic.com/postback/?clickid={external}'
    },
    imgSrc: 'trafficsources/hastraffic.png'
  },
  {
    id: 'traffic_source_32',
    name: 'Advertizer',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '[[campaign_id]]' },
      external: { name: 'external', value: '[[subid]]' },
      c1: { name: 'partner', value: '[[partner-id]]' },
      c2: { name: 'pid', value: '[[pid]]' },
      c3: { name: 'creative', value: '[[creative_id]]' },
      c4: { name: 'push', value: '[[pushid]]' },
      c5: { name: 'app', value: '[[app_name]]' }
    },
    typeCost: 'cpe',
    defaultCost: '[[click_cost]]',
    trackingType: 'postbackURL',
    tracking: {
      code: 'https://postback.advertizer.com/pb.php?clickid={external}&amount={payout}&advertiser_id=REPLACE_ME&key=REPLACE_ME'
    },
    imgSrc: 'trafficsources/advertizer.png'
  },
  {
    id: 'traffic_source_33',
    name: 'Adskeeper',
    trackingFieldSlots: {
      campaign: { name: 'utm_content', value: '', previousName: 'campaign' },
      external: { name: 'adclid', value: '', previousName: 'external' },
      c1: { name: 'widget', value: '{widget_id}' },
      c2: { name: 'teaser', value: '{teaser_id}' },
      c3: { name: 'category', value: '{category_id}' }
    },
    typeCost: 'cpe',
    defaultCost: '',
    trackingType: 'postbackURL',
    tracking: {
      code: 'https://a.adskeeper.co.uk/postback?c={external}&r={payout}&e={txid}'
    },
    imgSrc: 'trafficsources/adskeeper.png'
  },
  {
    id: 'traffic_source_34',
    name: 'AdMaven',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '{campaign}' },
      external: { name: 'external', value: '{conversion}' },
      c1: { name: 'site_id', value: '{pubfeed}-{subid}' },
      c2: { name: 'carrier', value: '{carrier}' },
      c3: { name: 'ad_id', value: '{banner}' },
      c4: { name: 'keyword', value: '{keyword}' },
      c5: { name: 'query', value: '{query}' }
    },
    typeCost: 'cpe',
    defaultCost: '{bid}',
    trackingType: 'postbackURL',
    tracking: {
      code: 'https://pop.rtb-passthrough.com/conversion?c={external}&value={payout}'
    },
    imgSrc: 'trafficsources/admaven.png'
  },
  {
    id: 'traffic_source_35',
    name: 'RichAds - Push',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '[CAMPAIGN_ID]' },
      external: { name: 'external', value: '[CLICK_ID]' },
      c1: { name: 'creative', value: '[CREATIVE_ID]' },
      c2: { name: 'pub', value: '[PUBLISHER_ID]' },
      c3: { name: 'site_id', value: '[SITE_ID]' },
      c4: { name: 'sublist', value: '[SUB_LIST_ID]' },
      c5: { name: 'os', value: '[OS]' }
    },
    typeCost: 'cpe',
    defaultCost: '[BID_PRICE]',
    trackingType: 'postbackURL',
    tracking: {
      code: 'https://xml.auxml.com/log?action=conversion&key={external}&price={payout}'
    },
    imgSrc: 'trafficsources/richads-push.png'
  },
  {
    id: 'traffic_source_36',
    name: 'Outbrain',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '{{campaign_id}}' },
      external: { name: 'external', value: '{{ob_click_id}}' },
      c1: { name: 'ad_id', value: '{{ad_id}}' },
      c2: { name: 'pub_id', value: '{{publisher_id}}' },
      c3: { name: 'pub_name', value: '{{publisher_name}}' },
      c4: { name: 'section_id', value: '{{section_id}}' },
      c5: { name: 'section_name', value: '{{section_name}}' }
    },
    typeCost: 'cpe',
    defaultCost: '',
    trackingType: 'postbackURL',
    tracking: {
      code: 'https://tr.outbrain.com/unifiedPixel?ob_click_id={external}&name=EVENT_NAME&orderId={txid}&orderValue={payout}'
    },
    imgSrc: 'trafficsources/outbrain.png'
  },
  {
    id: 'traffic_source_37',
    name: 'Exoclick',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '{campaign_id}' },
      external: { name: 'external', value: '{conversions_tracking}' },
      c1: { name: 'category', value: '{category_id}' },
      c2: { name: 'format', value: '{format}' },
      c3: { name: 'pricing_model', value: '{pricing_model}' },
      c4: { name: 'site_id', value: '{site_id}' },
      c5: { name: 'hostname', value: '{src_hostname}' },
      c6: { name: 'variation', value: '{variation_id}' },
      c7: { name: 'zone_id', value: '{zone_id}' }
    },
    typeCost: 'cpe',
    defaultCost: '{actual_cost}',
    trackingType: 'postbackURL',
    tracking: {
      code: 'https://main.exoclick.com/tag.php?goal=REPLACE&tag={external}'
    },
    imgSrc: 'trafficsources/exoclick.svg'
  },
  {
    id: 'traffic_source_38',
    name: 'Traffic Nomads',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '{campaignid}' },
      external: { name: 'external', value: '{clickid}' },
      c1: { name: 'pub', value: '{pubid}' },
      c2: { name: 'zone', value: '{zone}' },
      c3: { name: 'device', value: '{device}' },
      c4: { name: 'country', value: '{country}' }
    },
    typeCost: 'cpe',
    defaultCost: '',
    trackingType: 'postbackURL',
    tracking: {
      code: 'https://node2.trafficnomads.com/conv?clickid={external}&payout={payout}&pubid={data-pub}'
    },
    imgSrc: 'trafficsources/trafficnomads.png'
  },
  {
    id: 'traffic_source_39',
    name: 'RichAds - Pops',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '[CAMPAIGN_ID]' },
      external: { name: 'external', value: '[CLICK_ID]' },
      c1: { name: 'creative', value: '[CREATIVE_ID]' },
      c2: { name: 'zone', value: '[ZONE_ID]' },
      c3: { name: 'site_id', value: '[SITE_ID]' },
      c4: { name: 'pub', value: '[PUBLISHER_ID]' },
      c5: { name: 'os', value: '[OS]' }
    },
    typeCost: 'cpe',
    defaultCost: '[CPV_PRICE]',
    trackingType: 'postbackURL',
    tracking: {
      code: 'https://xml.auxml.com/log?action=conversion&key={external}&price={payout}'
    },
    imgSrc: 'trafficsources/richads-pops.png'
  },
  {
    id: 'traffic_source_40',
    name: 'ADxAD',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '{campaignId}' },
      external: { name: 'external', value: '' },
      c1: { name: 'creative', value: '{creativeId}' },
      c2: { name: 'project', value: '{projectId}' },
      c3: { name: 'partner', value: '{partnerId}' },
      c4: { name: 'site_id', value: '{siteId}' },
      c5: { name: 'user', value: '{userId}' },
      c6: { name: 'device', value: '{device}' }
    },
    typeCost: 'cpe',
    defaultCost: '{bid}',
    trackingType: 'none',
    tracking: {
      code: ''
    },
    imgSrc: 'trafficsources/adxad.svg'
  },
  {
    id: 'traffic_source_41',
    name: 'Adoperator',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '{campaign}' },
      external: { name: 'external', value: '{conversion}' },
      c1: { name: 'subid', value: '{pubid}' },
      c2: { name: 'country', value: '{country}' },
      c3: { name: 'browser', value: '{browser}' },
      c4: { name: 'os', value: '{os}' }
    },
    typeCost: 'cpe',
    defaultCost: '',
    trackingType: 'postbackURL',
    tracking: {
      code: 'https://xml.adop.co/conversion?id=REPLACE_ME&c={external}&count=1&value={payout}'
    },
    imgSrc: 'trafficsources/adoperator.png'
  },
  {
    id: 'traffic_source_42',
    name: 'AdHub',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '[camp_id]' },
      external: { name: 'external', value: '[click_id]' },
      c1: { name: 'adid', value: '[ad_id]' },
      c2: { name: 'site_id', value: '[site_id]' },
      c3: { name: 'country', value: '[country]' }
    },
    typeCost: 'cpe',
    defaultCost: '[bid]',
    trackingType: 'postbackURL',
    tracking: {
      code: 'https://adhub.pro/postback/?network=funnelflux&click_id={external}&status=approved&profit={payout}&currency=USD'
    },
    imgSrc: 'trafficsources/adhub.svg'
  },
  {
    id: 'traffic_source_43',
    name: 'JuicyAds',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '{dynamicCMP}' },
      external: { name: 'external', value: '{dynamicS2S}' },
      c1: { name: 'zone', value: '{dynamicZNE}' },
      c2: { name: 'pub', value: '{dynamicPUB}' },
      c3: { name: 'site_id', value: '{dynamicSITE}' },
      c4: { name: 'img', value: '{dynamicIMG}' }
    },
    typeCost: 'cpe',
    defaultCost: '',
    trackingType: 'postbackURL',
    tracking: {
      code: 'https://ck.juicyads.com/ilikeitjuicy.php?c=REPLACE&token=REPLACE&data=REPLACE&s2s={external}&amount={payout}'
    },
    imgSrc: 'trafficsources/juicyads.png'
  },
  {
    id: 'traffic_source_44',
    name: 'TikTok',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '__CAMPAIGN_ID__' },
      external: {
        name: 'ttclid',
        value: '__CLICKID__',
        previousName: 'external'
      },
      c1: { name: 'adgroup_id', value: '__AID__' },
      c2: { name: 'creative_id', value: '__CID__' },
      c3: { name: 'placement', value: '__PLACEMENT__' }
    },
    typeCost: 'cpe',
    defaultCost: '',
    trackingType: 'postbackURL',
    conversionTrackingSettings: {
      Conversions: {
        isCustomScenario: true,
        customEventType: 'TikTok',
        customEventData: {},
        postbackType: 'postbackURL',
        postbackURL: ''
      }
    },
    tracking: { code: '' },
    imgSrc: 'trafficsources/tiktok.png'
  },
  {
    id: 'traffic_source_45',
    name: 'Bidvertiser',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '{BV_CAMPID}' },
      external: { name: 'external', value: '{BV_CLICKID}' },
      c1: { name: 'adname', value: '{BV_ADNAME}' },
      c2: { name: 'keyword', value: '{BV_KEYWORD}' },
      c3: { name: 'source_id', value: '{BV_SRCID}' },
      c4: { name: 'category', value: '{BV_CATEGORY}' }
    },
    typeCost: 'cpe',
    defaultCost: '{BV_BID}',
    trackingType: 'postbackURL',
    tracking: {
      code: 'https://secure.bidvertiser.com/performance/pc.dbm?ver=1.0&AID=REPLACE_ME&CLICKID={external}&revenue={payout}'
    },
    imgSrc: 'trafficsources/bidvertiser.png'
  },
  {
    id: 'traffic_source_46',
    name: 'Snapchat',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '{{campaign.id}}' },
      external: { name: 'ScCid', value: '', previousName: 'external' },
      c1: { name: 'ad_id', value: '{{ad.id}}' },
      c2: { name: 'adset_id', value: '{{adSet.id}}' },
      c3: { name: 'creative_name', value: '{{creative.name}}' },
      c4: { name: 'ad_title', value: '{{creative.headline}}' },
      c5: { name: 'site_source', value: '{{site_source_name}}' }
    },
    typeCost: 'cpe',
    defaultCost: '',
    trackingType: 'postbackURL',
    conversionTrackingSettings: {
      Conversions: {
        isCustomScenario: true,
        customEventType: 'Snapchat',
        customEventData: {},
        postbackType: 'postbackURL',
        postbackURL: ''
      }
    },
    tracking: { code: '' },
    imgSrc: 'trafficsources/snapchat.png'
  },
  {
    id: 'traffic_source_47',
    name: 'EZmob',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '{campaign}' },
      external: { name: 'external', value: '{conversion}' },
      c1: { name: 'ad_id', value: '{banner}' },
      c2: { name: 'keyword', value: '{keyword}' },
      c3: { name: 'pubfeed', value: '{pubfeed}' },
      c4: { name: 'pubid', value: '{publisher}' },
      c5: { name: 'pubzone', value: '{pubzone}' },
      c6: { name: 'site_id', value: '{site_id}' }
    },
    typeCost: 'cpe',
    defaultCost: '',
    trackingType: 'postbackURL',
    tracking: {
      code: 'https://xml.ezmob.com/conversion?c={external}&value={payout}'
    },
    imgSrc: 'trafficsources/ezmob.png'
  },
  {
    id: 'traffic_source_48',
    name: 'SourceKnowledge',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '' },
      external: { name: 'external', value: '{ext.bidid}' },
      c1: { name: 'domain', value: '{destDomain}' },
      c2: { name: 'subid', value: '{sub_id}' },
      c3: { name: 'traffictype', value: '{traffic_type}' },
      c4: { name: 'domain', value: '{destDomain}' }
    },
    typeCost: 'cpe',
    defaultCost: '{adv_price}',
    trackingType: 'postbackURL',
    tracking: {
      code: 'https://upx.provenpixel.com/postback.php?clk={external}&order_amount={payout}&order_id={txid}'
    },
    imgSrc: 'trafficsources/sourceknowledge.png'
  },
  {
    id: 'traffic_source_49',
    name: 'Mondiad',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '[campaignid]' },
      external: { name: 'external', value: '[clickid]' },
      c1: { name: 'zone', value: '[zoneid]' },
      c2: { name: 'subid', value: '[subid]' },
      c3: { name: 'creative', value: '[creativeid]' },
      c4: { name: 'ad_format', value: '[adformat]' },
      c5: { name: 'sub_age', value: '[subscriptionage]' }
    },
    typeCost: 'cpe',
    defaultCost: '[bid]',
    trackingType: 'postbackURL',
    tracking: {
      code: 'https://postback.mondiad.com/track?uid=##REPLACE##&clickid={external}&payout={payout}'
    },
    imgSrc: 'trafficsources/mondiad.svg'
  },
  {
    id: 'traffic_source_50',
    name: 'EvaDav',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '{CAMPAIGN_ID}' },
      external: { name: 'external', value: '{CLICKID}' },
      c1: { name: 'creative', value: '{CREATIVE_ID}' },
      c2: { name: 'format', value: '{FORMAT}' },
      c3: { name: 'source_id', value: '{SOURCE_ID}' },
      c4: { name: 'sub_period', value: '{SUB_PERIOD}' },
      c5: { name: 'zone_id', value: '{ZONE_ID}' }
    },
    typeCost: 'cpe',
    defaultCost: '{COST}',
    trackingType: 'postbackURL',
    tracking: {
      code: 'https://evadav.com/phpb?click_id={external}&payout={payout}'
    },
    imgSrc: 'trafficsources/evadav.svg'
  },
  {
    id: 'traffic_source_51',
    name: 'Traforama',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '%CAMPAIGNID%' },
      external: { name: 'external', value: '%ID%' },
      c1: { name: 'spot_id', value: '%SPOTID%' },
      c2: { name: 'keyword', value: '%KW%' },
      c3: { name: 'ref_domain', value: '%REFDOMAIN%' },
      c4: { name: 'creative', value: '%CREATIVEID%' }
    },
    typeCost: 'cpe',
    defaultCost: '',
    trackingType: 'postbackURL',
    tracking: {
      code: 'https://p.pa5ka.com/api/click?suid=REPLACE&c=1&cpa={payout}&id={external}'
    },
    imgSrc: 'trafficsources/traforama.svg'
  },
  {
    id: 'traffic_source_52',
    name: 'Youtube',
    trackingFieldSlots: {
      campaign: {
        name: 'utm_campaign',
        value: '{campaignid}',
        previousName: 'campaign'
      },
      external: { name: 'gclid', value: '', previousName: 'external' },
      c1: { name: 'adgroup', value: '{adgroupid}' },
      c2: { name: 'ad', value: '{creative}' },
      c3: { name: 'matchtype', value: '{matchtype}' },
      c4: { name: 'network', value: '{network}' },
      c5: { name: 'kw', value: '{keyword}' },
      c6: { name: 'placement', value: '{placement}' },
      c7: { name: 'target', value: '{target}' },
      c8: { name: 'adposition', value: '{adposition}' },
      c9: { name: 'gbraid', value: '' },
      c10: { name: 'wbraid', value: '' }
    },
    typeCost: 'cpe',
    defaultCost: '',
    trackingType: 'postbackURL',
    conversionTrackingSettings: {
      Conversions: {
        isCustomScenario: true,
        customEventType: 'GoogleAds',
        customEventData: {},
        postbackType: 'postbackURL',
        postbackURL: ''
      }
    },
    tracking: { code: '' },
    imgSrc: 'trafficsources/youtube.png'
  },
  {
    id: 'traffic_source_53',
    name: 'Twitter / X',
    trackingFieldSlots: {
      external: { name: 'twclid', value: '', previousName: 'external' }
    },
    typeCost: 'cpe',
    defaultCost: '',
    trackingType: 'postbackURL',
    conversionTrackingSettings: {
      Conversions: {
        isCustomScenario: true,
        customEventType: 'Twitter',
        customEventData: {},
        postbackType: 'postbackURL',
        postbackURL: ''
      }
    },
    tracking: { code: '' },
    imgSrc: 'trafficsources/twitter.png'
  },
  {
    id: 'traffic_source_54',
    name: 'Kwai',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '' },
      external: { name: 'click_id', value: '', previousName: 'external' },
      c1: { name: 'ad_id', value: '' }
    },
    typeCost: 'cpe',
    defaultCost: '',
    trackingType: 'postbackURL',
    conversionTrackingSettings: {
      Conversions: {
        isCustomScenario: true,
        customEventType: 'Kwai',
        customEventData: {},
        postbackType: 'postbackURL',
        postbackURL: ''
      }
    },
    tracking: { code: '' },
    imgSrc: 'trafficsources/kwai.png'
  },
  {
    id: 'traffic_source_55',
    name: 'Galaksion',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '{cid}' },
      external: { name: 'external', value: '[:click_id]' },
      c1: { name: 'source', value: '{zoneid}' }
    },
    typeCost: 'cpe',
    defaultCost: '{cost}',
    trackingType: 'postbackURL',
    tracking: {
      code: 'https://postback.report/postback?cid=REPLACE&click_id={external}'
    },
    imgSrc: 'trafficsources/galaksion.png'
  },
  {
    id: 'traffic_source_56',
    name: 'Reddit',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '{{CAMPAIGN_ID}}' },
      external: { name: 'rdt_cid', value: '{{CLICK_ID}}', previousName: 'external' },
      c1: { name: 'account_id', value: '{{ADVERTISER_ID}}' },
      c2: { name: 'ad_id', value: '{{AD_ID}}' },
      c3: { name: 'adgroup_id', value: '{{ADGROUP_ID}}' },
      c4: { name: 'device_group', value: '{{DEVICE_GROUP}}' },
      c5: { name: 'post_id', value: '{{POST_ID}}' },
      c6: { name: 'opt_out', value: '{{DEVICE_OPT_OUT}}' }
    },
    typeCost: 'cpe',
    defaultCost: '',
    trackingType: 'postbackURL',
    conversionTrackingSettings: {
      Conversions: {
        isCustomScenario: true,
        customEventType: 'Reddit',
        customEventData: {},
        postbackType: 'postbackURL',
        postbackURL: ''
      }
    },
    tracking: { code: '' },
    imgSrc: 'trafficsources/reddit.png'
  },
  {
    id: 'traffic_source_57',
    name: 'ROI Ads',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '{campaign_id}' },
      external: { name: 'external', value: '{click_id}' },
      c1: { name: 'publisher', value: '{publisher_id}' },
      c2: { name: 'site', value: '{site_id}' },
      c3: { name: 'zone', value: '{zoneid}' },
      c4: { name: 'creative', value: '{creative_id}' },
      c5: { name: 'source_type', value: '{source_type}' },
    },
    typeCost: 'cpe',
    defaultCost: '{bid_price}',
    trackingType: 'postbackURL',
    tracking: {
      code:
        'https://us.roiapp.net/roiconv?action=conversion&id={external}'
    },
    imgSrc: 'trafficsources/roi-ads.png'
  },
  {
    id: 'traffic_source_58',
    name: 'Kadam',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '{campaign_id}' },
      external: { name: 'external', value: '{click_id}' },
      c1: { name: 'site_id', value: '{site_id}' },
      c2: { name: 'ad_id', value: '{ad_id}' },
      c3: { name: 'page_cat_id', value: '{page_cat_id}' },
      c4: { name: 'sub_age', value: '{sub_age}' },
    },
    typeCost: 'cpe',
    defaultCost: '',
    trackingType: 'postbackURL',
    tracking: {
      code:
        'https://kdtrk.net/ru/postback/?data={external}'
    },
    imgSrc: 'trafficsources/kadam.svg'
  },
  {
    id: 'traffic_source_59',
    name: 'Adsterra',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '##CAMPAIGN_ID##' },
      external: { name: 'external', value: '##SUB_ID_SHORT(action)##' },
      c1: { name: 'placement', value: '##PLACEMENT_ID##' },
      c2: { name: 'advertiser', value: '##ADVERTISER_ID##' },
      c3: { name: 'publisher', value: '##PUBLISHER_ID##' },
      c4: { name: 'zone', value: '##ZONE_ID##' },
      c5: { name: 'banner', value: '##BANNER_ID##' }
    },
    typeCost: 'cpe',
    defaultCost: '##COST_CPC##',
    trackingType: 'postbackURL',
    tracking: {
      code:
        'https://www.pbterra.com/name/REPLACE/at?subid_short={external}&atpay={payout}'
    },
    imgSrc: 'trafficsources/adsterra.svg'
  },
  {
    id: 'traffic_source_60',
    name: 'Bigo Ads',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '__CAMPAIGN_ID__' },
      external: { name: 'external', value: '__BBG__' },
      c1: { name: 'adgroup_id', value: '__AD_GROUP_ID__' },
      c2: { name: 'ad_id', value: '__AD_ID__' },
      c3: { name: 'pub_id', value: '__PUBLISHER_ID__' },
      c4: { name: 'sub_pub_id', value: '__SUB_PUBLISHER_ID__' },   
    },
    typeCost: 'cpe',
    defaultCost: '',
    trackingType: 'postbackURL',
    conversionTrackingSettings: {
      Conversions: {
        isCustomScenario: true,
        customEventType: 'Bigo',
        customEventData: {},
        postbackType: 'postbackURL'
      }
    },
    tracking: { code: '' },
    imgSrc: 'trafficsources/bigoads.png'
  },
  {
    id: 'traffic_source_61',
    name: 'Pinterest',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '{campaignid}' },
      external: { name: 'external', value: '' },
      c1: { name: 'adgroup', value: '{adgroupid}' },
      c2: { name: 'ad', value: '{adid}' },
      c3: { name: 'creative', value: '{creative_id}' },
      c4: { name: 'device', value: '{device}' },
      c5: { name: 'product', value: '{product_id}' },
      c6: { name: 'product_group', value: '{product_partition_id}' }
    },
    typeCost: 'cpe',
    defaultCost: '',
    trackingType: 'postbackURL',
    tracking: {
      code:
        ''
    },
    imgSrc: 'trafficsources/pinterest.svg'
  },
  {
    id: 'traffic_source_62',
    name: 'NewsBreak',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '__CAMPAIGN_ID__' },
      external: { name: 'external', value: '__CALLBACK_PARAM__' },
      c1: { name: 'adset', value: '__FLIGHT_ID__' },
      c2: { name: 'creative', value: '__CREATIVE_ID__' },
    },
    typeCost: 'cpe',
    defaultCost: '',
    trackingType: 'postbackURL',
    tracking: {
      code:
        'https://business.newsbreak.com/tracking/attribute?callback={external}&nb_value={payout}&event_type=REPLACE'
    },
    imgSrc: 'trafficsources/newsbreak.png'
  },
  {
    id: 'traffic_source_63',
    name: 'Push.House',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '{camp}' },
      external: { name: 'external', value: '{click_id}' },
      c1: { name: 'site_id', value: '{site}' },
      c2: { name: 'subscriber_id', value: '{pdpid}' },
      c3: { name: 'feed_id', value: '{feed}' },
      c4: { name: 'ad_format', value: '{format}' },
      c5: { name: 'sub_activity', value: '{user_activity}' }
    },
    typeCost: 'cpe',
    defaultCost: '{price}',
    trackingType: 'postbackURL',
    tracking: {
      code: 'https://api.push.house/v1/postback?click_id={external}&cost={payout}'
    },
    imgSrc: 'trafficsources/pushhouse.svg'
  },
  {
    id: 'traffic_source_64',
    name: 'FatAds',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '[campaign_id]' },
      external: { name: 'external', value: '[click_id]' },
      c1: { name: 'creative', value: '[creative_id]' },
      c2: { name: 'site', value: '[site_id]' },
      c3: { name: 'pub', value: '[pub_id]' },
    },
    typeCost: 'cpe',
    defaultCost: '[click_cost]',
    trackingType: 'postbackURL',
    tracking: {
      code:
        'https://us.getclear.online/fatconv?id={external}&action=conversion'
    },
    imgSrc: 'trafficsources/fatads.png'
  },
  {
    id: 'traffic_source_65',
    name: 'TrafficJunky',
    trackingFieldSlots: {
      campaign: { name: 'campaign', value: '{CampaignID}' },
      external: { name: 'external', value: '{ACLID}' },
      c1: { name: 'site', value: '{SiteName}' },
      c2: { name: 'spot', value: '{SpotID}' },
      c3: { name: 'banner', value: '{BanID}' },
      c4: { name: 'ad', value: '{AdID}' },
      c5: { name: 'key', value: '{Keywords}' },      
    },
    typeCost: 'cpe',
    defaultCost: '',
    trackingType: 'postbackURL',
    tracking: {
      code:
        'https://ads.trafficjunky.net/ct?a=REPLACE_ME&member_id=1002901651&cb={timestamp}&cti={txid}&ctv={payout}&ctd=CONVERSION_DESCRIPTION&aclid={external}'
    },
    imgSrc: 'trafficsources/trafficjunky.png'
  },   
];

export const OFFER_SOURCE_TEMPLATES: OfferSourceTemplate[] = [
  {
    id: 'offer_source_1',
    name: 'CAKE Platform',
    dataPassingFields: {
      s1: '{funnel-id}',
      s2: '{trafficsource-id}',
      s3: '{campaign}',
      s5: '{hit}'
    },
    tracking: {
      postbackSubId: '#s5#',
      postbackTxId: '#tid#',
      postbackPayout: '#price#'
    },
    imgSrc: 'offersources/cake.png'
  },
  {
    id: 'offer_source_2',
    name: 'HasOffers Platform',
    dataPassingFields: {
      aff_sub: '{funnel-id}',
      aff_sub2: '{trafficsource-id}',
      aff_sub3: '{campaign}',
      aff_sub5: '{hit}'
    },
    tracking: {
      postbackSubId: '{aff_sub5}',
      postbackTxId: '{transaction_id}',
      postbackPayout: '{payout}'
    },
    imgSrc: 'offersources/hasoffers.png'
  },
  {
    id: 'offer_source_3',
    name: 'HitPath Platform',
    dataPassingFields: {
      c1: '{funnel-id}',
      c2: '{trafficsource-id}',
      c3: '{campaign}',
      c5: '{hit}'
    },
    tracking: {
      postbackSubId: 'xxc5xx',
      postbackTxId: 'xxtransidxx',
      postbackPayout: 'xxpayoutxx'
    },
    imgSrc: 'offersources/hitpath.png'
  },
  {
    id: 'offer_source_4',
    name: 'LinkTrust Platform',
    dataPassingFields: {
      sid: '{hit}'
    },
    tracking: {
      postbackSubId: '[=SID=]',
      postbackTxId: '[=_TransID=]',
      postbackPayout: '[=AffiliateCommission=]'
    },
    imgSrc: 'offersources/linktrust.png'
  },
  {
    id: 'offer_source_5',
    name: 'A4D',
    dataPassingFields: {
      aff_sub: '{funnel-id}',
      aff_sub2: '{trafficsource-id}',
      aff_sub3: '{campaign}',
      aff_sub5: '{hit}'
    },
    tracking: {
      postbackSubId: '{aff_sub5}',
      postbackTxId: '{transaction_id}',
      postbackPayout: '{payout}'
    },
    imgSrc: 'offersources/a4d.png'
  },
  {
    id: 'offer_source_6',
    name: 'ActionAds',
    dataPassingFields: {
      aff_sub: '{funnel-id}',
      aff_sub2: '{trafficsource-id}',
      aff_sub3: '{campaign}',
      aff_sub5: '{hit}'
    },
    tracking: {
      postbackSubId: '{aff_sub5}',
      postbackTxId: '{transaction_id}',
      postbackPayout: '{payout}'
    },
    imgSrc: 'offersources/actionads.png'
  },
  {
    id: 'offer_source_7',
    name: 'Addiliate',
    dataPassingFields: {
      add1: '{funnel-id}',
      add2: '{trafficsource-id}',
      add3: '{campaign}',
      add5: '{hit}'
    },
    tracking: {
      postbackSubId: '%add5%',
      postbackTxId: '%txid%',
      postbackPayout: '%amount%'
    },
    imgSrc: 'offersources/addiliate.png'
  },
  {
    id: 'offer_source_9',
    name: 'Adsup',
    dataPassingFields: {
      aff_sub: '{funnel-id}',
      aff_sub2: '{trafficsource-id}',
      aff_sub3: '{campaign}',
      aff_sub5: '{hit}'
    },
    tracking: {
      postbackSubId: '{aff_sub5}',
      postbackTxId: '{transaction_id}',
      postbackPayout: '{payout}'
    },
    imgSrc: 'offersources/adsup.png'
  },
  {
    id: 'offer_source_10',
    name: 'Affiliaxe',
    dataPassingFields: {
      aff_sub: '{funnel-id}',
      aff_sub2: '{trafficsource-id}',
      aff_sub3: '{campaign}',
      aff_sub5: '{hit}'
    },
    tracking: {
      postbackSubId: '{aff_sub5}',
      postbackTxId: '{transaction_id}',
      postbackPayout: '{payout}'
    },
    imgSrc: 'offersources/affiliaxe.png'
  },
  {
    id: 'offer_source_13',
    name: 'Cash Network',
    dataPassingFields: {
      s1: '{funnel-id}',
      s2: '{trafficsource-id}',
      s3: '{campaign}',
      s5: '{hit}'
    },
    tracking: {
      postbackSubId: '#s5#',
      postbackTxId: '#tid#',
      postbackPayout: '#price#'
    },
    imgSrc: 'offersources/cashnetwork.png'
  },
  {
    id: 'offer_source_14',
    name: 'CJ (Commission Junction)',
    dataPassingFields: {
      sid: '{hit}'
    },
    tracking: {
      postbackSubId: '',
      postbackTxId: '',
      postbackPayout: ''
    },
    imgSrc: 'offersources/cj.png'
  },
  {
    id: 'offer_source_15',
    name: 'Clickbank (Affiliate)',
    dataPassingFields: {
      extclid: '{hit}',
      campaign: '{campaign}',
      traffic_source: '{trafficsource-id}',
      aff_sub1: '{funnel-id}',
      aff_sub2: '{trafficsource-id}',
    },
    tracking: {
      postbackSubId: '{ext_clid}',
      postbackTxId: '{event_id}',
      postbackPayout: '{affiliate_earnings}'
    },
    imgSrc: 'offersources/clickbank-affiliate.png'
  },
  {
    id: 'offer_source_16',
    name: 'Perform[cb]',
    dataPassingFields: {
      subid1: '{funnel-id}-{campaign}',
      subid2: '{trafficsource-id}',
      subid3: '{campaign}',
      subid5: '{hit}'
    },
    tracking: {
      postbackSubId: '{subid5}',
      postbackTxId: '{transid}',
      postbackPayout: '{payout}'
    },
    imgSrc: 'offersources/performcb.png'
  },
  {
    id: 'offer_source_17',
    name: 'Clickdealer',
    dataPassingFields: {
      s1: '{funnel-id}',
      s2: '{trafficsource-id}',
      s3: '{campaign}',
      s5: '{hit}'
    },
    tracking: {
      postbackSubId: '#s5#',
      postbackTxId: '#tid#',
      postbackPayout: '#price#'
    },
    imgSrc: 'offersources/clickdealer.png'
  },
  {
    id: 'offer_source_19',
    name: 'Convert2Media',
    dataPassingFields: {
      s1: '{funnel-id}',
      s2: '{trafficsource-id}',
      s3: '{campaign}',
      s5: '{hit}'
    },
    tracking: {
      postbackSubId: '#s5#',
      postbackTxId: '#tid#',
      postbackPayout: '#price#'
    },
    imgSrc: 'offersources/c2m.svg'
  },
  {
    id: 'offer_source_21',
    name: 'Everad',
    dataPassingFields: {
      sid1: '{funnel-id}',
      sid2: '{trafficsource-id}',
      sid3: '{campaign}',
      sid5: '{hit}'
    },
    tracking: {
      postbackSubId: '{sid1}',
      postbackTxId: '{id}-{status}',
      postbackPayout: '{payout}'
    },
    imgSrc: 'offersources/everad.png'
  },
  {
    id: 'offer_source_22',
    name: 'Everflow',
    dataPassingFields: {
      sub1: '{funnel-id}',
      sub2: '{trafficsource-id}',
      sub3: '{campaign}',
      sub5: '{hit}'
    },
    tracking: {
      postbackSubId: '{sub5}',
      postbackTxId: '{transaction_id}',
      postbackPayout: '{payout_amount}'
    },
    imgSrc: 'offersources/everflow.png'
  },
  {
    id: 'offer_source_24',
    name: 'GlobalWide Media',
    dataPassingFields: {
      s1: '{funnel-id}',
      s2: '{trafficsource-id}',
      s3: '{campaign}',
      s5: '{hit}'
    },
    tracking: {
      postbackSubId: '#s5#',
      postbackTxId: '#tid#',
      postbackPayout: '#price#'
    },
    imgSrc: 'offersources/globalwide.png'
  },
  {
    id: 'offer_source_25',
    name: 'JumbleBerry',
    dataPassingFields: {
      c1: '{funnel-id}',
      c2: '{trafficsource-id}',
      c3: '{campaign}',
      c5: '{hit}'
    },
    tracking: {
      postbackSubId: 'xxc5xx',
      postbackTxId: 'xxtransidxx',
      postbackPayout: 'xxpayoutxx'
    },
    imgSrc: 'offersources/jumbleberry.svg'
  },
  {
    id: 'offer_source_27',
    name: 'MaxBounty',
    dataPassingFields: {
      s1: '{funnel-id}',
      s2: '{trafficsource-id}',
      s3: '{campaign}',
      s5: '{hit}'
    },
    tracking: {
      postbackSubId: '#S5#',
      postbackTxId: '#OFFID#',
      postbackPayout: '#RATE#'
    },
    imgSrc: 'offersources/maxbounty.png'
  },
  {
    id: 'offer_source_28',
    name: 'Mobidea',
    dataPassingFields: {
      tag: '{hit}',
      data1: '{funnel-id}',
      data2: '{campaign}',
      website: '{trafficsource-id}'
    },
    tracking: {
      postbackSubId: '{{EXTERNAL_ID}}',
      postbackTxId: '',
      postbackPayout: '{{MONEY}}'
    },
    imgSrc: 'offersources/mobidea.png'
  },
  {
    id: 'offer_source_30',
    name: 'Mobileader',
    dataPassingFields: {
      aff_sub: '{funnel-id}',
      aff_sub2: '{trafficsource-id}',
      aff_sub3: '{campaign}',
      aff_sub5: '{hit}'
    },
    tracking: {
      postbackSubId: '{aff_sub5}',
      postbackTxId: '{transaction_id}',
      postbackPayout: '{payout}'
    },
    imgSrc: 'offersources/mobileader.svg'
  },
  {
    id: 'offer_source_31',
    name: 'MobVista',
    dataPassingFields: {
      aff_sub: '{funnel-id}',
      aff_sub2: '{trafficsource-id}',
      aff_sub3: '{campaign}',
      aff_sub5: '{hit}'
    },
    tracking: {
      postbackSubId: '{aff_sub5}',
      postbackTxId: '{transaction_id}',
      postbackPayout: '{payout}'
    },
    imgSrc: 'offersources/mobvista.svg'
  },
  {
    id: 'offer_source_34',
    name: 'Runative',
    dataPassingFields: {
      aff_sub: '{funnel-id}',
      aff_sub2: '{trafficsource-id}',
      aff_sub3: '{campaign}',
      aff_sub5: '{hit}'
    },
    tracking: {
      postbackSubId: '{aff_sub5}',
      postbackTxId: '{transaction_id}',
      postbackPayout: '{payout}'
    },
    imgSrc: 'offersources/runnative.svg'
  },
  {
    id: 'offer_source_35',
    name: 'LemonAds',
    dataPassingFields: {
      clickid: '{hit}'
    },
    tracking: {
      postbackSubId: '[lemonads_clickid]',
      postbackTxId: '',
      postbackPayout: '[lemonads_payout_dot]'
    },
    imgSrc: 'offersources/lemonads.png'
  },
  {
    id: 'offer_source_37',
    name: 'DMS / W4',
    dataPassingFields: {
      c1: '{funnel-id}',
      c2: '{trafficsource-id}',
      c3: '{campaign}',
      c5: '{hit}'
    },
    tracking: {
      postbackSubId: 'xxc5xx',
      postbackTxId: 'xxtransidxx',
      postbackPayout: 'xxpayoutxx'
    },
    imgSrc: 'offersources/dmsw4.jpg'
  },
  {
    id: 'offer_source_38',
    name: 'XY7Elite',
    dataPassingFields: {
      sid: '{hit}'
    },
    tracking: {
      postbackSubId: '[=SID=]',
      postbackTxId: '[=_TransID=]',
      postbackPayout: '[=AffiliateCommission=]'
    },
    imgSrc: 'offersources/xy7.png'
  },
  {
    id: 'offer_source_39',
    name: 'Yeahmobi',
    dataPassingFields: {
      aff_sub: '{funnel-id}',
      aff_sub2: '{trafficsource-id}',
      aff_sub3: '{campaign}',
      aff_sub5: '{hit}'
    },
    tracking: {
      postbackSubId: '{aff_sub5}',
      postbackTxId: '{transaction_id}',
      postbackPayout: '{payout}'
    },
    imgSrc: 'offersources/yeahmobi.svg'
  },
  {
    id: 'offer_source_40',
    name: 'Clickbank (Vendor)',
    dataPassingFields: {
      vid: '{hit}'
    },
    tracking: {
      postbackSubId: '',
      postbackTxId: '',
      postbackPayout: ''
    },
    imgSrc: 'offersources/clickbank-vendor.png'
  },
  {
    id: 'offer_source_41',
    name: 'Cryp.im',
    dataPassingFields: {
      sub1: '{funnel-id}',
      sub2: '{trafficsource-id}',
      sub3: '{connection-type}',
      click_id: '{hit}'
    },
    tracking: {
      postbackSubId: '{click_id}',
      postbackTxId: '{status}',
      postbackPayout: '{payout}'
    },
    imgSrc: 'offersources/crypim.png'
  },
  {
    id: 'offer_source_42',
    name: 'Gasmobi',
    dataPassingFields: {
      subid: '{funnel-id}',
      sub_pubid: '{trafficsource-id}',
      externalid: '{hit}'
    },
    tracking: {
      postbackSubId: '{externalid}',
      postbackTxId: '{tx_id}',
      postbackPayout: '{payout}'
    },
    imgSrc: 'offersources/gasmobi.png'
  },
  {
    id: 'offer_source_43',
    name: 'AdStart Media',
    dataPassingFields: {
      ref_id: '{hit}',
      sub1: '{funnel-id}',
      sub2: '{trafficsource-id}',
      sub3: '{campaign}'
    },
    tracking: {
      postbackSubId: '{ref_id}',
      postbackTxId: '{transactionid}',
      postbackPayout: '{sum}'
    },
    imgSrc: 'offersources/adstart.png'
  },
  {
    id: 'offer_source_44',
    name: 'Gotzha',
    dataPassingFields: {
      s1: '{funnel-id}',
      s2: '{trafficsource-id}',
      s3: '{campaign}',
      s5: '{hit}'
    },
    tracking: {
      postbackSubId: '#s5#',
      postbackTxId: '#tid#',
      postbackPayout: '#price#'
    },
    imgSrc: 'offersources/gotzha.svg'
  },
  {
    id: 'offer_source_45',
    name: 'Adcombo',
    dataPassingFields: {
      clickid: '{hit}',
      subacc: '{funnel-id}',
      subacc2: '{trafficsource-id}',
      subacc3: '{campaign}'
    },
    tracking: {
      postbackSubId: '{clickid}',
      postbackTxId: '{trans_id}',
      postbackPayout: '{revenue}'
    },
    imgSrc: 'offersources/adcombo.svg'
  },
  {
    id: 'offer_source_46',
    name: 'Haka',
    dataPassingFields: {
      aid: '{campaign}',
      keyword: '{funnel-id}',
      keyword2: '{trafficsource-id}',
      keyword3: '{hit}'
    },
    tracking: {
      postbackSubId: '{keyword3}',
      postbackTxId: '',
      postbackPayout: '{payout}'
    },
    imgSrc: 'offersources/haka.png'
  },
  {
    id: 'offer_source_47',
    name: 'Gamesvid',
    dataPassingFields: {
      aff_sub: '{funnel-id}',
      aff_sub2: '{trafficsource-id}',
      aff_sub3: '{campaign}',
      aff_sub5: '{hit}'
    },
    tracking: {
      postbackSubId: '{aff_sub5}',
      postbackTxId: '{transaction_id}',
      postbackPayout: '{payout}'
    },
    imgSrc: 'offersources/gamesvid.png'
  },
  {
    id: 'offer_source_48',
    name: 'Monetizer',
    dataPassingFields: {
      1: '{funnel-id}',
      2: '{trafficsource-id}',
      3: '{campaign}',
      cid: '{hit}'
    },
    tracking: {
      postbackSubId: '{clickID}',
      postbackTxId: '{transactionID}',
      postbackPayout: '{amount}'
    },
    imgSrc: 'offersources/monetizer.png'
  },
  {
    id: 'offer_source_49',
    name: 'Maxweb',
    dataPassingFields: {
      subid: '{trafficsource-id}',
      subid2: '{funnel-id}',
      subid3: '{campaign}',
      subid5: '{hit}'
    },
    tracking: {
      postbackSubId: '{SUBID5}',
      postbackTxId: '{ORDERID}',
      postbackPayout: '{COMMISSION_AMOUNT}'
    },
    imgSrc: 'offersources/maxweb.png'
  },
  {
    id: 'offer_source_50',
    name: 'Digistore24',
    dataPassingFields: {
      cid: '{hit}',
      sid1: '{funnel-id}',
      sid2: '{campaign}',
      sid3: '{trafficsource-id}'
    },
    tracking: {
      postbackSubId: '{cid}',
      postbackTxId: '{order_id}',
      postbackPayout: '{amount_affiliate}'
    },
    imgSrc: 'offersources/digistore24.png'
  },
  {
    id: 'offer_source_51',
    name: 'Zeydoo',
    dataPassingFields: {
      var: '{campaign}',
      ymid: '{hit}'
    },
    tracking: {
      postbackSubId: '{ymid}',
      postbackTxId: '',
      postbackPayout: '{amount}'
    },
    imgSrc: 'offersources/zeydoo.png'
  },
  {
    id: 'offer_source_52',
    name: 'BuyGoods',
    dataPassingFields: {
      subid: '{trafficsource-name}_{campaign}',
      subid2: '{funnel-id}',
      subid3: '{hit}'
    },
    tracking: {
      postbackSubId: '{SUBID3}',
      postbackTxId: '{ORDERID}',
      postbackPayout: '{COMMISSION_AMOUNT}'
    },
    imgSrc: 'offersources/buygoods.png'
  },
  {
    id: 'offer_source_53',
    name: 'System1',
    dataPassingFields: {
      compkey: '{data-keyword}',
      ref: '{referrer}',
      rskey: '{data-ad_title}',
      sub_id: '{trafficsource-name}_{funnel-id}_{campaign}',
      search_track_url: 'https://{tracking-domain}/action/1?vid={visitor}&rn={current-node-id}',
      click_track_url: 'https://{tracking-domain}/pb/?hit={hit}'
    },
    tracking: {
      postbackSubId: '',
      postbackTxId: '',
      postbackPayout: ''
    },
    imgSrc: 'offersources/system1.jpg'
  },
  {
    id: 'offer_source_54',
    name: 'Sedo',
    dataPassingFields: {
      kw1: '{data-keyword1}',
      kw2: '{data-keyword2}',
      kw3: '{data-keyword3}',
      kw4: '{data-keyword4}',
      kw5: '{data-keyword5}',
      adtitle: '{data-ad_title}',
      network: '{data-network_name}',
      site: '{data-site_id}',
      sub1: '{funnel-id}',
      sub2: '{campaign}',
      sub3: '{hit}'
    },
    tracking: {
      postbackSubId: '{sub3}',
      postbackTxId: '',
      postbackPayout: '{Estimated_CPC}'
    },
    imgSrc: 'offersources/sedo.png'
  },
  {
    id: 'offer_source_55',
    name: 'Leadbit',
    dataPassingFields: {
      sub1: '{funnel-id}',
      sub2: '{trafficsource-id}',
      sub3: '{campaign}',
      sub4: '{hit}'
    },
    tracking: {
      postbackSubId: '{sub4}',
      postbackTxId: '{id}',
      postbackPayout: '{cost}'
    },
    imgSrc: 'offersources/leadbit.svg'
  },
  {
    id: 'offer_source_56',
    name: 'Datify',
    dataPassingFields: {
      sub1: '{funnel-id}',
      sub2: '{trafficsource-id}',
      sub3: '{campaign}',
      clickid: '{hit}'
    },
    tracking: {
      postbackSubId: '{clickid}',
      postbackTxId: '{goal}',
      postbackPayout: '{sum}'
    },
    imgSrc: 'offersources/datify.png'
  },
  {
    id: 'offer_source_57',
    name: 'DoAff',
    dataPassingFields: {
      d_data1: '{funnel-id}',
      d_data2: '{trafficsource-id}',
      d_data3: '{campaign}',
      d_data4: '{hit}'
    },
    tracking: {
      postbackSubId: '{{data4}}',
      postbackTxId: '{{id}}',
      postbackPayout: '{{affiliateCommission}}'
    },
    imgSrc: 'offersources/doaff.png'
  },
  {
    id: 'offer_source_58',
    name: 'Tonic',
    dataPassingFields: {
      adtitle: '{data-ad_title}',
      site: '{data-site_id}',
      network: '{data-source_type}',
      subid1: '{funnel-id}',
      subid2: '{trafficsource-id}',
      subid3: '{campaign}',
      subid4: '{hit}'
    },
    tracking: {
      postbackSubId: '{subid4}',
      postbackTxId: '{keyword}',
      postbackPayout: '{revenue}'
    },
    imgSrc: 'offersources/tonic.svg'
  },
  {
    id: 'offer_source_59',
    name: 'PDL-Profit',
    dataPassingFields: {
      subid: '{hit}'
    },
    tracking: {
      postbackSubId: '{subid}',
      postbackTxId: '{transaction_id}',
      postbackPayout: '{aff_rev}'
    },
    imgSrc: 'offersources/pdlprofit.png'
  },
  {
    id: 'offer_source_60',
    name: 'Awin',
    dataPassingFields: {
      clickref: '{funnel-id}',
      clickref2: '{trafficsource-id}',
      clickref3: '{campaign}',
      clickref4: '{hit}'
    },
    tracking: {
      postbackSubId: '!!!clickRef4!!!',
      postbackTxId: '!!!transactionId!!!',
      postbackPayout: '!!!commission!!!'
    },
    imgSrc: 'offersources/awin.png'
  },
  {
    id: 'offer_source_61',
    name: 'Los Pollos',
    dataPassingFields: {
      cid: '{hit}',
      t: '{trafficsource-id}'
    },
    tracking: {
      postbackSubId: '{cid}',
      postbackTxId: '',
      postbackPayout: '{sum}'
    },
    imgSrc: 'offersources/lospollos.png'
  },
  {
    id: 'offer_source_62',
    name: 'MyLead',
    dataPassingFields: {
      ml_sub1: '{funnel-id}',
      ml_sub2: '{trafficsource-id}',
      ml_sub3: '{campaign}',
      ml_sub4: '{hit}'
    },
    tracking: {
      postbackSubId: '[ml_sub4]',
      postbackTxId: '[transaction_id]',
      postbackPayout: '[payout_decimal]'
    },
    imgSrc: 'offersources/mylead.png'
  },
  {
    id: 'offer_source_63',
    name: 'Mobipium',
    dataPassingFields: {
      campaign: '{campaign}',
      source: '{trafficsource-id}',
      click_id: '{hit}'
    },
    tracking: {
      postbackSubId: '{tid2}',
      postbackTxId: '{uni}',
      postbackPayout: '{pay}'
    },
    imgSrc: 'offersources/mobipium.png'
  },
  {
    id: 'offer_source_64',
    name: 'Golden Goose',
    dataPassingFields: {
      p1: '{funnel-id}',
      p2: '{hit}',
      source: '{trafficsource-id}',
      site: '{campaign}'
    },
    tracking: {
      postbackSubId: '{p2}',
      postbackTxId: '{event_id}',
      postbackPayout: '{profit}'
    },
    imgSrc: 'offersources/golden-goose.svg'
  },
  {
    id: 'offer_source_65',
    name: 'Traffic Company',
    dataPassingFields: {
      pi: '{funnel-id}-{trafficsource-id}',
      sub_id: '{campaign}',
      click_id: '{hit}'
    },
    tracking: {
      postbackSubId: '{click_id}',
      postbackTxId: '{unique_id}',
      postbackPayout: '{reward}'
    },
    imgSrc: 'offersources/traffic-company.svg'
  },
  {
    id: 'offer_source_66',
    name: 'Express Revenue',
    dataPassingFields: {
      source_id: '{trafficsource-id}',
      sub1: '{funnel-id}',
      sub2: '{campaign}',
      sub3: '{hit}'
    },
    tracking: {
      postbackSubId: '{sub3}',
      postbackTxId: '{transaction_id}',
      postbackPayout: '{payout_amount}'
    },
    imgSrc: 'offersources/express-revenue.png'
  },
  {
    id: 'offer_source_67',
    name: 'Offersify',
    dataPassingFields: {
      subid: '{trafficsource-id}',
      subid2: '{funnel-id}',
      subid3: '{campaign}',
      subid4: '{hit}'
    },
    tracking: {
      postbackSubId: '{subid4}',
      postbackTxId: '{transaction_id}',
      postbackPayout: '{payout}'
    },
    imgSrc: 'offersources/offersify.svg'
  },
  {
    id: 'offer_source_68',
    name: 'Ads.com',
    dataPassingFields: {
      ref_keyword: 'AD_HEADLINE',
      ref_adnetwork: '{trafficsource-name}',
      ref_pubsite: 'PLACEMENT_ID',
      subid1: '{funnel-id}',
      subid2: '{trafficsource-id}',
      subid3: '{campaign}',
      ffhitid: '{hit}'
    },
    tracking: {
      postbackSubId: '{ffhitid}',
      postbackTxId: '{click_term}',
      postbackPayout: '{estimated_revenue}'
    },
    imgSrc: 'offersources/ads-com.svg'
  },
  {
    id: 'offer_source_69',
    name: 'Rakuten Advertising',
    dataPassingFields: {
      u1: '{hit}',
    },
    tracking: {
      postbackSubId: '<u1>',
      postbackTxId: '<etransaction_id>',
      postbackPayout: '<commissions>'
    },
    imgSrc: 'offersources/rakuten.svg'
  },
  {
    id: 'offer_source_70',
    name: 'IMQueen Network',
    dataPassingFields: {
      sub1: '{funnel-id}',
      sub2: '{trafficsource-id}',
      sub3: '{campaign}',
      sub5: '{hit}',
    },
    tracking: {
      postbackSubId: '{sub5}',
      postbackTxId: '{transaction_id}',
      postbackPayout: '{payout_amount}'
    },
    imgSrc: 'offersources/imqueen.png'
  },
  {
    id: 'offer_source_71',
    name: 'MyLead (EU)',
    dataPassingFields: {
      ml_sub1: '{funnel-id}',
      ml_sub2: '{trafficsource-id}',
      ml_sub3: '{campaign}',
      ml_sub4: '{hit}',            
    },
    tracking: {
      postbackSubId: '[ml_sub4]',
      postbackTxId: '[destination_program_id]_[transaction_id]_',
      postbackPayout: '[payout_decimal]'
    },
    imgSrc: 'offersources/mylead-eu.png'
  },
  {
    id: 'offer_source_72',
    name: 'Explodely',
    dataPassingFields: {
      tid: '{hit}',
    },
    tracking: {
      postbackSubId: '',
      postbackTxId: '',
      postbackPayout: ''
    },
    imgSrc: 'offersources/explodely.png'
  },
  {
    id: 'offer_source_73',
    name: 'Big Bang Ads',
    dataPassingFields: {
      aff_click_id: '{hit}',
      source: '{trafficsource-id}',
      aff_sub: '{funnel-id}',
      aff_sub2: '{campaign}',

    },
    tracking: {
      postbackSubId: '{aff_click_id}',
      postbackTxId: '{transaction_id}',
      postbackPayout: '{payout}'
    },
    imgSrc: 'offersources/bigbangads.png'
  },

  {
    id: 'offer_source_74',
    name: 'Indoleads',
    dataPassingFields: {
      sub1: '{funnel-id}',
      sub2: '{trafficsource-id}',
      sub3: '{campaign}',
      click_id: '{hit}',

    },
    tracking: {
      postbackSubId: '{click_id}',
      postbackTxId: '{txn_id}',
      postbackPayout: '{payout}'
    },
    imgSrc: 'offersources/indoleads.png'
  },     
];

export const DATA_PASSING_TEMPLATE: FFSelectOption[] = [
  /* *** User Identifiers *** */
  {
    label: 'Hit ID (our tracking ID)',
    value: '{hit}',
    category: 'User Identifiers',
    objectValue: {
      hideTrackingTokenFrom: ['Traffic Source Conversion Tracking']
    }
  },
  {
    label: 'Visitor ID',
    value: '{visitor}',
    category: 'User Identifiers',
    objectValue: {
      hideTrackingTokenFrom: ['Traffic Source Conversion Tracking']
    }
  },
  /* *** Location Data *** */
  {
    label: 'Continent',
    value: '{continent}',
    category: 'Location Data'
  },
  {
    label: 'Country Code',
    value: '{country-code}',
    category: 'Location Data'
  },
  {
    label: 'Country Name',
    value: '{country-name}',
    category: 'Location Data'
  },
  {
    label: 'User Timezone',
    value: '{timezone}',
    category: 'Location Data'
  },
  {
    label: 'Region',
    value: '{region}',
    category: 'Location Data'
  },
  {
    label: 'City',
    value: '{city}',
    category: 'Location Data'
  },
  {
    label: 'User Latitude',
    value: '{latitude}',
    category: 'Location Data'
  },
  {
    label: 'User Longitude',
    value: '{longitude}',
    category: 'Location Data'
  },
  /* *** Device Properties *** */
  {
    label: 'Device Type',
    value: '{device-type}',
    category: 'Device Properties'
  },
  {
    label: 'Device Brand',
    value: '{brand}',
    category: 'Device Properties'
  },
  {
    label: 'Model Code',
    value: '{model}',
    category: 'Device Properties'
  },
  {
    label: 'Model Name',
    value: '{model-name}',
    category: 'Device Properties'
  },
  {
    label: 'Operating System',
    value: '{os}',
    category: 'Device Properties'
  },
  {
    label: 'Operating System Version',
    value: '{os-version}',
    category: 'Device Properties'
  },
  {
    label: 'Browser',
    value: '{browser}',
    category: 'Device Properties'
  },
  {
    label: 'Browser Version',
    value: '{browser-version}',
    category: 'Device Properties'
  },
  {
    label: 'Browser Language',
    value: '{browser-language}',
    category: 'Device Properties'
  },
  {
    label: 'Browser User-agent',
    value: '{user-agent}',
    category: 'Device Properties'
  },
  /* *** Connection Properties *** */
  {
    label: 'User IP',
    value: '{ip}',
    category: 'Connection Properties'
  },
  {
    label: 'ISP',
    value: '{isp}',
    category: 'Connection Properties'
  },
  {
    label: 'Mobile Carrier',
    value: '{carrier}',
    category: 'Connection Properties'
  },
  {
    label: 'Connection Type',
    value: '{connection-type}',
    category: 'Connection Properties'
  },
  /* *** General Tokens *** */
  {
    label: 'Funnel Group ID',
    value: '{group-id}',
    category: 'General Tokens',
    objectValue: {
      hideTrackingTokenFrom: ['Traffic Source Conversion Tracking']
    }
  },
  {
    label: 'Funnel Group Name',
    value: '{group-name}',
    category: 'General Tokens',
    objectValue: {
      hideTrackingTokenFrom: ['Traffic Source Conversion Tracking']
    }
  },
  {
    label: 'Funnel ID',
    value: '{funnel-id}',
    category: 'General Tokens'
  },
  {
    label: 'Funnel Name',
    value: '{funnel-name}',
    category: 'General Tokens',
    objectValue: {
      hideTrackingTokenFrom: ['Traffic Source Conversion Tracking']
    }
  },
  {
    label: 'Traffic Source Campaign',
    value: '{campaign}',
    category: 'General Tokens'
  },
  {
    label: 'Traffic Source ID',
    value: '{trafficsource-id}',
    category: 'General Tokens',
    objectValue: {
      hideTrackingTokenFrom: ['Traffic Source Conversion Tracking']
    }
  },
  {
    label: 'Traffic Source Name',
    value: '{trafficsource-name}',
    category: 'General Tokens',
    objectValue: {
      hideTrackingTokenFrom: ['Traffic Source Conversion Tracking']
    }
  },
  {
    label: 'Tracking Domain',
    value: '{tracking-domain}',
    category: 'General Tokens',
    objectValue: {
      hideTrackingTokenFrom: ['Traffic Source Conversion Tracking']
    }
  },
  {
    label: 'Funnel + TS Hash',
    value: '{tracking-fts}',
    category: 'General Tokens',
    objectValue: {
      hideTrackingTokenFrom: ['Traffic Source Conversion Tracking']
    }
  },
  {
    label: 'Referrer URL',
    value: '{referrer}',
    category: 'General Tokens'
  },
  {
    label: 'Referrer Domain',
    value: '{referrer-domain}',
    category: 'General Tokens'
  },
  {
    label: 'Current Unix Time',
    value: '{timestamp}',
    category: 'General Tokens'
  },
  /* *** Node-related *** */
  {
    label: 'Current Node ID',
    value: '{current-node-id}',
    category: 'Node-related',
    objectValue: {
      hideTrackingTokenFrom: ['Traffic Source Conversion Tracking']
    }
  },
  {
    label: 'Current Node Name',
    value: '{current-node-name}',
    category: 'Node-related',
    objectValue: {
      hideTrackingTokenFrom: ['Traffic Source Conversion Tracking']
    }
  },
  {
    label: 'Referring Node ID',
    value: '{referring-node-id}',
    category: 'Node-related',
    objectValue: {
      hideTrackingTokenFrom: ['Traffic Source Conversion Tracking']
    }
  },
  {
    label: 'Referring Node Name',
    value: '{referring-node-name}',
    category: 'Node-related',
    objectValue: {
      hideTrackingTokenFrom: ['Traffic Source Conversion Tracking']
    }
  },
  {
    label: 'Page ID',
    value: '{page-id}',
    category: 'Node-related'
  },
  {
    label: 'Page URL',
    value: '{page-url}',
    category: 'Node-related',
    objectValue: {
      hideTrackingTokenFrom: ['Data Passing Offers', 'Data Passing Landers']
    }
  },
  {
    label: 'Page Name',
    value: '{page-name}',
    category: 'Node-related'
  },
  {
    label: 'Offer Source ID',
    value: '{offer-source-id}',
    category: 'Node-related',
    objectValue: {
      hideTrackingTokenFrom: ['Traffic Source Conversion Tracking', 'Data Passing Landers']
    }
  },
  {
    label: 'Click Action Number',
    value: '{action-number}',
    category: 'Node-related',
    objectValue: {
      hideTrackingTokenFrom: ['Traffic Source Conversion Tracking']
    }
  },
  /* *** Custom Fields *** */
  {
    label: 'URL Data Field',
    value: '{data-fieldname}',
    category: 'Custom Fields'
  },
  {
    label: 'Custom String',
    value: 'Custom String',
    category: 'Custom Fields',
    objectValue: {
      hideTrackingTokenFrom: ['Traffic Source Conversion Tracking']
    }
  },
  {
    label: 'Transaction ID',
    value: '{txid}',
    category: 'Conversion Tracking',
    objectValue: {
      hideTrackingTokenFrom: ['Data Passing Landers', 'Data Passing Offers']
    }
  },
  {
    label: 'Revenue',
    value: '{payout}',
    category: 'Conversion Tracking',
    objectValue: {
      hideTrackingTokenFrom: ['Data Passing Landers', 'Data Passing Offers']
    }
  },
  {
    label: 'Conversion Timestamp',
    value: '{conversion-time}',
    category: 'Conversion Tracking',
    objectValue: {
      hideTrackingTokenFrom: ['Data Passing Landers', 'Data Passing Offers']
    }
  },
  {
    label: 'External Tracking ID',
    value: '{external}',
    category: 'Conversion Tracking'
  }
];

export const getAttributes = (type: TrackingTokenType) => {
  return DATA_PASSING_TEMPLATE.filter(item =>
    defined(item?.objectValue?.hideTrackingTokenFrom) ? item.objectValue.hideTrackingTokenFrom.indexOf(type) === -1 : true
  );
};
