const Settings = () => (
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
    <g id="cog-1--work-loading-cog-gear-settings-machine">
        <path id="Vector" fill="#d7e0ff" d="m5.23 2.25 0.43 -1.11c0.07251 -0.187936 0.2001 -0.349589 0.36605 -0.463788C6.19199 0.562014 6.38856 0.500595 6.59 0.5h0.82c0.20144 0.000595 0.398 0.062014 0.56395 0.176212 0.16594 0.114199 0.29353 0.275852 0.36605 0.463788l0.43 1.11 1.46 0.84 1.18 -0.18c0.1965 -0.02667 0.3965 0.00567 0.5745 0.09292 0.1781 0.08725 0.3262 0.22546 0.4255 0.39708l0.4 0.7c0.1025 0.17435 0.1497 0.37568 0.1354 0.57742 -0.0142 0.20174 -0.0894 0.39441 -0.2154 0.55258l-0.73 0.93v1.68l0.75 0.93c0.126 0.15817 0.2012 0.35084 0.2154 0.55258 0.0143 0.20174 -0.0329 0.40307 -0.1354 0.57742l-0.4 0.7c-0.0993 0.1716 -0.2474 0.3098 -0.4255 0.3971 -0.178 0.0872 -0.378 0.1196 -0.5745 0.0929l-1.18 -0.18 -1.46 0.84 -0.43 1.11c-0.07252 0.1879 -0.20011 0.3496 -0.36605 0.4638 -0.16595 0.1142 -0.36251 0.1756 -0.56395 0.1762h-0.84c-0.20144 -0.0006 -0.39801 -0.062 -0.56395 -0.1762 -0.16595 -0.1142 -0.29354 -0.2759 -0.36605 -0.4638l-0.43 -1.11 -1.46 -0.84 -1.18 0.18c-0.19649 0.0267 -0.39646 -0.0057 -0.57452 -0.0929 -0.17806 -0.0873 -0.32616 -0.2255 -0.42548 -0.3971l-0.4 -0.7c-0.1025 -0.17435 -0.14973 -0.37568 -0.13544 -0.57742 0.01428 -0.20174 0.0894 -0.39441 0.21544 -0.55258L2 7.84V6.16l-0.75 -0.93c-0.12604 -0.15817 -0.20116 -0.35084 -0.21544 -0.55258 -0.01429 -0.20174 0.03294 -0.40307 0.13544 -0.57742l0.4 -0.7c0.09932 -0.17162 0.24742 -0.30983 0.42548 -0.39708 0.17806 -0.08725 0.37803 -0.11959 0.57452 -0.09292l1.18 0.18 1.48 -0.84ZM5 7c0 0.39556 0.11729 0.78224 0.33706 1.11114 0.21976 0.3289 0.53212 0.58524 0.89757 0.73662 0.36545 0.15137 0.76758 0.19098 1.15555 0.11381 0.38796 -0.07717 0.74432 -0.26765 1.02403 -0.54736 0.2797 -0.2797 0.47019 -0.63607 0.54736 -1.02403 0.07717 -0.38796 0.03756 -0.79009 -0.11381 -1.15555 -0.15138 -0.36545 -0.40773 -0.67781 -0.73662 -0.89757C7.78224 5.1173 7.39556 5 7 5c-0.53044 0 -1.03914 0.21071 -1.41422 0.58579C5.21071 5.96086 5 6.46957 5 7Z" strokeWidth="1"></path>
        <path id="Vector_2" stroke="#4147d5" strokeLinecap="round" strokeLinejoin="round" d="m5.66 1.14 -0.43 1.11 -1.48 0.84 -1.18 -0.18c-0.19649 -0.02667 -0.39646 0.00567 -0.57452 0.09292 -0.17806 0.08725 -0.32616 0.22546 -0.42548 0.39708l-0.4 0.7c-0.1025 0.17435 -0.14973 0.37568 -0.13544 0.57742 0.01428 0.20174 0.0894 0.39441 0.21544 0.55258l0.75 0.93v1.68l-0.73 0.93c-0.12604 0.15817 -0.20116 0.35084 -0.21544 0.55258 -0.01429 0.20174 0.03294 0.40307 0.13544 0.57742l0.4 0.7c0.09932 0.1716 0.24742 0.3098 0.42548 0.3971 0.17806 0.0872 0.37803 0.1196 0.57452 0.0929l1.18 -0.18 1.46 0.84 0.43 1.11c0.07251 0.1879 0.2001 0.3496 0.36605 0.4638 0.16594 0.1142 0.36251 0.1756 0.56395 0.1762h0.84c0.20144 -0.0006 0.398 -0.062 0.56395 -0.1762 0.16594 -0.1142 0.29353 -0.2759 0.36605 -0.4638l0.43 -1.11 1.46 -0.84 1.18 0.18c0.1965 0.0267 0.3965 -0.0057 0.5745 -0.0929 0.1781 -0.0873 0.3262 -0.2255 0.4255 -0.3971l0.4 -0.7c0.1025 -0.17435 0.1497 -0.37568 0.1354 -0.57742 -0.0142 -0.20174 -0.0894 -0.39441 -0.2154 -0.55258L12 7.84V6.16l0.73 -0.93c0.126 -0.15817 0.2012 -0.35084 0.2154 -0.55258 0.0143 -0.20174 -0.0329 -0.40307 -0.1354 -0.57742l-0.4 -0.7c-0.0993 -0.17162 -0.2474 -0.30983 -0.4255 -0.39708 -0.178 -0.08725 -0.378 -0.11959 -0.5745 -0.09292l-1.18 0.18 -1.46 -0.84 -0.43 -1.11C8.26748 0.952064 8.13989 0.790411 7.97395 0.676212 7.808 0.562014 7.61144 0.500595 7.41 0.5h-0.82c-0.20144 0.000595 -0.39801 0.062014 -0.56395 0.176212C5.8601 0.790411 5.73251 0.952064 5.66 1.14Z" strokeWidth="1"></path>
        <path id="Vector_3" stroke="#4147d5" strokeLinecap="round" strokeLinejoin="round" d="M5.33706 8.11114C5.1173 7.78224 5 7.39556 5 7c0 -0.53043 0.21071 -1.03914 0.58579 -1.41421C5.96086 5.21071 6.46957 5 7 5c0.39556 0 0.78224 0.1173 1.11114 0.33706 0.3289 0.21976 0.58524 0.53212 0.73662 0.89757 0.15137 0.36546 0.19098 0.76759 0.11381 1.15555 -0.07717 0.38796 -0.26765 0.74433 -0.54736 1.02403 -0.2797 0.27971 -0.63607 0.47019 -1.02403 0.54736 -0.38796 0.07717 -0.79009 0.03756 -1.15555 -0.11381 -0.36545 -0.15138 -0.67781 -0.40772 -0.89757 -0.73662Z" strokeWidth="1"></path>
    </g>
</svg>
);

export default Settings;