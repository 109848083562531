import { PropsWithChildren } from 'react';
import { Modal as AntModal } from 'antd';
import { FFModalProps } from '@/uikit/types/modal';
import { FFIcon, FFRow } from '@/uikit';
import className from '@/utils/className';
import './style.scss';

const { blockClassName, getClass } = className('c-ffModal');

const Modal = ({ children, open, onCancel, title, centered = true, footer = null, width, destroyOnClose }: PropsWithChildren<FFModalProps>) => {
  return (
    <AntModal
      className={blockClassName}
      destroyOnClose={destroyOnClose}
      open={open}
      closable
      width={width}
      wrapClassName={getClass('wrapper')}
      centered={centered}
      closeIcon={<></>}
      title={
        <FFRow justifyContent="space-between" alignItems="center">
          <span className={getClass('title')}>{title}</span>
          <div className={getClass('closeIcon')} onClick={onCancel as any}>
            <FFIcon name="xCircle" size="bigger" />
          </div>
        </FFRow>
      }
      footer={footer}
    >
      {children}
    </AntModal>
  );
};

export default Modal;
