import useHttp from '@/hooks/http';
import { OfferSource } from '@/models/offerSource';
import { useMutation } from '@tanstack/react-query';
import useHttpNotification from '@/hooks/useHttpError';

export function useOfferSourceCreateMutation() {
  const { post } = useHttp();
  const { handleAssetNotification } = useHttpNotification();
  return useMutation<null, Error, OfferSource>({
    mutationKey: ['offersource', 'create'],
    mutationFn: async (offersource: OfferSource) => {
      await post('v1/offersource/save/', offersource);
      return null;
    },
    onError: (error: any, data) => {
      handleAssetNotification(data, 'added', 'offerSource', error);
    },
    onSuccess: (_, data) => {
      handleAssetNotification(data, 'added', 'offerSource');
    },
  });
}

export function useOfferSourceUpdateMutation() {
  const { put } = useHttp();
  const { handleAssetNotification } = useHttpNotification();
  return useMutation<null, Error, OfferSource>({
    mutationKey: ['offersource', 'update'],
    mutationFn: async (offersource: OfferSource) => {
      await put('v1/offersource/save/', offersource);
      return null;
    },
    onError: (error: any, data) => {
      handleAssetNotification(data, 'updated', 'offerSource', error);
    },
    onSuccess: (_, data) => {
      handleAssetNotification(data, 'updated', 'offerSource');
    },
  });
}

export function useOfferSourceDuplicateMutation(previousIdOfferSource: string) {
  const { post } = useHttp();
  const { handleAssetNotification } = useHttpNotification();
  return useMutation<OfferSource, Error, OfferSource>({
    mutationKey: ['offersource', 'duplicate'],
    mutationFn: async (offersource: OfferSource) => {
      const { data } = await post<OfferSource, OfferSource>(`v1/offersource/duplicate?idOfferSource=${previousIdOfferSource}`, offersource);
      return data;
    },
    onError: (error: any, data) => {
      handleAssetNotification(data, 'duplicated', 'offerSource', error);
    },
    onSuccess: (_, data) => {
      handleAssetNotification(data, 'duplicated', 'offerSource');
    },
  });
}


export function useOfferSourceDeleteMutation() {
  const { del } = useHttp();
  const { handleAssetNotification } = useHttpNotification();
  return useMutation<null, Error, string>({
    mutationKey: ['offersource', 'delete'],
    mutationFn: async (id: string) => {
      await del('v1/offersource/delete/', {
        data: { entries: [id] },
      });
      return null;
    },
    onError: (error: any, id) => {
      handleAssetNotification({ idOfferSource: id }, 'deleted', 'offerSource', error);
    },
    onSuccess: (_, id) => {
      handleAssetNotification({ idOfferSource: id }, 'deleted', 'offerSource');
    },
  });
}

export function useOfferSourcesArchiveMutation() {
  const { put } = useHttp();
  const { handleAssetNotification } = useHttpNotification();
  return useMutation<null, Error, OfferSource>({
    mutationKey: ['offersource', 'archive'],
    mutationFn: async (offersource: OfferSource) => {
      await put<OfferSource, null>('v1/offersource/save/', offersource);
      return null;
    },
    onError: (error: any, data) => {
      handleAssetNotification(data, 'archived', 'offerSource', error);
    },
    onSuccess: (_, data) => {
      handleAssetNotification(data, 'archived', 'offerSource');
    },
  });
}

export function useOfferSourcesUnArchiveMutation() {
  const { put } = useHttp();
  const { handleAssetNotification } = useHttpNotification();
  return useMutation<null, Error, OfferSource>({
    mutationKey: ['offersource', 'unarchive'],
    mutationFn: async (offerSource: OfferSource) => {
      await put<OfferSource, null>('v1/offersource/save/', offerSource);
      return null;
    },
    onError: (error: any, data) => {
      handleAssetNotification(data, 'unarchived', 'offerSource', error);
    },
    onSuccess: (_, data) => {
      handleAssetNotification(data, 'unarchived', 'offerSource');
    },
  });
}
