const PasteIcon = () => {
  return (
    <svg fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62.094 62.094">
      <path
        d="M58.933,28.698l-5.19-5.996V9.854c0-3.044-2.477-5.521-5.521-5.521h-6.29V1c0-0.552-0.448-1-1-1H15.728
	c-0.552,0-1,0.448-1,1v3.333h-6.29c-3.044,0-5.521,2.477-5.521,5.521v41.894c0,3.044,2.477,5.521,5.521,5.521h12.098
	c0.917,2.798,3.549,4.826,6.649,4.826h24.993c3.859,0,6.999-3.14,6.999-6.999V29.353C59.177,29.112,59.091,28.88,58.933,28.698z
	 M46.305,17.165l9.684,11.188h-4.685c-2.756,0-4.999-2.242-4.999-4.999V17.165z M16.728,2h23.204v5.572H16.728V2z M8.438,55.268
	c-1.941,0-3.521-1.58-3.521-3.521V9.854c0-1.941,1.58-3.521,3.521-3.521h6.29v2.24c0,0.552,0.448,1,1,1h25.204c0.552,0,1-0.448,1-1
	v-2.24h6.29c1.941,0,3.521,1.58,3.521,3.521v10.538l-5.682-6.564c-0.19-0.22-0.466-0.346-0.756-0.346h-18.12
	c-3.859,0-6.999,3.139-6.999,6.998v34.616c0,0.058,0.007,0.115,0.009,0.172H8.438z M52.178,60.094H27.185
	c-2.756,0-4.999-2.242-4.999-4.999V20.479c0-2.756,2.242-4.998,4.999-4.998h17.12v7.873c0,3.859,3.14,6.999,6.999,6.999h5.873
	v24.743C57.177,57.852,54.935,60.094,52.178,60.094z"
      />
    </svg>
  );
};

export default PasteIcon;
