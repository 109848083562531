const ArrowRight = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <g id="arrow-right-3">
      <path 
      id="Vector" 
      d="M7.3449,3.225l8.3025,8.3025c.0646.0606.116.1338.1512.2151.0351.0813.0533.1689.0533.2574s-.0182.1762-.0533.2574c-.0352.0813-.0867.1545-.1512.2151l-8.3025,8.3025" 
      fill="none"
      stroke="#333"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2" />
    </g>
  </svg>
);

export default ArrowRight;
