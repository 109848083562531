import useHttp from '@/hooks/http';
import { Category } from '@/models/category';
import { Page } from '@/models/page';
import { TrafficSource } from '@/models/trafficSource';
import { TrafficSourceCategoryInfo } from '@/models/trafficSourceCategoryInfo';
import { useQuery } from '@tanstack/react-query';

export function useTrafficSourceCategoriesInfoQuery() {
  const { get } = useHttp();
  return useQuery({
    queryKey: ['trafficsource', 'categoryInfo', 'list'],
    queryFn: async () => {
      const { data } = await get<TrafficSourceCategoryInfo[]>('v1/trafficsource/category/list?status=active&includeChilds=true');
      return data;
    },
  });
}

export function useTrafficSourceQuery(idTrafficSource: string) {
  const { get } = useHttp();
  return useQuery({
    queryKey: ['trafficsource', idTrafficSource],
    queryFn: async () => {
      const { data } = await get<TrafficSource>('v1/trafficsource/find/byId', { params: { idTrafficSource } });
      return data;
    },
    enabled: !!idTrafficSource,
  });
}

export function usePageQuery(idPage: string) {
  const { get } = useHttp();
  return useQuery({
    queryKey: ['page', idPage],
    queryFn: async () => {
      const { data } = await get<Page>('v1/page/find/byId', { params: { idPage } });
      return data;
    },
    enabled: !!idPage,
  });
}

export function useCategoryQuery(idCategory: string) {
  const { get } = useHttp();
  return useQuery({
    queryKey: ['category', idCategory],
    queryFn: async () => {
      const { data } = await get<Category>('v1/category/find/byId', { params: { idCategory } });
      return data;
    },
    enabled: !!idCategory,
  });
}
