import { useTrafficSourceCategoriesInfoQuery, useTrafficSourceQuery } from '@/api/queries/general';
import CodeSnippet from '@/components/CodeSnippet';
import SectionBox from '@/components/SectionBox';
import { FunnelNode } from '@/models/funnelNode';
import useSystemSettingsStore from '@/stores/systemSettings';
import { FFCol, FFField, FFInput, FFSelect } from '@/uikit';
import { FFSelectOption } from '@/uikit/types/select';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

interface RedirectLinkSettings {
  idTrafficSource: string;
  cost: string;
  domain: string;
  destination: string;
}

const RedirectLinks = ({ flowId, funnelNodes }: { flowId: string; funnelNodes: FunnelNode[] }) => {
  const { domains, userSettings } = useSystemSettingsStore();
  const {
    control,
    formState: { errors },
    watch,
  } = useForm<RedirectLinkSettings>({
    defaultValues: {
      domain: userSettings.defaultCustomDomain,
      cost: '',
      idTrafficSource: '',
      destination: '',
    },
  });
  const idTrafficSource = watch('idTrafficSource');
  const domain = watch('domain');
  const cost = watch('cost');
  const destination = watch('destination') || '';

  const { data: trafficSourceCategoriesList = [], isLoading: trafficSourceGroupsIsPending } = useTrafficSourceCategoriesInfoQuery();
  const { data: trafficSource, isLoading: trafficSourceIsLoading } = useTrafficSourceQuery(idTrafficSource);

  const trafficSourceOptions = useMemo(() => {
    const options: FFSelectOption[] = [];
    for (const trafficSourceGroup of trafficSourceCategoriesList) {
      (trafficSourceGroup.trafficSources || []).forEach((trafficSource) => {
        options.push({
          value: trafficSource.idTrafficSource,
          label: trafficSource.trafficSourceName,
          category: trafficSourceGroup.categoryName,
        });
      });
    }
    return options;
  }, [trafficSourceCategoriesList]);

  const getCode = useMemo(() => {
    if (trafficSourceIsLoading) return 'Fetching TrafficSource Data...';
    if (!trafficSource) return 'Please select a traffic source first';

    let url = new URL(`https://${domain}/fts/${flowId}-${idTrafficSource}/${destination}`);
    Object.values(trafficSource?.trackingFieldSlots || {}).forEach((value) => {
      if (value.value) {
        url.searchParams.append(value.name, value.value);
      }
    });
    const costValue = cost || trafficSource?.defaultCost || '';
    if (costValue) {
      url.searchParams.append('c', costValue);
    }
    return decodeURIComponent(url.toString());
  }, [destination, idTrafficSource, domain, cost, trafficSource, trafficSourceIsLoading, flowId]);

  return (
    <SectionBox title="Redirect Links">
      <FFCol gap="12px">
        <Controller
          name="idTrafficSource"
          control={control}
          rules={{ required: 'Traffic Source is required' }}
          render={(opt) => (
            <FFField label="Traffic Source" block direction="row">
              <FFSelect
                disabled={trafficSourceGroupsIsPending}
                loading={trafficSourceGroupsIsPending}
                error={errors.idTrafficSource?.message}
                value={opt.field.value}
                onChange={opt.field.onChange}
                options={trafficSourceOptions}
                groupOptions={true}
                sortGroup
                selectAll
                showSearch
                autoFocus
                valueGetter={(opt) => opt.value}
                labelGetter={(opt) => opt.label}
                placeholder="Select a Traffic Source"
              />
            </FFField>
          )}
        />
        <Controller
          name="destination"
          control={control}
          render={(opt) => (
            <FFField label="Destination" block direction="row">
              <FFSelect
                value={opt.field.value}
                onChange={opt.field.onChange}
                options={funnelNodes}
                placeholder="Start of this flow"
                valueGetter={(opt) => opt.idNode}
                labelGetter={(opt) => opt.nodeName}
                filterOption={true}
                showSearch={true}
              />
            </FFField>
          )}
        />
        <Controller
          name="cost"
          control={control}
          render={(opt) => (
            <FFField label="Entrance cost (optional)" block direction="row">
              <FFInput value={opt.field.value} onChange={opt.field.onChange} placeholder="Entrance cost (optional)" />
            </FFField>
          )}
        />
        <Controller
          name="domain"
          control={control}
          render={(opt) => (
            <FFField label="Tracking Domain" block direction="row">
              <FFSelect
                value={opt.field.value}
                onChange={opt.field.onChange}
                options={domains}
                valueGetter={(opt) => opt.domain}
                labelGetter={(opt) => opt.domain}
              />
            </FFField>
          )}
        />
        <CodeSnippet code={getCode} multiline />
      </FFCol>
    </SectionBox>
  );
};

export default RedirectLinks;
