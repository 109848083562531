import CodeSnippet from '@/components/CodeSnippet';
import SectionBox from '@/components/SectionBox';
import useSystemSettingsStore from '@/stores/systemSettings';
import { FFCol, FFField, FFSelect, FFSwitch } from '@/uikit';
import { useMemo, useState } from 'react';

function combineURLs(baseURL: string, relativeURL: string) {
  try {
    if (!/^https?:\/\//i.test(baseURL)) {
      baseURL = 'http://' + baseURL;
    }
    const base = new URL(baseURL);
    let combinedPath = relativeURL.startsWith('/') ? relativeURL : '/' + relativeURL;
    const combinedURL = new URL(combinedPath, base.origin);
    return combinedURL.toString();
  } catch (error) {
    return '';
  }
}

const ActionLinks = ({ idFunnel, nodeId }: { idFunnel: string; nodeId: string }) => {
  const { domains, userSettings } = useSystemSettingsStore();
  const [domain, setDomain] = useState(userSettings.defaultCustomDomain);
  const [addDefaultRedirectParams, setAddDefaultRedirectParams] = useState(false);

  const url = useMemo(() => {
    if (!addDefaultRedirectParams) {
      return combineURLs(domain, '/action/1');
    } else {
      return combineURLs(domain, `/action/${idFunnel}/${nodeId}/1`);
    }
  }, [addDefaultRedirectParams, domain, idFunnel, nodeId]);

  return (
    <SectionBox title="Action Links">
      <FFCol gap="12px">
        <FFField label="Custom domain to use:">
          <FFSelect
            value={domain}
            onSelect={setDomain}
            options={domains}
            valueGetter={(opt) => opt.domain}
            labelGetter={(opt) => opt.domain}
          />
        </FFField>
        <FFField label="Custom domain to use:">
          <CodeSnippet code={url} />
        </FFField>
        <FFSwitch checked={addDefaultRedirectParams} onClick={setAddDefaultRedirectParams}>
          Add Default Redirect Parameters
        </FFSwitch>
      </FFCol>
    </SectionBox>
  );
};

export default ActionLinks;
