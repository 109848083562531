import { color } from '@/uikit/modules/color';
import { Slider as AntdSlider, ConfigProvider } from 'antd';
import { SliderSingleProps } from 'antd/es/slider';

const Slider = ({ ...props }: SliderSingleProps) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Slider: {
            handleSize: 20,
            handleColor: color('slider-handle'),
            railBg: color('slider-rail'),
            trackBg: color('slider-track'),
          },
        },
      }}
    >
      <AntdSlider {...props} />
    </ConfigProvider>
  );
};

export default Slider;
