import SectionBox from '@/components/SectionBox';
import useMitt from '@/hooks/mitt';
import { SimpleFlowRule } from '@/models/simpleFlowRules';
import useFormStore from '@/stores/forms';
import { SimpleFlowRuleEditorFormRefType } from '@/types/forms/simpleFlow';
import { FFCol, FFIconButton, FFInput, FFRow, VisibilityWrapper } from '@/uikit';
import className from '@/utils/className';
import { forwardRef, Ref, useImperativeHandle, useMemo, useState, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Rule from '../Rule';
import useSystemSettingsStore from '@/stores/systemSettings';
import { useTrafficSourcesCategoryListQuery } from '@/api/queries/trafficSource';
import { FFSelectOption } from '@/uikit/types/select';
import { findDuplicateNames } from '@/utils/array';
import AntMessageContext from '@/contexts/antMessage';

const { getClass } = className('c-ruleEditor');

const RuleEditor = forwardRef(({ allRules }: { allRules: SimpleFlowRule[] }, ref: Ref<SimpleFlowRuleEditorFormRefType>) => {
  const { messageApi } = useContext(AntMessageContext);
  const emitter = useMitt();

  const defaultValues = useFormStore((state) => state.simpleFlowRuleEditor.data);
  const domains = useSystemSettingsStore((state) => state.domains);
  const closeSimpleFlowRuleEditorForm = useFormStore((state) => state.closeSimpleFlowRuleEditorForm);

  const { data: trafficSourceCategoryList = [] } = useTrafficSourcesCategoryListQuery();

  const [isEditingName, setIsEditingName] = useState(false);

  const { handleSubmit, control, getValues, watch, setValue } = useForm<SimpleFlowRule>({
    defaultValues: defaultValues || {},
  });

  const isDefault = watch('isDefault');

  const trafficSourceOptions = useMemo(() => {
    const options: FFSelectOption[] = [];
    trafficSourceCategoryList.forEach((category) => {
      (category.trafficSources || []).forEach((trafficSource) => {
        options.push({ value: trafficSource.idTrafficSource, label: trafficSource.trafficSourceName, category: category.categoryName });
      });
    });
    return options;
  }, [trafficSourceCategoryList]);

  const toggleEditMode = () => setIsEditingName((prev) => !prev);

  const onSave = handleSubmit((data) => {
    const duplicateNames = findDuplicateNames([...allRules.filter((rule) => rule.ruleId !== data.ruleId), data], 'ruleName');
    if (!data.ruleName || data.ruleName === 'New Rule') {
      messageApi.warning('All rules must have a name added');
      return;
    }
    if (duplicateNames.includes(data.ruleName)) {
      messageApi.warning('All rules must have unique names');
      return;
    }

    emitter.emit('onSimpleFlowRuleSave', data);
    closeSimpleFlowRuleEditorForm();
  });

  const onAddGroup = () => {
    setValue('rule.groups', [...(getValues('rule.groups') || []), { operator: 'or', rules: [{ attribute: '' as any, test: '' as any }] }]);
  };

  const onAddRule = (idGroup: number) => {
    setValue('rule.groups', [
      ...getValues('rule.groups')?.map((group, idx) => {
        if (idx === idGroup) {
          return {
            ...group,
            rules: [...(group.rules || []), { attribute: '' as any, test: '' as any }],
          };
        }
        return group;
      }),
    ]);
  };

  const onDeleteGroup = (idGroup: number) => {
    setValue('rule.groups', [...getValues('rule.groups')?.filter((_, idx) => idx !== idGroup)]);
  };

  const onDeleteRule = (idGroup: number, idRule: number) => {
    setValue('rule.groups', [
      ...getValues('rule.groups')?.map((group, idx) => {
        if (idx === idGroup) {
          return {
            ...group,
            rules: group.rules?.filter((_, idx) => idx !== idRule),
          };
        }
        return group;
      }),
    ]);
  };

  useImperativeHandle(ref, () => ({ onSave }));

  return (
    <>
      <FFCol flex="1" gap="12px">
        <SectionBox>
          <FFRow alignItems="center" gap="8px">
            <VisibilityWrapper visible={!isDefault}>
              <FFIconButton
                buttonType="transparent"
                iconName={isEditingName ? 'general/line/check' : 'general/line/pencil'}
                onClick={toggleEditMode}
              />
            </VisibilityWrapper>
            <Controller
              name="ruleName"
              control={control}
              render={(opt) =>
                isEditingName ? (
                  <FFInput value={opt.field.value} onChange={opt.field.onChange} placeholder="Rule Name" />
                ) : (
                  <span className={getClass('ruleName')}>{opt.field.value}</span>
                )
              }
            />
          </FFRow>
        </SectionBox>
        <SectionBox>
          <Rule
            onAddGroup={onAddGroup}
            onAddRule={onAddRule}
            onDeleteGroup={onDeleteGroup}
            onDeleteRule={onDeleteRule}
            trafficSources={trafficSourceOptions}
            domains={domains}
            control={control}
          />
        </SectionBox>
      </FFCol>
    </>
  );
});

export default RuleEditor;
