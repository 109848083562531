/**
 * FunnelFlux Pro Ledger API
 * API endpoints to manage customers' assets
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FunnelConnection } from './funnelConnection';
import { FunnelNode } from './funnelNode';
import { Meta } from './meta';
import { Postback } from './postback';

export interface Funnel {
  /**
   * ID of the funnel
   */
  idFunnel: string;
  /**
   * ID of the parent campaign
   */
  idCampaign: string;
  /**
   * Funnel's name.
   */
  funnelName: string;
  canvasWidth?: number;
  canvasHeight?: number;
  /**
   * Query parameters to add to the tracking url for this funnel
   */
  accumulatedUrlParams?: { [key: string]: string };
  /**
   * Custom tokens usable in conditions, JS nodes and PHP nodes
   */
  customTokens?: { [key: string]: string };
  /**
   * A list of traffic source cost overrides. The key represents the traffic source id and the value the new cost to apply to new visits from that traffic source.
   */
  incomingCostOverrides?: Funnel.IncomingCostOverrides;
  /**
   * A list of traffic source postback overrides for this funnel.
   */
  postbackOverrides?: Array<Postback>;
  /**
   * A list of nodes used in this funnel
   */
  nodes?: Array<FunnelNode>;
  /**
   * A list of connections linking this funnel's nodes.
   */
  connections?: Array<FunnelConnection>;
  defaultCost?: string;
  status?: Funnel.StatusEnum;

  /**
   * IP Anonymizer name
   */
  ipAnonymizer?: Funnel.IpAnonymizerEnum;
  /**
   * A list of funnel IDs that can generate indirect conversions on this funnel.
   */
  idIndirectFunnels?: Array<string>;
  /**
   * DEPRECATED
   */
  isArchived?: boolean;
  /**
   * Only filled by loading endpoints. Ignored for all saving operations as the owner is already passed as the endpoint's key query param.
   */
  owner?: string;
  meta?: Meta;
  funnelType?: Funnel.FunnelTypeEnum;
}
export namespace Funnel {
  export interface IncomingCostOverrides {
    [key: string]: string;
  }
  export type StatusEnum = 'active' | 'archived' | 'deleted';
  export const StatusEnum = {
    Active: 'active' as StatusEnum,
    Archived: 'archived' as StatusEnum,
    Deleted: 'deleted' as StatusEnum,
  };

  export type IpAnonymizerEnum = 'disabled' | 'All IPs' | 'EU IPs';
  export const IpAnonymizerEnum = {
    Disabled: 'disabled' as IpAnonymizerEnum,
    AllIPs: 'All IPs' as IpAnonymizerEnum,
    EUIPs: 'EU IPs' as IpAnonymizerEnum,
  };

  export type FunnelTypeEnum = 'visual' | 'flow';
  export const FunnelTypeEnum = {
    Visual: 'visual' as FunnelTypeEnum,
    Flow: 'flow' as FunnelTypeEnum,
  };
}
