/**
 * FunnelFlux Pro Ledger API
 * API endpoints to manage customers' assets
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FunnelConditionRoute } from './funnelConditionRoute';
import { Meta } from './meta';


export interface FunnelCondition { 
    /**
     * ID of the condition
     */
    idCondition: string;
    conditionName: string;
    routes: Array<FunnelConditionRoute>;
    /**
     * Category ID. Empty if uncategorized
     */
    idCategory?: string;
    /**
     * If empty, then can be used in all funnels, otherwise, id of the funnel that can use this condition.
     */
    restrictToFunnelId?: string;
    status?: FunnelCondition.StatusEnum;
    meta?: Meta;
}
export namespace FunnelCondition {
    export type StatusEnum = 'active' | 'archived' | 'deleted';
    export const StatusEnum = {
        Active: 'active' as StatusEnum,
        Archived: 'archived' as StatusEnum,
        Deleted: 'deleted' as StatusEnum
    };
}
