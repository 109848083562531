const Delete = () => (
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
    <g id="delete-PDF--essential-files-folder-delete-PDF-page">
        <path id="Subtract" fill="currentColor" fillRule="evenodd" d="M11.5 5.11611C11.0512 4.88222 10.5411 4.75 10 4.75c-1.35197 0 -2.51114 0.82552 -3.00092 2H6.5c-0.9665 0 -1.75 0.7835 -1.75 1.75 0 0.6981 0.40876 1.30073 1 1.5816V12.5c0 0.3528 0.06643 0.6901 0.18747 1H1.5c-0.26522 0 -0.51957 -0.1054 -0.707107 -0.2929C0.605357 13.0196 0.5 12.7652 0.5 12.5v-11c0 -0.26522 0.105357 -0.51957 0.292893 -0.707107C0.98043 0.605357 1.23478 0.5 1.5 0.5H8L11.5 4v1.11611Z" clipRule="evenodd" strokeWidth="0"></path>
        <path id="Rectangle 609" fill="none" d="M7.5 8.5h5v4c0 0.5523 -0.4477 1 -1 1h-3c-0.55228 0 -1 -0.4477 -1 -1v-4Z" strokeWidth="1"></path>
        <path id="Rectangle 610" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M7.5 8.5h5v4c0 0.5523 -0.4477 1 -1 1h-3c-0.55228 0 -1 -0.4477 -1 -1v-4Z" strokeWidth="1"></path>
        <path id="Vector 1264" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M13.5 8.5h-7" strokeWidth="1"></path>
        <path id="Vector 1265" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M8.5 8.5V8c0 -0.82843 0.67157 -1.5 1.5 -1.5v0c0.8284 0 1.5 0.67157 1.5 1.5v0.5" strokeWidth="1"></path>
        <path id="Vector" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M11.5 4.5V4L8 0.5H1.5C1.23478 0.5 0.98043 0.605357 0.792893 0.792893 0.605357 0.98043 0.5 1.23478 0.5 1.5v11c0 0.2652 0.105357 0.5196 0.292893 0.7071 0.187537 0.1875 0.441887 0.2929 0.707107 0.2929h4" strokeWidth="1"></path>
    </g>
</svg>
);

export default Delete;
