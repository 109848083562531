import mitt, { Emitter } from 'mitt';
import { VersioningType } from '@/types/stores/forms';
import { Page } from '@/models/page';
import { Funnel } from '@/models/funnel';
import { SimpleFlowPath } from '@/models/simpleFlowPath';
import { SimpleFlowRule } from '@/models/simpleFlowRules';
import { FunnelCondition } from '@/models/funnelCondition';
import { PageGroup } from '@/models/pageGroup';
import { TrafficSource } from '@/models/trafficSource';
import { OfferSource } from '@/models/offerSource';
import { PageCategoryInfo } from '@/models/pageCategoryInfo';
import { OfferSourceCategoryInfo } from '@/models/offerSourceCategoryInfo';
import { TrafficSourceCategoryInfo } from '@/models/trafficSourceCategoryInfo';
import { FunnelConditionCategoryInfo } from '@/models/funnelConditionCategoryInfo';
import { FunnelGroupInfo } from '@/models/funnelGroupInfo';
import { FunnelGroup } from '@/models/funnelGroup';

type onSimpleFlowPathSaveArgs = {
  path: SimpleFlowPath;
  updatedGroupIds: string[]
}

type PageWithCategory<T> = {
  data: T
  category: PageCategoryInfo;
}

type OfferSourceWithCategory<T> = {
  data: T;
  category: OfferSourceCategoryInfo;
};

type TrafficSourceWithCategory<T> = {
  data: T;
  category: TrafficSourceCategoryInfo;
};

type ConditionWithCategory<T> = {
  data: T;
  category: FunnelConditionCategoryInfo;
};

type FunnelWithFunnelGroup<T> = {
  data: T;
  funnelGroup: FunnelGroupInfo;
};

type Events = {
  // funnel groups
  onFunnelGroupSave: FunnelGroup;
  onFunnelGroupCreate: FunnelGroup;
  onFunnelGroupUpdate: FunnelGroup;
  // funnels
  onFunnelSave: Funnel;
  onFunnelCreate: FunnelWithFunnelGroup<Funnel>;
  onFunnelUpdate: Funnel;
  // traffic sources
  onTrafficSourceCreate: TrafficSourceWithCategory<TrafficSource>;
  onTrafficSourceUpdate: TrafficSource;
  onTrafficSourceSave: TrafficSource;
  // landers
  onLanderCreate: PageWithCategory<Page>;
  onLanderUpdate: Page;
  onLanderSave: Page;
  // offers
  onOfferCreate: PageWithCategory<Page>;
  onOfferUpdate: Page;
  onOfferSave: Page;
  // offer sources
  onOfferSourceCreate: OfferSourceWithCategory<OfferSource>;
  onOfferSourceUpdate: OfferSource;
  onOfferSourceSave: OfferSource;
  // page groups
  onPageGroupCreate: PageGroup;
  onPageGroupUpdate: PageGroup;
  onPageGroupSave: PageGroup;
  // versioning
  onVersioningConfirm: VersioningType;
  onVersioningCancel: VersioningType;
  // categories
  onCategorySave: void;
  // simple flow paths
  onSimpleFlowPathSave: onSimpleFlowPathSaveArgs;
  // simple flow rules
  onSimpleFlowRuleSave: SimpleFlowRule;
  // funnel conditions
  onConditionCreate: ConditionWithCategory<FunnelCondition>;
  onConditionUpdate: FunnelCondition;  
  // funnel editor
  onNodeCreateError: { id: string, message: string };
};

const emitter: Emitter<Events> = mitt<Events>();

const useMitt = () => {
  return emitter;
}

export default useMitt;