import { User } from '@/models/user';

export const identifyCanny = (user: User) => {
  if (!window.Canny) return;
  window.Canny('identify', {
    appID: '59e7472bc5a0712fda9d076c',
    user: {
      email: user.email,
      name: user.name,
      id: user.user_id,
    },
  });
  window.Canny('initChangelog', {
    appID: '59e7472bc5a0712fda9d076c',
    position: 'bottom',
    align: 'left',
    theme: 'light',
  });
};
