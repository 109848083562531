const Reporting = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30">
    <g id="content-statistic--product-data-analysis-analytics-graph-line-business-board-chart">
      <path
        id="Vector"
        fill="currentColor"
        d="m2.9860714285714285 26.874642857142856 21.89785714285714 0c1.0994715 0 1.9907142857142857 -0.8912427857142856 1.9907142857142857 -1.9907142857142857l0 -21.89785714285714c0 -1.0994416392857143 -0.8912427857142856 -1.9907142857142857 -1.9907142857142857 -1.9907142857142857l-21.89785714285714 0c-1.0994416392857143 0 -1.9907142857142857 0.8912726464285714 -1.9907142857142857 1.9907142857142857l0 21.89785714285714c0 1.0994715 0.8912726464285714 1.9907142857142857 1.9907142857142857 1.9907142857142857Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m2.9860714285714285 26.874642857142856 21.89785714285714 0c1.0994715 0 1.9907142857142857 -0.8912427857142856 1.9907142857142857 -1.9907142857142857l0 -21.89785714285714c0 -1.0994416392857143 -0.8912427857142856 -1.9907142857142857 -1.9907142857142857 -1.9907142857142857l-21.89785714285714 0c-1.0994416392857143 0 -1.9907142857142857 0.8912726464285714 -1.9907142857142857 1.9907142857142857l0 21.89785714285714c0 1.0994715 0.8912726464285714 1.9907142857142857 1.9907142857142857 1.9907142857142857Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_3"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5.972142857142857 5.972142857142857h3.9814285714285713"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_4"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5.972142857142857 10.94892857142857h8.958214285714286"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m22.893214285714286 10.94892857142857 -5.972142857142857 9.95357142857143 -6.967499999999999 -3.9814285714285713 -3.9814285714285713 5.972142857142857"
        strokeWidth="2.13"
      ></path>
    </g>
  </svg>
);

export default Reporting;
