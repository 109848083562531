const VisibilityWrapper = ({
  visible,
  children,
  beRenderedAlways = false,
  style,
}: {
  style?: React.CSSProperties,
  visible: boolean;
  children: JSX.Element;
  beRenderedAlways?: boolean;
}) => {
  const displayStyle = visible ? {} : { display: 'none' };

  if (beRenderedAlways) {
    return <div style={{ ...displayStyle, ...style, width: '100%' }}>{children}</div>;
  }
  if (!visible) {
    return null;
  }

  return children;
};

export default VisibilityWrapper;
