/**
 * FunnelFlux Stats API
 * API endpoints to manage stats and run statistic reports
 *
 * OpenAPI spec version: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ConvertedHit } from './convertedHit';


export interface ConversionsUpdateParams { 
    hits: Array<ConvertedHit>;
    /**
     * Call the traffic source postbacks of the sources that generated theses conversions. 
     * If set to 'none', then the postbacks are not called for any of the specified hits. 
     * If set to 'onlyOnce', then the postbacks are only called for the hits that never 
     * called them before. If set to 'all', 
     * the postbacks are called for all the specified hits, 
     * even if they were already called in the past for some/all hits.
     */
    postbackCalls?: ConversionsUpdateParams.PostbackCallsEnum;
    overridePayout?: string;
}
export namespace ConversionsUpdateParams {
    export type PostbackCallsEnum = 'none' | 'onlyOnce' | 'all';
    export const PostbackCallsEnum = {
        None: 'none' as PostbackCallsEnum,
        OnlyOnce: 'onlyOnce' as PostbackCallsEnum,
        All: 'all' as PostbackCallsEnum
    };
}
