import useHttp from '@/hooks/http';
import useHttpNotification from '@/hooks/useHttpError';
import useHttpNotificationNew from '@/hooks/useHttpNotification';
import { FunnelCondition } from '@/models/funnelCondition';
import { useMutation } from '@tanstack/react-query';

export function useConditionCreateMutation() {
  const { post } = useHttp();
  const { handleAssetNotification } = useHttpNotification();
  return useMutation<FunnelCondition, Error, FunnelCondition>({
    mutationKey: ['condition', 'create'],
    mutationFn: async (condition: FunnelCondition) => {
      const { data } = await post<FunnelCondition, FunnelCondition>('v1/campaign/funnel/condition/save/', condition);
      return data;
    },
    onError: (error: any, data) => {
      handleAssetNotification(data, 'added', 'condition', error);
    },
    onSuccess: (_, data) => {
      handleAssetNotification(data, 'added', 'condition');
    },
  });
}

export function useConditionUpdateMutation() {
  const { put } = useHttp();
  const { handleAssetNotification } = useHttpNotification();
  return useMutation<FunnelCondition, Error, FunnelCondition>({
    mutationKey: ['condition', 'update'],
    mutationFn: async (condition: FunnelCondition) => {
      const { data } = await put<FunnelCondition, FunnelCondition>('v1/campaign/funnel/condition/save/', condition);
      return data;
    },
    onError: (error: any, data) => {
      handleAssetNotification(data, 'updated', 'condition', error);
    },
    onSuccess: (_, data) => {
      handleAssetNotification(data, 'updated', 'condition');
    },
  });
}

export function useConditionDuplicateMutation(previousIdCondition: string) {
  const { post } = useHttp();
  const { handleAssetNotification } = useHttpNotification();
  return useMutation<FunnelCondition, Error, FunnelCondition>({
    mutationKey: ['condition', 'duplicate'],
    mutationFn: async (condition: FunnelCondition) => {
      const { data } = await post<FunnelCondition, FunnelCondition>(`v1/campaign/funnel/condition/duplicate?idCondition=${previousIdCondition}`, condition);
      return data;
    },
    onError: (error: any, data) => {
      handleAssetNotification(data, 'duplicated', 'condition', error);
    },
    onSuccess: (_, data) => {
      handleAssetNotification(data, 'duplicated', 'condition');
    },
  });
}

export function useConditionDeleteMutation() {
  const { del } = useHttp();
  const { showNotification } = useHttpNotificationNew('DeleteConditions');
  return useMutation<null, Error, string[]>({
    mutationKey: ['condition', 'delete'],
    mutationFn: async (ids: string[]) => {
      await del('v1/campaign/funnel/condition/delete/', {
        data: { entries: ids },
      });
      return null;
    },
    onError: (error: any, ids) => {
      showNotification({ error, params: { entries: ids } });
    },
    onSuccess: (_, ids) => {
      showNotification({ params: { entries: ids } });
    },
  });
}
