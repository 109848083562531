export const CUSTOM_EVENT_LOGOUT_ON_AUTH_ERROR = 'custom_event_logout_on_auth_error';

export const defaultCustomEventAliases = {
    '1': {
      alias: '',
      shortAlias: ''
    },
    '2': {
      alias: '',
      shortAlias: ''
    },
    '3': {
      alias: '',
      shortAlias: ''
    },
    '4': {
      alias: '',
      shortAlias: ''
    },
    '5': {
      alias: '',
      shortAlias: ''
    },
    '6': {
      alias: '',
      shortAlias: ''
    },
    '7': {
      alias: '',
      shortAlias: ''
    },
    '8': {
      alias: '',
      shortAlias: ''
    },
    '9': {
      alias: '',
      shortAlias: ''
    },
    '10': {
      alias: '',
      shortAlias: ''
    }
  };