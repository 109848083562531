import React, { useState, useEffect } from 'react';

interface ImageAsyncSrcProps {
  src: string;
  alt: string;
  className?: string;
}

const images = import.meta.glob('/src/imgs/**/*.*', { eager: true, query: '?url', import: 'default' });

const ImageAsyncSrc: React.FC<ImageAsyncSrcProps> = ({ src, alt, className }) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const loadImage = () => {
      try {
        const extensions = ['', '.png', '.jpg', '.jpeg', '.svg', '.gif', '.webp'];
        for (const ext of extensions) {
          const key = `/src/imgs/${src}${ext}`;
          if (images[key]) {
            //@ts-ignore
            setImageSrc(images[key]);
            setError(false);
            return;
          }
        }
        if (src.startsWith('http') || src.startsWith('/')) {
          setImageSrc(src);
          return;
        }
        
        setError(true);
      } catch (err) {
        setError(true);
      }
    };

    loadImage();
  }, [src]);

  if (error || !imageSrc) {
    return null;
  }

  return <img src={imageSrc} alt={alt} className={className} />;
};

export default ImageAsyncSrc;
