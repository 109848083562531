import React from 'react';

const Pause = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <g>
      <path
        id="Union"
        fill="currentColor"
        fillRule="evenodd"
        d="M3.4285714285714284 0.8571428571428571C2.0084057142857143 0.8571428571428571 0.8571428571428571 2.0084057142857143 0.8571428571428571 3.4285714285714284v17.142857142857142c0 1.4201142857142857 1.1512628571428571 2.571428571428571 2.571428571428571 2.571428571428571h4.285714285714286c1.4201657142857143 0 2.571428571428571 -1.1513142857142855 2.571428571428571 -2.571428571428571V3.4285714285714284C10.285714285714285 2.0084057142857143 9.134451428571428 0.8571428571428571 7.7142857142857135 0.8571428571428571H3.4285714285714284Zm12.857142857142856 0C14.865548571428569 0.8571428571428571 13.714285714285714 2.0084057142857143 13.714285714285714 3.4285714285714284v17.142857142857142c0 1.4201142857142857 1.1512628571428571 2.571428571428571 2.571428571428571 2.571428571428571H20.57142857142857c1.4201142857142857 0 2.571428571428571 -1.1513142857142855 2.571428571428571 -2.571428571428571V3.4285714285714284c0 -1.4201657142857143 -1.1513142857142855 -2.571428571428571 -2.571428571428571 -2.571428571428571H16.285714285714285Z"
        clipRule="evenodd"
        strokeWidth="1"
      ></path>
    </g>
  </svg>
);

export default Pause;
