const RedirectLinks = () => (
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
    <g id="shuffle-line-arrow--multimedia-shuffle-multi-button-controls-media">
        <path id="Vector" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="m11.5 1.5 2 2 -2 2" strokeWidth="1"></path>
        <path id="Vector_2" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M8.5 3.5h5" strokeWidth="1"></path>
        <path id="Vector_3" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="m11.5 8.5 2 2 -2 2" strokeWidth="1"></path>
        <path id="Vector_4" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M0.5 3.5H4l4 7h5.5" strokeWidth="1"></path>
        <path id="Vector_5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M0.5 10.5H4" strokeWidth="1"></path>
    </g>
</svg>
  );
  
  export default RedirectLinks;