/**
 * FunnelFlux Pro Ledger API
 * API endpoints to manage customers' assets
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface TrafficFilter { 
    /**
     * ID of the traffic filter
     */
    idTrafficFilter: string;
    /**
     * Traffic filter's name.
     */
    trafficFilterName: string;
    filterType: TrafficFilter.FilterTypeEnum;
    /**
     * The list of entries to filter against. If filterType = ipRanges, then each entry of the list must be formatted as 'rangeStart, rangeEnd', with a comma to separate the range's start and end. If filter type = knownBotsAndSpiders, then this list must be empty.
     */
    filterEntries?: Array<string>;
    /**
     * If set to an actual URL, then the filtered traffic is also redirected to that URL instead of flowing in the funnel
     */
    redirectToURL?: string;
    status?: TrafficFilter.StatusEnum;
    /**
     * DEPRECATED
     */
    isArchived?: boolean;
}
export namespace TrafficFilter {
    export type FilterTypeEnum = 'ipAddresses' | 'ipRanges' | 'referrers' | 'userAgents' | 'ISPs' | 'countries' | 'knownBotsAndSpiders';
    export const FilterTypeEnum = {
        IpAddresses: 'ipAddresses' as FilterTypeEnum,
        IpRanges: 'ipRanges' as FilterTypeEnum,
        Referrers: 'referrers' as FilterTypeEnum,
        UserAgents: 'userAgents' as FilterTypeEnum,
        ISPs: 'ISPs' as FilterTypeEnum,
        Countries: 'countries' as FilterTypeEnum,
        KnownBotsAndSpiders: 'knownBotsAndSpiders' as FilterTypeEnum
    };
    export type StatusEnum = 'active' | 'archived' | 'deleted';
    export const StatusEnum = {
        Active: 'active' as StatusEnum,
        Archived: 'archived' as StatusEnum,
        Deleted: 'deleted' as StatusEnum
    };
}
