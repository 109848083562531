/**
 * FunnelFlux Pro Ledger API
 * API endpoints to manage customers' assets
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FunnelCodeSnippetJavascriptParams } from './funnelCodeSnippetJavascriptParams';


export interface FunnelCodeSnippet { 
    /**
     * ID of the funnel
     */
    idCode: string;
    codeName: string;
    /**
     * If codeType = 'javascript' then codeJavascriptParams is required
     */
    codeType: FunnelCodeSnippet.CodeTypeEnum;
    codeContent: string;
    codeJavascriptParams?: FunnelCodeSnippetJavascriptParams;
}
export namespace FunnelCodeSnippet {
    export type CodeTypeEnum = 'javascript' | 'php';
    export const CodeTypeEnum = {
        Javascript: 'javascript' as CodeTypeEnum,
        Php: 'php' as CodeTypeEnum
    };
}
