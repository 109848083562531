const AssetLogos = {
  ROOT: (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1">
      <g>
        <defs>
          <linearGradient id="traffic-gradient" x1="26.08" x2="-6.293" y1="29.083" y2="6.088" gradientUnits="userSpaceOnUse">
            <stop stopColor="#ff9a23"></stop>
            <stop offset="1" stopColor="#ff0843"></stop>
          </linearGradient>
        </defs>
        <path
          id="Union"
          fill="url(#traffic-gradient)"
          fillRule="evenodd"
          d="M12 0c1.3401 0 2.6289.219674 3.8324.624978L13.3559 3.10146C12.9137 3.03464 12.4609 3 12 3c-4.97056 0-9 4.02944-9 9 0 4.9706 4.02944 9 9 9 4.9706 0 9-4.0294 9-9 0-.4609-.0346-.9137-.1015-1.3559l2.4765-2.47648C23.7803 9.37107 24 10.6599 24 12c0 6.6274-5.3726 12-12 12-6.62742 0-12-5.3726-12-12C0 5.37258 5.37258 0 12 0Zm0 5.25c.0707 0 .1411.00109.2112.00324l.3822 1.91073-1.1232 1.12316C9.65003 8.54451 8.25 10.1088 8.25 12c0 2.0711 1.67893 3.75 3.75 3.75 1.8912 0 3.4555-1.4 3.7129-3.2202l1.1231-1.1232 1.9108.3822c.0021.0701.0032.1405.0032.2112 0 3.7279-3.0221 6.75-6.75 6.75-3.72792 0-6.75-3.0221-6.75-6.75 0-3.72792 3.02208-6.75 6.75-6.75ZM19.2141.0716553l.3665 1.8322347.4192 2.09628 2.0963.41925 1.8323.36645-1.3213 1.32124-3.3 3.3-.3784.37843-.5248-.10496-2.2252-.44504-3.4716 3.47156-1.4142-1.4142 3.4716-3.47157-.4451-2.22521-.1049-.52479.3784-.37843 3.3-3.3L19.2141.0716553Z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  ),
  ROTATOR: (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" strokeWidth="1">
      <defs>
        <linearGradient id="rotator-gradient" x1="45.085" x2="-7.003" y1="46.906" y2="19.795" gradientUnits="userSpaceOnUse">
          <stop stopColor="#f8a902"></stop>
          <stop offset="1" stopColor="#f3696e"></stop>
        </linearGradient>
      </defs>
      <g>
        <path id="Union" fill="url(#rotator-gradient)" d="M19.4702 35.9439c1.6818.2419 2.2868 1.8903 1.2276 3.2189-.6722.843-1.5801 1.9206-2.797 3.2678-1.6161 1.789-2.9222 2.9718-3.8912 3.7411-1.2164.9656-2.8138.9658-4.03029.0001-.96906-.7692-2.27515-1.952-3.89105-3.7412-1.21639-1.3468-2.12399-2.4241-2.7961-3.2671-1.05952-1.3288-.45447-2.9778 1.22774-3.2198 1.01564-.1461 2.32441-.2808 3.98057-.3625l0-10.5792c0-2.7565 1.35363-6.5202 4.55483-7.7905 1.7887-.7097 4.273-1.4208 7.4452-1.7087V3.66358c0-1.29059.8329-2.44206 2.1157-2.58414.42-.04652.8864-.07749 1.3838-.07749.4975 0 .9641.03098 1.3843.07753 1.283.14211 2.1162 1.29374 2.1162 2.58454l0 11.83878c3.1722.2879 5.6565.999 7.4451 1.7087 3.2012 1.2703 4.5549 5.034 4.5549 7.7905v10.5769c1.653.0817 2.9597.2163 3.974.3621 1.6822.242 2.2872 1.891 1.2277 3.2198-.6721.843-1.5797 1.9204-2.7961 3.2671-1.6159 1.7892-2.922 2.972-3.891 3.7412-1.2165.9657-2.8139.9656-4.0303-.0001-.969-.7693-2.2751-1.952-3.8912-3.7411-1.2169-1.3471-2.1248-2.4248-2.797-3.2678-1.0592-1.3285-.4542-2.9769 1.2276-3.2189 1.0147-.146 2.3221-.2806 3.9763-.3624l0-10.076c0-1.0206-.4583-1.458-.7678-1.5808-1.668-.6619-3.907-1.4192-7.7327-1.4192-3.8258 0-6.0637.7573-7.7317 1.4192-.3096.1228-.7678.5602-.7678 1.5808l0 10.079c1.6511.0818 2.9563.2163 3.9697.3621Z"></path>
      </g>
    </svg>
  ),
  ROTATOR_AI: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 14 14"
      id="Ai-Chip-Spark--Streamline-Core-Gradient.svg"
      strokeWidth="1"
    >
      <defs>
        <linearGradient id="rotator-ai-gradient" x1="13.953" x2="-2.626" y1="14.003" y2="4.677" gradientUnits="userSpaceOnUse">
          <stop stopColor="#ff51e3"></stop>
          <stop offset="1" stopColor="#1b4dff"></stop>
        </linearGradient>
      </defs>
      <g>
        <path
          id="Subtract"
          fill="url(#rotator-ai-gradient)"
          fillRule="evenodd"
          d="M12.375 3.20833v1.05565h.875c.4142 0 .75.33578.75.75 0 .41421-.3358.75-.75.75h-.875v2.47204h.875c.4142 0 .75.33579.75.75 0 .41422-.3358.75-.75.75h-.875v1.05568c0 .8744-.7089 1.5833-1.5833 1.5833H9.73608v.875c0 .4142-.33578.75-.75.75-.41421 0-.75-.3358-.75-.75v-.875H5.76392v.875c0 .4142-.33579.75-.75.75-.41422 0-.75-.3358-.75-.75v-.875H3.20833c-.87445 0-1.58333-.7089-1.58333-1.5833V9.73602H.75c-.414214 0-.75-.33578-.75-.75 0-.41421.335786-.75.75-.75h.875V5.76398H.75c-.414214 0-.75-.33579-.75-.75 0-.41422.335786-.75.75-.75h.875V3.20833c0-.87445.70888-1.58333 1.58333-1.58333h1.05559V.75c0-.414214.33578-.75.75-.75.41421 0 .75.335787.75.75v.875h2.47216V.75c0-.414214.33579-.75.75-.75.41422 0 .75.335787.75.75v.875h1.05562c.8744 0 1.5833.70888 1.5833 1.58333ZM6.25063 4.60407c.17253-.80144 1.30809-.80723 1.48771-.00647l.02023.0902c.18219.81224.82404 1.42706 1.62014 1.5679.82839.14654.82839 1.34206 0 1.48861-.7961.14083-1.43795.75565-1.62014 1.56789l-.02023.0902c-.17962.8008-1.31518.795-1.48771-.00647l-.01666-.0774c-.17549-.81518-.81647-1.43446-1.61374-1.5755-.82697-.1463-.82698-1.33976 0-1.48606.79727-.14104 1.43825-.76032 1.61374-1.57549l.01666-.07741Z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  ),
  CONDITION: (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" strokeWidth="1">
      <defs>
        <linearGradient id="condition-gradient" x1="13.953" x2="-2.626" y1="14.003" y2="4.677" gradientUnits="userSpaceOnUse">
          <stop stopColor="#ff9eb9"></stop>
          <stop offset="1" stopColor="#ff4799"></stop>
        </linearGradient>
      </defs>
      <g>
        <path
          id="Union"
          fill="url(#condition-gradient)"
          fillRule="evenodd"
          d="M.5 0C.312706 0 .141118.104674.0554162.271209-.030286.437745-.015729.638212.0931333.790619L5 7.66023V13.5c0 .1844.10149.3538.26407.4408.16258.087.35985.0775.51328-.0248l3-2C8.91645 11.8233 9 11.6672 9 11.5V7.66023L13.9069.790619c.1088-.152407.1234-.352874.0377-.51941C13.8589.104674 13.6873 0 13.5 0H.5Z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  ),
  LANDER: (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" strokeWidth="1">
      <defs>
        <linearGradient id="lander-gradient" x1="5.075" x2="54.662" y1="4.298" y2="28.678" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00b8d0"></stop>
          <stop offset="1" stopColor="#007df0"></stop>
        </linearGradient>
      </defs>
      <g>
        <path
          id="Union"
          fill="url(#lander-gradient)"
          fillRule="evenodd"
          d="M27.1366.52331c.1209.001825.2427.006554.3653.01447.0243 3.50166.2788 7.17495.5246 9.95532.3057 3.4583 3.0224 6.1749 6.4807 6.4806 2.7711.2449 6.4293.4985 9.9205.5242.0049.0888.0083.1773.0101.2653C44.477 19.643 44.5 21.7185 44.5 24c0 8.0065-.2836 13.476-.5518 16.8578-.2605 3.2856-2.7873 5.8344-6.0739 6.1159-3.0401.2604-7.6649.5263-13.8743.5263s-10.8342-.2659-13.8743-.5263c-3.28661-.2815-5.81343-2.8303-6.07393-6.1159C3.78365 37.476 3.5 32.0065 3.5 24s.28365-13.476.55177-16.85779c.2605-3.28565 2.78732-5.83445 6.07393-6.11593C13.1658.765905 17.7906.5 24 .5c1.0948 0 2.1403.008266 3.1366.02331ZM38.0607 6.9403c2.8531 2.85308 4.5638 5.7096 5.4795 7.5469-3.0968-.057-6.2781-.2808-8.7688-.5009-2.0102-.1777-3.5788-1.7463-3.7565-3.7565-.2202-2.49072-.444-5.6721-.501-8.76886 1.8379.91589 4.6941 2.62666 7.5468 5.47936Z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  ),
  LANDER_GROUP: (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" strokeWidth="1">
      <defs>
        <linearGradient id="lander-group-gradient" x1="5.075" x2="54.662" y1="4.298" y2="28.678" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00b8d0"></stop>
          <stop offset="1" stopColor="#007df0"></stop>
        </linearGradient>
      </defs>
      <g>
        <path id="Union" d="M12.4023,44.3971c-5.9018,0,.1576-.2527-2.7319-.5002-3.1238-.2675-5.5255-2.6901-5.7731-5.813-.2548-3.2143-.5244-8.4128-.5244-16.0227s.2696-12.8085.5244-16.0227C4.1449,2.9156,6.3458.3928,9.4696.1252,10.2976-.0019,10.3843,0,16.2861,0c5.9018,0,5.914.0415,8.3527.2255,3.1238.2676,5.5254,2.6901,5.773,5.8129.2549,3.2143.5245,8.4128.5245,16.0227,0,2.1685-.0219,4.1412-.0591,5.9279-.0017.0836-.0049.1678-.0096.2522-3.3183.0244-6.7953.2655-9.4291.4982-3.287.2906-5.8691,2.8726-6.1597,6.1596-.2336,2.6426.4749,6.134.4518,9.4622-.1165.0075-.2323.012-.3472.0138-.9469.0143-1.9407.0222-2.9812.0222ZM17.6431,43.4838c.0542-2.9434.2669-5.9671.4762-8.3345.1689-1.9106,1.6598-3.4015,3.5704-3.5704,2.3673-.2092,5.391-.4219,8.3344-.4761-.8703,1.7463-2.4963,4.4613-5.2081,7.1731-2.7114,2.7114-5.4261,4.3374-7.173,5.2079Z" fill="#00689e" />
      </g>
      <g data-name="new-file--empty-common-file-content">
        <path id="Union-2" data-name="Union" d="M30.3633,3.3503c.1149.0017.2307.0062.3472.0138.0231,3.3282.265,6.8195.4986,9.4622.2906,3.287,2.8727,5.869,6.1597,6.1596,2.6338.2328,6.1108.4738,9.4291.4982.0047.0844.0079.1685.0096.2522.0373,1.7867.0591,3.7594.0591,5.9279,0,7.6099-.2696,12.8085-.5245,16.0227-.2476,3.1228-2.6492,5.5454-5.773,5.8129-2.8895.2475-7.2852.5002-13.187.5002s-10.2975-.2527-13.187-.5002c-3.1238-.2676-5.5255-2.6901-5.7731-5.8129-.2548-3.2143-.5244-8.4129-.5244-16.0227s.2696-12.8085.5244-16.0227c.2476-3.1229,2.6492-5.5454,5.7731-5.813,2.8895-.2475,7.2852-.5002,13.187-.5002,1.0406,0,2.0343.0079,2.9812.0222ZM40.7463,9.4494c2.7118,2.7118,4.3377,5.4268,5.2081,7.1731-2.9434-.0542-5.9671-.2669-8.3344-.4761-1.9106-.1689-3.4015-1.6598-3.5704-3.5704-.2093-2.3673-.422-5.3911-.4762-8.3345,1.7469.8705,4.4616,2.4965,7.173,5.2079Z" fill="url(#lander-group-gradient)" fillRule="evenodd" />
      </g>
    </svg>
  ),
  OFFER: (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" strokeWidth="1">
      <defs>
        <linearGradient id="offer-gradient" x1="10.202" x2="45.286" y1="9.538" y2="26.496" gradientUnits="userSpaceOnUse">
          <stop stopColor="#11d496"></stop>
          <stop offset="1" stopColor="#00b518"></stop>
        </linearGradient>
      </defs>
      <path
        fill="url(#offer-gradient)"
        fillRule="evenodd"
        d="M10.1257 1.02628C13.1658.765905 17.7906.5 24 .5c1.0948 0 2.1403.008266 3.1366.02331.8217.012409 1.6888.158989 2.5162.52914 1.7055.76297 5.0591 2.53812 8.4079 5.88689 3.2538 3.25376 5.0218 6.51196 5.8191 8.25786.3843.8413.5405 1.7245.558 2.566C44.477 19.643 44.5 21.7185 44.5 24c0 8.0065-.2836 13.476-.5518 16.8578-.2605 3.2856-2.7873 5.8344-6.0739 6.1159-3.0401.2604-7.6649.5263-13.8743.5263s-10.8342-.2659-13.8743-.5263c-3.28661-.2815-5.81343-2.8303-6.07393-6.1159C3.78365 37.476 3.5 32.0065 3.5 24s.28365-13.476.55177-16.85779c.2605-3.28565 2.78732-5.83445 6.07393-6.11593Zm1.3971 13.77012c-1.4367-.0715-2.5347-1.0476-2.58927-2.5708-.00988-.2758-.01556-.5806-.01556-.9168 0-.3361.00568-.6409.01556-.9167.05457-1.5232 1.15257-2.49928 2.58927-2.57082 7.0594-.35155 10.813-.35155 17.8724-.00001 1.4367.07155 2.5347 1.04763 2.5892 2.57083.0099.2758.0156.5806.0156.9167 0 .3362-.0057.641-.0156.9168-.0545 1.5232-1.1525 2.4993-2.5892 2.5708-7.0594.3515-10.813.3515-17.8724 0Z"
        clipRule="evenodd"
      ></path>
    </svg>
  ),
  OFFER_GROUP: (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" strokeWidth="1">
      <defs>
        <linearGradient id="offer-group-gradient" x1="10.202" x2="45.286" y1="9.538" y2="26.496" gradientUnits="userSpaceOnUse">
          <stop stopColor="#11d496"></stop>
          <stop offset="1" stopColor="#00b518"></stop>
        </linearGradient>
      </defs>
      <path d="M36.1208,44.9297c-2.9391.2517-8.4102.5088-14.4133.5088s-9.4742-.2571-12.4133-.5088c-3.1774-.2721-5.6203-2.7363-5.8721-5.9127-.2592-3.2694-.5334-8.5572-.5334-16.2977s.2742-13.0283.5334-16.2977C3.6739,3.245,6.1167.7809,9.2941.5088,12.2332.2571,16.7044,0,22.7075,0h5.5678c2.6918,0,4.7497,1.8,5.2497,5.8" fill="#00689e" fillRule="evenodd" />
      <rect x="12.1197" y="9.6828" width="24.1813" height="7.9134" fill="#fff" />
      <path d="M14.1792,4.4432c2.9391-.244,7.4102-.4932,13.4133-.4932,1.0584,0,2.0692.0077,3.0324.0218.7944.0116,1.6327.149,2.4326.4959,1.6488.7151,4.891,2.3788,8.1286,5.5174,3.1457,3.0495,4.855,6.1032,5.6258,7.7395.3715.7885.5225,1.6163.5395,2.4049.0379,1.7618.0601,3.707.0601,5.8453,0,7.504-.2742,12.6302-.5335,15.7997-.2518,3.0794-2.6947,5.4682-5.8721,5.732-2.9391.2441-7.4102.4933-13.4133.4933s-10.4742-.2492-13.4133-.4933c-3.1774-.2638-5.6203-2.6527-5.8721-5.732-.2592-3.1695-.5334-8.2957-.5334-15.7997s.2742-12.6302.5334-15.7997c.2518-3.0794,2.6947-5.4682,5.8721-5.7321ZM15.5299,17.3491c-1.389-.067-2.4505-.9818-2.5032-2.4094-.0096-.2585-.015-.5442-.015-.8593s.0055-.6007.015-.8592c.0528-1.4276,1.1143-2.3424,2.5032-2.4095,6.8249-.3295,10.4538-.3295,17.2786,0,1.389.0671,2.4505.9819,2.5032,2.4095.0096.2585.0151.5442.0151.8592s-.0055.6008-.0151.8593c-.0527,1.4276-1.1142,2.3424-2.5032,2.4094-6.8249.3294-10.4538.3294-17.2786,0Z" fill="url(#offer-group-gradient)" fillRule="evenodd" />
    </svg>
  ),
  EXTERNAL_URL: (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" strokeWidth="1">
      <g>
        <defs>
          <linearGradient id="external-url-gradient" x1="50.986" x2="-11.061" y1="56.743" y2="12.668" gradientUnits="userSpaceOnUse">
            <stop stopColor="#9bb2e5"></stop>
            <stop offset="1" stopColor="#698cbf"></stop>
          </linearGradient>
        </defs>
        <path
          id="Union"
          fill="url(#external-url-gradient)"
          fillRule="evenodd"
          d="M42.1902 15.7909c1.9 1.7369 4.3555.7947 4.5618-1.7713.0831-1.0334.1575-2.2521.2116-3.678.1239-3.26113-.0891-5.39193-.33-6.70165-.218-1.18549-1.0879-2.05552-2.2734-2.27359-1.3097-.24094-3.4406-.453996-6.7017-.32986-1.4252.05425-2.6434.12869-3.6764.21175-2.5665.20634-3.5092 2.66245-1.7719 4.56286.6083.66549 1.3272 1.4332 2.1753 2.31516-5.8041 5.83883-10.9688 11.18813-13.427 13.74813-.9493.9886-1.3426 2.4326-.5401 3.5436.5797.8027 1.3406 1.5655 2.1425 2.1485 1.1202.8143 2.58.4098 3.5716-.557 2.5492-2.4853 7.8567-7.6702 13.6976-13.4371.9008.867 1.6833 1.5999 2.3601 2.2185ZM22 5c-5.57 0-9.7018.1825-12.56045.38296-4.36854.30635-7.75024 3.68804-8.05659 8.05654C1.1825 16.2982 1 20.43 1 26c0 5.5699.1825 9.7018.38296 12.5604.30635 4.3686 3.68805 7.7503 8.05659 8.0566C12.2982 46.8175 16.43 47 22 47c5.57 0 9.7018-.1825 12.5604-.383 4.3686-.3063 7.7503-3.688 8.0566-8.0565C42.8175 35.7018 43 31.57 43 26c0-1.2063-.0086-2.3451-.024-3.4182-.0239-1.6567-1.3862-2.9803-3.0429-2.9565-1.6567.0239-2.9803 1.3862-2.9565 3.0429C36.9916 23.712 37 24.8221 37 26c0 5.4392-.1782 9.4308-.3683 12.1407-.0971 1.3857-1.1053 2.3939-2.491 2.491C31.4308 40.8218 27.4392 41 22 41c-5.4392 0-9.4308-.1782-12.14072-.3683-1.3857-.0971-2.39384-1.1053-2.49102-2.491C7.17822 35.4308 7 31.4392 7 26c0-5.4392.17822-9.4308.36826-12.1407.09718-1.3857 1.10532-2.3939 2.49102-2.491C12.5692 11.1782 16.5608 11 22 11c1.1779 0 2.288.0084 3.3318.0234 1.6567.0238 3.019-1.29984 3.0429-2.95652.0238-1.65668-1.2998-3.01902-2.9565-3.04287C24.3451 5.00856 23.2063 5 22 5Z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  ),
};

export const tableVisitorJourneyIcons = {
  rotator: AssetLogos.ROTATOR,
  rotatorAi: AssetLogos.ROTATOR_AI,
  condition: AssetLogos.CONDITION,
  landerGroup: AssetLogos.LANDER_GROUP,
  lander: AssetLogos.LANDER,
  offerGroup: AssetLogos.OFFER_GROUP,
  offer: AssetLogos.OFFER,
  externalUrl: AssetLogos.EXTERNAL_URL,
};

export default AssetLogos;
