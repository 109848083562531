import useHttp from '@/hooks/http';
import { Page } from '@/models/page';
import { PageCategoryInfo } from '@/models/pageCategoryInfo';
import { PageInfo } from '@/models/pageInfo';
import { useQuery } from '@tanstack/react-query';

export function useOfferQuery(idPage: string) {
  const { get } = useHttp();
  return useQuery({
    queryKey: ['offer', idPage],
    queryFn: async () => {
      const { data } = await get<Page>('v1/page/find/byId', {
        params: {
          idPage,
        },
      });
      return data;
    },
    enabled: !!idPage,
  });
}

export function useOfferCategoriesListQuery() {
  const { get } = useHttp();
  return useQuery({
    queryKey: ['offer', 'categoriesList'],
    queryFn: async () => {
      const { data } = await get<PageCategoryInfo[]>('v1/page/category/list', {
        params: {
          pageType: 'offer',
          status: 'active',
          includeChilds: true,
        },
      });
      return data;
    },
  });
}

export function useOfferListQuery() {
  const { get } = useHttp();
  return useQuery({
    queryKey: ['offer', 'list'],
    queryFn: async () => {
      const { data } = await get<PageInfo[]>('v1/page/list', {
        params: {
          pageType: 'offer',
          status: 'active',
        },
      });
      return data;
    },
  });
}
