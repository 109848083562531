import SectionBox from '@/components/SectionBox';
import { FunnelCondition } from '@/models/funnelCondition';
import useFormStore from '@/stores/forms';
import {
  FFButton,
  FFIconButton,
  FFCol,
  FFField,
  FFIcon,
  FFInput,
  FFRow,
  FFSelect,
  FFSidePanel,
  VisibilityWrapper,
  FFNewIcon,
} from '@/uikit';
import { SidebarCopyItems, SidebarTab } from '@/uikit/types/sidebar';
import className from '@/utils/className';
import { getSidebarOffsetLevel, getSidebarZIndex } from '@/utils/sidebar';
import { Badge } from 'antd';
import { Ref, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import Tooltip from '@/uikit/components/Tooltip';
import Skeleton from 'react-loading-skeleton';
import { InfoCircleOutlined } from '@ant-design/icons';
import ConditionRoute from './components/Route/Route';
import { FunnelConditionRoute } from '@/models/funnelConditionRoute';
import useSystemSettingsStore from '@/stores/systemSettings';
import useHttp from '@/hooks/http';
import { generateEntityId } from '@/utils/id';
import { serverVersionIsAheadOfLocal, withIncrementedVersion } from '@/utils/model';
import { useConditionCategoriesListQuery, useConditionQuery } from '@/api/queries/condition';
import { useTrafficSourcesCategoryListQuery } from '@/api/queries/trafficSource';
import useMitt from '@/hooks/mitt';
import { FFSelectOption } from '@/uikit/types/select';
import { useConditionCreateMutation, useConditionDuplicateMutation, useConditionUpdateMutation } from '@/api/mutations/condition';
import { addOrIncrementCopySuffix, trimStringPropertiesInObject } from '@/utils/string';
import { DEFAULT_CONDITION } from '@/constants/conditions';
import { CommonFormProps } from '@/types/forms/form';
import { getRouteName, isRouteNameAutoGenerated } from './utils';
import RedirectLinks from './components/RedirectLinks';
import { FunnelNode } from '@/models/funnelNode';
import { categoriesWithoutUncategorized } from '@/utils/array';
import './style.scss';

const { getClass } = className('c-conditionForm');

const defaultRoute = (idRoute: number): FunnelConditionRoute => ({
  routeName: `Route ${idRoute}`,
  operator: 'or',
  groups: [
    {
      operator: 'or',
      rules: [
        {
          attribute: '' as any,
          test: '' as any,
        },
      ],
    },
  ],
});

export type ConditionFormTabId = 'general' | 'redirectLinks' | 'help';

const ROUTES_TOOLTIP_CONTENT = (
  <span>
    Routes correspond to the outgoing connections from your condition node. For each route you can define rules that must be matched. Routes
    process in the order in the list below.
  </span>
);

export interface ConditionFormRefType {
  onSave: () => void;
}

const tabs: SidebarTab<ConditionFormTabId>[] = [
  {
    title: 'General Settings',
    tabId: 'general',
    icon: <FFNewIcon name="sidebar-tabs/general-settings" size="md" type="sidebartab" display="inline-block" />,
  },
  {
    title: 'Help',
    tabId: 'help',
    icon: <FFNewIcon name="sidebar-tabs/help" size="md" type="sidebartab" display="inline-block" />,
  },
];

interface FormProps extends Omit<CommonFormProps<FunnelCondition, ConditionFormTabId>, 'onUpdate'> {
  funnelNode?: FunnelNode;
  idFunnel?: string;
  onUpdate?: (condition: FunnelCondition, deletedRouteIds: number[]) => void;
}

export const ConditionFormSettings = forwardRef(
  (
    {
      currentTabId,
      isDuplication,
      saveMode = 'online',
      defaultValues,
      funnelNode,
      idFunnel = '',
      closeForm,
      setSubmitLoading,
      onUpdate = () => {},
      onCreate = () => {},
    }: FormProps,
    ref: Ref<ConditionFormRefType>,
  ) => {
    const http = useHttp();
    const emitter = useMitt();
    const {
      data: conditionCategoriesInfo = [],
      isLoading: conditionCategoriesInfoLoading,
      refetch: refetchConditionCategoriesInfo,
    } = useConditionCategoriesListQuery();
    const { data: trafficSourceCategoryList = [] } = useTrafficSourcesCategoryListQuery();
    const { mutateAsync: createCondition } = useConditionCreateMutation();
    const { mutateAsync: updateCondition } = useConditionUpdateMutation();
    const { mutateAsync: duplicateCondition } = useConditionDuplicateMutation(defaultValues?.idCondition);
    const [deletedRouteIds, setDeletedRouteIds] = useState<number[]>([]);

    const form = useForm<FunnelCondition>({
      defaultValues: defaultValues,
      shouldUnregister: false,
    });
    const routes = form.watch('routes');

    const isGlobalCondition = !form.getValues('restrictToFunnelId');
    const [latestVersionOfCondition, setLatestVersionOfCondition] = useState<FunnelCondition>();

    const { domains } = useSystemSettingsStore();
    const openCategoryForm = useFormStore((state) => state.openCategoryForm);
    const openVersioningForm = useFormStore((state) => state.openVersioningForm);
    const setVersioningType = useFormStore((state) => state.setVersioningType);

    useEffect(() => {
      emitter.on('onVersioningConfirm', () => {
        onSave(true);
      });
      emitter.on('onCategorySave', () => {
        refetchConditionCategoriesInfo();
      });
    }, []);

    const trafficSourceOptions = useMemo(() => {
      const options: FFSelectOption[] = [];
      trafficSourceCategoryList.forEach((category) => {
        (category.trafficSources || []).forEach((trafficSource) => {
          options.push({ value: trafficSource.idTrafficSource, label: trafficSource.trafficSourceName, category: category.categoryName });
        });
      });
      return options;
    }, [trafficSourceCategoryList]);

    const onAddRoute = () => {
      form.setValue('routes', [...form.getValues('routes'), defaultRoute(form.getValues('routes').length)]);
    };

    const onDeleteRoute = (idRoute: number) => {
      form.setValue(
        'routes',
        form.getValues('routes').filter((_, index) => index !== idRoute),
      );
      setDeletedRouteIds([...deletedRouteIds, idRoute]);
    };

    const onSwapUp = (idRoute: number) => {
      const routes = [...form.getValues('routes')];
      if (idRoute === 0) {
        return;
      }
      const route = routes[idRoute];
      routes[idRoute] = routes[idRoute - 1];
      routes[idRoute - 1] = route;
      form.setValue('routes', routes);
    };

    const onSwapDown = (idRoute: number) => {
      const routes = [...form.getValues('routes')];
      const route = routes[idRoute];
      if (idRoute === routes.length - 1) {
        return;
      }
      routes[idRoute] = routes[idRoute + 1];
      routes[idRoute + 1] = route;
      form.setValue('routes', routes);
    };

    const onAddRule = (idRoute: number, idGroup: number) => {
      const routes = [...form.getValues('routes')];
      routes[idRoute].groups[idGroup].rules.push({
        attribute: '' as any,
        test: '' as any,
      });
      form.setValue('routes', routes);
    };

    const onDeleteRule = (idRoute: number, idGroup: number, idRule: number) => {
      const routes = [...form.getValues('routes')];
      routes[idRoute].groups[idGroup].rules = routes[idRoute].groups[idGroup].rules.filter((_, index) => index !== idRule);
      form.setValue('routes', routes);
    };

    const onDeleteGroup = (idRoute: number, idGroup: number) => {
      const routes = [...form.getValues('routes')];
      routes[idRoute].groups.splice(idGroup, 1);
      form.setValue('routes', routes);
    };

    const onAddGroup = (idRoute: number) => {
      const routes = [...form.getValues('routes')];
      routes[idRoute].groups.push({
        operator: 'or',
        rules: [],
      });
      form.setValue('routes', routes);
    };

    const onSave = (isConfirmingVersioning = false) =>
      form.handleSubmit(async (data) => {
        setSubmitLoading(true);
        const createFn = saveMode === 'offline' ? onCreate : createCondition;
        const updateFn = saveMode === 'offline' ? onUpdate : updateCondition;

        try {
          const newID = generateEntityId();
          const model = trimStringPropertiesInObject(data, ['conditionName']);
          model.routes = model.routes.map((route, index) => {
            if (isRouteNameAutoGenerated(route.routeName)) {
              return { ...route, routeName: getRouteName(route, index, trafficSourceOptions) };
            }
            return route;
          });
          const updateModel = saveMode === 'offline' ? model : withIncrementedVersion(model);
          const duplicateModel: FunnelCondition = { ...model, meta: { version: 1 } };
          const createModel: FunnelCondition = { ...model, idCondition: newID, meta: { version: 1 } };
          const category = conditionCategoriesInfo.find((category) => category.idCategory === data.idCategory)!;

          if (isConfirmingVersioning) {
            try {
              let versionedUpdate = withIncrementedVersion(updateModel, latestVersionOfCondition?.meta?.version);
              await updateFn(versionedUpdate, deletedRouteIds);
              emitter.emit('onConditionUpdate', versionedUpdate);
            } catch (e) {
              //
            } finally {
              setSubmitLoading(false);
            }
            return;
          }
          if (isDuplication) {
            const duplicatedCondition = await duplicateCondition(duplicateModel);
            emitter.emit('onConditionCreate', { data: duplicatedCondition, category });
          } else if (model.idCondition) {
            const condition = await http.get<FunnelCondition>('v1/campaign/funnel/condition/find/byId', {
              params: { idCondition: data.idCondition },
            });
            setLatestVersionOfCondition(condition.data);
            if (data.meta && serverVersionIsAheadOfLocal(data.meta.version, condition.data.meta?.version)) {
              setVersioningType('condition');
              openVersioningForm();
              return;
            } else {
              await updateFn(data, deletedRouteIds);
              emitter.emit('onConditionUpdate', updateModel);
            }
          } else {
            await createFn(createModel);
            emitter.emit('onConditionCreate', { data: createModel, category });
          }

          closeForm();
        } catch (e) {
          //
        } finally {
          setSubmitLoading(false);
        }
      })();

    useImperativeHandle(ref, () => ({ onSave }));

    return (
      <FormProvider {...form}>
        <VisibilityWrapper style={{ flex: 1 }} visible={currentTabId === 'general'} beRenderedAlways>
          <FFCol gap="18px" className={getClass('container')}>
            <SectionBox title="General Settings" className={getClass('sectionBox')}>
              <Badge.Ribbon
                color={isGlobalCondition ? 'blue' : 'green'}
                text={
                  isGlobalCondition ? (
                    <FFRow alignItems="center" gap="4px">
                      <FFIcon name="global" size="small" />
                      Global
                    </FFRow>
                  ) : (
                    <FFRow alignItems="center" gap="4px">
                      <FFIcon name="local" size="small" />
                      Local
                    </FFRow>
                  )
                }
                className={getClass('ribbon')}
              />
              <FFCol gap="18px" height="max-content" flex="1">
                <Controller
                  name="conditionName"
                  control={form.control}
                  rules={{ required: 'Condition name is required' }}
                  render={(opt) => (
                    <FFField label="Name" block direction="row">
                      <FFInput
                        value={opt.field.value}
                        onChange={opt.field.onChange}
                        error={opt.fieldState.error?.message}
                        placeholder="Condition Name"
                      />
                    </FFField>
                  )}
                />
                {isGlobalCondition && (
                  <Controller
                    name="idCategory"
                    control={form.control}
                    render={(opt) => (
                      <FFField label="Category" block direction="row">
                        <FFSelect
                          options={categoriesWithoutUncategorized(conditionCategoriesInfo)}
                          defaultValue=""
                          valueGetter={(opt) => opt.idCategory}
                          labelGetter={(opt) => opt.categoryName}
                          value={opt.field.value}
                          showSearch
                          onChange={opt.field.onChange}
                          loading={conditionCategoriesInfoLoading}
                          disabled={conditionCategoriesInfoLoading}
                          defaultValueFallback={{
                            label: 'Uncategorized',
                            value: '',
                          }}
                          error={opt.fieldState.error?.message}
                          placeholder="Uncategorized"
                        />
                        <FFIconButton
                          buttonType="blue"
                          iconName="general/line/add-circle"
                          onClick={() => openCategoryForm('condition')}
                        />
                      </FFField>
                    )}
                  />
                )}
              </FFCol>
            </SectionBox>
            <div className={getClass('routes')}>
              <div className={getClass('routesHeader')}>
                <FFRow gap="8px">
                  <span>Routes</span>
                  <Tooltip title={ROUTES_TOOLTIP_CONTENT}>
                    <InfoCircleOutlined />
                  </Tooltip>
                </FFRow>
              </div>
              {routes.map((route, index) =>
                index === 0 ? (
                  <FFCol className={getClass('defaultRoute')} key={index}>
                    <FFRow gap="12px">
                      <span className={getClass('routeIndex')}>{index}</span>
                      Default Route
                    </FFRow>
                  </FFCol>
                ) : (
                  <>
                    <ConditionRoute
                      key={index}
                      idRoute={index}
                      route={route}
                      control={form.control}
                      onAddGroup={() => onAddGroup(index)}
                      onAddRule={(idGroup) => onAddRule(index, idGroup)}
                      onDeleteRule={(idGroup, idRule) => onDeleteRule(index, idGroup, idRule)}
                      onDeleteGroup={(idGroup) => onDeleteGroup(index, idGroup)}
                      onDelete={() => onDeleteRoute(index)}
                      watch={form.watch}
                      onSwapUp={onSwapUp}
                      onSwapDown={onSwapDown}
                      trafficSources={trafficSourceOptions}
                      domains={domains}
                    />
                  </>
                ),
              )}
              <FFRow justifyContent="center" alignItems="center" height="55px" backgroundColor="#fff">
                <FFButton onClick={onAddRoute}>Add Route</FFButton>
              </FFRow>
            </div>
          </FFCol>
        </VisibilityWrapper>
        <VisibilityWrapper beRenderedAlways visible={currentTabId === 'redirectLinks'}>
          <RedirectLinks
            idFunnel={idFunnel}
            nodeId={funnelNode?.idNode!}
          />
        </VisibilityWrapper>
        <VisibilityWrapper visible={currentTabId === 'help'} beRenderedAlways>
          <SectionBox title="Help">
            <FFCol gap="12px">
              <p>Conditions are nodes that you can use in funnels to make decisions.</p>
              <p>You define "Routes" which are the connections you make in the funnel builder from this node to others.</p>
              <p>Then, you define rules and criteria that must be met for that Route to be activated.</p>
              <p>
                Conditions process from the top down, so the earliest matching route will be returned. The default route will be returned if
                none match.
              </p>
              <p>
                Need more help? Read our documentation{' '}
                <a href="https://help.funnelflux.pro/article/101" target="_blank" rel="noopener noreferrer">
                  here
                </a>
              </p>
            </FFCol>
          </SectionBox>
        </VisibilityWrapper>
      </FormProvider>
    );
  },
);

const ConditionForm = () => {
  const [currentTabId, setCurrentTabId] = useState<ConditionFormTabId>('general');
  const isOpen = useFormStore((state) => state.condition.isGlobalOpen);
  const openedForms = useFormStore((state) => state.openedForms);
  const isDuplication = useFormStore((state) => state.condition.isDuplication);
  const closeForm = useFormStore((state) => state.closeConditionForm);
  const [submitLoading, setSubmitLoading] = useState(false);
  const conditionFormProps = useFormStore((state) => state.condition);
  const { data: condition = DEFAULT_CONDITION, isFetching } = useConditionQuery(conditionFormProps?.data?.id!);
  const formRef = useRef<ConditionFormRefType>({
    onSave: () => {},
  });

  const defaultFormValues = useMemo(() => {
    if (isDuplication) {
      return {
        ...condition,
        conditionName: addOrIncrementCopySuffix(condition?.conditionName || ''),
      };
    }
    return condition;
  }, [condition, isDuplication]);

  const copyItems: SidebarCopyItems[] = useMemo(() => {
    if (conditionFormProps.data?.id) {
      return [{ title: 'Condition ID', value: conditionFormProps.data.id }];
    }
    return [];
  }, [conditionFormProps.data?.id]);

  const sidebarTitle = useMemo(() => {
    if (isDuplication) {
      return 'Duplicate Condition';
    } else if (conditionFormProps.data?.id) {
      return 'Edit Condition';
    } else {
      return 'Create Condition';
    }
  }, [isDuplication, conditionFormProps.data?.id]);

  const onClose = () => {
    setCurrentTabId('general');
    setSubmitLoading(false);
    closeForm('global');
  };

  return (
    <>
      <FFSidePanel
        isOpen={isOpen}
        minWidth={600}
        maxWidth={1100}
        tabs={tabs}
        copyItems={copyItems}
        onClose={onClose}
        sidebarName="ConditionForm"
        currentTabId={currentTabId}
        offsetLevel={getSidebarOffsetLevel(openedForms, 'condition')}
        zIndex={getSidebarZIndex(openedForms, 'condition')}
        title={sidebarTitle}
        setCurrentTabId={(tabId) => setCurrentTabId(tabId as ConditionFormTabId)}
        actions={
          <FFRow gap="8px">
            <FFButton onClick={() => formRef?.current?.onSave()} loading={submitLoading} disabled={submitLoading}>
              Save
            </FFButton>
            <FFButton type="tertiary" disabled={submitLoading} onClick={onClose}>
              Cancel
            </FFButton>
          </FFRow>
        }
      >
        {isFetching ? (
          <Skeleton width="100%" height="400px" />
        ) : (
          <ConditionFormSettings
            defaultValues={defaultFormValues}
            currentTabId={currentTabId}
            closeForm={onClose}
            setSubmitLoading={setSubmitLoading}
            submitLoading={submitLoading}
            isDuplication={isDuplication}
            ref={formRef}
          />
        )}
      </FFSidePanel>
    </>
  );
};

export default ConditionForm;
