import { SystemSettingsStore, SystemSettingsStoreProps } from '@/types/stores/systemSettings';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

const DEFAULT_PROPS: SystemSettingsStoreProps = {
  domains: [],
  colorMode: 'light',
  systemColorMode: 'light',
  selectedColorMode: 'light',
  userSettings: {
    defaultHomepageURL: '',
    defaultOfferRedirect: '307',
    defaultLanderRedirect: '307',
    ipAnonymizer: 'All IPs',
    defaultCustomDomain: '',
    thirdPartyKeys: {
      clickbankIPN: '',
    },
    savedViews: [],
  },
};

const useSystemSettingsStore = create<SystemSettingsStore>()(
  devtools((set, get) => ({
    ...DEFAULT_PROPS,
    colorMode: get()?.selectedColorMode === 'system' ? get()?.systemColorMode : get()?.selectedColorMode,
    setDomains: (domains) => set({ domains }),
    setUserSettings: (userSettings) => set({ userSettings }),
    setSelectedColorMode: (colorMode) => set({ colorMode }),
    setSystemColorMode: (systemColorMode) => set({ systemColorMode }),
    addDomain: (domain) => set({ domains: [...get().domains, domain] }),
    deleteDomain: (domain) => set({ domains: get().domains.filter((item) => item.domain !== domain.domain) }),
  })),
);

export default useSystemSettingsStore;
