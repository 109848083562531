import { Suspense, useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { ConfigProvider, theme, notification, message } from 'antd';
import { FFButton, FFNewIcon, FFRow } from '@/uikit';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import useSystemSettingsStore from '@/stores/systemSettings';
import { SmileOutlined } from '@ant-design/icons';
import AntMessageContext from '@/contexts/antMessage';

const VERSION_HASH_KEY = 'versionHash';
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

type AppProviderProps = {
  children: React.ReactNode;
};

let currentVersionHash = localStorage.getItem(VERSION_HASH_KEY) || '';
const VERSION_UPDATE_NOTIFICATION_KEY = 'new-version-available';

const VersionNotificationButton = () => {
  const [isReloading, setIsReloading] = useState(false);

  const reload = async () => {
    setIsReloading(true);
    localStorage.setItem(VERSION_HASH_KEY, currentVersionHash);
    window.location.reload();
  };

  return <FFButton onClick={reload} loading={isReloading}>Update</FFButton>
}

export const AppProvider = ({ children }: AppProviderProps) => {
  const [messageApi, messageContextHolder] = message.useMessage({ maxCount: 3 });
  const colorMode = useSystemSettingsStore((state) => state.colorMode);
  const isDarkMode = colorMode === 'dark';
  const [api, notificationContextHolder] = notification.useNotification();
  
  const openNotification = () => {
    api.open({
      key: VERSION_UPDATE_NOTIFICATION_KEY,
      message: 'New Version Available',
      description:
        'A new version of FunnelFlux is available. Please update to the latest version to continue using the app.',
      icon: <SmileOutlined style={{ color: '#108ee9' }} />,
      btn: <VersionNotificationButton />,
      duration: 0,
      placement: 'bottomLeft'
    });
  };

  const checkForNewVersion = () => {
    const checkVersion = async () => {
      try {
        const response = await fetch('/version.json?t=' + new Date().getTime());
        const data = await response.json();
        api.destroy(VERSION_UPDATE_NOTIFICATION_KEY);
        if (!currentVersionHash) {
          currentVersionHash = data.hash;
          localStorage.setItem(VERSION_HASH_KEY, currentVersionHash);
        } else if (data.hash && data.hash !== currentVersionHash) {
          currentVersionHash = data.hash;
          openNotification();
        }
      } catch (error) {
        console.error('Version check failed:', error);
      }
    };
    checkVersion();
    setInterval(checkVersion, 3 * 60 * 1000);
  };

  useEffect(() => {
    checkForNewVersion();
  }, [])

  return (
    <Suspense
      fallback={
        <FFRow height="100vh" width="100vw" position="fixed" alignItems="center" justifyContent="center">
          <FFNewIcon name="general/app-loading" width={100} height={100} />
        </FFRow>
      }
    >
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <ConfigProvider
            theme={{
              algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
              token: {
                fontFamily: 'Noto Sans, Arial, Helvetica, sans-serif',
                fontSize: 14,
                fontSizeLG: 14,
                fontSizeSM: 14,
                controlHeight: 35,
              },
            }}
          >
            <AntMessageContext.Provider value={{ messageApi, messageContextHolder }}>
              {messageContextHolder}
              {notificationContextHolder}
              {children}
            </AntMessageContext.Provider>
            <ReactQueryDevtools initialIsOpen={false} />
          </ConfigProvider>
        </QueryClientProvider>
      </HelmetProvider>
    </Suspense>
  );
};
