const Domains = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30">
    <g id="web--server-world-internet-earth-www-globe-worldwide-web-network">
      <path
        id="Vector"
        fill="currentColor"
        d="M13.934999999999999 26.874642857142856c7.146465214285715 0 12.939642857142857 -5.793177642857143 12.939642857142857 -12.939642857142857C26.874642857142856 6.788634321428571 21.081465214285714 0.9953571428571428 13.934999999999999 0.9953571428571428 6.788634321428571 0.9953571428571428 0.9953571428571428 6.788634321428571 0.9953571428571428 13.934999999999999c0 7.146465214285715 5.793277178571428 12.939642857142857 12.939642857142857 12.939642857142857Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.934999999999999 26.874642857142856c7.146465214285715 0 12.939642857142857 -5.793177642857143 12.939642857142857 -12.939642857142857C26.874642857142856 6.788634321428571 21.081465214285714 0.9953571428571428 13.934999999999999 0.9953571428571428 6.788634321428571 0.9953571428571428 0.9953571428571428 6.788634321428571 0.9953571428571428 13.934999999999999c0 7.146465214285715 5.793277178571428 12.939642857142857 12.939642857142857 12.939642857142857Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_3"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M0.9953571428571428 13.934999999999999h25.879285714285714"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_4"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M18.911785714285713 13.934999999999999c-0.24445971428571428 4.73190795 -1.9873698857142856 9.263390785714286 -4.976785714285715 12.939642857142857C10.945584171428571 23.198390785714285 9.202674 18.66690795 8.958214285714286 13.934999999999999c0.24445971428571428 -4.73190795 1.9873698857142856 -9.26349032142857 4.976785714285715 -12.939642857142857C16.92441582857143 4.671509678571428 18.667326 9.203092049999999 18.911785714285713 13.934999999999999v0Z"
        strokeWidth="2.13"
      ></path>
    </g>
  </svg>
);

export default Domains;
