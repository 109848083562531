const Info = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.57 -0.57 16 16">
    <g>
      <path
        id="Vector"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.43 14.329285714285714c3.8104224285714285 0 6.899285714285714 -3.0888632857142855 6.899285714285714 -6.899285714285714C14.329285714285714 3.6196306428571425 11.240422428571428 0.5307142857142857 7.43 0.5307142857142857 3.6196306428571425 0.5307142857142857 0.5307142857142857 3.6196306428571425 0.5307142857142857 7.43c0 3.8104224285714285 3.088916357142857 6.899285714285714 6.899285714285714 6.899285714285714Z"
        strokeWidth="1.14"
      ></path>
      <path
        id="Vector_2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5.837857142857143 10.614285714285714h3.184285714285714"
        strokeWidth="1.14"
      ></path>
      <path
        id="Vector_3"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.43 10.614285714285714V6.899285714285714H6.368571428571428"
        strokeWidth="1.14"
      ></path>
      <g id="Group 2605">
        <path
          id="Ellipse 1111"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M7.43 4.511071428571428c-0.14655144285714286 0 -0.26535714285714285 -0.1188057 -0.26535714285714285 -0.26535714285714285s0.1188057 -0.26535714285714285 0.26535714285714285 -0.26535714285714285"
          strokeWidth="1.14"
        ></path>
        <path
          id="Ellipse 1112"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M7.43 4.511071428571428c0.14655144285714286 0 0.26535714285714285 -0.1188057 0.26535714285714285 -0.26535714285714285s-0.1188057 -0.26535714285714285 -0.26535714285714285 -0.26535714285714285"
          strokeWidth="1.14"
        ></path>
      </g>
    </g>
  </svg>
);

export default Info;