const Billing = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30">
    <g id="money-transfer--money-transfer-arrow-cash">
      <path
        id="Vector"
        fill="currentColor"
        d="M24.88392857142857 5.972142857142857h-21.89785714285714c-1.0994416392857143 0 -1.9907142857142857 0.8912826 -1.9907142857142857 1.9907142857142857v11.944285714285714c0 1.0994715 0.8912726464285714 1.9907142857142857 1.9907142857142857 1.9907142857142857h21.89785714285714c1.0994715 0 1.9907142857142857 -0.8912427857142856 1.9907142857142857 -1.9907142857142857V7.962857142857143c0 -1.0994316857142856 -0.8912427857142856 -1.9907142857142857 -1.9907142857142857 -1.9907142857142857Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M22.893214285714286 5.972142857142857h-19.90714285714286c-1.0994416392857143 0 -1.9907142857142857 0.8912826 -1.9907142857142857 1.9907142857142857v11.944285714285714"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_3"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M26.874642857142856 7.962857142857143v11.944285714285714c0 1.0994715 -0.8912427857142856 1.9907142857142857 -1.9907142857142857 1.9907142857142857h-19.90714285714286"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_4"
        fill="#ffffff"
        d="M13.934999999999999 17.41875c1.9240253571428572 0 3.4837499999999997 -1.5597246428571427 3.4837499999999997 -3.4837499999999997S15.859025357142857 10.45125 13.934999999999999 10.45125 10.45125 12.010974642857143 10.45125 13.934999999999999 12.010974642857143 17.41875 13.934999999999999 17.41875Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.934999999999999 17.41875c1.9240253571428572 0 3.4837499999999997 -1.5597246428571427 3.4837499999999997 -3.4837499999999997S15.859025357142857 10.45125 13.934999999999999 10.45125 10.45125 12.010974642857143 10.45125 13.934999999999999 12.010974642857143 17.41875 13.934999999999999 17.41875Z"
        strokeWidth="2.13"
      ></path>
      <g id="Group 8">
        <path
          id="Ellipse 1"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M5.524033071428572 14.432678571428571c-0.2748579214285714 0 -0.4976785714285714 -0.22282065 -0.4976785714285714 -0.4976785714285714s0.22282065 -0.4976785714285714 0.4976785714285714 -0.4976785714285714"
          strokeWidth="2.13"
        ></path>
        <path
          id="Ellipse 2"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M5.524092792857142 14.432678571428571c0.2748579214285714 0 0.4976785714285714 -0.22282065 0.4976785714285714 -0.4976785714285714s-0.22282065 -0.4976785714285714 -0.4976785714285714 -0.4976785714285714"
          strokeWidth="2.13"
        ></path>
      </g>
      <g id="Group 9">
        <path
          id="Ellipse 1_2"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M22.34596692857143 14.432678571428571c-0.2749176428571429 0 -0.4976785714285714 -0.22282065 -0.4976785714285714 -0.4976785714285714s0.22276092857142857 -0.4976785714285714 0.4976785714285714 -0.4976785714285714"
          strokeWidth="2.13"
        ></path>
        <path
          id="Ellipse 2_2"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M22.34596692857143 14.432678571428571c0.2749176428571429 0 0.4976785714285714 -0.22282065 0.4976785714285714 -0.4976785714285714s-0.22276092857142857 -0.4976785714285714 -0.4976785714285714 -0.4976785714285714"
          strokeWidth="2.13"
        ></path>
      </g>
      <path
        id="Vector 3212"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.90714285714286 2.9860714285714285 22.893214285714286 5.972142857142857 19.90714285714286 8.958214285714286"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector 3211"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.962857142857143 18.911785714285713 4.976785714285715 21.89785714285714 7.962857142857143 24.88392857142857"
        strokeWidth="2.13"
      ></path>
    </g>
  </svg>
);

export default Billing;
