const AddNodes = () => (
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
    <g id="pen-pressure--pencil-drawing-tablet">
        <path id="Vector" stroke="#4147d5" strokeLinecap="round" strokeLinejoin="round" d="M10.4882 8.92375c-0.1538 2.93475 -1.93286 4.57635 -4.98865 4.57635C2.29984 13.5001 0.5 11.7002 0.5 8.5005c0 -3.05578 1.64156 -4.8348 4.57631 -4.98866" strokeWidth="1"></path>
        <path id="Vector_2" stroke="#4147d5" strokeLinecap="round" strokeLinejoin="round" d="M7.9731 9.91991c-0.41134 0.86739 -1.25971 1.33699 -2.47332 1.33699 -1.76423 0 -2.75662 -0.9924 -2.75662 -2.7566 0 -1.21361 0.4696 -2.06198 1.33701 -2.47332" strokeWidth="1"></path>
        <path id="Vector_3" fill="#d7e0ff" d="M10.3766 0.968044 6.32296 5.1056l-0.66693 2.48558c-0.09971 0.37155 0.27325 0.74776 0.64614 0.65177l2.54204 -0.65431 4.16949 -3.94782c0.6927 -0.65582 0.6391 -1.81676 -0.1181 -2.5612 -0.7395 -0.726914 -1.8672 -0.776865 -2.519 -0.111576Z" strokeWidth="1"></path>
        <path id="Vector_4" stroke="#4147d5" strokeLinecap="round" strokeLinejoin="round" d="M10.3766 0.968044 6.32296 5.1056l-0.66693 2.48558c-0.09971 0.37155 0.27325 0.74776 0.64614 0.65177l2.54204 -0.65431 4.16949 -3.94782c0.6927 -0.65582 0.6391 -1.81676 -0.1181 -2.5612 -0.7395 -0.726914 -1.8672 -0.776865 -2.519 -0.111576Z" strokeWidth="1"></path>
    </g>
</svg>
);

export default AddNodes;