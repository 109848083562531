const DeleteSquare = () => (
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.855 -0.855 24 24" height="24" width="24">
    <g id="delete-square--button-remove-buttons-add-square-delete-cross-x-mathematics-multiply-math">
        <path id="Vector" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="m15.125357142857142 7.164642857142857 -7.960714285714285 7.960714285714285" strokeWidth="1.71"></path>
        <path id="Vector_2" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="m7.164642857142857 7.164642857142857 7.960714285714285 7.960714285714285" strokeWidth="1.71"></path>
        <path id="Vector_3" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M16.7175 0.7960714285714285h-11.145c-2.6379418928571425 0 -4.776428571428571 2.1384866785714287 -4.776428571428571 4.776428571428571v11.145c0 2.6380215 2.1384866785714287 4.776428571428571 4.776428571428571 4.776428571428571h11.145c2.6380215 0 4.776428571428571 -2.1384070714285714 4.776428571428571 -4.776428571428571v-11.145c0 -2.6379418928571425 -2.1384070714285714 -4.776428571428571 -4.776428571428571 -4.776428571428571Z" strokeWidth="1.71"></path>
    </g>
</svg>
);

export default DeleteSquare;
