import { CostSegment } from '@/models/costSegment';
import { FFSelectOption } from '@/uikit/types/select';

export const deviceTypes: CostSegment.RestrictToDeviceTypeEnum[] = [
  "Console",
  "Desktop",
  "EReader",
  "MediaHub",
  "SmallScreen",
  "SmartPhone",
  "SmartWatch",
  "TV",
  "Tablet",
  "Unknown"
];

export const deviceTypesById: FFSelectOption<
  CostSegment.RestrictToDeviceTypeEnum
>[] = [
  {
    value: 'Unknown',
    label: 'Unknown'
  },
  {
    value: 'Console',
    label: 'Console'
  },
  {
    value: 'Desktop',
    label: 'Desktop'
  },
  {
    value: 'EReader',
    label: 'EReader'
  },
  {
    value: 'MediaHub',
    label: 'MediaHub'
  },
  {
    value: 'SmallScreen',
    label: 'SmallScreen'
  },
  {
    value: 'SmartPhone',
    label: 'SmartPhone'
  },
  {
    value: 'SmartWatch',
    label: 'SmartWatch'
  },
  {
    value: 'Tablet',
    label: 'Tablet'
  },
  {
    value: 'TV',
    label: 'TV'
  }
];
