const Highlight = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.57 -0.57 16 16">
    <g id="image-saturation">
      <path
        id="Vector"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.254673228571428 12.177876142857142V4.2457142857142856c-1.0561108142857143 0.9578862142857142 -3.168332442857143 2.7752854428571427 -3.168332442857143 5.393107957142857 0 1.9367993285714284 2.1122216285714286 2.5652711857142854 3.168332442857143 2.5390539000000003Z"
        strokeWidth="1.14"
      ></path>
      <path
        id="Vector_2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12.737142857142857 9.54876002857143C12.737142857142857 5.8354477 7.43 0.5307142857142857 7.43 0.5307142857142857S2.1228571428571428 5.8354477 2.1228571428571428 9.54876002857143c0.0740664857142857 1.3352346857142856 0.6738479285714286 2.586871257142857 1.6682685142857143 3.481443257142857C4.785556857142858 13.924775285714285 6.368571428571428 14.323023285714285 7.43 14.323023285714285s2.644443142857143 -0.39824799999999994 3.6388955714285713 -1.2928199999999999c0.9944524285714285 -0.8945719999999999 1.5941595714285715 -2.1462085714285712 1.6682472857142858 -3.481443257142857Z"
        strokeWidth="1.14"
      ></path>
    </g>
  </svg>
);

export default Highlight;