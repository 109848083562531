import { AxiosError, Method } from 'axios';
import { ModelError } from '@/models/modelError';
import { TrafficSource } from '@/models/trafficSource';
import { defined } from '@/utils/define';
import { Page } from '@/models/page';
import { OfferSource } from '@/models/offerSource';
import { PageGroup } from '@/models/pageGroup';
import { Funnel } from '@/models/funnel';
import { FunnelGroup } from '@/models/funnelGroup';
import { StringList } from '@/models/stringList';
import { FunnelCondition } from '@/models/funnelCondition';
import { Category } from '@/models/category';
import { AINodeSetting } from '@/models/aINodeSetting';
import { CostUpdateParams } from '@/models/costUpdateParams';
import { ConversionsUpdateParams } from '@/models/conversionsUpdateParams';
import { ResetUpdateParams } from '@/models/resetUpdateParams';
import AntMessageContext from '@/contexts/antMessage';
import { useContext } from 'react';

type Asset =
  | TrafficSource
  | Page
  | OfferSource
  | PageGroup
  | FunnelGroup
  | Funnel
  | StringList
  | FunnelCondition
  | Category
  | AINodeSetting;
type ActionType = 'added' | 'updated' | 'deleted' | 'archived' | 'unarchived' | 'duplicated' | 'moved';
type AssetType =
  | 'trafficSource'
  | 'lander'
  | 'offer'
  | 'offerSource'
  | 'pageGroup'
  | 'funnel'
  | 'funnelGroup'
  | 'condition'
  | 'category'
  | 'ai';

const messageMap: { [key in AssetType]: (data: Partial<any>) => string } = {
  trafficSource: (data: Partial<TrafficSource>) => data?.trafficSourceName || `Traffic Source (${data?.idTrafficSource})`,
  lander: (data: Partial<Page>) => data?.pageName || `Lander (${data?.idPage})`,
  offer: (data: Partial<OfferSource>) => data?.offerSourceName || `Offer Source (${data?.idOfferSource})`,
  offerSource: (data: Partial<OfferSource>) => data?.offerSourceName || `Offer Source (${data?.idOfferSource})`,
  pageGroup: (data: Partial<PageGroup>) => data?.pageGroupName || `Page Group (${data?.idPageGroup})`,
  funnel: (data: Partial<Funnel>) => data?.funnelName || `${data.funnelType === 'flow' ? 'Flow' : 'Funnel'} (${data?.idFunnel})`,
  condition: (data: Partial<FunnelCondition>) => data?.conditionName || `Condition (${data?.idCondition})`,
  ai: (data: Partial<AINodeSetting>) => data?.aiNodeSettingName || `AI Node (${data?.idAINodeSetting})`,
  category: (data: Partial<Category> | Partial<StringList>) => {
    if ('categoryName' in data) {
      return data?.categoryName || `Category (${data?.idCategory})`;
    } else if ('entries' in data) {
      return `${data?.entries?.length} Categories`;
    }
    return '';
  },
  funnelGroup: (data: Partial<FunnelGroup> | Partial<StringList>) => {
    if ('campaignName' in data) {
      return data?.campaignName || `Funnel Group (${data?.idCampaign})`;
    } else if ('entries' in data) {
      return `${data?.entries?.length} Funnels`;
    }
    return '';
  },
};

const generalMessageMap = {
  resetData: (data: Partial<ResetUpdateParams>, method: Method, error?: AxiosError<ModelError>) => {
    if (error) {
      if (method === 'delete') {
        return 'Reset data has failed';
      }
      if (method === 'post') {
        return 'Estimate has failed';
      }
    } else {
      if (method === 'delete') {
        return 'Data has been successfully deleted';
      }
      if (method === 'post') {
        return 'Estimate has been successfully calculated';
      }
    }
  },
  updateTrafficCosts: (data: Partial<CostUpdateParams>, method: Method, error?: AxiosError<ModelError>) => {
    if (error) {
      if (method === 'put') {
        return 'Cost update has failed';
      }
    } else {
      if (method === 'put') {
        return 'Cost update has been submitted';
      }
    }
  },
  updateConversions: (data: Partial<ConversionsUpdateParams>, method: Method, error?: AxiosError<ModelError>) => {
    if (error ) {
      if (method === 'put') {
        return 'Conversion data update has failed';
      }
    } else {
      if (method === 'put') {
        return 'Conversion data update has been submitted';
      }
    }
  },
};

const useHttpNotification = (method?: Method) => {
  const { messageApi } = useContext(AntMessageContext);
  const handleAssetNotification = (data: Partial<Asset>, type: ActionType, assetType: AssetType, error?: AxiosError<ModelError>) => {
    let name = messageMap[assetType](data);
    if (defined(error)) {
      const errorMessage = error?.response?.data?.message;
      messageApi.error(`${name} ${`cannot be ${type}. ${errorMessage}`}`);
    } else {
      messageApi.success(`${name} has been ${type} successfully.`);
    }
  };

  const handleGeneralNotification = (
    data: Partial<CostUpdateParams | ResetUpdateParams | ConversionsUpdateParams>,
    type: keyof typeof generalMessageMap,
    error?: AxiosError<ModelError>,
  ) => {
    if (error) {
      messageApi.error(generalMessageMap[type](data, method!, error));
    } else {
      messageApi.success(generalMessageMap[type](data, method!));
    }
  };

  const handleReportingNotification = (error: AxiosError<ModelError>) => {
    if (!error?.response?.data?.message) {
      messageApi.error('Failed to fetch reporting');
    } else {
      messageApi.error(error?.response?.data?.message);
    }
  };

  return {
    handleAssetNotification,
    handleReportingNotification,
    handleGeneralNotification,
  };
};

export default useHttpNotification;
