import className from '@/utils/className';
import { Tooltip, TooltipProps } from 'antd';
import './style.scss';
import clsx from 'clsx';

const { getClass } = className('c-ffTooltip');

const FFTooltip = ({ title, placement = 'top', children, className }: TooltipProps) => (
  <Tooltip
    title={title}
    placement={placement}
    className={clsx(getClass('tooltip'), className)}
    overlayClassName={getClass('tooltipOverlay')}
  >
    {children}
  </Tooltip>
);

export default FFTooltip;
