const UnArchive = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
    <g>
      <path id="Vector" fill="transparent" d="m12 3.5 -1.5 10h-7L2 3.5" strokeWidth="1"></path>
      <path
        id="Vector_2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m12 3.5 -1.5 10h-7L2 3.5"
        strokeWidth="1"
      ></path>
      <path id="Vector_3" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M1 3.5h12" strokeWidth="1"></path>
      <path
        id="Vector_4"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.5 3.5v-2c0 -0.26522 0.10536 -0.51957 0.29289 -0.707107C4.98043 0.605357 5.23478 0.5 5.5 0.5h3c0.26522 0 0.51957 0.105357 0.70711 0.292893C9.39464 0.98043 9.5 1.23478 9.5 1.5v2"
        strokeWidth="1"
      ></path>
      <path id="Vector_5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M7 11V6" strokeWidth="1"></path>
      <path id="Vector_6" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M9 8 7 6 5 8" strokeWidth="1"></path>
    </g>
  </svg>
);

export default UnArchive;
