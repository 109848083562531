import SectionBox from '@/components/SectionBox';
import { getAttributes, TRAFFIC_SOURCE_TEMPLATE } from '@/constants/templates';
import { TrackingField } from '@/models/trackingField';
import { TrafficSource } from '@/models/trafficSource';
import useFormStore from '@/stores/forms';
import { TrafficSourceTemplate } from '@/types/trafficsource';
import {
  FFAddGroup,
  FFButton,
  FFIconButton,
  FFCol,
  FFField,
  FFInput,
  FFNewIcon,
  FFRow,
  FFSelect,
  FFSidePanel,
  FFSwitch,
  FFTextArea,
  VisibilityWrapper,
  FFTag,
} from '@/uikit';
import { FFSelectOption } from '@/uikit/types/select';
import { defined } from '@/utils/define';
import { moveToFirstByFunc, sortByName } from '@/utils/sort';
import { Ref, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import className from '@/utils/className';
import { addOrIncrementCopySuffix, trimStringPropertiesInObject, withoutWhiteSpace } from '@/utils/string';
import { Postback } from '@/models/postback';
import CopyButton from '@/components/CopyButton';
import AntMessageContext from '@/contexts/antMessage';
import FacebookCustomScenario from './components/CustomScenarios/Facebook';
import TwitterCustomScenario from './components/CustomScenarios/Twitter';
import GoogleAdsCustomScenario from './components/CustomScenarios/GoogleAds';
import KwaiCustomScenario from './components/CustomScenarios/Kwai';
import RedditCustomScenario from './components/CustomScenarios/Reddit';
import MicrosoftAdsCustomScenario from './components/CustomScenarios/MicrosoftAds';
import SnapchatCustomScenario from './components/CustomScenarios/Snapchat';
import TikTokCustomScenario from './components/CustomScenarios/TikTok';
import { useTrafficSourcesCategoryListQuery } from '@/api/queries/trafficSource';
import { generateEntityId } from '@/utils/id';
import { withIncrementedVersion } from '@/utils/model';
import {
  useTrafficSourceCreateMutation,
  useTrafficSourceDuplicateMutation,
  useTrafficSourceUpdateMutation,
} from '@/api/mutations/trafficSources';
import useHttp from '@/hooks/http';
import {
  FacebookCustomScenarioProps,
  GoogleAdsCustomScenarioProps,
  KwaiCustomScenarioProps,
  MicrosoftAdsCustomScenarioProps,
  RedditCustomScenarioProps,
  SnapchatCustomScenarioProps,
  TikTokCustomScenarioProps,
  TrafficSourceFormRefType,
  TrafficSourceFormTabId,
  TwitterCustomScenarioProps,
} from '@/types/forms/trafficSource';
import { getSidebarOffsetLevel, getSidebarZIndex } from '@/utils/sidebar';
import { useTrafficSourceQuery } from '@/api/queries/trafficSource';
import { SidebarCopyItems, SidebarTab } from '@/uikit/types/sidebar';
import { TemplateSelector } from '@/components/TemplateSelector';
import Skeleton from 'react-loading-skeleton';
import useMitt from '@/hooks/mitt';
import useSystemSettingsStore from '@/stores/systemSettings';
import { defaultCustomEventAliases } from '@/constants/customEvents';
import {
  getFacebookPostbackUrl,
  getGooglePostbackUrl,
  getKwaiPostbackUrl,
  getMicrosoftAdsPostbackUrl,
  getReditPostbackUrl,
  getSnapchatPostbackUrl,
  getTikTokPostbackUrl,
  getTwitterPostbackUrl,
} from '@/utils/trafficSource';
import { categoriesWithoutUncategorized } from '@/utils/array';
import './style.scss';

const { blockClassName, getClass } = className('c-trafficSourceForm');

const defaultTrackingFieldSlots: {
  [key: string]: TrackingField;
} = {
  campaign: { name: 'campaign', value: '', status: 'active' },
  external: { name: 'external', value: '', status: 'active' },
  c1: { name: '', value: '', status: 'archived' },
  c2: { name: '', value: '', status: 'archived' },
  c3: { name: '', value: '', status: 'archived' },
  c4: { name: '', value: '', status: 'archived' },
  c5: { name: '', value: '', status: 'archived' },
  c6: { name: '', value: '', status: 'archived' },
  c7: { name: '', value: '', status: 'archived' },
  c8: { name: '', value: '', status: 'archived' },
  c9: { name: '', value: '', status: 'archived' },
  c10: { name: '', value: '', status: 'archived' },
  c11: { name: '', value: '', status: 'archived' },
  c12: { name: '', value: '', status: 'archived' },
  c13: { name: '', value: '', status: 'archived' },
  c14: { name: '', value: '', status: 'archived' },
  c15: { name: '', value: '', status: 'archived' },
  c16: { name: '', value: '', status: 'archived' },
  c17: { name: '', value: '', status: 'archived' },
  c18: { name: '', value: '', status: 'archived' },
  c19: { name: '', value: '', status: 'archived' },
  c20: { name: '', value: '', status: 'archived' },
};

const defaultTracking = {
  external: 'external',
  campaign: 'campaign',
};

const RESERVED_FIELD_NAMES = ['cost', 'external', 'campaign', 'f', 'ts', 'n', 'c', 'vid'];

const CUSTOM_SCENARIO = 'customScenario';

const customScenarioTypeOptions: FFSelectOption<TrafficSource.CustomScenarioTypeEnum>[] = [
  {
    label: 'Facebook',
    value: 'Facebook',
  },
  {
    label: 'Google Ads',
    value: 'GoogleAds',
  },
  {
    label: 'Kwai',
    value: 'Kwai',
  },
  {
    label: 'Microsoft Ads',
    value: 'MicrosoftAds',
  },
  {
    label: 'Snapchat',
    value: 'Snapchat',
  },
  {
    label: 'Reddit',
    value: 'Reddit',
  },
  {
    label: 'Twitter/X',
    value: 'Twitter',
  },
  { label: 'TikTok', value: 'TikTok' },
];

const postbackValues: {
  [key in Postback.PostbackTypeEnum]: Postback.PostbackTypeEnum;
} = {
  html: 'html',
  none: 'none',
  postbackURL: 'postbackURL',
};

const POSTBACK_TYPES: FFSelectOption[] = [
  {
    value: postbackValues.none,
    label: 'None',
  },
  {
    value: postbackValues.postbackURL,
    label: 'Postback URL',
  },
  {
    value: postbackValues.html,
    label: 'HTML',
  },
  {
    value: CUSTOM_SCENARIO,
    label: 'Custom Scenario',
  },
];

interface TrackingFieldWithKey extends TrackingField {
  key: string;
}

const DEFAULT_TRAFFICSOURCE: TrafficSource = {
  idTrafficSource: '',
  trafficSourceName: '',
  costType: 'cpe',
  status: 'active',
};

const tabs: SidebarTab<TrafficSourceFormTabId>[] = [
  {
    title: 'General Settings',
    tabId: 'general',
    icon: <FFNewIcon name="sidebar-tabs/general-settings" size="md" display="inline-block" type="sidebartab" />,
  },
  {
    title: 'Tracking Fields',
    tabId: 'trackingFields',
    icon: <FFNewIcon name="sidebar-tabs/tracking-fields" size="md" display="inline-block" type="sidebartab" />,
  },
  {
    title: 'Conversion Tracking',
    tabId: 'conversionTracking',
    icon: <FFNewIcon name="sidebar-tabs/conversion-tracking" size="md" display="inline-block" type="sidebartab" />,
  },
  {
    title: 'Advanced Settings',
    tabId: 'advanced',
    icon: <FFNewIcon name="sidebar-tabs/advanced-flow-settings" size="md" display="inline-block" type="sidebartab" />,
  },
  {
    title: 'Help',
    tabId: 'help',
    icon: <FFNewIcon name="sidebar-tabs/help" size="md" display="inline-block" type="sidebartab" />,
  },
];

const costTypes: FFSelectOption<TrafficSource.CostTypeEnum>[] = [
  {
    value: 'cpe',
    label: 'CPE',
  },
  {
    value: 'cpa',
    label: 'CPA',
  },
];

interface FormProps {
  closeForm: () => void;
  currentTabId: TrafficSourceFormTabId;
  selectedTemplate?: TrafficSourceTemplate;
  defaultFormValues: TrafficSource | null;
  submitLoading: boolean;
  setSubmitLoading: (loading: boolean) => void;
}

const Form = forwardRef(
  (
    { currentTabId, closeForm, setSubmitLoading, selectedTemplate, submitLoading, defaultFormValues }: FormProps,
    ref: Ref<TrafficSourceFormRefType>,
  ) => {
    const { messageApi } = useContext(AntMessageContext);
    const { get: httpGet } = useHttp();
    const emitter = useMitt();

    const { data: categoryList = [], refetch: refetchCategories } = useTrafficSourcesCategoryListQuery();
    const { mutateAsync: createTrafficSource } = useTrafficSourceCreateMutation();
    const { mutateAsync: updateTrafficSource } = useTrafficSourceUpdateMutation();
    const { mutateAsync: duplicateTrafficSource } = useTrafficSourceDuplicateMutation(defaultFormValues?.idTrafficSource || '');

    const openCategoryForm = useFormStore((state) => state.openCategoryForm);
    const openVersioningForm = useFormStore((state) => state.openVersioningForm);
    const setVersioningType = useFormStore((state) => state.setVersioningType);
    const isDuplication = useFormStore((state) => state.trafficSource.isDuplication);
    const customEventAliases = useSystemSettingsStore((state) => state.userSettings.customEventAliases);

    const {
      handleSubmit,
      control,
      formState: { errors },
      getFieldState,
      getValues,
      setError,
      setValue,
      clearErrors,
      watch,
    } = useForm<TrafficSource>({
      defaultValues: defaultFormValues || {},
    });

    const [trackingFieldsArray, setTrackingFieldsArray] = useState<TrackingFieldWithKey[]>([]);
    const [isCampaignLocked, setIsCampaignLocked] = useState(true);
    const [isExternalLocked, setIsExternalLocked] = useState(true);
    const [latestVersionOfTrafficSource, setLatestVersionOfTrafficSource] = useState<TrafficSource>();
    const [postbackURLCursorPosition, setPostbackURLCursorPosition] = useState<number>(0);
    const [htmlCursorPosition, setHtmlCursorPosition] = useState<number>(0);
    const [triggeringEvent, setTriggeringEvent] = useState<string>('Conversions');
    const [incomingCostModifierEnabled, setIncomingCostModifierEnabled] = useState<boolean>(false);
    const [outgoingRevenueModifierEnabled, setOutgoingRevenueModifierEnabled] = useState<boolean>(false);
    const [outgoingPostbackThrottlingEnabled, setOutgoingPostbackThrottlingEnabled] = useState<boolean>(false);

    const attributeOptions = useMemo(() => getAttributes('Traffic Source Conversion Tracking'), []);

    const isHTML = watch(`conversionTrackingSettings.${triggeringEvent}.postbackType`) === 'html';
    const isPostbackURL = watch(`conversionTrackingSettings.${triggeringEvent}.postbackType`) === 'postbackURL';
    const isPostbackURLOrHTML = isPostbackURL || isHTML;
    const isCustomScenario = watch(`conversionTrackingSettings.${triggeringEvent}.isCustomScenario`);
    const isFacebook = watch(`conversionTrackingSettings.${triggeringEvent}.customEventType`) === 'Facebook' && isCustomScenario;
    const isTwitter = watch(`conversionTrackingSettings.${triggeringEvent}.customEventType`) === 'Twitter' && isCustomScenario;
    const isGoogleAds = watch(`conversionTrackingSettings.${triggeringEvent}.customEventType`) === 'GoogleAds' && isCustomScenario;
    const isKwai = watch(`conversionTrackingSettings.${triggeringEvent}.customEventType`) === 'Kwai' && isCustomScenario;
    const isReddit = watch(`conversionTrackingSettings.${triggeringEvent}.customEventType`) === 'Reddit' && isCustomScenario;
    const isMsAds = watch(`conversionTrackingSettings.${triggeringEvent}.customEventType`) === 'MicrosoftAds' && isCustomScenario;
    const isSnapchat = watch(`conversionTrackingSettings.${triggeringEvent}.customEventType`) === 'Snapchat' && isCustomScenario;
    const isTikTok = watch(`conversionTrackingSettings.${triggeringEvent}.customEventType`) === 'TikTok' && isCustomScenario;

    const triggeringEventOptions = useMemo(() => {
      const options: FFSelectOption[] = [];
      options.push({
        label: 'Conversions',
        value: 'Conversions',
      });
      Object.entries(customEventAliases || defaultCustomEventAliases).forEach(([key, value]) => {
        options.push({
          label: `Custom Event ${key} ${value.alias ? `(${value.alias})` : ''}`,
          value: `${key}`,
        });
      });
      return options;
    }, [customEventAliases]);

    useEffect(() => {
      setTrackingFieldsArray(getTrackingFieldsArrayFromSlots(defaultFormValues?.trackingFieldSlots));
      if (!defaultFormValues?.conversionTrackingSettings) {
        setValue('conversionTrackingSettings', {
          [triggeringEvent]: {
            isCustomScenario: defaultFormValues?.customScenarioData?.isCustomScenario || false,
            customEventType: defaultFormValues?.customScenarioData?.type || '',
            customEventData: defaultFormValues?.customScenarioData?.data || {},
            postbackType: defaultFormValues?.postback?.postbackType || 'none',
            postbackHTML: defaultFormValues?.postback?.postbackHTML || '',
            postbackURL: defaultFormValues?.postback?.postbackURL || '',
          },
        });
      }
      emitter.on('onVersioningConfirm', () => onSave(false, true));
      emitter.on('onCategorySave', () => refetchCategories());
    }, []);

    useEffect(() => {
      requestAnimationFrame(extraValidationTrackingFields);
    }, [trackingFieldsArray]);

    const onSaveAndCreate = () => {
      onSave(true);
    };

    const onClose = () => {
      closeForm();
    };

    const getDataWithPostbackUrl = (data: TrafficSource) => {
      let dataWithPostbackUrl = data;
      dataWithPostbackUrl.conversionTrackingSettings = Object.entries(data.conversionTrackingSettings || {}).reduce((acc, [key, value]) => {
        if (value.isCustomScenario) {
          if (value.customEventType === 'Facebook') {
            acc[key].postbackURL = getFacebookPostbackUrl(value.customEventData as FacebookCustomScenarioProps);
          } else if (value.customEventType === 'Twitter') {
            acc[key].postbackURL = getTwitterPostbackUrl(value.customEventData as TwitterCustomScenarioProps);
          } else if (value.customEventType === 'GoogleAds') {
            acc[key].postbackURL = getGooglePostbackUrl(value.customEventData as GoogleAdsCustomScenarioProps);
          } else if (value.customEventType === 'Kwai') {
            acc[key].postbackURL = getKwaiPostbackUrl(value.customEventData as KwaiCustomScenarioProps);
          } else if (value.customEventType === 'Reddit') {
            acc[key].postbackURL = getReditPostbackUrl(value.customEventData as RedditCustomScenarioProps);
          } else if (value.customEventType === 'MicrosoftAds') {
            acc[key].postbackURL = getMicrosoftAdsPostbackUrl(value.customEventData as MicrosoftAdsCustomScenarioProps);
          } else if (value.customEventType === 'Snapchat') {
            acc[key].postbackURL = getSnapchatPostbackUrl(value.customEventData as SnapchatCustomScenarioProps);
          } else if (value.customEventType === 'TikTok') {
            acc[key].postbackURL = getTikTokPostbackUrl(value.customEventData as TikTokCustomScenarioProps);
          }
        }
        return acc;
      }, dataWithPostbackUrl.conversionTrackingSettings || {});
      return dataWithPostbackUrl;
    };

    const sanitizeFormData = (data: TrafficSource) => {
      if (data.conversionTrackingSettings) {
        data.conversionTrackingSettings = Object.entries(data.conversionTrackingSettings).reduce((acc, [key, value]) => {
          if (value.postbackType === 'postbackURL' && !value.postbackURL) {
            delete acc[key];
          }
          if (value.postbackType === 'html' && !value.postbackHTML) {
            delete acc[key];
          }
          return acc;
        }, data.conversionTrackingSettings);
      }
      return data;
    };

    const sanizeAdvancedSettings = (model: TrafficSource) => {
      if (incomingCostModifierEnabled && model.incomingCostModifier) {
        model.incomingCostModifier = Number(model.incomingCostModifier) || 0;
      } else {
        delete model.incomingCostModifier;
      }
      if (outgoingRevenueModifierEnabled && model.outgoingRevenueModifier) {
        model.outgoingRevenueModifier = Number(model.outgoingRevenueModifier) || 0;
      } else {
        delete model.outgoingRevenueModifier;
      }
      if (outgoingPostbackThrottlingEnabled && model.outgoingPostbackProbability) {
        model.outgoingPostbackProbability = Number(model.outgoingPostbackProbability) || 0;
      } else {
        delete model.outgoingPostbackProbability;
      }
      return model;
    }

    const getCustomEventPostback = (model: TrafficSource, idTrafficSource?: string) => {
      model.customEventPostback = { idTrafficSource: model.idTrafficSource || idTrafficSource!, customEvents: {} };
      Object.entries(model.conversionTrackingSettings || {}).forEach(([key, value]) => {
        if (key !== 'Conversions') {
          model.customEventPostback!.customEvents[key] = {
            postbackType: value.postbackType,
            postbackURL: value.postbackURL,
            postbackHTML: value.postbackHTML,
          };
        }
      });
      return model;
    }

    const onSave = (isSaveAndCreate = false, isConfirmingVersioning = false) =>
      handleSubmit(
        async (data) => {
          const hasTrackingFieldErrors = checkFieldsName();
          const onSaveSuccessFn = (trafficSource: TrafficSource) => {
            emitter.emit('onTrafficSourceSave', trafficSource);
            onClose();
          };
          let dataWithPostbackUrl = getDataWithPostbackUrl(data);
          dataWithPostbackUrl = sanitizeFormData(dataWithPostbackUrl);

          if (!hasTrackingFieldErrors) {
            try {
              setSubmitLoading(true);
              const newID = generateEntityId();
              let model = trimStringPropertiesInObject<TrafficSource>(dataWithPostbackUrl, ['trafficSourceName']);
              model.postback = {
                ...(model.postback || {}),
                postbackType: data.conversionTrackingSettings?.Conversions?.postbackType || 'none',
                postbackURL: data.conversionTrackingSettings?.Conversions?.postbackURL || '',
                postbackHTML: data.conversionTrackingSettings?.Conversions?.postbackHTML || '',
                idTrafficSource: newID,
              };
              model.trackingFieldSlots = getTrackingFields(model.trackingFieldSlots || {});
              model = sanizeAdvancedSettings(model);
              model = getCustomEventPostback(model, newID);
              const updateModel = withIncrementedVersion(model);
              const duplicateModel = { ...model, meta: { version: 1 } };
              const createModel: TrafficSource = { ...model, idTrafficSource: newID, meta: { version: 1 } };
              const category = categoryList.find((category) => category.idCategory === model.idCategory)!;

              if (isConfirmingVersioning) {
                try {
                  let versionedUpdate = withIncrementedVersion(updateModel, latestVersionOfTrafficSource?.meta?.version);
                  await updateTrafficSource(versionedUpdate);
                  emitter.emit('onTrafficSourceUpdate', versionedUpdate);
                  onSaveSuccessFn(versionedUpdate);
                } catch(e) {
                  //
                } finally {
                  setSubmitLoading(false);
                }
                return;
              }
              if (isDuplication) {
                const duplicatedTrafficSource = await duplicateTrafficSource(duplicateModel);
                emitter.emit('onTrafficSourceCreate', { data: duplicatedTrafficSource, category });
              } else if (model.idTrafficSource) {
                const trafficSource = await httpGet<TrafficSource>('v1/trafficsource/find/byId', {
                  params: { idTrafficSource: model.idTrafficSource },
                });
                setLatestVersionOfTrafficSource(trafficSource.data);
                if (model.meta?.version !== trafficSource.data.meta?.version) {
                  setVersioningType('trafficSource');
                  openVersioningForm();
                  return;
                } else {
                  await updateTrafficSource(updateModel);
                  emitter.emit('onTrafficSourceUpdate', updateModel);
                }
              } else {
                await createTrafficSource(createModel);
                emitter.emit('onTrafficSourceCreate', { data: createModel, category });
              }

              if (isSaveAndCreate) {
                setValue('trafficSourceName', addOrIncrementCopySuffix(data.trafficSourceName));
              } else {
                closeForm();
              }
            } catch(e) {
              //
            } finally {
              setSubmitLoading(false);
            }
          }
        },
        () => {
          const trackingFieldSlotsError = getFieldState('trackingFieldSlots');
          if (trackingFieldSlotsError.error) {
            messageApi.error(trackingFieldSlotsError.error.message);
          }
        },
      )();

    useImperativeHandle(ref, () => ({ onSave }));

    const getTrackingFieldsArrayFromSlots = (
      trackingFieldSlots: {
        [key: string]: TrackingField;
      } = {},
    ) => {
      const _trackingFieldSlots = {
        ...defaultTrackingFieldSlots,
        ...trackingFieldSlots,
      };
      if (!Object.keys(trackingFieldSlots).length) {
        _trackingFieldSlots.c1.status = 'active';
        _trackingFieldSlots.c2.status = 'active';
        _trackingFieldSlots.c3.status = 'active';
      }
      let trackingFields = Object.keys(_trackingFieldSlots).reduce((acc: TrackingFieldWithKey[], key) => {
        acc.push({
          name: _trackingFieldSlots?.[key].name || '',
          status: _trackingFieldSlots?.[key].status || 'active',
          value: _trackingFieldSlots?.[key].value || '',
          key,
        });
        return acc;
      }, []);
      trackingFields = sortByName(trackingFields, 'key');
      trackingFields = moveToFirstByFunc(trackingFields, (trackingField) => trackingField.key === 'external');
      trackingFields = moveToFirstByFunc(trackingFields, (trackingField) => trackingField.key === 'campaign');
      return trackingFields;
    };

    const saveInputPosition = (e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
      if (getValues(`conversionTrackingSettings.${triggeringEvent}.postbackType`) === 'postbackURL') {
        setPostbackURLCursorPosition(e.target.selectionStart || 0);
      } else {
        setHtmlCursorPosition(e.target.selectionStart || 0);
      }
    };

    const isCampaignOrExternal = (key: string) => {
      return key === 'campaign' || key === 'external';
    };

    const onTrackingFieldsChange = (key: keyof TrackingFieldWithKey, value: string, idx: number) => {
      const _value = key === 'key' ? value.replace(/[^a-zA-Z0-9\-_]/g, '') : value;
      setTrackingFieldsArray(
        trackingFieldsArray.map((trackingField, _idx) => {
          if (_idx === idx) {
            return {
              ...trackingField,
              [key]: _value,
            };
          } else {
            return trackingField;
          }
        }),
      );
    };

    const checkFieldsName = () => {
      return defined(trackingFieldsArray.find((trackingField) => checkTrackingFieldNameReserved(trackingField)));
    };

    const getTrackingFields = (trackingFieldSlots: { [key: string]: TrackingField }) => {
      return trackingFieldsArray.reduce((acc: { [key: string]: TrackingField }, trackingField) => {
        const previousNameHasChanged = trackingFieldSlots?.[trackingField.key]?.name !== trackingField.name;
        if (trackingField.name) {
          acc[trackingField.key] = {
            ...trackingField,
            previousName: isCampaignOrExternal(trackingField.key)
              ? trackingField.key
              : previousNameHasChanged
              ? trackingFieldSlots?.[trackingField.key]?.name
              : undefined,
          };
        }
        return acc;
      }, {});
    };

    const checkTrackingFieldNameReserved = (trackingField: TrackingFieldWithKey) =>
      !['campaign', 'external'].includes(trackingField.key)
        ? RESERVED_FIELD_NAMES.includes(trackingField.name)
          ? `Field with name "${trackingField.name}" using one of reserved words`
          : trackingField.name.match(/^0*([1-9]|1[0-9]|20)$/g)
          ? 'Values 1-20 are not allowed'
          : false
        : false;

    const getTrackingFieldNameError = (data: TrackingFieldWithKey) => {
      const reserved = checkTrackingFieldNameReserved(data);
      return reserved || '';
    };

    const extraValidationTrackingFields = () => {
      const names = trackingFieldsArray.map(field => field.name).filter(name => name.trim() !== '');
      const hasDuplicateNames = new Set(names).size !== names.length;
      if (hasDuplicateNames) {
        setError('trackingFieldSlots', { message: 'You cannot have multiple URL tracking fields with same name' });
      } else {
        clearErrors('trackingFieldSlots');
      }
    };

    const getToken = (trackingField: TrackingFieldWithKey) => {
      if (isCampaignOrExternal(trackingField.key)) {
        return `{${trackingField.key}}`;
      }
      return trackingField.name !== '' ? `{data-${trackingField.name}}` : '';
    };

    const isDisableTrackingField = (trackingField: TrackingFieldWithKey) => {
      return !isCampaignOrExternal(trackingField.key) && trackingField.status === 'archived';
    };

    const isLockedTrackingField = (key: string) => {
      return key === 'campaign' ? isCampaignLocked : key === 'external' ? isExternalLocked : false;
    };

    const setTrackingFieldLockStatus = (key: string) => {
      if (key === 'campaign') {
        setIsCampaignLocked(!isCampaignLocked);
      } else {
        setIsExternalLocked(!isExternalLocked);
      }
    };

    const disableTrackingFieldSlot = (idx: number) => {
      setTrackingFieldsArray(
        trackingFieldsArray.map((trackingField, _idx) => {
          if (idx === _idx) {
            return {
              ...trackingField,
              status: trackingField.status === 'active' ? 'archived' : 'active',
            };
          } else {
            return trackingField;
          }
        }),
      );
    };

    const getCorrectValueToken = (token: string) => {
      if ([defaultTracking.campaign, defaultTracking.external].indexOf(token) > -1) {
        return `{${token}}`;
      }
      return token !== '' ? `{data-${token}}` : '';
    };

    const handlePostbackTokens = (value: string) => {
      const postbackURL = getValues(`conversionTrackingSettings.${triggeringEvent}.postbackURL`)!;
      const postbackHTML = getValues(`conversionTrackingSettings.${triggeringEvent}.postbackHTML`)!;
      const isHTML = getValues(`conversionTrackingSettings.${triggeringEvent}.postbackType`) === 'html';
      const inputSelectionStart = isHTML ? htmlCursorPosition : postbackURLCursorPosition;

      const getPostback = (postbackValue: string) => {
        postbackValue = defined(postbackValue) && postbackValue.length > 0 ? postbackValue : '';
        return inputSelectionStart >= 0
          ? postbackValue.substr(0, inputSelectionStart) + value + postbackValue.substr(inputSelectionStart, postbackValue.length)
          : postbackValue;
      };

      setValue(`conversionTrackingSettings.${triggeringEvent}.postbackURL`, isHTML ? postbackURL : getPostback(postbackURL));
      setValue(`conversionTrackingSettings.${triggeringEvent}.postbackHTML`, isHTML ? getPostback(postbackHTML) : postbackHTML);
    };

    const onPostbackTypeChange = (value: Postback.PostbackTypeEnum | typeof CUSTOM_SCENARIO) => {
      if (value === CUSTOM_SCENARIO) {
        setValue(`conversionTrackingSettings.${triggeringEvent}.isCustomScenario`, true);
        setValue(`conversionTrackingSettings.${triggeringEvent}.postbackType`, 'postbackURL');
      } else {
        setValue(`conversionTrackingSettings.${triggeringEvent}.postbackType`, value);
        setValue(`conversionTrackingSettings.${triggeringEvent}.isCustomScenario`, false);
      }
    };

    const preventWheelChange = (e: any) => {
      e.target.blur();
    };

    return (
      <div className={blockClassName}>
        <VisibilityWrapper visible={currentTabId === 'general'} beRenderedAlways>
          <SectionBox title="General Settings">
            <FFCol gap="18px" height="max-content">
              <FFRow gap="8px">
                <Controller
                  name="trafficSourceName"
                  control={control}
                  rules={{ required: 'Traffic Source Name is required' }}
                  render={(opt) => (
                    <FFField label="Traffic Source Name" block>
                      <FFInput
                        value={opt.field.value}
                        onChange={opt.field.onChange}
                        error={errors.trafficSourceName?.message}
                        placeholder="Traffic Source Name"
                      />
                    </FFField>
                  )}
                />
                <Controller
                  name="idCategory"
                  control={control}
                  render={(opt) => (
                    <FFField label="Category">
                      <FFSelect
                        value={opt.field.value}
                        defaultValue=""
                        onSelect={opt.field.onChange}
                        options={categoriesWithoutUncategorized(categoryList)}
                        valueGetter={(opt) => opt.idCategory}
                        labelGetter={(opt) => opt.categoryName}
                        defaultValueFallback={{
                          label: 'Uncategorized',
                          value: '',
                        }}
                        placeholder="Category"
                        style={{ width: 170 }}
                      />
                      <FFIconButton
                        buttonType="transparent"
                        iconName="general/line/add-circle"
                        onClick={() => openCategoryForm('trafficsources')}
                      />
                    </FFField>
                  )}
                />
              </FFRow>
              <FFRow alignItems="center" gap="8px">
                <Controller
                  name="costType"
                  control={control}
                  render={(opt) => (
                    <FFField label="Cost Type">
                      <FFSelect
                        value={opt.field.value}
                        onSelect={opt.field.onChange}
                        options={costTypes}
                        valueGetter={(opt) => opt.value}
                        labelGetter={(opt) => opt.label}
                        placeholder="Cost Type"
                        style={{ width: 100 }}
                      />
                    </FFField>
                  )}
                />
                <Controller
                  name="defaultCost"
                  control={control}
                  render={(opt) => (
                    <FFField label="Default Cost per Entry" block>
                      <FFInput
                        value={opt.field.value}
                        onChange={opt.field.onChange}
                        error={errors.trafficSourceName?.message}
                        placeholder="Ex: 0.005"
                        style={{ width: 180 }}
                      />
                      <span>Note: you can use tokens here</span>
                    </FFField>
                  )}
                />
              </FFRow>
              <FFButton type="primary" onClick={onSaveAndCreate} loading={submitLoading}>
                Save & Create New
              </FFButton>
            </FFCol>
          </SectionBox>
        </VisibilityWrapper>
        <VisibilityWrapper visible={currentTabId === 'trackingFields'} beRenderedAlways>
          <FFCol gap="18px">
            <SectionBox title="Tracking Fields">
              <p>
                Here you can define the URL tracking fields that are used to pass custom data from this traffic source. Below, define the
                URL parameter names and placeholder values. These will be appended to links generated for this source. See the help tab for
                more details.
              </p>
            </SectionBox>
            <SectionBox title="URL Tracking Field Configuration" className={getClass('trackingFieldsArea')}>
              <FFCol>
                <FFAddGroup
                  rows={trackingFieldsArray}
                  headerRow={
                    <FFAddGroup.Row>
                      <FFAddGroup.Col bold textPosition="right" maxWidth={120}>
                        Name
                      </FFAddGroup.Col>
                      <FFAddGroup.Col bold textPosition="center">
                        URL Parameter
                      </FFAddGroup.Col>
                      <FFAddGroup.Col bold textPosition="center">
                        Source Token
                      </FFAddGroup.Col>
                      <FFAddGroup.Col bold textPosition="center">
                        FunnelFlux Token
                      </FFAddGroup.Col>
                      <FFAddGroup.Col bold textPosition="right" maxWidth={70}>
                        Append
                      </FFAddGroup.Col>
                    </FFAddGroup.Row>
                  }
                  showAddRow={false}
                  renderRow={(row, rowIdx) => {
                    return (
                      <>
                        <FFAddGroup.Col
                          textPosition="right"
                          maxWidth={120}
                          className={getClass('trackingFieldCol', row.status !== 'active' && 'disabled')}
                        >
                          <VisibilityWrapper visible={row.key === 'campaign'}>
                            <span>Campaign ID</span>
                          </VisibilityWrapper>
                          <VisibilityWrapper visible={row.key === 'external'}>
                            <span>External Click ID</span>
                          </VisibilityWrapper>
                          <VisibilityWrapper visible={!isCampaignOrExternal(row.key)}>
                            <span>Custom - {row.key}</span>
                          </VisibilityWrapper>
                        </FFAddGroup.Col>
                        <FFAddGroup.Col>
                          <FFInput
                            name="name"
                            value={row.name}
                            data-testid={`fieldName${row.key}`}
                            placeholder="URL parameter name"
                            error={getTrackingFieldNameError(row)}
                            onChange={(e) => onTrackingFieldsChange('name', withoutWhiteSpace(e.target.value), rowIdx)}
                            disabled={isDisableTrackingField(row) || isLockedTrackingField(row.key)}
                          />
                        </FFAddGroup.Col>
                        <FFAddGroup.Col>
                          <FFInput
                            name="token"
                            value={row.value}
                            // error={errorValue ? `On field name "${row.key}" value is required` : ''}
                            data-testid={`fieldValue${row.key}`}
                            placeholder={
                              row.key === defaultTracking.campaign
                                ? 'Campaign ID here'
                                : row.key === defaultTracking.external
                                ? 'External tracking ID here'
                                : 'Source Token'
                            }
                            onChange={(e) => onTrackingFieldsChange('value', e.target.value, rowIdx)}
                            disabled={isDisableTrackingField(row)}
                          />
                        </FFAddGroup.Col>
                        <FFAddGroup.Col>
                          <FFInput
                            name="funnelfluxToken"
                            value={getToken(row)}
                            placeholder="{data-fieldname}"
                            readOnly
                            className={getClass('funnelfluxTokenInput')}
                            disabled={isDisableTrackingField(row)}
                          />
                        </FFAddGroup.Col>
                        <FFAddGroup.Col maxWidth={50}>
                          <VisibilityWrapper visible={!isCampaignOrExternal(row.key)}>
                            <FFSwitch
                              data-testid={`fieldArchive${rowIdx}`}
                              className="form-TrafficSource__urlFieldsAppendSwitch"
                              onClick={() => disableTrackingFieldSlot(rowIdx)}
                              checked={row.status === 'active'}
                            />
                          </VisibilityWrapper>
                          <VisibilityWrapper visible={isCampaignOrExternal(row.key)}>
                            <FFRow alignItems="center" justifyContent="center" cursor="pointer">
                              {isLockedTrackingField(row.key) && (
                                <FFNewIcon
                                  name="general/line/lock"
                                  type="tertiary"
                                  size="sm"
                                  onClick={() => setTrackingFieldLockStatus(row.key)}
                                />
                              )}
                              {!isLockedTrackingField(row.key) && (
                                <FFNewIcon
                                  name="general/line/unlock"
                                  type="tertiary"
                                  size="sm"
                                  onClick={() => setTrackingFieldLockStatus(row.key)}
                                />
                              )}
                            </FFRow>
                          </VisibilityWrapper>
                        </FFAddGroup.Col>
                      </>
                    );
                  }}
                />
              </FFCol>
            </SectionBox>
          </FFCol>
        </VisibilityWrapper>
        <VisibilityWrapper visible={currentTabId === 'conversionTracking'} beRenderedAlways>
          <FFCol gap="18px">
            <SectionBox title="Conversion Tracking">
              <FFCol gap="18px">
                <p>
                  Here you can set postbacks or custom scenarios to trigger for conversions and custom events. The settings for each event
                  are independent.
                </p>
                {defined(selectedTemplate?.trackingHelpLink) && (
                  <p>
                    You can view documentation on <a href={selectedTemplate?.trackingHelpLink}>{selectedTemplate?.name}</a> tracking here.
                  </p>
                )}
              </FFCol>
            </SectionBox>
            <SectionBox>
              <FFField label="Triggering Event" direction="row">
                <FFSelect
                  value={triggeringEvent}
                  onSelect={setTriggeringEvent}
                  options={triggeringEventOptions}
                  valueGetter={(opt) => opt.value}
                  labelGetter={(opt) => opt.label}
                  placeholder="Tracking Type"
                  style={{ width: 200 }}
                />
              </FFField>
            </SectionBox>
            <SectionBox>
              <FFCol gap="8px">
                <FFRow gap="8px">
                  <Controller
                    key={`postbackType-${triggeringEvent}`}
                    name={`conversionTrackingSettings.${triggeringEvent}.postbackType`}
                    control={control}
                    render={(opt) => (
                      <FFField label="Tracking Type">
                        <FFSelect
                          value={isCustomScenario ? CUSTOM_SCENARIO : opt.field.value}
                          onSelect={onPostbackTypeChange}
                          options={POSTBACK_TYPES}
                          valueGetter={(opt) => opt.value}
                          labelGetter={(opt) => opt.label}
                          placeholder="Tracking Type"
                          style={{ width: 200 }}
                        />
                      </FFField>
                    )}
                  />
                  {isCustomScenario && (
                    <Controller
                      key={`customEventType-${triggeringEvent}`}
                      name={`conversionTrackingSettings.${triggeringEvent}.customEventType`}
                      control={control}
                      render={(opt) => (
                        <FFField label="Custom Scenario Type">
                          <FFSelect
                            value={opt.field.value}
                            onSelect={opt.field.onChange}
                            options={customScenarioTypeOptions}
                            valueGetter={(opt) => opt.value}
                            labelGetter={(opt) => opt.label}
                            placeholder="Custom Scenario Type"
                            style={{ width: 200 }}
                          />
                        </FFField>
                      )}
                    />
                  )}
                </FFRow>
                {isFacebook && <FacebookCustomScenario key={`facebook-${triggeringEvent}`} triggeringEvent={triggeringEvent} control={control} />}
                {isTwitter && <TwitterCustomScenario key={`twitter-${triggeringEvent}`} triggeringEvent={triggeringEvent} control={control} />}
                {isGoogleAds && <GoogleAdsCustomScenario key={`google-ads-${triggeringEvent}`} triggeringEvent={triggeringEvent} control={control} />}
                {isKwai && <KwaiCustomScenario key={`kwai-${triggeringEvent}`} triggeringEvent={triggeringEvent} control={control} />}
                {isReddit && <RedditCustomScenario key={`reddit-${triggeringEvent}`} triggeringEvent={triggeringEvent} control={control} />}
                {isMsAds && <MicrosoftAdsCustomScenario key={`ms-ads-${triggeringEvent}`} triggeringEvent={triggeringEvent} control={control} />}
                {isSnapchat && <SnapchatCustomScenario key={`snapchat-${triggeringEvent}`} triggeringEvent={triggeringEvent} control={control} />}
                {isTikTok && <TikTokCustomScenario key={`tik-tok-${triggeringEvent}`} triggeringEvent={triggeringEvent} control={control} />}
                {isPostbackURL && !isCustomScenario && (
                  <Controller
                    name={`conversionTrackingSettings.${triggeringEvent}.postbackURL`}
                    key={`postbackURL-${triggeringEvent}`}
                    control={control}
                    render={(opt) => (
                      <FFField label="Postback URL" block>
                        <FFTextArea
                          value={opt.field.value}
                          rows={6}
                          onBlur={saveInputPosition}
                          onChange={(e) => opt.field.onChange(withoutWhiteSpace(e.target.value))}
                          placeholder="Insert your postback URL here"
                        />
                        <CopyButton value={opt.field.value!} />
                      </FFField>
                    )}
                  />
                )}
                {isHTML && !isCustomScenario && (
                  <Controller
                    name={`conversionTrackingSettings.${triggeringEvent}.postbackHTML`}
                    key={`postbackHTML-${triggeringEvent}`}
                    control={control}
                    render={(opt) => (
                      <FFField label="HTML Content" block>
                        <FFTextArea
                          value={opt.field.value}
                          rows={6}
                          onBlur={saveInputPosition}
                          onChange={(e) => opt.field.onChange(withoutWhiteSpace(e.target.value))}
                          placeholder="Paste your tracking HTML"
                        />
                        <CopyButton value={opt.field.value!} />
                      </FFField>
                    )}
                  />
                )}
                {isPostbackURLOrHTML && !isCustomScenario && (
                  <FFRow gap="8px" key={`fieldTokens-${triggeringEvent}`}>
                    <FFSelect
                      options={trackingFieldsArray.filter((trackingField) => !['', 'campaign', 'external'].includes(trackingField.key))}
                      valueGetter={(opt) => getCorrectValueToken(opt.key)}
                      labelGetter={(opt) => opt.key}
                      placeholder="Field Tokens"
                      id="fieldTokens"
                      data-testid="fieldTokens"
                      style={{ width: 150 }}
                      onChange={handlePostbackTokens}
                    />
                    <FFSelect
                      options={attributeOptions}
                      valueGetter={(opt) => opt.value}
                      labelGetter={(opt) => opt.label}
                      filterOption={true}
                      showSearch={true}
                      popupMatchSelectWidth={false}
                      placeholder="Attributes"
                      id="attributes"
                      groupOptions={true}
                      style={{ width: 150 }}
                      onChange={handlePostbackTokens}
                      sortGroup={true}
                    />
                    <FFButton onClick={() => handlePostbackTokens('{external}')}>{'{external}'}</FFButton>
                    <FFButton onClick={() => handlePostbackTokens('{payout}')}>{'{payout}'}</FFButton>
                    <FFButton onClick={() => handlePostbackTokens('{txid}')}>{'{txid}'}</FFButton>
                    <FFButton onClick={() => handlePostbackTokens('{timestamp}')}>{'{timestamp}'}</FFButton>
                  </FFRow>
                )}
              </FFCol>
            </SectionBox>
          </FFCol>
        </VisibilityWrapper>
        <VisibilityWrapper visible={currentTabId === 'advanced'} beRenderedAlways>
          <FFCol gap="18px">
            <SectionBox title="Advanced Settings">
              <p>Here you can set optional advanced settings for this traffic source.</p>
            </SectionBox>
            <SectionBox title="Incoming cost modifier">
              <FFCol gap="18px">
                <p>
                  When enabled, this will multiply incoming costs by the provided value in percent. Use this to adjust for click loss by
                  setting a value of {'>'}100% (e.g. "110" would add 10% to costs to account for loss).
                </p>
                <FFSwitch checked={incomingCostModifierEnabled} onClick={setIncomingCostModifierEnabled}>
                  Enable incoming cost modification
                </FFSwitch>
                <VisibilityWrapper visible={incomingCostModifierEnabled} beRenderedAlways>
                  <Controller
                    name="incomingCostModifier"
                    control={control}
                    rules={{
                      min: { value: 0, message: 'Must be greater than 0' },
                      max: { value: 500, message: 'Must be less than 500' },
                    }}
                    render={(opt) => (
                      <FFField label="Multiplier in percent" direction="row">
                        <FFInput
                          onWheel={preventWheelChange}
                          error={opt.fieldState.error?.message}
                          value={opt.field.value}
                          onChange={(e) => opt.field.onChange(e.target.value)}
                          type="number"
                          style={{ width: 95 }}
                        />
                        %
                      </FFField>
                    )}
                  />
                </VisibilityWrapper>
              </FFCol>
            </SectionBox>
            <SectionBox title="Outgoing revenue modifier">
              <FFCol gap="18px">
                <p>
                  When enabled, this will multiply the value of <FFTag color="geekblue">{'{payout}'}</FFTag> used in postbacks to traffic
                  sources. Setting a value of "75" would multiply outgoing revenues to 75% of their original value.
                </p>
                <FFSwitch checked={outgoingRevenueModifierEnabled} onClick={setOutgoingRevenueModifierEnabled}>
                  Enable outgoing revenue modification
                </FFSwitch>
                <VisibilityWrapper visible={outgoingRevenueModifierEnabled} beRenderedAlways>
                  <Controller
                    name="outgoingRevenueModifier"
                    control={control}
                    rules={{
                      min: { value: 0, message: 'Must be greater than 0' },
                      max: { value: 500, message: 'Must be less than 500' },
                    }}
                    render={(opt) => (
                      <FFField label="Multiplier in percent" direction="row">
                        <FFInput
                          onWheel={preventWheelChange}
                          error={opt.fieldState.error?.message}
                          value={opt.field.value}
                          onChange={(e) => opt.field.onChange(e.target.value)}
                          type="number"
                          style={{ width: 95 }}
                        />
                        %
                      </FFField>
                    )}
                  />
                </VisibilityWrapper>
              </FFCol>
            </SectionBox>
            <SectionBox title="Disable zero revenue postbacks">
              <FFCol gap="18px">
                <p>
                  When enabled, conversions or custom events with a payout value of zero will no longer be sent to traffic sources. Use this
                  to prevent non-revenue events being sent to your traffic source automatically.
                </p>
                <Controller
                  name="disableZeroRevenuePostbacks"
                  control={control}
                  render={(opt) => (
                    <FFSwitch checked={opt.field.value} onClick={opt.field.onChange}>
                      Prevent zero revenue postbacks
                    </FFSwitch>
                  )}
                />
              </FFCol>
            </SectionBox>
            <SectionBox title="Outgoing Postback Throttling">
              <FFCol gap="18px">
                <p>
                  When enabled, set the % probability of a postback being sent to the traffic source. Use this when you need to reduce the
                  number of postbacks going to the traffic source arbitrarily by chance. Setting a value of “80” would give an 80% chance of
                  the postback being sent.
                </p>
                <FFSwitch checked={outgoingPostbackThrottlingEnabled} onClick={setOutgoingPostbackThrottlingEnabled}>
                  Limit postback events
                </FFSwitch>
                <VisibilityWrapper visible={outgoingPostbackThrottlingEnabled} beRenderedAlways>
                  <Controller
                    name="outgoingPostbackProbability"
                    control={control}
                    rules={{
                      min: { value: 0, message: 'Must be greater than 0' },
                      max: { value: 99.9, message: 'Must be less than 99.9' },
                    }}
                    render={(opt) => (
                      <FFField label="Chance in percent" direction="row">
                        <FFInput
                          onWheel={preventWheelChange}
                          error={opt.fieldState.error?.message}
                          value={opt.field.value}
                          onChange={(e) => opt.field.onChange(e.target.value)}
                          type="number"
                          style={{ width: 95 }}
                        />
                        %
                      </FFField>
                    )}
                  />
                </VisibilityWrapper>
              </FFCol>
            </SectionBox>
          </FFCol>
        </VisibilityWrapper>
        <VisibilityWrapper visible={currentTabId === 'help'}>
          <SectionBox title="Help">
            <FFCol gap="12px">
              <p>Traffic Sources are wherever your users come from prior to entering your funnels.</p>

              <p>
                These could be sources like Facebook, Google Adwords, Taboola etc. or could be your own source labels like Internal Email,
                Affiliate Referrals, etc.
              </p>

              <p>
                These are an important label of incoming traffic and they define what custom URL fields you will use to collect data, which
                will also be added to links you generate.
              </p>
              <h3>Tracking Field Configuration</h3>
              <p>
                The tracking field config defines what parameters you will pass in generated URLs and hence capture from the traffic source.
                Here are the column definitions:
              </p>
              <ul>
                <li>
                  <strong>Name:</strong> The tracking field name. There are two reserved fields for campaign IDs and external click IDs.
                  These cannot be removed, but you can click unlock to alias the parameter name for rare situations.
                </li>
                <li>
                  <strong>URL Parameter:</strong> this is the name of the URL parameter or the "key" that will appear in the URL. It should
                  be lowercase and use no symbols other than underscores
                </li>
                <li>
                  <strong>Source Token:</strong> here you set the corresponding value that will be passed by a parameter, giving a
                  name=value pair in the URL. Use available traffic source tokens, which depend on the source, or a static placeholder like
                  REPLACE
                </li>
                <li>
                  <strong>FunnelFlux Token:</strong> this is the internal FunnelFlux token that will reference the value passed. This token
                  is what you would use in data passing config for landers/offers to pass traffic source information onward.
                </li>
                <li>
                  <strong>Append:</strong> here you can choose whether a field will be appended to generated links. Turning this off will
                  stop the field appearing in links, though the defined field will still capture values. Use it to turn off fields you no
                  longer wish to pass.
                </li>
              </ul>
            </FFCol>
          </SectionBox>
        </VisibilityWrapper>
      </div>
    );
  },
);

const TrafficSourceForm = () => {
  const isOpen = useFormStore((state) => state.trafficSource.isOpen);
  const openedForms = useFormStore((state) => state.openedForms);
  const closeForm = useFormStore((state) => state.closeTrafficSourceForm);
  const [showTemplates, setShowTemplates] = useState(false);
  const [templateSearch, setTemplateSearch] = useState('');
  const [currentTabId, setCurrentTabId] = useState('general');
  const idTrafficSource = useFormStore((state) => state.trafficSource.data?.id);
  const isDuplication = useFormStore((state) => state.trafficSource.isDuplication);
  const formRef = useRef<TrafficSourceFormRefType>({
    onSave: () => {},
  });
  const [selectedTemplate, setSelectedTemplate] = useState<TrafficSourceTemplate>();
  const [submitLoading, setSubmitLoading] = useState(false);
  const { data: defaultFormValues = DEFAULT_TRAFFICSOURCE, isFetching } = useTrafficSourceQuery(idTrafficSource!);

  const computedDefaultFormValues = useMemo(() => {
    if (isDuplication) {
      return {
        ...defaultFormValues,
        trafficSourceName: addOrIncrementCopySuffix(defaultFormValues.trafficSourceName),
      };
    }
    return defaultFormValues;
  }, [defaultFormValues, isDuplication]);

  const copyItems: SidebarCopyItems[] = useMemo(() => {
    if (idTrafficSource) {
      return [{ title: 'Traffic Source ID', value: idTrafficSource }];
    }
    return [];
  }, [idTrafficSource]);

  useEffect(() => {
    if (isOpen) {
      setShowTemplates(!idTrafficSource);
    }
  }, [isOpen, idTrafficSource]);

  const defaultValuesFromTemplate: TrafficSource | null = useMemo(() => {
    if (!selectedTemplate) return DEFAULT_TRAFFICSOURCE;
    return {
      idTrafficSource: '',
      trafficSourceName: selectedTemplate.name,
      trackingFieldSlots: selectedTemplate.trackingFieldSlots,
      costType: selectedTemplate.typeCost,
      defaultCost: selectedTemplate.defaultCost,
      conversionTrackingSettings: selectedTemplate.conversionTrackingSettings,
      postback: {
        idTrafficSource: '',
        postbackType: selectedTemplate.trackingType,
        postbackURL: selectedTemplate.trackingType === 'postbackURL' ? selectedTemplate.tracking.code : '',
        postbackHTML: selectedTemplate.trackingType === 'html' ? selectedTemplate.tracking.code : '',
      },
    };
  }, [selectedTemplate]);

  const onClose = () => {
    setTemplateSearch('');
    setCurrentTabId('general');
    setSelectedTemplate(undefined);
    closeForm();
  };

  const onSelectTemplate = (template?: TrafficSourceTemplate) => {
    if (template) {
      setSelectedTemplate(template);
    }
    setShowTemplates(false);
  };

  return (
    <FFSidePanel
      isOpen={isOpen}
      minWidth={600}
      maxWidth={1100}
      tabs={showTemplates ? [] : tabs}
      onClose={onClose}
      copyItems={copyItems}
      sidebarName="TrafficSourceForm"
      currentTabId={currentTabId}
      offsetLevel={getSidebarOffsetLevel(openedForms, 'trafficSource')}
      zIndex={getSidebarZIndex(openedForms, 'trafficSource')}
      title={isDuplication ? 'Duplicate Traffic Source' : idTrafficSource ? 'Edit Traffic Source' : 'Create Traffic Source'}
      setCurrentTabId={setCurrentTabId}
      actions={
        <FFRow gap="8px">
          <FFButton onClick={() => formRef.current.onSave()} loading={submitLoading} disabled={submitLoading}>
            Save
          </FFButton>
          <FFButton type="tertiary" disabled={submitLoading} onClick={onClose}>
            Cancel
          </FFButton>
        </FFRow>
      }
    >
      {showTemplates ? (
        <TemplateSelector
          onSelect={onSelectTemplate}
          onSearch={setTemplateSearch}
          searchValue={templateSearch}
          templates={TRAFFIC_SOURCE_TEMPLATE}
          type="trafficsource"
        />
      ) : isFetching ? (
        <Skeleton width="100%" height="400px" />
      ) : (
        <Form
          defaultFormValues={idTrafficSource ? computedDefaultFormValues : defaultValuesFromTemplate}
          currentTabId={currentTabId as TrafficSourceFormTabId}
          closeForm={closeForm}
          setSubmitLoading={setSubmitLoading}
          submitLoading={submitLoading}
          selectedTemplate={selectedTemplate}
          ref={formRef}
        />
      )}
    </FFSidePanel>
  );
};

export default TrafficSourceForm;
