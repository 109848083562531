/**
 * FunnelFlux Pro Ledger API
 * API endpoints to manage customers' assets
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Meta } from "./meta";
export interface OfferSource { 
    /**
     * ID of the offer source
     */
    idOfferSource: string;
    /**
     * Offer source's name.
     */
    offerSourceName: string;
    /**
     * Name of the parameter to pass unique hit ids to your offer source
     */
    subId?: string;
    /**
     * Query parameters to add to the url. Tokens can be used as values.
     */
    queryParams?: { [key: string]: string; };
    /**
     * Name of the subid parameter your offer source sends back to FunnelFlux when a conversion occurs
     */
    postbackSubId?: string;
    /**
     * Name of the unique transaction id parameter your offer source sends back to FunnelFlux when a conversion occurs
     */
    postbackTxId?: string;
    /**
     * Name of the payout parameter your offer source sends back to FunnelFlux when a conversion occurs
     */
    postbackPayout?: string;
    status?: OfferSource.StatusEnum;
    /**
     * DEPRECATED
     */
    isArchived?: boolean;
    /**
     * Category ID. Empty if uncategorized
     */
    idCategory?: string;
    meta?: Meta;
}
export namespace OfferSource {
    export type StatusEnum = 'active' | 'archived' | 'deleted';
    export const StatusEnum = {
        Active: 'active' as StatusEnum,
        Archived: 'archived' as StatusEnum,
        Deleted: 'deleted' as StatusEnum
    };
}
