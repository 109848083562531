const GeneralSettings = () => (
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
    <g id="hierarchy-10--node-organization-links-structure-link-nodes-network-hierarchy">
        <path id="Vector" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M3.5 9.5v-5" strokeWidth="1"></path>
        <path id="Vector_2" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M8.5 6.5h-2c-0.79565 0 -1.55871 0.31607 -2.12132 0.87868C3.81607 7.94129 3.5 8.70435 3.5 9.5" strokeWidth="1"></path>
        <path id="Vector 4344" fill="currentColor" d="M1.5 4V1c0 -0.276142 0.22386 -0.5 0.5 -0.5h3c0.27614 0 0.5 0.223858 0.5 0.5v3c0 0.27614 -0.22386 0.5 -0.5 0.5H2c-0.27614 0 -0.5 -0.22386 -0.5 -0.5Z" strokeWidth="1"></path>
        <path id="Vector 4345" fill="currentColor" d="M1.5 13v-3c0 -0.27614 0.22386 -0.5 0.5 -0.5h3c0.27614 0 0.5 0.22386 0.5 0.5v3c0 0.2761 -0.22386 0.5 -0.5 0.5H2c-0.27614 0 -0.5 -0.2239 -0.5 -0.5Z" strokeWidth="1"></path>
        <path id="Vector 4346" fill="currentColor" d="M8.5 8V5c0 -0.27614 0.22386 -0.5 0.5 -0.5h3c0.2761 0 0.5 0.22386 0.5 0.5v3c0 0.27614 -0.2239 0.5 -0.5 0.5H9c-0.27614 0 -0.5 -0.22386 -0.5 -0.5Z" strokeWidth="1"></path>
        <path id="Vector 4347" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M1.5 4V1c0 -0.276142 0.22386 -0.5 0.5 -0.5h3c0.27614 0 0.5 0.223858 0.5 0.5v3c0 0.27614 -0.22386 0.5 -0.5 0.5H2c-0.27614 0 -0.5 -0.22386 -0.5 -0.5Z" strokeWidth="1"></path>
        <path id="Vector 4348" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M1.5 13v-3c0 -0.27614 0.22386 -0.5 0.5 -0.5h3c0.27614 0 0.5 0.22386 0.5 0.5v3c0 0.2761 -0.22386 0.5 -0.5 0.5H2c-0.27614 0 -0.5 -0.2239 -0.5 -0.5Z" strokeWidth="1"></path>
        <path id="Vector 4349" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M8.5 8V5c0 -0.27614 0.22386 -0.5 0.5 -0.5h3c0.2761 0 0.5 0.22386 0.5 0.5v3c0 0.27614 -0.2239 0.5 -0.5 0.5H9c-0.27614 0 -0.5 -0.22386 -0.5 -0.5Z" strokeWidth="1"></path>
    </g>
</svg>
);

export default GeneralSettings;
