import className from '@/utils/className';
import { useForm, Controller } from 'react-hook-form';
import { FFButton, FFInput, FFRow } from '@/uikit';
import { Link, useNavigate } from 'react-router-dom';
import { useResetPasswordMutation } from '../../api/mutation/login';
import './style.scss';
import ImageAsyncSrc from '@/components/ImageAsyncSrc';

const { blockClassName, getClass } = className('v-resetPassword');

const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

const checkEmail = (value: string) => {
  if (!value) {
    return 'Email is required';
  }
  if (!validateEmail(value)) {
    return 'Please enter a valid email address';
  }
  return true;
};

const ResetPassword = () => {
  const navigate = useNavigate();
  const { mutateAsync: login, isPending } = useResetPasswordMutation();
  const { handleSubmit, control, formState: { errors } } = useForm<{ email: string }>({
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = async (data: { email: string }) => {
    try {
      await login(data.email);
      navigate('/');
    } catch (e) {
      //
    }
  };

  return (
    <div className={blockClassName}>
      <div className={getClass('container')}>
        <div className={getClass('content')}>
          <ImageAsyncSrc src="funnelflux.svg" alt="FunnelFlux" className={getClass('img')} />
          <div className={getClass('formWrapper')}>
            <div className={getClass('formContent')}>
              <Controller
                name="email"
                control={control}
                rules={{ required: true, validate: checkEmail }}
                render={(opt) => (
                  <FFInput
                    name="email"
                    type="text"
                    autoComplete="email"
                    error={errors.email?.message}
                    onChange={opt.field.onChange}
                    ref={opt.field.ref}
                    placeholder="Email Address"
                    className={getClass('input')}
                  />
                )}
              />
              <FFButton
                block
                size="large"
                type="brandRed"
                loading={isPending}
                className={getClass('loginBtn')}
                onClick={handleSubmit(onSubmit)}
              >
                RESET MY PASSWORD
              </FFButton>

              <FFRow justifyContent="center" alignItems="center" marginTop="18px">
                <Link to="/">
                  <FFButton type="tertiary">Back to Login</FFButton>
                </Link>
              </FFRow>
            </div>

            <div className={getClass('footer')}>
              <FFRow gap="18px" justifyContent="center" alignItems="center" width="100%" className={getClass('buttonGroup')}>
                <FFButton type="tertiary" onClick={() => window.open('https://status.funnelflux.pro/', '_blank')}>
                  Status Page
                </FFButton>
                <FFButton type="tertiary" onClick={() => window.open('https://funnelflux.canny.io/changelog', '_blank')}>
                  Changelog
                </FFButton>
                <FFButton type="tertiary" onClick={() => window.open('mailto:support@funnelflux.pro')}>
                  Contact Support
                </FFButton>
              </FFRow>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
