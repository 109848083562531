const ShareLinkApproved = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30">
    <g id="share-link-approved--share-transmit-correct-check-link">
      <path
        id="Vector"
        fill="#d7e0ff"
        d="M5.474464285714285 18.41410714285714C7.948205485714286 18.41410714285714 9.95357142857143 16.408741199999998 9.95357142857143 13.934999999999999S7.948205485714286 9.455892857142857 5.474464285714285 9.455892857142857 0.9953571428571428 11.4612588 0.9953571428571428 13.934999999999999s2.005365942857143 4.479107142857143 4.479107142857143 4.479107142857143Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_2"
        fill="#d7e0ff"
        d="M22.395535714285714 9.95357142857143c2.473661571428571 0 4.479107142857143 -2.005365942857143 4.479107142857143 -4.479107142857143S24.869197285714282 0.9953571428571428 22.395535714285714 0.9953571428571428 17.916428571428572 3.0007230857142857 17.916428571428572 5.474464285714285 19.921874142857142 9.95357142857143 22.395535714285714 9.95357142857143Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_3"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5.474464285714285 18.41410714285714C7.948205485714286 18.41410714285714 9.95357142857143 16.408741199999998 9.95357142857143 13.934999999999999S7.948205485714286 9.455892857142857 5.474464285714285 9.455892857142857 0.9953571428571428 11.4612588 0.9953571428571428 13.934999999999999s2.005365942857143 4.479107142857143 4.479107142857143 4.479107142857143Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_4"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M22.395535714285714 9.95357142857143c2.473661571428571 0 4.479107142857143 -2.005365942857143 4.479107142857143 -4.479107142857143S24.869197285714282 0.9953571428571428 22.395535714285714 0.9953571428571428 17.916428571428572 3.0007230857142857 17.916428571428572 5.474464285714285 19.921874142857142 9.95357142857143 22.395535714285714 9.95357142857143Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_5"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m9.475819907142858 11.944285714285714 8.9184 -4.479107142857143"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_6"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m9.475819907142858 15.925714285714285 8.9184 4.479107142857143"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_7"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m26.874642857142856 17.916428571428572 -7.962857142857143 8.958214285714286 -3.9814285714285713 -2.9860714285714285"
        strokeWidth="2.13"
      ></path>
    </g>
  </svg>
);

export default ShareLinkApproved;
