const Columns = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.57 -0.57 16 16" height="16" width="16">
    <g id="layout-window-11--columns-layout-layouts-three">
      <path
        id="Vector"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.267857142857142 0.5307142857142857h-11.675714285714285c-0.5862110785714286 0 -1.0614285714285714 0.4752174928571428 -1.0614285714285714 1.0614285714285714v11.675714285714285c0 0.5862269999999999 0.4752174928571428 1.0614285714285714 1.0614285714285714 1.0614285714285714h11.675714285714285c0.5862269999999999 0 1.0614285714285714 -0.4752015714285714 1.0614285714285714 -1.0614285714285714v-11.675714285714285c0 -0.5862110785714286 -0.4752015714285714 -1.0614285714285714 -1.0614285714285714 -1.0614285714285714Z"
        strokeWidth="1.14"
      ></path>
      <path
        id="Vector_2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5.130149642857142 0.5307142857142857v13.798571428571428"
        strokeWidth="1.14"
      ></path>
      <path
        id="Vector_3"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.729850357142857 0.5307142857142857v13.798571428571428"
        strokeWidth="1.14"
      ></path>
    </g>
  </svg>
);

export default Columns;