import { PropsWithChildren, ReactNode } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import className from '@/utils/className';
import clsx from 'clsx';
import FFTooltip from '../Tooltip';
import { defined } from '@/utils/define';
import './style.scss';

const { blockClassName, getClass } = className('c-ffField');

const Field = ({
  label,
  children,
  error,
  htmlFor,
  block = false,
  tooltipContent,
  labelPrefix,
  maxWidth,
  minWidth,
  direction = 'column'
}: PropsWithChildren<{
  label?: string;
  labelPrefix?: ReactNode;
  error?: string;
  htmlFor?: string;
  block?: boolean;
  maxWidth?: string;
  minWidth?: string;
  direction?: 'column' | 'row';
  tooltipContent?: string | JSX.Element;
}>) => (
  <div
    className={clsx(blockClassName, block ? `${blockClassName}--block` : `${blockClassName}--minimum`, `${blockClassName}--${direction}`)}
    style={{ maxWidth: maxWidth, minWidth: minWidth }}
  >
    {defined(label) && (
      <label htmlFor={htmlFor} className={getClass('label')}>
        {labelPrefix}
        <span className={getClass('labelText')}>{label}</span>
        {tooltipContent && (
          <FFTooltip title={tooltipContent}>
            <InfoCircleOutlined />
          </FFTooltip>
        )}
      </label>
    )}
    <div className={getClass('childContent')}>{children}</div>
    {error && <span className={getClass('error')}>{error}</span>}
  </div>
);

export default Field;
