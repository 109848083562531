const StoryPost = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.855 -0.855 24 24">
    <g id="story-post">
      <path id="Vector" stroke="currentColor" strokeLinecap="round" d="M11.145 7.164642857142857v7.960714285714285" strokeWidth="1.71"></path>
      <path
        id="Vector_2"
        stroke="currentColor"
        strokeLinecap="round"
        d="M7.164642857142857 11.145h7.960714285714285"
        strokeWidth="1.71"
      ></path>
      <path
        id="Ellipse 300"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M0.9706005364285715 9.774037628571428c-0.05122401214285714 0.41545375714285715 -0.07760104285714285 0.8385975642857143 -0.07760104285714285 1.2679188857142856 0 0.42930539999999995 0.02637703071428571 0.8524651285714285 0.07760104285714285 1.2679188857142856"
        strokeWidth="1.71"
      ></path>
      <path
        id="Ellipse 305"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5.053509192857143 2.7325629428571427c0.33417486428571425 -0.25208397857142856 0.6874554428571428 -0.48651109285714284 1.0592526428571427 -0.7011637928571428s0.7514436642857143 -0.4033853142857142 1.1368536857142857 -0.5667519085714285"
        strokeWidth="1.71"
      ></path>
      <path
        id="Ellipse 303"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1.70016975 7.0518554571428576c0.16335385714285713 -0.38541002142857145 0.3520864714285714 -0.7650724071428572 0.5667550928571429 -1.1368536857142857 0.21465269999999997 -0.3717972 0.4490638928571429 -0.7250777785714285 0.7011637928571428 -1.0592526428571427"
        strokeWidth="1.71"
      ></path>
      <path
        id="Ellipse 306"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.164233085714285 0.7960714285714285C16.815416785714287 0.7960714285714285 21.396807857142857 5.429445964285714 21.396807857142857 11.145c0 5.715633642857143 -4.5813910714285715 10.348928571428571 -10.23257477142857 10.348928571428571 -3.894333664285714 0 -7.280534935714286 -2.200182214285714 -9.009633921428572 -5.438600785714286"
        strokeWidth="1.71"
      ></path>
    </g>
  </svg>
);

export default StoryPost;