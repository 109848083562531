import { RuleTimeDayOfWeekParams } from '@/models/ruleTimeDayOfWeekParams';
import { connectionTypes } from './connectionTypes';
import { continents } from './continents';
import { countries } from './countries';
import { deviceOS } from './deviceOS';
import { deviceTypes } from './deviceTypes';
import { languages } from './languages';
import { timeZones } from './timeZones';
import { FunnelConditionRule } from '@/models/funnelConditionRule';
import { FFSelectOption } from '@/uikit/types/select';
import { FunnelConditionRoute } from '@/models/funnelConditionRoute';
import { FunnelCondition } from '@/models/funnelCondition';

const defaultRoute = (idRoute: number): FunnelConditionRoute => ({
  routeName: `Route ${idRoute}`,
  operator: 'or',
  groups: [
    {
      operator: 'or',
      rules: [
        {
          attribute: '' as any,
          test: '' as any,
        },
      ],
    },
  ],
});

export const DEFAULT_CONDITION: FunnelCondition = {
  idCondition: '',
  idCategory: '',
  conditionName: '',
  routes: [
    {
      routeName: 'Default Route',
      groups: [],
      operator: 'or'
    },
    {
      routeName: 'Route 1',
      operator: 'or',
      groups: [
        {
          operator: 'or',
          rules: [{} as FunnelConditionRule]
        }
      ],  
    }
  ],
};

export const daysOfWeek: FFSelectOption<RuleTimeDayOfWeekParams['day']>[] = [
    {
      value: 'monday',
      label: 'Monday',
    },
    {
      value: 'tuesday',
      label: 'Tuesday',
    },
    {
      value: 'wednesday',
      label: 'Wednesday',
    },
    {
      value: 'thursday',
      label: 'Thursday',
    },
    {
      value: 'friday',
      label: 'Friday',
    },
    {
      value: 'saturday',
      label: 'Saturday',
    },
    {
      value: 'sunday',
      label: 'Sunday',
    }
  ];

function makeFieldsFromEnum(attributes: { [key: string]: string }) {
  var options: FFSelectOption[] = [];
  for (let key in attributes) {
    const value = attributes[key];
    const splited = value.split(':');
    if (splited.length > 1) {
      options.push({
        category: splited[0],
        label: splited[1],
        value: attributes[key]
      });
    } else {
      options.push({
        category: 'Others',
        label: splited[0],
        value: attributes[key]
      });
    }
  }
  return options;
}

export const attributeFields = makeFieldsFromEnum(FunnelConditionRule.AttributeEnum);

export interface ConditionRuleSelectParams<T = any> {
  options: T[];
  defaultValueFallback?: { label: string; value: string; };
  valueGetter: (opt: T) => string;
  labelGetter: (opt: T) => string;
}

export const continentSelectParams: ConditionRuleSelectParams<string> = {
  options: continents,
  valueGetter: opt => opt,
  labelGetter: opt => opt
};

export const countrySelectParams: ConditionRuleSelectParams<FFSelectOption> = {
  options: countries,
  valueGetter: opt => opt.value,
  labelGetter: opt => opt.label
};

export const timezoneSelectParams: ConditionRuleSelectParams<FFSelectOption> = {
  options: timeZones,
  valueGetter: opt => opt.value,
  labelGetter: opt => opt.label
};

export const deviceTypeSelectParams: ConditionRuleSelectParams<string> = {
  options: deviceTypes,
  valueGetter: opt => opt,
  labelGetter: opt => opt
};

export const deviceOsSelectParams: ConditionRuleSelectParams<string> = {
  options: deviceOS,
  valueGetter: opt => opt,
  labelGetter: opt => opt
};

export const deviceBrowserLanguageParams: ConditionRuleSelectParams<FFSelectOption> = {
  options: languages,
  valueGetter: opt => opt.value,
  labelGetter: opt => opt.label
};

export const connectionTypeParams: ConditionRuleSelectParams<string> = {
  options: connectionTypes,
  valueGetter: opt => opt,
  labelGetter: opt => opt
};

export const dayOfWeekParams: ConditionRuleSelectParams<FFSelectOption> = {
  options: daysOfWeek,
  valueGetter: opt => opt.value,
  labelGetter: opt => opt.label
};

export const dayOfMonthParams: ConditionRuleSelectParams<string> = {
  options: Array.from(Array(31).keys()).map(i => `${i + 1}`),
  valueGetter: opt => opt,
  labelGetter: opt => opt
};

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
export const monthOfYearParams: ConditionRuleSelectParams<string> = {
  options: Array.from(Array(12).keys()).map(i => `${i + 1}`),
  valueGetter: opt => opt,
  labelGetter: opt => months[Number(opt) - 1]
};
