const ArrowUp = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.64 -0.64 18 18">
    <g id="arrow-up-3--arrow-up-keyboard">
      <path
        id="Vector"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m0.5971428571428572 12.122238857142857 7.344857142857144 -7.3449168571428585c0.05361148571428572 -0.05712268571428571 0.11836565714285716 -0.10264885714285715 0.19026165714285717 -0.13377194285714286 0.07188405714285714 -0.031123085714285716 0.14939320000000003 -0.04718622857142858 0.22773834285714287 -0.04718622857142858 0.07834514285714288 0 0.15585428571428572 0.01606314285714286 0.22773834285714287 0.04718622857142858 0.071896 0.031123085714285716 0.13665017142857144 0.07664925714285716 0.19026165714285717 0.13377194285714286l7.344857142857144 7.3449168571428585"
        strokeWidth="1.28"
      ></path>
    </g>
  </svg>
);

export default ArrowUp;
