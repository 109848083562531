import React, { useState, useEffect, useCallback, useRef } from 'react';
import { getAttributes } from '@/constants/templates';
import { FFAddGroup, FFIconButton, FFInput, FFSelect, VisibilityWrapper } from '@/uikit';
import { definedObject } from '@/utils/define';
import { naturalSort } from '@/utils/sort';
import { withoutWhiteSpace } from '@/utils/string';
import { TrackingTokenType } from '@/types';
import { FFSelectOption } from '@/uikit/types/select';
import className from '@/utils/className';
import './style.scss';

const { getClass } = className('c-dataPassing');

const getTrafficSourceField = () => '{data-fieldname}';
const isTrafficSourceField = (value: string) => value === '{data-traffic-source}';
const isUrlTrackingField = (value: string) => value === '{data-fieldname}';
const isBufferField = (value: string) => value === '{buffer-fieldname}';
const isCustomString = (value: string) => value === 'Custom String';

const showTextField = (value: string) =>
  isTrafficSourceField(value) || isUrlTrackingField(value) || isBufferField(value) || isCustomString(value);

const getTextFieldPlaceholder = (value: string) =>
  isUrlTrackingField(value)
    ? 'Enter URL parameter name to pass'
    : isCustomString(value)
    ? 'Custom string entry'
    : isTrafficSourceField(value)
    ? 'Traffic source field name or numbered value'
    : 'Manual text entry';

const getCustomFieldTypesValue = (value: string, manualValue: string) => {
  return `${
    isCustomString(value)
      ? value.replace('Custom String', manualValue)
      : isTrafficSourceField(value)
      ? value.replace('traffic-source', manualValue)
      : value.replace('fieldname', manualValue)
  }`;
};

const CONFLICT_ERROR = 'This parameter conflicts with one that is in your base URL. Please remove one of the conflicting params.';

interface Param {
  key: string;
  value: string;
  manual?: string;
}

interface Error {
  key: string;
  value: string;
  manual: string;
}

interface Props {
  queryParams: { [key: string]: string };
  pageName: TrackingTokenType;
  disableFields?: boolean;
  disableRemove?: boolean;
  disableAdd?: boolean;
  baseURL?: string;
  idOfferSource?: string;
  showErrors?: boolean;
  reinitialize?: boolean;
  setError?: (hasError: boolean) => void;
  onChange?(queryParams: { [key: string]: string }): void;
}

const defaultParam: Param = {
  key: '',
  value: '',
  manual: '',
};

const keyCheckRegex = /^[a-z0-9_-]+$/i;

const DataPassing: React.FC<Props> = ({
  queryParams: initialQueryParams = {},
  pageName,
  disableFields = false,
  disableRemove = false,
  disableAdd = false,
  baseURL,
  idOfferSource,
  showErrors = false,
  reinitialize = false,
  setError = () => {},
  onChange = () => {},
}) => {
  const [queryParams, setQueryParams] = useState<Param[]>([defaultParam]);
  const [errors, setErrors] = useState<{ [key: number]: Error }>({});
  const [initialized, setInitialized] = useState(false);
  const timeout = useRef<NodeJS.Timeout | null>(null);

  const fillByInitialParams = (queryParams: { [key: string]: string }) => {
    const sortedParams = Object.entries(definedObject(queryParams) ? queryParams : { '': '' })
      .sort((a, b) => {
        const nameA = a[0].toLowerCase();
        const nameB = b[0].toLowerCase();
        return naturalSort(nameA, nameB);
      })
      .map(([key, value]: [string, string]) => ({
        key: key,
        value: value,
      }));
    setQueryParams(sortedParams);
  };

  useEffect(() => {
    if (reinitialize ? true : !initialized) {
      fillByInitialParams(initialQueryParams);
    }
    setInitialized(true);
  }, [reinitialize, initialQueryParams, initialized]);

  const checkErrors = useCallback(
    (newParams = queryParams) => {
      try {
        const url = new URL(baseURL || 'https://dummy');
        const newErrors: { [key: number]: Error } = {};
        const allKeys = newParams.map((param) => param.key);
        newParams.forEach((param, idx) => {
          const keyError =
            !param.key && param.value
              ? 'Required'
              : param.key && url.searchParams.has(param.key)
              ? CONFLICT_ERROR
              : allKeys.filter((x) => x === param.key).length > 1
              ? 'Duplicated Key'
              : '';
          const valueError = param.key && !param.value ? 'Required' : '';
          const manualError = param.value && param.key && !param.manual && isCustomString(param.value) ? 'Required' : '';

          if (keyError || valueError || manualError) {
            newErrors[idx] = { key: keyError, value: valueError, manual: manualError };
          }
        });
        setErrors(newErrors);
        setError(Object.keys(newErrors).length > 0);
      } catch (e) {
        setError(true);
      }
    },
    [baseURL, queryParams, setError],
  );

  useEffect(() => {
    checkErrors();
  }, [baseURL, idOfferSource, showErrors, checkErrors]);

  const debounce = (func: Function, ms = 400) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      func();
    }, ms);
  };

  const onChangeDebounced = useCallback(
    (newParams: Param[]) => {
      debounce(() => {
        checkErrors(newParams);
        const paramObject = newParams.reduce((acc: { [key: string]: string }, item) => {
          if (item.manual) {
            acc[item.key] = getCustomFieldTypesValue(item.value, item.manual);
          } else if (item.key && item.value) {
            acc[item.key] = item.value;
          }
          return acc;
        }, {});
        onChange(paramObject);
      }, 400);
    },
    [onChange, checkErrors],
  );

  const onKeyChange = (idx: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value && !keyCheckRegex.test(e.target.value)) return;
    const newParams = [...queryParams];
    newParams[idx] = { ...newParams[idx], key: withoutWhiteSpace(e.target.value) };
    setQueryParams(newParams);
    onChangeDebounced(newParams);
  };

  const onValueChange = (idx: number) => (value: string) => {
    const newParams = [...queryParams];
    if (!isCustomString(value)) {
      newParams[idx] = { ...newParams[idx], value, manual: '' };
    } else {
      newParams[idx] = { ...newParams[idx], value, manual: newParams[idx].manual || '' };
    }
    setQueryParams(newParams);
    onChangeDebounced(newParams);
  };

  const onManualValueChange = (idx: number) => (value: string | React.ChangeEvent<HTMLInputElement>) => {
    const newParams = [...queryParams];
    newParams[idx] = { ...newParams[idx], manual: typeof value === 'string' ? value : value.target.value };
    setQueryParams(newParams);
    onChangeDebounced(newParams);
  };

  const onAddRow = () => {
    setQueryParams([...queryParams, defaultParam]);
    onChangeDebounced([...queryParams, defaultParam]);
  };

  const onRemoveRow = (idx: number) => () => {
    const newParams = queryParams.filter((_, index) => index !== idx);
    setQueryParams(newParams);
    onChangeDebounced(newParams);
  };

  const getErrors = (idx: number, key: 'key' | 'value' | 'manual') => {
    if (!showErrors) return;
    return errors?.[idx]?.[key];
  };

  const valueGetter = (opt: FFSelectOption) => {
    switch (opt.value) {
      case 'custom_fields_custom_string':
        return `Custom String`;
      case 'custom_fields_url_data':
        return `{data-fieldname}`;
      case 'custom_fields_traffic_source_data':
        return `{data-traffic-source}`;
      default:
        return opt.value;
    }
  };

  return (
    <FFAddGroup
      rows={queryParams}
      showAddRow={!disableAdd}
      onAddRow={onAddRow}
      headerRow={
        <FFAddGroup.Row>
          <FFAddGroup.Col>
            <span className={getClass('headerText')}>Fields</span>
          </FFAddGroup.Col>
          <FFAddGroup.Col>
            <span className={getClass('headerText')}>Values to pass</span>
          </FFAddGroup.Col>
          <VisibilityWrapper visible={!disableRemove}>
            <FFAddGroup.Col maxWidth={30} />
          </VisibilityWrapper>
        </FFAddGroup.Row>
      }
      renderRow={(row, rowIdx) => (
        <>
          <FFAddGroup.Col gap="8px">
            <FFAddGroup.Row>
              <FFAddGroup.Col>
                <FFInput
                  name="key"
                  value={row.key}
                  placeholder="Key"
                  disabled={disableFields}
                  onChange={onKeyChange(rowIdx)}
                  error={getErrors(rowIdx, 'key')}
                />
              </FFAddGroup.Col>
              <FFAddGroup.Col>
                <FFSelect
                  value={
                    row.manual
                      ? getCustomFieldTypesValue(row.value, row.manual)
                      : isTrafficSourceField(row.value)
                      ? getTrafficSourceField()
                      : row.value
                  }
                  options={getAttributes(pageName)}
                  valueGetter={valueGetter}
                  labelGetter={(opt) => opt.label}
                  disabled={disableFields}
                  placeholder="Value"
                  optionLabelProp="value"
                  groupOptions={true}
                  sortGroup={true}
                  showSearch
                  error={getErrors(rowIdx, 'value')}
                  onChange={onValueChange(rowIdx)}
                />
              </FFAddGroup.Col>
              <VisibilityWrapper visible={!disableRemove}>
                <FFAddGroup.Col maxWidth={30}>
                  <FFIconButton
                    iconName="general/line/delete-circle"
                    buttonType="transparent"
                    iconType="danger"
                    onClick={onRemoveRow(rowIdx)}
                  />
                </FFAddGroup.Col>
              </VisibilityWrapper>
            </FFAddGroup.Row>
            {showTextField(row.value) && (
              <FFAddGroup.Row>
                <FFInput
                  name="manual"
                  value={row.manual}
                  placeholder={getTextFieldPlaceholder(row.value)}
                  disabled={disableFields}
                  error={getErrors(rowIdx, 'manual')}
                  onChange={onManualValueChange(rowIdx)}
                />
              </FFAddGroup.Row>
            )}
          </FFAddGroup.Col>
        </>
      )}
    />
  );
};

export default DataPassing;
