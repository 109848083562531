const AddTab = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
  <g id="browser-add--app-code-apps-add-window-plus">
    <path id="Subtract" fill="#ffffff" fillRule="evenodd" d="M1 3.5v8.5769c0 0.5098 0.41328 0.9231 0.92308 0.9231H12.0769c0.5098 0 0.9231 -0.4133 0.9231 -0.9231V3.5H1Z" clipRule="evenodd" strokeWidth="1"></path>
    <path id="Intersect" fill="#d7e0ff" fillRule="evenodd" d="M13 3.51839H1V1.92308C1 1.41328 1.41328 1 1.92308 1H12.0769c0.5098 0 0.9231 0.41328 0.9231 0.92308v1.59531Z" clipRule="evenodd" strokeWidth="1"></path>
    <path id="Vector" stroke="#4147d5" strokeLinecap="round" strokeLinejoin="round" d="M12.0769 1H1.92308C1.41328 1 1 1.41328 1 1.92308V12.0769c0 0.5098 0.41328 0.9231 0.92308 0.9231H12.0769c0.5098 0 0.9231 -0.4133 0.9231 -0.9231V1.92308C13 1.41328 12.5867 1 12.0769 1Z" strokeWidth="1"></path>
    <path id="Vector_2" stroke="#4147d5" strokeLinecap="round" strokeLinejoin="round" d="M1 3.5h12.0001" strokeWidth="1"></path>
    <path id="Vector_3" stroke="#4147d5" strokeLinecap="round" strokeLinejoin="round" d="M9.5 8.25h-5" strokeWidth="1"></path>
    <path id="Vector_4" stroke="#4147d5" strokeLinecap="round" strokeLinejoin="round" d="M7 5.75v5" strokeWidth="1"></path>
  </g>
</svg>
);

export default AddTab;
