import React from 'react';

const Chat = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30">
    <g id="help-chat-2--bubble-help-mark-message-query-question-speech-circle">
      <path
        id="Vector"
        fill="currentColor"
        d="M13.931317178571428 1.1158909114285716c-2.318823814285714 0.0007226292857142857 -4.5940509857142855 0.6303935207142857 -6.583331957142857 1.82194551 -1.9892610642857143 1.1915619428571427 -3.6180833999999997 2.9003711785714286 -4.7129762571428575 4.944436607142857C1.5401121257142856 9.926338457142856 1.0201574614285713 12.229117114285714 1.1305186799999998 14.545313185714287 1.2408779078571428 16.861529164285717 1.9774222864285713 19.104427135714285 3.2617057285714286 21.03508157142857L1.1120010557142856 26.754602785714287l7.198534337142857 -1.3017280714285715c1.733394557142857 0.8470489285714285 3.635601685714285 1.2919735714285714 5.5648626214285715 1.3015290000000002 1.9292609357142858 0.00955542857142857 3.8357879142857145 -0.41645742857142853 5.57748375 -1.2463862142857143 1.7416560214285712 -0.8297297142857143 3.2737097357142857 -2.0420747142857145 4.481476092857142 -3.5464575000000003 1.2079654285714285 -1.5041837142857144 2.0609865000000003 -3.2617853571428572 2.494962214285714 -5.14159695 0.4341747857142857 -1.8797916857142858 0.4385543571428571 -3.8333393357142858 0.0125415 -5.715002292857143 -0.42601285714285714 -1.8816828642857142 -1.2710710714285713 -3.6429474214285715 -2.472467142857143 -5.152506064285714 -1.2013960714285714 -1.50957855 -2.728074857142857 -2.7284929071428574 -4.466087871428571 -3.5659266857142855C17.76525372857143 1.5490882564285713 15.860598021428572 1.114752222857143 13.931317178571428 1.1158909114285716Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.931317178571428 1.1158909114285716c-2.318823814285714 0.0007226292857142857 -4.5940509857142855 0.6303935207142857 -6.583331957142857 1.82194551 -1.9892610642857143 1.1915619428571427 -3.6180833999999997 2.9003711785714286 -4.7129762571428575 4.944436607142857C1.5401121257142856 9.926338457142856 1.0201574614285713 12.229117114285714 1.1305186799999998 14.545313185714287 1.2408779078571428 16.861529164285717 1.9774222864285713 19.104427135714285 3.2617057285714286 21.03508157142857L1.1120010557142856 26.754602785714287l7.198534337142857 -1.3017280714285715c1.733394557142857 0.8470489285714285 3.635601685714285 1.2919735714285714 5.5648626214285715 1.3015290000000002 1.9292609357142858 0.00955542857142857 3.8357879142857145 -0.41645742857142853 5.57748375 -1.2463862142857143 1.7416560214285712 -0.8297297142857143 3.2737097357142857 -2.0420747142857145 4.481476092857142 -3.5464575000000003 1.2079654285714285 -1.5041837142857144 2.0609865000000003 -3.2617853571428572 2.494962214285714 -5.14159695 0.4341747857142857 -1.8797916857142858 0.4385543571428571 -3.8333393357142858 0.0125415 -5.715002292857143 -0.42601285714285714 -1.8816828642857142 -1.2710710714285713 -3.6429474214285715 -2.472467142857143 -5.152506064285714 -1.2013960714285714 -1.50957855 -2.728074857142857 -2.7284929071428574 -4.466087871428571 -3.5659266857142855C17.76525372857143 1.5490882564285713 15.860598021428572 1.114752222857143 13.931317178571428 1.1158909114285716v0Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_3"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.94892857142857 10.94892857142857c0 -0.5905852071428571 0.17512313571428573 -1.167912257142857 0.5032525714285715 -1.65896175 0.32810952857142855 -0.4910694 0.7944741642857143 -0.8738041285714285 1.3400891357142857 -1.0998099214285715 0.5456348785714286 -0.22600579285714287 1.1460343071428571 -0.2851499142857143 1.7252923500000001 -0.16992737142857142 0.5792381357142857 0.11522254285714285 1.1112963428571427 0.3996159857142857 1.5289083857142858 0.8172280285714285 0.4176120428571428 0.4176120428571428 0.7020054857142857 0.9496702499999999 0.8172280285714285 1.5289282928571428 0.11522254285714285 0.5792381357142857 0.05607842142857143 1.1796375642857144 -0.16992737142857142 1.7252724428571429 -0.22600579285714287 0.5456348785714286 -0.6087405214285714 1.011979607142857 -1.0997900142857142 1.3401090428571427C15.102912257142858 13.759876864285713 14.525585207142857 13.934999999999999 13.934999999999999 13.934999999999999v1.9907142857142857"
        strokeWidth="2.13"
      ></path>
      <g id="Group 2576">
        <path
          id="Vector_4"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M13.938881892857141 20.9025c-0.2748579214285714 0 -0.4976785714285714 -0.22276092857142857 -0.4976785714285714 -0.4976785714285714s0.22282065 -0.4976785714285714 0.4976785714285714 -0.4976785714285714"
          strokeWidth="2.13"
        ></path>
        <path
          id="Vector_5"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M13.938881892857141 20.9025c0.2748579214285714 0 0.4976785714285714 -0.22276092857142857 0.4976785714285714 -0.4976785714285714s-0.22282065 -0.4976785714285714 -0.4976785714285714 -0.4976785714285714"
          strokeWidth="2.13"
        ></path>
      </g>
    </g>
  </svg>
);

export default Chat;
