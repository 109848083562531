
const UpdateConversions = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30">
    <g id="pouch-add-2">
      <path
        id="Vector 1187"
        fill="currentColor"
        strokeOpacity="0"
        d="M12.362534785714285 26.874642857142856C7.237361228571428 26.874642857142856 1.2714134742857144 24.791360357142857 1.2714134742857144 19.48055269285714c0 -6.649602835714286 3.8538994328571428 -10.27156812857143 7.3940861828571425 -12.015374121428572L6.026489357142856 2.41061565c-0.38580042857142854 -0.7330188235714286 0.3192906642857143 -1.590853442142857 1.1213693571428571 -1.383898785 1.573838807142857 0.40608779785714283 3.6729474857142854 0.8929010507142857 5.214676071428571 0.8929010507142857 1.5417285857142857 0 3.6676521857142856 -0.4868132528571429 5.2415109 -0.8929010507142857 0.8020786928571428 -0.20695465714285713 1.5071498785714286 0.6508799614285714 1.1213494499999999 1.383898785L16.059569914285714 7.465178571428571c3.54018675 1.7438059928571428 7.394030442857143 5.365771285714285 7.394030442857143 12.015374121428572 0 5.310807664285714 -5.965892014285714 7.394090164285714 -11.09106557142857 7.394090164285714Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector 950"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.665639007142858 7.465178571428571c0.6161658857142858 0.9242687357142857 1.8485175642857143 1.8485175642857143 3.6970351285714287 1.8485175642857143S15.443543378571428 8.389447307142857 16.059709264285715 7.465178571428571"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector 951"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.963281 10.288648457142855C18.765249235714286 9.04144605 17.39024297142857 8.120641157142856 16.059569914285714 7.465178571428571l2.6658252214285714 -5.054562921428571c0.38580042857142854 -0.7330188235714286 -0.31927075714285713 -1.590853442142857 -1.1213494499999999 -1.383898785 -1.573858714285714 0.40608779785714283 -3.6997823142857142 0.8929010507142857 -5.2415109 0.8929010507142857 -1.5417285857142857 0 -3.640837264285714 -0.4868132528571429 -5.214676071428571 -0.8929010507142857 -0.8020786928571428 -0.20695465714285713 -1.5071697857142856 0.6508799614285714 -1.1213693571428571 1.383898785L8.665499657142858 7.465178571428571C5.125312907142857 9.208984564285714 1.2714134742857144 12.830949857142857 1.2714134742857144 19.48055269285714 1.2714134742857144 24.791360357142857 7.237361228571428 26.874642857142856 12.362534785714285 26.874642857142856c1.1020196142857142 0 2.242897971428571 -0.09635057142857142 3.355010507142857 -0.30039878571428574"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M26.874642857142856 20.9025h-9.95357142857143"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M21.89785714285714 15.925714285714285v9.95357142857143"
        strokeWidth="2.13"
      ></path>
    </g>
  </svg>
);

export default UpdateConversions;