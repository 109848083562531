const HelpQuestion = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.855 -0.855 24 24">
    <g id="help-question-1--circle-faq-frame-help-info-mark-more-query-question">
      <path
        id="Vector"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.145 21.49392857142857c5.715633642857143 0 10.348928571428571 -4.633294928571428 10.348928571428571 -10.348928571428571C21.49392857142857 5.429445964285714 16.86063364285714 0.7960714285714285 11.145 0.7960714285714285 5.429445964285714 0.7960714285714285 0.7960714285714285 5.429445964285714 0.7960714285714285 11.145c0 5.715633642857143 4.633374535714285 10.348928571428571 10.348928571428571 10.348928571428571Z"
        strokeWidth="1.71"
      ></path>
      <path
        id="Vector_2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.35875 8.358351964285713c0 -0.5510724857142857 0.1635608357142857 -1.0897580999999998 0.47000057142857143 -1.5479449714285713 0.30642381428571425 -0.4582027928571428 0.7419863357142857 -0.8153204357142858 1.2515675785714284 -1.0261997571428572 0.5095812428571429 -0.21089524285714284 1.0703180357142856 -0.26606299285714285 1.611280414285714 -0.15856150714285713 0.5409783 0.10751740714285715 1.0378860857142858 0.3728798571428571 1.4279133214285715 0.7625409 0.3900113142857143 0.38966104285714287 0.6556125857142857 0.8861230285714287 0.7632255214285715 1.426591842857143 0.10759701428571429 0.5404847357142857 0.0523815 1.1006961214285713 -0.1587048 1.609815642857143 -0.21107037857142855 0.5091195214285714 -0.5685223714285714 0.9442680857142858 -1.0271391214285714 1.2504212357142857 -0.4586008285714286 0.3061690714285714 -0.9977959285714286 0.46957069285714287 -1.5493619785714285 0.46957069285714287V13.002075428571427"
        strokeWidth="1.71"
      ></path>
      <g id="Group 2567">
        <path
          id="Vector_3"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M11.145 16.65142607142857c-0.21982716428571428 0 -0.39803571428571427 -0.1781607857142857 -0.39803571428571427 -0.39803571428571427s0.17820855 -0.39803571428571427 0.39803571428571427 -0.39803571428571427"
          strokeWidth="1.71"
        ></path>
        <path
          id="Vector_4"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M11.145 16.65142607142857c0.21982716428571428 0 0.39803571428571427 -0.1781607857142857 0.39803571428571427 -0.39803571428571427s-0.17820855 -0.39803571428571427 -0.39803571428571427 -0.39803571428571427"
          strokeWidth="1.71"
        ></path>
      </g>
    </g>
  </svg>
);

export default HelpQuestion;