/**
 * FunnelFlux Pro Ledger API
 * API endpoints to manage customers' assets
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface FunnelNodePageParams { 
    /**
     * ID of the lander or offer page
     */
    idPage: string;
    /**
     * If true, then the accumulated URL params are forwarded to this node's url
     */
    accumulateUrlParams?: boolean;
    /**
     * Tokens to compute and then forward to this node's url
     */
    additionalTokens?: { [key: string]: string; };
    /**
     * Allows to override a page's default redirectType
     */
    redirectOverride?: FunnelNodePageParams.RedirectOverrideEnum;
}
export namespace FunnelNodePageParams {
    export type RedirectOverrideEnum = '301' | '307' | 'umr' | 'fluxify';
    export const RedirectOverrideEnum = {
        _301: '301' as RedirectOverrideEnum,
        _307: '307' as RedirectOverrideEnum,
        Umr: 'umr' as RedirectOverrideEnum,
        Fluxify: 'fluxify' as RedirectOverrideEnum
    };
}
