import useHttp from '@/hooks/http';
import { Page } from '@/models/page';
import { useMutation } from '@tanstack/react-query';
import useHttpNotification from '@/hooks/useHttpError';

export function useLanderCreateMutation() {
  const { post } = useHttp();
  const { handleAssetNotification } = useHttpNotification();
  return useMutation<Page, Error, Page>({
    mutationKey: ['landerForm', 'lander', 'create'],
    mutationFn: async (page: Page) => {
      const { data } = await post<Page, Page>('v1/page/save', page);
      return data;
    },
    onError: (error: any, data) => {
      handleAssetNotification(data, 'added', 'lander', error);
    },
    onSuccess: (_, data) => {
      handleAssetNotification(data, 'added', 'lander');
    },
  });
}

export function useLanderUpdateMutation() {
  const { put } = useHttp();
  const { handleAssetNotification } = useHttpNotification();
  return useMutation<Page, Error, Page>({
    mutationKey: ['lander', 'update'],
    mutationFn: async (page: Page) => {
      const { data } = await put<Page, Page>('v1/page/save', page);
      return data;
    },
    onError: (error: any, data) => {
      handleAssetNotification(data, 'updated', 'lander', error);
    },
    onSuccess: (_, data) => {
      handleAssetNotification(data, 'updated', 'lander');
    },
  });
}

export function useLanderDuplicateMutation(previousIdLander: string) {
  const { post } = useHttp();
  const { handleAssetNotification } = useHttpNotification();
  return useMutation<Page, Error, Page>({
    mutationKey: ['lander', 'duplicate'],
    mutationFn: async (page: Page) => {
      const { data } = await post<Page, Page>(`v1/page/duplicate?idPage=${previousIdLander}`, page);
      return data;
    },
    onError: (error: any, data) => {
      handleAssetNotification(data, 'duplicated', 'lander', error);
    },
    onSuccess: (_, data) => {
      handleAssetNotification(data, 'duplicated', 'lander');
    },
  });
}

export function useLanderDeleteMutation() {
  const { del } = useHttp();
  const { handleAssetNotification } = useHttpNotification();
  return useMutation<null, Error, string>({
    mutationKey: ['lander', 'delete'],
    mutationFn: async (id: string) => {
      await del('v1/page/delete/', {
        data: { entries: [id] },
      });
      return null;
    },
    onError: (error: any, id) => {
      handleAssetNotification({ idPage: id }, 'deleted', 'lander', error);
    },
    onSuccess: (_, id) => {
      handleAssetNotification({ idPage: id }, 'deleted', 'lander');
    },
  });
}

export function useLanderArchiveMutation() {
  const { put } = useHttp();
  const { handleAssetNotification } = useHttpNotification();
  return useMutation<null, Error, Page>({
    mutationKey: ['lander', 'archive'],
    mutationFn: async (page: Page) => {
      await put<Page, null>('v1/page/save/', page);
      return null;
    },
    onError: (error: any, data) => {
      handleAssetNotification(data, 'archived', 'lander', error);
    },
    onSuccess: (_, data) => {
      handleAssetNotification(data, 'archived', 'lander');
    },
  });
}

export function useLanderUnArchiveMutation() {
  const { put } = useHttp();
  const { handleAssetNotification } = useHttpNotification();
  return useMutation<null, Error, Page>({
    mutationKey: ['lander', 'unarchive'],
    mutationFn: async (page: Page) => {
      await put<Page, null>('v1/page/save/', page);
      return null;
    },
    onError: (error: any, data) => {
      handleAssetNotification(data, 'unarchived', 'lander', error);
    },
    onSuccess: (_, data) => {
      handleAssetNotification(data, 'unarchived', 'lander');
    },
  });
}
