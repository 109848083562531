import { actionNumbers } from '@/constants';
import { getConditionNode, getPageGroupNode, getRotatorNode, getTrafficNode } from '@/constants/builder';
import { Funnel } from '@/models/funnel';
import { FunnelCondition } from '@/models/funnelCondition';
import { FunnelConnection } from '@/models/funnelConnection';
import { FunnelNode } from '@/models/funnelNode';
import { SimpleFlowPath } from '@/models/simpleFlowPath';
import { SimpleFlowRoutingEntry } from '@/models/simpleFlowRouting';
import { SimpleFlowRule } from '@/models/simpleFlowRules';
import { generateEntityId } from '@/utils/id';

export const getFunnelNodesConnections = (
  funnel: Funnel,
  condition: FunnelCondition,
  rules: SimpleFlowRule[],
  paths: SimpleFlowPath[],
  routings: { [key: string]: SimpleFlowRoutingEntry[] },
): Funnel => {
  const pathsMap = paths.reduce((acc: { [key: string]: SimpleFlowPath }, crr) => {
    acc[crr.pathId] = crr;
    return acc;
  }, {});

  // nodes
  const nodes = [...(funnel.nodes || [])];
  const nodesObj = nodes.reduce((acc: { [key: string]: FunnelNode }, crr) => {
    acc[crr.idNode] = crr;
    return acc;
  }, {});
  const trafficNode = nodes.shift() || getTrafficNode();
  const conditionNode = getConditionNode({
    idNode: nodes.shift()?.idNode,
    idCondition: condition.idCondition,
    nodeName: condition.conditionName,
    posY: 250,
  });
  const ruleRotatorNodes = rules.map((r, idx) =>
    getRotatorNode({
      nodeName: r.ruleName,
      idNode: nodesObj[r.ruleId]?.idNode || r.ruleId,
      posY: conditionNode.posY! + 120,
      posX: idx * 120,
    }),
  );
  const pathRotatorNodes = paths.map((p, idx) =>
    getRotatorNode({
      nodeName: p.pathName,
      idNode: nodesObj[p.pathId]?.idNode || p.pathId,
      posY: conditionNode.posY! + 240,
      posX: idx * 120,
    }),
  );
  funnel.nodes = [trafficNode, conditionNode, ...ruleRotatorNodes, ...pathRotatorNodes];
  for (const path of paths) {
    path.groups.forEach((pageGroup, idx) => {
      funnel.nodes!.push(
        getPageGroupNode({
          idNode: nodesObj[pageGroup.idPageGroup]?.idNode || pageGroup.idPageGroup,
          nodeName: pageGroup.pageGroupName,
          idPageGroup: nodesObj[pageGroup.idPageGroup]?.idNode || pageGroup.idPageGroup,
          nodeType: pageGroup.pageType === 'lander' ? 'landerGroup' : 'offerGroup',
          posY: conditionNode.posY! + 360 + idx * 60,
        }),
      );
    });
  }

  // connections
  const connections = [...(funnel.connections || [])];
  const connectionsObject = connections.reduce((acc: { [key: string]: FunnelConnection }, crr) => {
    acc[`${crr.idSourceNode}-${crr.idTargetNode}`] = crr;
    return acc;
  }, {});
  funnel.connections = [];
  const connectionkey = (idSourceNode: string, idTargetNode: string) => `${idSourceNode}-${idTargetNode}`;

  funnel.connections.push(
    connectionsObject[connectionkey(trafficNode.idNode, conditionNode.idNode)] || {
      idConnection: generateEntityId(),
      idSourceNode: trafficNode.idNode,
      idTargetNode: conditionNode.idNode,
      connectionRotatorParams: { weight: 1 },
    },
  );

  ruleRotatorNodes.forEach((roleRotatorNode, idx) => {
    funnel.connections!.push({
      idConnection: generateEntityId(),
      idSourceNode: conditionNode.idNode,
      idTargetNode: roleRotatorNode.idNode,
      connectionConditionParams: { onRouteNumber: idx },
    });
  });

  ruleRotatorNodes.forEach((roleRotatorNode) => {
    routings[roleRotatorNode.idNode].forEach((routingEntity) => {
      funnel.connections!.push({
        idConnection: generateEntityId(),
        idSourceNode: roleRotatorNode.idNode,
        idTargetNode: routingEntity.path.pathId,
        connectionRotatorParams: { weight: routingEntity.weight },
      });
    });
  });

  pathRotatorNodes.forEach((pathRotator) => {
    const groups = [...pathsMap[pathRotator.idNode].groups];

    if (groups.length) {
      funnel.connections!.push({
        idConnection: generateEntityId(),
        idSourceNode: pathRotator.idNode,
        idTargetNode: groups[0].idPageGroup,
        connectionRotatorParams: { weight: 1 },
      });
      for (let i = 0; i < groups.length - 1; i++) {
        funnel.connections!.push({
          idConnection: generateEntityId(),
          idSourceNode: groups[i].idPageGroup,
          idTargetNode: groups[i + 1].idPageGroup,
          connectionPageParams: { onActionNumbers: actionNumbers.map((a) => Number(a.value)) },
        });
      }
    }
  });

  return funnel;
};
