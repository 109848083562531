import useHttp from '@/hooks/http';
import useHttpNotification from '@/hooks/useHttpError';
import { Category } from '@/models/category';
import { useMutation } from '@tanstack/react-query';

export function useDeleteCategoryMutation() {
  const { del } = useHttp();
  const { handleAssetNotification } = useHttpNotification(); 
  return useMutation<null, Error, string[]>({
    mutationKey: ['category', 'delete'],
    mutationFn: async (ids: string[] = []) => {
      await del('v1/category/delete/', {
        data: { entries: [...ids] },
      });
      return null;
    },
    onError: (error: any, id) => {
      handleAssetNotification({ entries: id }, 'deleted', 'category', error);
    },
    onSuccess: (_, id) => {
      handleAssetNotification({ entries: id }, 'deleted', 'category');
    },
  });
}

export function useArchiveCategoryMutation() {
  const { put } = useHttp();
  const { handleAssetNotification } = useHttpNotification();
  return useMutation<null, Error, Category>({
    mutationKey: ['category', 'archive'],
    mutationFn: async (category: Category) => {
      await put<Category>('v1/category/save/', {
        ...category,
        status: 'archived',
      });
      return null;
    },
    onError: (error: any, data) => {
      handleAssetNotification(data, 'archived', 'category', error);
    },
    onSuccess: (_, data) => {
      handleAssetNotification(data, 'archived', 'category');
    },
  });
}

export function useUnArchiveCategoryMutation() {
  const { put } = useHttp();
  const { handleAssetNotification } = useHttpNotification();
  return useMutation<null, Error, Category>({
    mutationKey: ['category', 'archive'],
    mutationFn: async (category: Category) => {
      await put<Category>('v1/category/save/', {
        ...category,
        status: 'active',
      });
      return null;
    },
    onError: (error: any, data) => {
      handleAssetNotification(data, 'unarchived', 'category', error);
    },
    onSuccess: (_, data) => {
      handleAssetNotification(data, 'unarchived', 'category');
    },
  });
}

export function useCreateCategoryMutation() {
  const { post } = useHttp();
  const { handleAssetNotification } = useHttpNotification();
  return useMutation<null, Error, Category>({
    mutationKey: ['category', 'create'],
    mutationFn: async (category: Category) => {
      await post('v1/category/save/', category);
      return null;
    },
    onError: (error: any, data) => {
      handleAssetNotification(data, 'added', 'category', error);
    },
    onSuccess: (_, data) => {
      handleAssetNotification(data, 'added', 'category');
    },
  });
}

export function useUpdateCategoryMutation() {
  const { put } = useHttp();
  const { handleAssetNotification } = useHttpNotification();
  return useMutation<null, Error, Category>({
    mutationKey: ['category', 'update'],
    mutationFn: async (category: Category) => {
      await put('v1/category/save/', category);
      return null;
    },
    onError: (error: any, data) => {
      handleAssetNotification(data, 'updated', 'category', error);
    },
    onSuccess: (_, data) => {
      handleAssetNotification(data, 'updated', 'category');
    },
  });
}
