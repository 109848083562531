import className from "@/utils/className";
import { CSSProperties, PropsWithChildren } from "react";
import './style.scss';

const { getClass } = className('c-ffText');

type TextType = 'title-3' | 'body-1' | 'body-2-strong' | 'body-2';

const Text = ({ children }: PropsWithChildren<{ style: CSSProperties }>) => {
    return <span>{children}</span>;
};

Text.Span = ({ children, type = 'title-3' }: PropsWithChildren<{ type: TextType }>) => {
    return <span className={getClass('span', type)}>{children}</span>;
};

Text.Em = ({ children }: { children: React.ReactNode }) => {
    return <em>{children}</em>;
};

Text.Strong = ({ children }: { children: React.ReactNode }) => {
    return <strong>{children}</strong>;
};

Text.Code = ({ children }: { children: React.ReactNode }) => {
    return <code>{children}</code>;
};

Text.Pre = ({ children }: { children: React.ReactNode }) => {
    return <pre>{children}</pre>;
};

Text.P = ({ children, type = 'title-3' }: PropsWithChildren<{ type: TextType }>) => {
    return <p className={getClass('span', type)}>{children}</p>;
};

export default Text;