const ResetData = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30">
    <g id="cloud-delete--cloud-network-internet-subtract-server-cross-delete">
      <path
        id="Vector"
        fill="currentColor"
        strokeOpacity="0"
        d="M22.659504428571427 11.157854035714285c-0.08938307142857144 -0.02669547857142857 -0.1694097857142857 -0.07839432857142857 -0.23032564285714285 -0.1490248714285714 -0.06091585714285714 -0.07063054285714285 -0.10053107142857143 -0.15722661428571427 -0.11386885714285715 -0.24957585000000002 -0.16005342857142857 -1.75399845 -0.916524857142857 -3.4001200928571427 -2.1434020714285715 -4.663845428571428 -1.2268374 -1.2637253357142857 -2.849807035714286 -2.0686706571428575 -4.598291207142857 -2.2806021000000003 -1.7484841714285713 -0.21195134999999998 -3.516795857142857 0.18193137857142858 -5.010010735714285 1.1159745214285715 -1.4932148785714285 0.9340232357142857 -2.6211137785714285 2.3517104142857144 -3.1955742 4.016684121428572 -0.03802264285714285 0.07763785714285715 -0.09744546428571428 0.14277402857142857 -0.17128105714285716 0.18772435714285712 -0.0738555 0.044930421428571427 -0.15899834999999998 0.06782363571428572 -0.24543516428571427 0.06593245714285713 -0.8327356928571429 0.055899257142857144 -1.6463207142857141 0.27529587857142856 -2.3942718857142857 0.6456284571428571 -0.7479710785714285 0.37033257857142854 -1.41565665 0.8843549142857143 -1.9649743499999999 1.5127437857142858 -1.1093613685714285 1.26906045 -1.6691601792857143 2.926847678571429 -1.556238902142857 4.608642921428571 0.11292127714285713 1.6818151499999998 0.8893137835714285 3.2498808857142856 2.1583702521428574 4.359246235714285 1.269080357142857 1.1094250714285714 2.926847678571429 1.6692139285714285 4.608662828571428 1.5563404285714286h13.642703421428571c1.3301952857142858 -0.007962857142857143 2.611219928571429 -0.5034516428571428 3.600405857142857 -1.3925046428571428 0.989385 -0.8889932785714286 1.618450714285714 -2.109938164285714 1.7679533571428572 -3.4315932857142855 0.1497017142857143 -1.3216750285714285 -0.19051135714285714 -2.6523082714285717 -0.9561400714285714 -3.7399549285714286s-1.903521 -1.8567989357142856 -3.1982815714285713 -2.1618161785714283Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m18.58419377142857 16.657899 -8.30072117142857 8.300681357142857"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_3"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m10.2834726 16.657899 8.30072117142857 8.300681357142857"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_4"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.802803714285714 21.883723071428573c-1.6818151499999998 0.1128735 -3.3395824714285713 -0.44691535714285713 -4.608662828571428 -1.5563404285714286C1.9250824264285713 19.21801729285714 1.1486919107142857 17.649951557142856 1.0357706335714285 15.968136407142856c-0.11292127714285713 -1.6817952428571428 0.44687753357142856 -3.3395824714285713 1.556238902142857 -4.608642921428571 0.5493177 -0.6283888714285714 1.2170032714285715 -1.1424112071428572 1.9649743499999999 -1.5127437857142858 0.7479511714285714 -0.37033257857142854 1.561536192857143 -0.5897292 2.3942718857142857 -0.6456284571428571 0.08643681428571429 0.0018911785714285714 0.17157966428571428 -0.021002035714285713 0.24543516428571427 -0.06593245714285713 0.07383559285714285 -0.04495032857142857 0.13325841428571428 -0.1100865 0.17128105714285716 -0.18772435714285712 0.5744604214285713 -1.664973707142857 1.7023593214285713 -3.082660885714285 3.1955742 -4.016684121428572 1.4932148785714285 -0.9340431428571428 3.2615265642857145 -1.3279258714285713 5.010010735714285 -1.1159745214285715 1.7484841714285713 0.21193144285714285 3.371453807142857 1.0168767642857142 4.598350928571428 2.2806021000000003 1.2266781428571427 1.2637253357142857 1.9833486428571427 2.9098469785714287 2.1434020714285715 4.663845428571428 0.013337785714285715 0.09234923571428572 0.05275392857142857 0.17894530714285714 0.11386885714285715 0.24957585000000002 0.06091585714285714 0.07063054285714285 0.14094257142857142 0.12232939285714285 0.23032564285714285 0.1490248714285714 1.2945615 0.3050172428571428 2.432453785714286 1.0741695214285714 3.1980825 2.1618161785714283 0.7656287142857142 1.0876466571428571 1.1058417857142857 2.4182799 0.9563391428571428 3.7399549285714286 -0.14950264285714285 1.3216551214285714 -0.7785683571428571 2.542600007142857 -1.7679533571428572 3.4315932857142855 -0.989385 0.889053 -2.270409642857143 1.3845417857142857 -3.600405857142857 1.3925046428571428"
        strokeWidth="2.13"
      ></path>
    </g>
  </svg>
);

export default ResetData;
