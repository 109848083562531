const GeneralSettings = () => (
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
    <g id="story-post">
        <path id="Vector" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M7 4.25v5.5" strokeWidth="1"></path>
        <path id="Vector_2" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M4.25 7h5.5" strokeWidth="1"></path>
        <path id="Ellipse 1851" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M7.00015 0.5C10.59 0.5 13.5002 3.41015 13.5002 7c0 3.5899 -2.9102 6.5 -6.50005 6.5 -2.3012 0 -4.32311 -1.1958 -5.47818 -3" strokeWidth="1"></path>
        <path id="Ellipse 1852" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M4.49537 1c-0.45469 0.19001 -0.88319 0.43019 -1.27857 0.71364" strokeWidth="1"></path>
        <path id="Ellipse 1853" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M0.998047 4.5c0.225563 -0.54088 0.522103 -1.04476 0.877993 -1.5" strokeWidth="1"></path>
        <path id="Ellipse 1854" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M0.624839 8.2742C0.542946 7.86216 0.5 7.4361 0.5 7.00001c0 -0.196 0.008675 -0.38997 0.025661 -0.58155" strokeWidth="1"></path>
    </g>
</svg>
);

export default GeneralSettings;
