import { usePageQuery, useTrafficSourceCategoriesInfoQuery, useTrafficSourceQuery } from '@/api/queries/general';
import CodeSnippet from '@/components/CodeSnippet';
import SectionBox from '@/components/SectionBox';
import { Page } from '@/models/page';
import { PageGroup } from '@/models/pageGroup';
import { FFCol, FFField, FFInput, FFSelect, FFSwitch, FFText } from '@/uikit';
import { FFSelectOption } from '@/uikit/types/select';
import { ucFirst } from '@/utils/string';
import { Divider } from 'antd';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

function combineURLs(baseURL: string, relativeURL: string) {
  try {
    const base = new URL(baseURL);
    const baseSearchParams = new URLSearchParams(base.search);
    const relativeSearchParams = new URLSearchParams(relativeURL);
    for (const [key, value] of relativeSearchParams) {
      baseSearchParams.set(key, value);
    }
    const combinedURL = `${base.origin}${base.pathname}?${baseSearchParams.toString()}`;
    return decodeURIComponent(combinedURL);
  } catch (error) {
    return '';
  }
}

const BASE_PAGE_URL_TOOLTIP = (
  <>
    <p>
      If using platforms like Facebook, Google Ads and Microsoft Ads, use the base page URL for your ad and put the URL suffix in the
      appropriate field, e.g. optional URL params or final URL suffix.
    </p>
  </>
);

const URL_SUFFIX_TOOLTIP = (
  <>
    <p>This is the optional URL query string data that you should put under final URL suffix or similar options.</p>
  </>
);

const FULL_DIRECT_URL_TOOLTIP = (
  <>
    <p>
      This is the resulting full URL that the traffic source should hopefully load on click. If you are using a traffic source that only has
      one field for URL, use the full direct URL.
    </p>
  </>
);

interface DirectLinkSettings {
  idTrafficSource: string;
  cost: string;
  idPage: string;
  isWordpressBased: boolean;
}

const DirectLinks = ({
  nodeId,
  idFunnel,
  pages,
  pageType,
}: {
  nodeId: string;
  idFunnel: string;
  pages: Page[];
  pageType: PageGroup.PageTypeEnum;
}) => {
  // Get default page value if there's only one page
  const defaultPageId = pages.length === 1 ? pages[0].idPage : undefined;
  
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    trigger,
    setValue
  } = useForm<DirectLinkSettings>({
    defaultValues: {
      isWordpressBased: false,
      idPage: defaultPageId
    },
  });
  
  const idTrafficSource = watch('idTrafficSource');
  const cost = watch('cost');
  const idPage = watch('idPage');
  const isWordpressBased = watch('isWordpressBased');
  const { data: page, isLoading: pageIsLoading } = usePageQuery(idPage);
  const { data: trafficSourceGroups = [], isLoading: trafficSourceGroupsIsPending } = useTrafficSourceCategoriesInfoQuery();
  const { data: trafficSource, isLoading: trafficSourceIsLoading } = useTrafficSourceQuery(idTrafficSource);

  // Auto-select page if there's only one page and no selection yet
  useEffect(() => {
    if (pages.length === 1 && pages[0].idPage && !idPage) {
      setValue('idPage', pages[0].idPage);
    }
  }, [pages, idPage, setValue]);

  useEffect(() => {
    trigger()
  }, [idTrafficSource, idPage]);

  const trafficSourceOptions = useMemo(() => {
    const trafficSourceOptions: FFSelectOption[] = [];
    for (const trafficSourceGroup of trafficSourceGroups) {
      (trafficSourceGroup.trafficSources || []).forEach((trafficSource) => {
        trafficSourceOptions.push({
          value: trafficSource.idTrafficSource,
          label: trafficSource.trafficSourceName,
          category: trafficSourceGroup.categoryName,
        });
      });
    }
    return trafficSourceOptions;
  }, [trafficSourceGroups]);

  const pageOptions = useMemo(() => {
    const pageOptions: FFSelectOption[] = [];
    pages.forEach((page) => {
      pageOptions.push({
        value: page.idPage!,
        label: page.pageName!,
      });
    });
    return pageOptions;
  }, [pages]);

  const baseUrl = useMemo(() => {
    if (pageIsLoading) return 'Fetching Page Data...';
    if (!page) return 'Please select a page first';
    return page.baseURL;
  }, [page, pageIsLoading]);

  const urlSuffix = useMemo(() => {
    let suffix = new URLSearchParams('');
    
    suffix.set('f', idFunnel);
    suffix.set('ts', idTrafficSource);
    suffix.set('n', nodeId);
    
    const costValue = cost || trafficSource?.defaultCost || '';
    if (costValue) {
      suffix.set('c', costValue);
    }    
    if (idPage && !isWordpressBased) {
      suffix.set('p', idPage);
    }    
    Object.entries(trafficSource?.trackingFieldSlots || {}).forEach(([key, value]) => {
      if (value.value) { 
        suffix.set(value.name, value.value);
      }
    });
    
    return decodeURIComponent(suffix.toString());
  }, [idPage, trafficSource, idTrafficSource, idFunnel, nodeId, cost, isWordpressBased]);

  const fullUrl = useMemo(() => {
    if (!idPage || !idTrafficSource) return 'Please select an option in all of the required fields';
    if (trafficSourceIsLoading) return 'Fetching TrafficSource Data...';
    return combineURLs(baseUrl, urlSuffix);
  }, [baseUrl, urlSuffix, idPage, idTrafficSource, trafficSourceIsLoading]);

  return (
    <SectionBox title="Direct Links">
      <FFCol gap="12px">
        <FFText.P type="body-2">
          Generate direct links to a page that also pass tracker/traffic source data. Many traffic sources now require these direct URLs to
          be used. You will need to use our Javascript to track the arriving user.
        </FFText.P>
        <FFField label="Node ID" block direction="row">
          <CodeSnippet code={nodeId} />
        </FFField>
        <Controller
          name="idTrafficSource"
          control={control}
          rules={{ required: 'Traffic Source is required' }}
          render={(opt) => (
            <FFField label="Traffic Source" block direction="row">
              <FFSelect
                disabled={trafficSourceGroupsIsPending}
                loading={trafficSourceGroupsIsPending}
                error={opt.fieldState.error?.message}
                value={opt.field.value}
                onChange={opt.field.onChange}
                options={trafficSourceOptions}
                groupOptions={true}
                sortGroup
                selectAll
                showSearch
                autoFocus
                valueGetter={(opt) => opt.value}
                labelGetter={(opt) => opt.label}
                placeholder="Select a Traffic Source"
              />
            </FFField>
          )}
        />
        <Controller
          name="cost"
          control={control}
          render={(opt) => (
            <FFField label="Entrance cost (optional)" block direction="row">
              <FFInput value={opt.field.value} onChange={opt.field.onChange} placeholder="Entrance cost (optional)" />
            </FFField>
          )}
        />
        <Controller
          name="idPage"
          control={control}
          rules={{ required: 'Page is required' }}
          render={(opt) => (
            <FFField label={ucFirst(pageType)} block direction="row">
              <FFSelect
                value={opt.field.value}
                onChange={opt.field.onChange}
                options={pageOptions}
                valueGetter={(opt) => opt.value}
                labelGetter={(opt) => opt.label}
                error={opt.fieldState.error?.message}
                placeholder={pages.length > 1 ? "Select a page" : undefined}
              />
            </FFField>
          )}
        />
        <Controller
          name="isWordpressBased"
          control={control}
          render={(opt) => (
            <FFSwitch checked={opt.field.value} onClick={opt.field.onChange}>
              Toggle if this is a Wordpress-based page
            </FFSwitch>
          )}
        />
        <Divider style={{ margin: 0 }} />
        <FFField label="Base page URL" tooltipContent={BASE_PAGE_URL_TOOLTIP}>
          <CodeSnippet code={baseUrl} multiline />
        </FFField>
        <FFField label="URL suffix" tooltipContent={URL_SUFFIX_TOOLTIP}>
          <CodeSnippet code={urlSuffix} multiline />
        </FFField>
        <FFField label="Full Direct URL" tooltipContent={FULL_DIRECT_URL_TOOLTIP}>
          <CodeSnippet code={fullUrl} multiline />
        </FFField>
      </FFCol>
    </SectionBox>
  );
};

export default DirectLinks;