const GeneralSettings = () => (
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
    <g id="checklist-rule--checklist-rule-check-cross">
        <path id="Vector 4306" stroke="currentColor" strokeLinecap="round" d="M8 12h5" strokeWidth="1"></path>
        <path id="Vector 4307" stroke="currentColor" strokeLinecap="round" d="M8 2h5" strokeWidth="1"></path>
        <path id="Vector 4308" stroke="currentColor" strokeLinecap="round" d="M8 5.33325h5" strokeWidth="1"></path>
        <path id="Vector 4309" stroke="currentColor" strokeLinecap="round" d="M8 8.66675h5" strokeWidth="1"></path>
        <path id="Vector" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M6 1 2.66667 5 1 3.8" strokeWidth="1"></path>
        <path id="Vector_2" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M5.25001 8.75 1 13" strokeWidth="1"></path>
        <path id="Vector_3" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="m1 8.75488 4.25001 4.24002" strokeWidth="1"></path>
    </g>
</svg>
);

export default GeneralSettings;
