const LabelFolderTag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30" height="30" width="30">
    <g id="label-folder-tag">
      <path
        id="Subtract"
        fill="currentColor"
        fillRule="evenodd"
        d="M3.0171663857142854 23.889367714285715c-0.5279573357142857 0 -1.0343054678571428 -0.2096222142857143 -1.4076380528571428 -0.5830802142857142 -0.3733305942857143 -0.37325892857142856 -0.5830662792857143 -0.8796966428571429 -0.5830662792857143 -1.4076340714285713V2.986907528571429c0 -0.5279573357142857 0.209735685 -1.0343074585714285 0.5830662792857143 -1.4076380528571428 0.373332585 -0.373332585 0.8796807171428571 -0.5830682699999999 1.4076380528571428 -0.5830682699999999h7.345755621428571c0.45473886428571425 -0.013248203571428571 0.9003005357142857 0.12966119357142855 1.262511 0.40493915571428574 0.3622104642857143 0.2752779621428571 0.6191917714285714 0.66631596 0.7282032857142857 1.1079957385714285l0.5573800928571429 2.4684857142857144h11.944245899999999c0.5279374285714286 0 1.0343751428571428 0.20974165714285714 1.4076340714285713 0.5830802142857142 0.37345799999999996 0.37331865 0.5830802142857142 0.8796767357142857 0.5830802142857142 1.4076340714285713v12.430457957142858l-0.5520250714285714 -0.12264790714285713c-0.05753164285714285 -0.00877905 -0.11466514285714285 -0.004737900000000001 -0.16702092857142858 0.011864657142857142 -0.052156714285714285 0.01658265 -0.09854035714285714 0.04530865714285714 -0.1351695 0.08404795714285714l-4.517527928571428 4.51730895H3.0171663857142854Z"
        clipRule="evenodd"
        strokeWidth="2.13"
      ></path>
      <path
        id="vector"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.361448878571428 23.889367714285715h-7.345735714285714c-0.5279573357142857 0 -1.03431144 -0.2096222142857143 -1.407642034285714 -0.5830802142857142 -0.373332585 -0.37325892857142856 -0.5830662792857143 -0.8796966428571429 -0.5830662792857143 -1.4076340714285713V2.986907528571429c0 -0.5279573357142857 0.20973369428571428 -1.0343074585714285 0.5830662792857143 -1.4076380528571428 0.3733305942857143 -0.373332585 0.8796846985714286 -0.5830682699999999 1.407642034285714 -0.5830682699999999h7.345735714285714c0.4547587714285714 -0.013248203571428571 0.9003204428571429 0.12966119357142855 1.2625309071428572 0.40493915571428574 0.3622104642857143 0.2752779621428571 0.6191917714285714 0.66631596 0.7281833785714286 1.1079957385714285l0.5574 2.4684857142857144h11.94430562142857c0.5279374285714286 0 1.0343751428571428 0.20974165714285714 1.4076340714285713 0.5830802142857142 0.37325892857142856 0.37331865 0.5830802142857142 0.8796767357142857 0.5830802142857142 1.4076340714285713V10.326830357142857"
        strokeWidth="2.13"
      ></path>
      <g id="group 53">
        <path
          id="vector_2"
          fill="#ffffff"
          d="m17.84285177142857 26.53940657142857 -4.459299535714286 -4.4592c-0.1610089714285714 -0.16124785714285714 -0.25988775 -0.37126821428571427 -0.27487782857142856 -0.5840755714285714 -0.015009985714285714 -0.21280735714285712 0.05508306428571429 -0.41108249999999996 0.19485111428571428 -0.5510297142857142l7.68417705 -7.6841969571428566c0.036828214285714284 -0.038739300000000004 0.08301278571428572 -0.0674454 0.13536857142857142 -0.08404795714285714 0.052156714285714285 -0.01658265 0.10929021428571428 -0.020643707142857144 0.16682185714285713 -0.011844750000000001l4.145861571428571 0.9209442428571428c0.09814221428571428 0.012043821428571428 0.19349742857142854 0.05970152142857143 0.26755199999999996 0.13379590714285716 0.07405457142857141 0.07407447857142857 0.12163264285714286 0.16933015714285712 0.13377599999999998 0.26743255714285713l0.9209044285714285 4.1459212928571425c0.008759142857142858 0.057591364285714285 0.004777714285714285 0.11462532857142857 -0.011745214285714286 0.16692139285714283 -0.016721999999999997 0.05229606428571429 -0.045388285714285714 0.09854035714285714 -0.08420721428571427 0.13530885L18.977957057142856 26.619433285714287c-0.13998702857142856 0.13994721428571427 -0.3381427285714286 0.21002035714285713 -0.5509899 0.19489092857142856 -0.2128272642857143 -0.014930357142857141 -0.4229073428571428 -0.11386885714285715 -0.5841153857142857 -0.2749176428571429Z"
          strokeWidth="2.13"
        ></path>
      </g>
      <path
        id="vector_3"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m17.84285177142857 26.53940657142857 -4.459299535714286 -4.4592c-0.1610089714285714 -0.16124785714285714 -0.25988775 -0.37126821428571427 -0.27487782857142856 -0.5840755714285714 -0.015009985714285714 -0.21280735714285712 0.05508306428571429 -0.41108249999999996 0.19485111428571428 -0.5510297142857142l7.68417705 -7.6841969571428566c0.036828214285714284 -0.038739300000000004 0.08301278571428572 -0.0674454 0.13536857142857142 -0.08404795714285714 0.052156714285714285 -0.01658265 0.10929021428571428 -0.020643707142857144 0.16682185714285713 -0.011844750000000001l4.145861571428571 0.9209442428571428c0.09814221428571428 0.012043821428571428 0.19349742857142854 0.05970152142857143 0.26755199999999996 0.13379590714285716 0.07405457142857141 0.07407447857142857 0.12163264285714286 0.16933015714285712 0.13377599999999998 0.26743255714285713l0.9209044285714285 4.1459212928571425c0.008759142857142858 0.057591364285714285 0.004777714285714285 0.11462532857142857 -0.011745214285714286 0.16692139285714283 -0.016721999999999997 0.05229606428571429 -0.045388285714285714 0.09854035714285714 -0.08420721428571427 0.13530885L18.977957057142856 26.619433285714287c-0.13998702857142856 0.13994721428571427 -0.3381427285714286 0.21002035714285713 -0.5509899 0.19489092857142856 -0.2128272642857143 -0.014930357142857141 -0.4229073428571428 -0.11386885714285715 -0.5841153857142857 -0.2749176428571429Z"
        strokeWidth="2.13"
      ></path>
      <g id="group 2218">
        <path
          id="vector_4"
          stroke="#48508b"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M21.902236714285714 18.020781814285712c-0.1944927857142857 -0.1943534357142857 -0.1944927857142857 -0.5094635999999999 0 -0.7038170357142857 0.1942937142857143 -0.1943534357142857 0.5094237857142857 -0.1943534357142857 0.7037175 0"
          strokeWidth="2.13"
        ></path>
        <path
          id="vector_5"
          stroke="#48508b"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M21.90203764285714 18.020722092857145c0.1944927857142857 0.1943534357142857 0.5096228571428572 0.1943534357142857 0.7039165714285714 0 0.1942937142857143 -0.1943534357142857 0.1942937142857143 -0.5094835071428571 0 -0.7038369428571428"
          strokeWidth="2.13"
        ></path>
      </g>
    </g>
  </svg>
);

export default LabelFolderTag;