import { FFIconButton } from '@/uikit';
import AntMessageContext from '@/contexts/antMessage';
import { useContext } from 'react';

const CopyButton = ({ value }: { value: string }) => {
  const { messageApi } = useContext(AntMessageContext);

  const handleClick = async () => {
    try {
      await navigator.clipboard.writeText(value);
      success();
    } catch (err) {
      warning();
    }
  };

  const success = (content = 'Copied') => {
    messageApi.open({
      type: 'success',
      content,
    });
  };

  const warning = () => {
    messageApi.open({
      type: 'warning',
      content: 'Nothing to be copied',
    });
  };

  return (
    <>
      <FFIconButton buttonType="transparent" iconName="general/line/copy" iconType="tertiary" onClick={handleClick} />
    </>
  );
};

export default CopyButton;
