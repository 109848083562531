import { useQuery } from '@tanstack/react-query';
import useHttp from '@/hooks/http';
import { UserSettings } from '@/models/userSettings';
import useSystemSettingsStore from '@/stores/systemSettings';


export function useSystemSettingsQuery() {
  const { get } = useHttp();
  const setUserSettings = useSystemSettingsStore((state) => state.setUserSettings);

  return useQuery({
    queryKey: ['systemsettings', 'data'],
    queryFn: async () => {
      const { data } = await get<UserSettings>('v1/user/settings/');
      setUserSettings(data);
      return data;
    },
  });
}
