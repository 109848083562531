import { Category } from '@/models/category';
import { Funnel } from '@/models/funnel';
import { FunnelCondition } from '@/models/funnelCondition';
import { FunnelGroup } from '@/models/funnelGroup';
import { OfferSource } from '@/models/offerSource';
import { Page } from '@/models/page';
import { PageGroup } from '@/models/pageGroup';
import { TrafficSource } from '@/models/trafficSource';
import { defined } from './define';
import { Meta } from '@/models/meta';

type EntityType = Page | PageGroup | Category | OfferSource | TrafficSource | FunnelCondition | Funnel | FunnelGroup;

export const getActiveEntities = <T extends { filter: Function }>(entities: T): T => {
  if (Array.isArray(entities)) {
    return (entities as T).filter((item: EntityType) => !defined(item.status) || item.status === 'active');
  }
  return entities;
};

export const getDeletedEntities = <T extends { filter: Function }>(entities: T): T => {
  if (Array.isArray(entities)) {
    return (entities as T).filter((item: EntityType) => defined(item.status) && item.status === 'deleted');
  }
  return entities;
};

/**
 * @param localVersion
 * @param serverVersion
 * @returns {Boolean}
 */
export const serverVersionIsAheadOfLocal = (localVersion?: number, serverVersion?: number) => {
  if (!serverVersion || !localVersion) {
    return false;
  }
  return serverVersion > localVersion;
};

export const withIncrementedVersion = <T extends { meta?: Meta }>(
  model: T,
  latestVersion?: number
): T => ({
  ...model,
  meta: {
    ...(model?.meta || {}),
    version: (latestVersion || model?.meta?.version || 0) + 1
  }
});