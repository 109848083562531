import AssetLogo from '@/components/AssetLogo';
import SectionBox from '@/components/SectionBox';
import { Attribute } from '@/models/attribute';
import useFormStore from '@/stores/forms';
import { FFButton, FFCol, FFSidePanel, FFText } from '@/uikit';

const CreatePageGroupForm = () => {
  const openPageGroupForm = useFormStore((state) => state.openPageGroupForm);
  const setPageGroupData = useFormStore((state) => state.setPageGroupData);
  const closePageGroupForm = useFormStore((state) => state.closePageGroupForm);
  const isOpen = useFormStore((state) => state.pageGroup.isSelectionOpen);

  const onCancel = () => {
    closePageGroupForm('selection');
  };

  const onCreateLanderGroup = () => {
    closePageGroupForm('selection');
    openPageGroupForm('global');
    setPageGroupData({ id: '', attribute: Attribute.ElementLanderGroup, value: '', status: 'active' }, false);
  };

  const onCreateOfferGroup = () => {
    closePageGroupForm('selection');
    openPageGroupForm('global');
    setPageGroupData({ id: '', attribute: Attribute.ElementOfferGroup, value: '', status: 'active' }, false);
  };

  return (
    <FFSidePanel
      sidebarName="CreatePageGroup"
      isOpen={isOpen}
      minWidth={600}
      maxWidth={800}
      title="Create Lander Or Offer Group"
      onClose={onCancel}
    >
      <FFCol gap="12px">
        <SectionBox title="Create Lander Group" titlePrefix={<AssetLogo assetType="landerGroup" />}>
          <FFCol gap="12px">
            <FFText.P type="body-2">
              Lander groups are a collection of landing pages. You can reuse these in multiple funnels and their configuration, such as
              rotation weights, will be shared across all funnels.
            </FFText.P>
            <FFButton onClick={onCreateLanderGroup}>Create Lander Group</FFButton>
          </FFCol>
        </SectionBox>
        <SectionBox title="Create Offer Group" titlePrefix={<AssetLogo assetType="offerGroup" />}>
          <FFCol gap="12px">
            <FFText.P type="body-2">
              Offer groups are a collection of offer pages, much like lander groups. These are useful for split-testing many related offers
              across multiple funnels and adjusting rotations universally.
            </FFText.P>
            <FFButton onClick={onCreateOfferGroup}>Create Offer Group</FFButton>
          </FFCol>
        </SectionBox>
      </FFCol>
    </FFSidePanel>
  );
};

export default CreatePageGroupForm;
