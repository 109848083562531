/**
 * FunnelFlux Ledger API
 * FunnelFlux PRO consumer API endpoints for management of assets (so-called 'ledger' API)
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@funnelflux.pro
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface FunnelNodeRotatorParams { 
    /**
     * How the rotator will operate.
     */
    rotatorType?: FunnelNodeRotatorParams.RotatorTypeEnum;
}
export namespace FunnelNodeRotatorParams {
    export type RotatorTypeEnum = 'random' | 'ai';
    export const RotatorTypeEnum = {
        Random: 'random' as RotatorTypeEnum,
        Ai: 'ai' as RotatorTypeEnum
    };
}
