const Pencil = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.715 -0.715 20 20">
    <g id="pencil--change-edit-modify-pencil-write-writing">
      <path
        id="Vector"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m6.632142857142858 16.235353071428573 -5.968928571428572 1.6713 1.6713 -5.968928571428572L13.264285714285716 1.0610088878571429c0.12349050000000002 -0.1263370157142857 0.27112200000000003 -0.22671980357142857 0.4338747857142857 -0.295253715 0.1628854285714286 -0.06853258500000001 0.3378413571428571 -0.10383680785714287 0.514521642857143 -0.10383680785714287 0.17668028571428573 0 0.3516362142857143 0.03530422285714286 0.514521642857143 0.10383680785714287 0.16275278571428572 0.06853391142857143 0.3103842857142858 0.1689166992857143 0.4338747857142857 0.295253715L17.508857142857142 3.4220530714285715c0.12428635714285716 0.1233048 0.22297264285714288 0.2700078 0.2903552142857143 0.4316463857142857 0.06738257142857143 0.16163858571428572 0.10200235714285714 0.33501606428571434 0.10200235714285714 0.5101179 0 0.1751018357142857 -0.03461978571428572 0.3484793142857143 -0.10200235714285714 0.5101179 -0.06738257142857143 0.16162532142857144 -0.16606885714285716 0.3083415857142857 -0.2903552142857143 0.4316463857142857l-10.876714285714286 10.92977142857143Z"
        strokeWidth="1.43"
      ></path>
    </g>
  </svg>
);

export default Pencil;