const Integrations = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30">
    <g id="webhook">
      <path
        id="ellipse 175"
        fill="currentColor"
        d="M12.974540078571428 2.5894813285714284c-2.5332834642857143 0 -4.586904321428571 2.053640764285714 -4.586904321428571 4.586904321428571 0 2.5332834642857143 2.053620857142857 4.586904321428571 4.586904321428571 4.586904321428571 2.5332834642857143 0 4.586904321428571 -2.053620857142857 4.586904321428571 -4.586904321428571 0 -2.533263557142857 -2.053620857142857 -4.586904321428571 -4.586904321428571 -4.586904321428571Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="ellipse 176"
        fill="currentColor"
        d="M20.900509285714286 15.132653528571428c-2.5333232785714284 0 -4.586964042857143 2.053640764285714 -4.586964042857143 4.586924228571428 0 2.533223742857143 2.053640764285714 4.5868446 4.586964042857143 4.5868446 2.5331839285714284 0 4.5868047857142855 -2.053620857142857 4.5868047857142855 -4.5868446 0 -2.5332834642857143 -2.053620857142857 -4.586924228571428 -4.5868047857142855 -4.586924228571428Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="ellipse 177"
        fill="currentColor"
        d="M6.380557799999999 15.196933692857144c-2.5332834642857143 0 -4.586906312142857 2.053640764285714 -4.586906312142857 4.586904321428571S3.8472743357142853 24.37072242857143 6.380557799999999 24.37072242857143s4.586904321428571 -2.053620857142857 4.586904321428571 -4.586884414285714c0 -2.533263557142857 -2.053620857142857 -4.586904321428571 -4.586904321428571 -4.586904321428571Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="ellipse 172"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M17.56158375 7.176385649999999c0 -2.533263557142857 -2.053620857142857 -4.586904321428571 -4.586904321428571 -4.586904321428571 -2.5332834642857143 0 -4.586904321428571 2.053640764285714 -4.586904321428571 4.586904321428571 0 1.7178669857142856 0.9443550428571429 3.2151827357142855 2.3422545214285715 4.001096828571428L6.959238535714285 18.854333699999998"
        strokeWidth="2.13"
      ></path>
      <path
        id="ellipse 174"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M18.596456571428572 23.692884214285712c2.1939662142857146 1.2666914999999999 4.999280785714285 0.5149977857142857 6.265773214285714 -1.6789684285714286 1.2666914999999999 -2.193846771428571 0.5149977857142857 -4.999161342857143 -1.6789684285714286 -6.265793121428572 -1.4876607857142856 -0.8589334928571428 -3.2564104285714284 -0.7897561714285715 -4.636074964285714 0.027909814285714284L13.7842233 8.671989385714287"
        strokeWidth="2.13"
      ></path>
      <path
        id="ellipse 173"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.353174557142856 15.669668614285714C2.081490857142857 16.790799085714283 1.1487635764285715 19.541229664285716 2.269892057142857 21.812853642857142c1.1211105642857142 2.271803142857143 3.871541142857143 3.2044527857142855 6.143224842857142 2.0832824999999997 1.5404943428571427 -0.7602537857142857 2.46524085 -2.2696133571428567 2.55135915 -3.871143l8.552944671428572 -0.015925714285714286"
        strokeWidth="2.13"
      ></path>
    </g>
  </svg>
);

export default Integrations;
