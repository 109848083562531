const GeneralSettings = () => (
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
    <g id="arrow-cursor-click-1--mouse-select-cursor-clicks-point">
        <path id="Ellipse 1113" fill="#ffffff" d="M0.5 6a5.5 5.5 0 1 0 11 0 5.5 5.5 0 1 0 -11 0" strokeWidth="1"></path>
        <path id="Ellipse 978" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M11.5 6C11.5 2.96243 9.03757 0.5 6 0.5S0.5 2.96243 0.5 6s2.46243 5.5 5.5 5.5" strokeWidth="1"></path>
        <path id="Ellipse 979" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M8.29198 5C7.9062 4.11705 7.02516 3.5 6 3.5 4.61929 3.5 3.5 4.61929 3.5 6c0 1.02516 0.61705 1.9062 1.5 2.29198" strokeWidth="1"></path>
        <path id="Vector" fill="currentColor" d="M13.2337 9.15353c0.0841 -0.03504 0.1384 -0.05061 0.1883 -0.10051s0.078 -0.11757 0.078 -0.18814c0 -0.07057 -0.0281 -0.13825 -0.078 -0.18815s-0.0668 -0.0798 -0.1883 -0.11543c-0.1215 -0.03564 -6.02863 -2.03261 -6.02863 -2.03261 -0.09426 -0.03225 -0.19569 -0.03743 -0.29276 -0.01495 -0.09706 0.02249 -0.18587 0.07173 -0.25633 0.14213 -0.07045 0.0704 -0.11973 0.15914 -0.14223 0.25613 -0.02249 0.09699 -0.01731 0.19834 0.01496 0.29254 0 0 2.04421 5.94856 2.06918 6.02936 0.02497 0.0809 0.05719 0.1383 0.10712 0.1882 0.04994 0.0499 0.11767 0.0779 0.1883 0.0779 0.07062 0 0.13835 -0.028 0.18829 -0.0779 0.04993 -0.0499 0.06135 -0.0782 0.09878 -0.1882 0.03744 -0.11 1.04432 -3.012 1.04432 -3.012s2.925 -1.03333 3.009 -1.06837Z" strokeWidth="1"></path>
        <path id="Vector_2" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M13.2337 9.15353c0.0841 -0.03504 0.1384 -0.05061 0.1883 -0.10051 0.0499 -0.04989 0.078 -0.11757 0.078 -0.18814 0 -0.07057 -0.0281 -0.13825 -0.078 -0.18815s-0.0668 -0.0798 -0.1883 -0.11543c-0.1215 -0.03564 -6.02863 -2.03261 -6.02863 -2.03261 -0.09426 -0.03225 -0.19569 -0.03743 -0.29276 -0.01495 -0.09706 0.02249 -0.18587 0.07173 -0.25633 0.14213 -0.07045 0.0704 -0.11973 0.15914 -0.14223 0.25613 -0.02249 0.09699 -0.01731 0.19834 0.01496 0.29254 0 0 2.04421 5.94856 2.06918 6.02936 0.02497 0.0809 0.05719 0.1383 0.10712 0.1882 0.04994 0.0499 0.11767 0.0779 0.1883 0.0779 0.07062 0 0.13835 -0.028 0.18829 -0.0779 0.04993 -0.0499 0.06135 -0.0782 0.09878 -0.1882 0.03744 -0.11 1.04432 -3.012 1.04432 -3.012s2.925 -1.03333 3.009 -1.06837Z" strokeWidth="1"></path>
    </g>
</svg>
);

export default GeneralSettings;
