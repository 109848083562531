const ReportBuilder = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30">
    <g id="graph-increase--analytics-business-product-graph-data-chart-analysis">
      <path
        id="Vector"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1.0160924228571429 1.0160426550000001V26.853939428571426H26.854138499999998"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector 2175"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m7.072968042857142 19.432775550000002 4.865027014285714 -6.725190257142858 7.1075069357142855 3.0048836785714284 4.57890165 -7.583725607142857"
        strokeWidth="2.13"
      ></path>
    </g>
  </svg>
);

export default ReportBuilder;
