const isProd = process.env.NODE_ENV === 'production';

/**
 * Get value of given name
 * @param {String} key cookie name
 */
export const getCookie = (key: string) => {
  const all = document.cookie.split(';');

  if (!(document.cookie && all.length)) {
    return null;
  }

  const cookies = all.reduce((acc: { [key: string]: string }, item) => {
    const [key, value] = item.split('=');
    if (typeof key === 'string' && typeof value === 'string') {
      acc[key.trim()] = value.trim();
    }
    return acc;
  }, {});

  return cookies[key];
};

/**
 * set a cookie in browser
 * @param {String} key cookie name
 * @param {String} value cookie value
 * @param {Number} ttl indicates how many days cookie would exist
 */
export const setCookie = (key: string, value = '', ttl = 100) => {
  const now = new Date();
  now.setDate(now.getDate() + ttl);
  const expires = now.toUTCString();
  const cookie = [
    [key, value],
    ['expires', expires],
    ['path', '/'],
    ['size', ''],
    ['HTTP'],
    window.location.protocol === 'https:' ? ['secure'] : [],
    // Instead of using getDomain() which strips subdomains, just set the domain to the exact hostname
    // This keeps cookies specific to each subdomain (ui. vs ui-staging.)
    ...(isProd ? [['domain', window.location.hostname]] : [])
  ].map(item => item.join('='));

  document.cookie = cookie.join(';');
};

/**
 * delete a cookie in browser
 * @param {String} key cookie name
 */
export const removeCookie = (key: string) => {
  // Also update the removal function to use the current hostname
  document.cookie = `${key}=;Max-Age=-1;path=/;domain=${window.location.hostname}`;
  document.cookie = `${key}=;Max-Age=-1`;
};
