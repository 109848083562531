import React from 'react';

const OfferSources = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30">
    <g id="briefcase-dollar--briefcase-payment-cash-money-finance-baggage-bag">
      <path
        id="Vector"
        fill="currentColor"
        d="M24.88392857142857 6.967499999999999h-21.89785714285714c-1.0994416392857143 0 -1.9907142857142857 0.8912826 -1.9907142857142857 1.9907142857142857v15.925714285714285c0 1.0994715 0.8912726464285714 1.9907142857142857 1.9907142857142857 1.9907142857142857h21.89785714285714c1.0994715 0 1.9907142857142857 -0.8912427857142856 1.9907142857142857 -1.9907142857142857v-15.925714285714285c0 -1.0994316857142856 -0.8912427857142856 -1.9907142857142857 -1.9907142857142857 -1.9907142857142857Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M24.88392857142857 6.967499999999999h-21.89785714285714c-1.0994416392857143 0 -1.9907142857142857 0.8912826 -1.9907142857142857 1.9907142857142857v15.925714285714285c0 1.0994715 0.8912726464285714 1.9907142857142857 1.9907142857142857 1.9907142857142857h21.89785714285714c1.0994715 0 1.9907142857142857 -0.8912427857142856 1.9907142857142857 -1.9907142857142857v-15.925714285714285c0 -1.0994316857142856 -0.8912427857142856 -1.9907142857142857 -1.9907142857142857 -1.9907142857142857Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_3"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.90714285714286 6.967499999999999v-3.9814285714285713c0 -0.5279772428571429 -0.20974165714285714 -1.0343154214285712 -0.5830603071428571 -1.4076480064285715C18.95074399285714 1.205092827857143 18.444405814285712 0.9953571428571428 17.916428571428572 0.9953571428571428H9.95357142857143c-0.5279772428571429 0 -1.0343154214285712 0.209735685 -1.4076539785714286 0.5830662792857143C8.1725988 1.9517560071428572 7.962857142857143 2.4580941857142857 7.962857142857143 2.9860714285714285v3.9814285714285713"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector 3"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.476067157142857 14.267031235714285c-0.08739235714285715 -0.24726662142857142 -0.22224334285714287 -0.4720978928571428 -0.39362393571428567 -0.6635647928571429 -0.3644997857142857 -0.4072802357142857 -0.894228857142857 -0.6635847 -1.483818707142857 -0.6635847h-1.5408924857142856c-0.9814022357142856 0 -1.7769712928571428 0.7955889642857142 -1.7769712928571428 1.7769911999999999 0 0.8350648285714286 0.5814677357142858 1.5574751357142858 1.3972425428571427 1.7359426714285715l2.3460368785714287 0.5131862357142858c0.9139170214285715 0.19992743571428573 1.5653185499999998 1.0098097285714287 1.5653185499999998 1.9453259999999999 0 1.0995312214285713 -0.8912826 1.9913712214285713 -1.990734192857143 1.9913712214285713h-1.3271494928571428c-0.8667769071428572 0 -1.6041573857142857 -0.5538167142857143 -1.877442642857143 -1.3270698642857142"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector 2489"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.935119442857143 12.939662764285714V10.94892857142857"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector 2490"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.935119442857143 22.893214285714286v-1.9907142857142857"
        strokeWidth="2.13"
      ></path>
    </g>
  </svg>
);

export default OfferSources;
