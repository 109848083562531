import { DrilldownReportRowAttribute } from '@/models/drilldownReportRowAttribute';
import { FFSelectOption } from '@/uikit/types/select';
import { DrilldownReportCustomEventMetric } from '@/models/drilldownReportCustomEventMetric';

export const defaultDrilldownReportEvents: DrilldownReportCustomEventMetric[] = [
  {
    count: 0,
    revenue: '0'
  },
  {
    count: 0,
    revenue: '0'
  },
  {
    count: 0,
    revenue: '0'
  },
  {
    count: 0,
    revenue: '0'
  },
  {
    count: 0,
    revenue: '0'
  },
  {
    count: 0,
    revenue: '0'
  },
  {
    count: 0,
    revenue: '0'
  },
  {
    count: 0,
    revenue: '0'
  },
  {
    count: 0,
    revenue: '0'
  },
  {
    count: 0,
    revenue: '0'
  }
];

export const statusOptions: FFSelectOption<DrilldownReportRowAttribute.StatusEnum | 'all'>[] = [
  { label: 'View All', value: 'all' },
  { label: 'View Active', value: 'active' },
  { label: 'View Archived', value: 'archived' }
];
