import { FormStore, FormsStoreProps } from '@/types/stores/forms';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { produce } from 'immer';

const DEFAULT_PROPS: FormsStoreProps = {
  versioning: {
    isOpen: false,
    type: 'funnel',
  },
  funnel: {
    isGlobalOpen: false,
    isBuilderOpen: false,
    data: null,
    isDuplication: false,
    defaultTabId: 'general',
  },
  funnelGroup: {
    isOpen: false,
    data: null,
    isDuplication: false,
  },
  offer: {
    isOpen: false,
    data: null,
    isDuplication: false,
  },
  category: {
    isOpen: false,
    data: null,
    isDuplication: false,
    categoryType: 'offer',
  },
  offerSource: {
    isOpen: false,
    data: null,
    isDuplication: false,
  },
  lander: {
    isOpen: false,
    data: null,
    isDuplication: false,
  },
  trafficSource: {
    isOpen: false,
    data: null,
    isDuplication: false,
  },
  condition: {
    isBuilderOpen: false,
    isGlobalOpen: false,
    data: null,
    isDuplication: false,
  },
  pageGroup: {
    isGlobalOpen: false,
    isBuilderOpen: false,
    isSelectionOpen: false,
    data: null,
    pageGroupType: 'lander',
    isDuplication: false,
  },
  externalUrl: {
    isOpen: false,
    isDuplication: false,
  },
  modifyWeights: {
    isOpen: false,
    isDuplication: false,
    defaultTabId: 'general',
  },
  simpleFlow: {
    isOpen: false,
    data: null,
    isDuplication: false,
  },
  simpleFlowPathEditor: {
    isOpen: false,
    data: null,
  },
  simpleFlowRuleEditor: {
    isOpen: false,
    data: null,
  },
  ai: {
    isOpen: false,
    data: null,
    defaultTabId: 'general',
  },
  openedForms: [],
};

const useFormStore = create<FormStore>()(
  devtools((set, get) => ({
    ...DEFAULT_PROPS,
    // versioning
    openVersioningForm: () =>
      set(
        produce<FormStore>((state) => {
          state.versioning.isOpen = true;
          state.openedForms.push('versioning');
        }),
      ),
    closeVersioningForm: () =>
      set(
        produce<FormStore>((state) => {
          state.versioning.isOpen = false;
          state.openedForms = state.openedForms.filter((form) => form !== 'versioning');
        }),
      ),
    setVersioningType: (type) =>
      set(
        produce<FormStore>((state) => {
          state.versioning.type = type;
        }),
      ),
    //funnel group
    openFunnelGroupForm: () =>
      set(
        produce<FormStore>((state) => {
          state.funnelGroup.isOpen = true;
          state.openedForms.push('funnelGroup');
        }),
      ),
    closeFunnelGroupForm: () =>
      set(
        produce<FormStore>((state) => {
          state.funnelGroup.isOpen = false;
          state.funnelGroup.data = null;
          state.funnelGroup.isDuplication = false;
          state.openedForms = state.openedForms.filter((form) => form !== 'funnelGroup');
        }),
      ),
    setFunnelGroupData: (data, isDuplication) =>
      set(
        produce<FormStore>((state) => {
          state.funnelGroup.data = data;
          state.funnelGroup.isDuplication = isDuplication;
        }),
      ),
    //funnel
    openFunnelForm: (type) =>
      set(
        produce<FormStore>((state) => {
          if (type === 'global') {
            state.funnel.isGlobalOpen = true;
          } else {
            state.funnel.isBuilderOpen = true;
          }
          state.openedForms.push('funnel');
        }),
      ),
    closeFunnelForm: () =>
      set(
        produce<FormStore>((state) => {
          state.funnel.isGlobalOpen = false;
          state.funnel.isBuilderOpen = false;
          state.funnel.defaultTabId = 'general';
          state.funnel.data = null;
          state.funnel.isDuplication = false;
          state.openedForms = state.openedForms.filter((form) => form !== 'funnel');
        }),
      ),
    setFunnelData: (data, isDuplication) =>
      set(
        produce<FormStore>((state) => {
          state.funnel.data = data;
          state.funnel.isDuplication = isDuplication;
        }),
      ),
    setFunnelDefaultTabId: (defaultTabId) =>
      set(
        produce<FormStore>((state) => {
          state.funnel.defaultTabId = defaultTabId;
        }),
      ),
    // offer
    openOfferForm: () =>
      set(
        produce<FormStore>((state) => {
          state.offer.isOpen = true;
          state.openedForms.push('offer');
        }),
      ),
    closeOfferForm: () =>
      set(
        produce<FormStore>((state) => {
          state.offer.isOpen = false;
          state.offer.data = null;
          state.offer.isDuplication = false;
          state.openedForms = state.openedForms.filter((form) => form !== 'offer');
        }),
      ),
    setOfferData: (data, isDuplication) =>
      set(
        produce<FormStore>((state) => {
          state.offer.data = data;
          state.offer.isDuplication = isDuplication;
        }),
      ),
    // pageGroup
    openPageGroupForm: (type) =>
      set(
        produce<FormStore>((state) => {
          if (type === 'global') {
            state.pageGroup.isGlobalOpen = true;
          } else if (type === 'builder') {
            state.pageGroup.isBuilderOpen = true;
          } else {
            state.pageGroup.isSelectionOpen = true;
          }
          state.openedForms.push('pageGroup');
        }),
      ),
    closePageGroupForm: (type) =>
      set(
        produce<FormStore>((state) => {
          if (type === 'global') {
            state.pageGroup.isGlobalOpen = false;
          } else if (type === 'builder') {
            state.pageGroup.isBuilderOpen = false;
          } else {
            state.pageGroup.isSelectionOpen = false;
          }
          state.pageGroup.data = null;
          state.pageGroup.isDuplication = false;
          state.openedForms = state.openedForms.filter((form) => form !== 'pageGroup');
        }),
      ),
    setPageGroupData: (data, isDuplication) =>
      set(
        produce<FormStore>((state) => {
          state.pageGroup.data = data;
          state.pageGroup.isDuplication = isDuplication;
        }),
      ),
    // category
    openCategoryForm: (categoryType) =>
      set(
        produce<FormStore>((state) => {
          state.category.isOpen = true;
          state.openedForms.push('category');
          state.category.categoryType = categoryType;
        }),
      ),
    closeCategoryForm: () =>
      set(
        produce<FormStore>((state) => {
          state.category.isOpen = false;
          state.category.data = null;
          state.category.isDuplication = false;
          state.openedForms = state.openedForms.filter((form) => form !== 'category');
        }),
      ),
    setCategoryData: (data, isDuplication) =>
      set(
        produce<FormStore>((state) => {
          state.category.data = data;
          state.category.isDuplication = isDuplication;
        }),
      ),
    setCategoryType: (categoryType) =>
      set(
        produce<FormStore>((state) => {
          state.category.categoryType = categoryType;
        }),
      ),
    // offersources
    openOfferSourceForm: () =>
      set(
        produce<FormStore>((state) => {
          state.offerSource.isOpen = true;
          state.openedForms.push('offerSource');
        }),
      ),
    closeOfferSourceForm: () =>
      set(
        produce<FormStore>((state) => {
          state.offerSource.isOpen = false;
          state.offerSource.data = null;
          state.offerSource.isDuplication = false;
          state.openedForms = state.openedForms.filter((form) => form !== 'offerSource');
        }),
      ),
    setOfferSourceData: (data, isDuplication) =>
      set(
        produce<FormStore>((state) => {
          state.offerSource.data = data;
          state.offerSource.isDuplication = isDuplication;
        }),
      ),
    // landers
    openLanderForm: () =>
      set(
        produce<FormStore>((state) => {
          state.lander.isOpen = true;
          state.openedForms.push('lander');
        }),
      ),
    closeLanderForm: () =>
      set(
        produce<FormStore>((state) => {
          state.lander.isOpen = false;
          state.lander.data = null;
          state.lander.isDuplication = false;
          state.openedForms = state.openedForms.filter((form) => form !== 'lander');
        }),
      ),
    setLanderData: (data, isDuplication) =>
      set(
        produce<FormStore>((state) => {
          state.lander.data = data;
          state.lander.isDuplication = isDuplication;
        }),
      ),
    // trafficSource
    setTrafficSourceData: (data, isDuplication) =>
      set(
        produce<FormStore>((state) => {
          state.trafficSource.data = data;
          state.trafficSource.isDuplication = isDuplication;
        }),
      ),
    openTrafficSourceForm: () =>
      set(
        produce<FormStore>((state) => {
          state.trafficSource.isOpen = true;
          state.openedForms.push('trafficSource');
        }),
      ),
    closeTrafficSourceForm: () =>
      set(
        produce<FormStore>((state) => {
          state.trafficSource.isOpen = false;
          state.trafficSource.data = null;
          state.trafficSource.isDuplication = false;
          state.openedForms = state.openedForms.filter((form) => form !== 'trafficSource');
        }),
      ),
    // simple flow
    openSimpleFlowForm: () =>
      set(
        produce<FormStore>((state) => {
          state.simpleFlow.isOpen = true;
          state.openedForms.push('simpleFlow');
        }),
      ),
    closeSimpleFlowForm: () =>
      set(
        produce<FormStore>((state) => {
          state.simpleFlow.isOpen = false;
          state.simpleFlow.data = null;
          state.simpleFlow.isDuplication = false;
          state.openedForms = state.openedForms.filter((form) => form !== 'simpleFlow');
        }),
      ),
    setSimpleFlowData: (data, isDuplication) =>
      set(
        produce<FormStore>((state) => {
          state.simpleFlow.data = data;
          state.simpleFlow.isDuplication = isDuplication;
        }),
      ),
    // simple flow path editor
    openSimpleFlowPathEditorForm: (data) =>
      set(
        produce<FormStore>((state) => {
          state.simpleFlowPathEditor.data = data;
          state.simpleFlowPathEditor.isOpen = true;
          state.openedForms.push('simpleFlowPathEditor');
        }),
      ),
    closeSimpleFlowPathEditorForm: () =>
      set(
        produce<FormStore>((state) => {
          state.simpleFlowPathEditor.isOpen = false;
          state.simpleFlowPathEditor.data = null;
          state.openedForms = state.openedForms.filter((form) => form !== 'simpleFlowPathEditor');
        }),
      ),
    setSimpleFlowPathEditorData: (data) =>
      set(
        produce<FormStore>((state) => {
          state.simpleFlowPathEditor.data = data;
        }),
      ),
    // simple flow rule editor
    openSimpleFlowRuleEditorForm: (data) =>
      set(
        produce<FormStore>((state) => {
          state.simpleFlowRuleEditor.data = data;
          state.simpleFlowRuleEditor.isOpen = true;
          state.openedForms.push('simpleFlowRuleEditor');
        }),
      ),
    closeSimpleFlowRuleEditorForm: () =>
      set(
        produce<FormStore>((state) => {
          state.simpleFlowRuleEditor.isOpen = false;
          state.simpleFlowRuleEditor.data = null;
          state.openedForms = state.openedForms.filter((form) => form !== 'simpleFlowRuleEditor');
        }),
      ),
    setSimpleFlowRuleEditorData: (data) =>
      set(
        produce<FormStore>((state) => {
          state.simpleFlowRuleEditor.data = data;
        }),
      ),
    // condition
    setConditionData: (data, isDuplication) =>
      set(
        produce<FormStore>((state) => {
          state.condition.data = data;
          state.condition.isDuplication = isDuplication;
        }),
      ),

    openConditionForm: (type) =>
      set(
        produce<FormStore>((state) => {
          if (type === 'global') {
            state.condition.isGlobalOpen = true;
          } else {
            state.condition.isBuilderOpen = true;
          }
          state.openedForms.push('condition');
        }),
      ),
    closeConditionForm: (type) =>
      set(
        produce<FormStore>((state) => {
          if (type === 'global') {
            state.condition.isGlobalOpen = false;
          } else {
            state.condition.isBuilderOpen = false;
          }
          state.condition.data = null;
          state.condition.isDuplication = false;
          state.openedForms = state.openedForms.filter((form) => form !== 'condition');
        }),
      ),
    // external url
    openExternalUrlForm: () =>
      set(
        produce<FormStore>((state) => {
          state.externalUrl.isOpen = true;
          state.openedForms.push('externalUrl');
        }),
      ),
    closeExternalUrlForm: () =>
      set(
        produce<FormStore>((state) => {
          state.externalUrl.isOpen = false;
          state.externalUrl.isDuplication = false;
          state.openedForms = state.openedForms.filter((form) => form !== 'externalUrl');
        }),
      ),
    // modify weights
    openModifyWeightsForm: () =>
      set(
        produce<FormStore>((state) => {
          state.modifyWeights.isOpen = true;
          state.openedForms.push('modifyWeights');
        }),
      ),
    closeModifyWeightsForm: () =>
      set(
        produce<FormStore>((state) => {
          state.modifyWeights.isOpen = false;
          state.modifyWeights.isDuplication = false;
          state.modifyWeights.defaultTabId = 'general';
          state.openedForms = state.openedForms.filter((form) => form !== 'modifyWeights');
        }),
      ),
    setModifyWeightsDefaultTabId: (tabId) =>
      set(
        produce<FormStore>((state) => {
          state.modifyWeights.defaultTabId = tabId;
        }),
      ),
    // ai
    openAiForm: () =>
      set(
        produce<FormStore>((state) => {
          state.ai.isOpen = true;
          state.openedForms.push('ai');
        }),
      ),
    closeAiForm: () =>
      set(
        produce<FormStore>((state) => {
          state.ai.isOpen = false;
          state.ai.data = null;
          state.openedForms = state.openedForms.filter((form) => form !== 'ai');
        }),
      ),
    setAiData: (data) =>
      set(
        produce<FormStore>((state) => {
          state.ai.data = data;
        }),
      ),
    setAiDefaultTabId: (tabId) =>
      set(
        produce<FormStore>((state) => {
          state.ai.defaultTabId = tabId;
        }),
      ),
  })),
);

export default useFormStore;
