import React, { PropsWithChildren, useEffect, useContext, useState } from 'react';
import { FFNewIcon, VisibilityWrapper } from '@/uikit';
import ReactPortal from '@/components/Portal';
import className from '@/utils/className';
import { SidebarCopyItems, SidebarTab } from '@/uikit/types/sidebar';
import clsx from 'clsx';
import AntMessageContext from '@/contexts/antMessage';
import './style.scss';

const { blockClassName, getClass } = className('c-ffSidePanel');

interface Props<T> {
  title: string | React.ReactNode;
  actions?: React.ReactNode;
  tabs?: SidebarTab<T>[];
  sidebarName: string;
  zIndex?: number;
  isOpen: boolean;
  onClose: () => void;
  headerClassName?: string;
  contentClassName?: string;
  contentStyle?: React.CSSProperties;
  currentTabId?: T;
  width?: number | string;
  minWidth?: number;
  maxWidth?: number;
  padding?: number | string;
  offsetLevel?: number;
  closableByOverlay?: boolean;
  copyItems?: SidebarCopyItems[];
  renderAnyway?: boolean;
  setCurrentTabId?: (tabId: T) => void;
  transitionDuration?: number;
}

const SidePanel = <T = string>({
  zIndex = 100,
  sidebarName,
  isOpen,
  onClose,
  title,
  headerClassName,
  contentClassName,
  contentStyle,
  actions,
  children,
  tabs = [],
  padding,
  minWidth,
  maxWidth,
  width,
  currentTabId,
  copyItems,
  closableByOverlay = false,
  offsetLevel,
  renderAnyway = false,
  setCurrentTabId = () => {},
  transitionDuration = 300,
}: PropsWithChildren<Props<T>>) => {
  const { messageApi } = useContext(AntMessageContext);
  const hasTabs = tabs.length > 0;
  const [currentTabIdState, setCurrentTabIdState] = React.useState(currentTabId);
  const [shouldRenderContent, setShouldRenderContent] = useState(isOpen || renderAnyway);

  const onTabClick = (tab: SidebarTab<T>) => {
    setCurrentTabIdState(tab.tabId);
    setCurrentTabId(tab.tabId);
  };

  const onOverlayClick = () => {
    if (closableByOverlay) {
      onClose();
    }
  };

  const onCopyItemClick = async (item: SidebarCopyItems) => {
    try {
      await navigator.clipboard.writeText(item.value);
      messageApi.open({
        type: 'success',
        content: 'Copied',
      });
    } catch (err) {
      messageApi.open({
        type: 'warning',
        content: 'Nothing to be copied',
      });
    }
  };

  useEffect(() => {
    if (isOpen) {
      setShouldRenderContent(true);
      setCurrentTabIdState(currentTabId);
    } else if (!renderAnyway) {
      const timer = setTimeout(() => {
        setShouldRenderContent(false);
      }, transitionDuration);
      return () => clearTimeout(timer);
    }
  }, [isOpen, currentTabId, renderAnyway, transitionDuration]);

  return (
    <ReactPortal zIndex={zIndex} dataPortalKey={sidebarName}>
      <>
        <div className={getClass('backdrop', [isOpen && 'open'])} style={{ zIndex }} onClick={onOverlayClick} />
        <div
          style={{ zIndex, minWidth, maxWidth, width }}
          className={clsx(
            blockClassName,
            `${blockClassName}--${isOpen ? 'open' : 'close'}`,
            `${blockClassName}--offsetLevel${offsetLevel}`,
          )}
        >
          <div className={clsx(getClass('header'), headerClassName)}>
            <h3 className={getClass('title')}>{title}</h3>
            <div className={getClass('actions')}>
              {copyItems && (
                <div className={getClass('copyItems')}>
                  {copyItems.map((item) => (
                    <div key={item.title} className={getClass('copyItem')} onClick={() => onCopyItemClick(item)}>
                      <span className={getClass('copyItemTitle')}>{item.title}</span>
                      <span className={getClass('copyItemValue')}>{item.value}</span>
                      <div className={getClass('copyItemIcon')}>
                        <FFNewIcon name="general/line/copy" size="sm" />
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {actions}
            </div>
          </div>
          <div style={{ padding }} className={getClass('body')}>
            {shouldRenderContent && (
              <div style={contentStyle} className={clsx(getClass('children'), contentClassName)}>
                {children}
              </div>
            )}
            <VisibilityWrapper visible={hasTabs}>
              <div className={getClass('tabs')}>
                {tabs.map((tab, index) => (
                  <div
                    key={index}
                    className={getClass('tab', [
                      !!tab.hidden && 'hidden',
                      !!tab.highlightable && 'highlightable',
                      !!tab.disabled && 'disabled',
                      tab.tabId === currentTabIdState ? 'active' : 'inactive',
                    ])}
                    onClick={() => onTabClick(tab)}
                  >
                    <span className={getClass('tabTitle')}>{tab.title}</span>
                    <span className={getClass('tabIcon')}>{tab.icon}</span>
                  </div>
                ))}
              </div>
            </VisibilityWrapper>
          </div>
        </div>
      </>
    </ReactPortal>
  );
};

export default SidePanel;
