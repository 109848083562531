import { TrafficSource } from '@/models/models';
import { FFCol, FFField, FFInput, FFSelect } from '@/uikit';
import { FFSelectOption } from '@/uikit/types/select';
import { Control, Controller } from 'react-hook-form';

const EVENT_NAMES: Array<FFSelectOption> = [
  {
    value: 'AddToCart',
    label: 'Add to Cart',
  },
  {
    value: 'AddToWishlist',
    label: 'Add to Wishlist',
  },
  {
    value: '{txid}',
    label: 'Custom – Transaction ID Value',
  },
  {
    value: 'Lead',
    label: 'Lead',
  },
  {
    value: 'Purchase',
    label: 'Purchase',
  },
  {
    value: 'Search',
    label: 'Search',
  },
  {
    value: 'SignUp',
    label: 'Sign Up',
  },
  {
    value: 'ViewContent',
    label: 'View Content',
  },
];

const RedditCustomScenario = ({ triggeringEvent, control }: { triggeringEvent: string; control: Control<TrafficSource> }) => {
  return (
    <FFCol gap="12px">
      <Controller
        name={`conversionTrackingSettings.${triggeringEvent}.customEventData.refreshToken`}
        control={control}
        render={(opt) => (
          <FFField label="Refresh Token:" block direction="row">
            <FFInput
              value={opt.field.value}
              onChange={(e) => opt.field.onChange(e.target.value.trim())}
              error={opt.fieldState.error?.message}
              placeholder="Refresh Token"
              style={{ width: 280 }}
            />
          </FFField>
        )}
      />
      <Controller
        name={`conversionTrackingSettings.${triggeringEvent}.customEventData.eventName`}
        control={control}
        render={(opt) => (
          <FFField label="Event name:" block direction="row">
            <FFSelect
              options={EVENT_NAMES}
              valueGetter={(opt) => opt.value}
              labelGetter={(opt) => opt.label}
              value={opt.field.value}
              onChange={opt.field.onChange}
              error={opt.fieldState.error?.message}
              placeholder="Event name"
              style={{ width: 280 }}
            />
          </FFField>
        )}
      />
    </FFCol>
  );
};

export default RedditCustomScenario;
