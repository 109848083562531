import { PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';

const appRoot = document.getElementById('root')!;

const Portal = ({
  children,
  dataPortalKey,
  className,
  zIndex,
}: PropsWithChildren<{ className?: string; dataPortalKey: string; zIndex?: number }>) => {
  return createPortal(
    <div data-portal-key={dataPortalKey} className={className} style={{ zIndex }}>
      {children}
    </div>,
    appRoot,
  );
};

export default Portal;
