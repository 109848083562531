const Stats = () => (
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
    <g id="graph-bar-increase-square--up-product-performance-increase-arrow-graph-business-chart">
        <path id="Vector" fill="#d7e0ff" d="M12.0769 1H1.92308C1.41328 1 1 1.41328 1 1.92308V12.0769c0 0.5098 0.41328 0.9231 0.92308 0.9231H12.0769c0.5098 0 0.9231 -0.4133 0.9231 -0.9231V1.92308C13 1.41328 12.5867 1 12.0769 1Z" strokeWidth="1"></path>
        <path id="Vector 11" fill="#ffffff" d="M8.25 3.5v7H11v-7H8.25Z" strokeWidth="1"></path>
        <path id="Vector 10" fill="#ffffff" d="M5.75 5.5v5h2.5v-5h-2.5Z" strokeWidth="1"></path>
        <path id="Vector 9" fill="#ffffff" d="M3 7.5v3h2.75v-3H3Z" strokeWidth="1"></path>
        <path id="Vector 6" stroke="#4147d5" strokeLinecap="round" strokeLinejoin="round" d="M3 7.5v3h2.75v-3H3Z" strokeWidth="1"></path>
        <path id="Vector 7" stroke="#4147d5" strokeLinecap="round" strokeLinejoin="round" d="M5.75 5.5v5h2.5v-5h-2.5Z" strokeWidth="1"></path>
        <path id="Vector 8" stroke="#4147d5" strokeLinecap="round" strokeLinejoin="round" d="M8.25 3.5v7H11v-7H8.25Z" strokeWidth="1"></path>
        <path id="Vector_2" stroke="#4147d5" strokeLinecap="round" strokeLinejoin="round" d="M12.0769 1H1.92308C1.41328 1 1 1.41328 1 1.92308V12.0769c0 0.5098 0.41328 0.9231 0.92308 0.9231H12.0769c0.5098 0 0.9231 -0.4133 0.9231 -0.9231V1.92308C13 1.41328 12.5867 1 12.0769 1Z" strokeWidth="1"></path>
    </g>
</svg>
);

export default Stats;