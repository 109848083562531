import SectionBox from '@/components/SectionBox';
import { FFAddGroup, FFCol, FFRow, FFSidePanel, FFButton, FFIconButton } from '@/uikit';
import FFInput from '@/uikit/components/Input';
import useFormStore from '@/stores/forms';
import { Ref, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Category } from '@/models/models';
import { getSidebarOffsetLevel, getSidebarZIndex } from '@/utils/sidebar';
import { generateEntityId } from '@/utils/id';
import Skeleton from 'react-loading-skeleton';
import { useCategoriesQuery } from '@/api/queries/category';
import { useCreateCategoryMutation, useDeleteCategoryMutation, useUpdateCategoryMutation } from '@/api/mutations/category';
import useMitt from '@/hooks/mitt';

interface FormProps {
  closeForm: () => void;
  submitLoading: boolean;
  setSubmitLoading: (loading: boolean) => void;
  openCategoryDeleteConfirm: () => void;
}

interface FlaggedCategory extends Category {
  flag: 'new' | 'edited' | 'default';
}

interface RefType {
  onSave: () => void;
  onConfirmDeleteCategory: () => void;
}

const Form = forwardRef(({ closeForm, setSubmitLoading, openCategoryDeleteConfirm }: FormProps, ref: Ref<RefType>) => {
  const emitter = useMitt();
  const categoryFormProps = useFormStore(state => state.category);

  const { data: categories, isLoading: isGettingCategories } = useCategoriesQuery(categoryFormProps.categoryType);
  const { mutateAsync: createCategory } = useCreateCategoryMutation();
  const { mutateAsync: updateCategory } = useUpdateCategoryMutation();
  const { mutateAsync: deleteCategory } = useDeleteCategoryMutation();

  const [categoriesList, setCategoriesList] = useState<FlaggedCategory[]>([]);
  const [deleteCategoryId, setDeleteCategoryId] = useState<string>('');

  const notDeletedCategories = useMemo(() => {
    return categoriesList.filter(category => category.status !== 'deleted');
  }, [categoriesList]);

  useEffect(() => {
    if (Array.isArray(categories)) {
      setCategoriesList(categories.map(category => ({ ...category, flag: 'default' })));
    }
  }, [categories]);

  const onEditCategoryName = (e: React.ChangeEvent<HTMLInputElement>, categoryIdx: number) => {
    const newCategories = [...categoriesList];
    newCategories[categoryIdx].categoryName = e.target.value;
    if (newCategories[categoryIdx].flag !== 'new')  {
      newCategories[categoryIdx].flag = 'edited';
    }
    setCategoriesList(newCategories);
  };

  const onConfirmDeleteCategory = () => {
    setCategoriesList(
      categoriesList.map((category) => {
        if (category.idCategory === deleteCategoryId) {
          return { ...category, status: 'deleted' };
        }
        return category;
      })
    );
  };

  const onDeleteCategory = (category: Category) => {
    setDeleteCategoryId(category.idCategory);
    openCategoryDeleteConfirm();
  };

  const onSave = async () => {
    try {
      setSubmitLoading(true);
      const forDeleteCategoryIds = categoriesList
        .filter(category => category.status === 'deleted' && category.flag !== 'new')
        .map(category => category.idCategory);
      if (forDeleteCategoryIds.length) {
        await deleteCategory(forDeleteCategoryIds);
      }
      for (const category of categoriesList) {
        if (category.flag  === 'new' && category.status !== 'deleted') {
          const { flag, ...rest } = category;
          await createCategory({
            ...rest,
            idCategory: generateEntityId()
          });
        } else if (category.flag === 'edited' && category.status !== 'deleted') {
          const { flag, ...rest } = category;
          await updateCategory({
            ...rest
          });
        }
      }

      emitter.emit('onCategorySave');
      closeForm();
    } catch (e) {
      //
    } finally {
      setSubmitLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({ onConfirmDeleteCategory, onSave }));

  const onAddCategory = () => {
    const newCategories = [...categoriesList];
    const newCategory: FlaggedCategory = {
      idCategory: generateEntityId(),
      categoryName: '',
      categoryType: categoryFormProps.categoryType,
      status: 'active',
      flag: 'new'
    };
    newCategories.push(newCategory);
    setCategoriesList(newCategories);
  };

  return (
    <FFRow width="100%">
      <SectionBox>
        {isGettingCategories && <Skeleton count={10} height={33} />}
        <FFAddGroup
          showAddRow
          onAddRow={onAddCategory}
          rows={notDeletedCategories}
          renderRow={(row, rowIdx) => {
            return (
              <FFAddGroup.Col>
                <FFAddGroup.Row>
                  <FFInput name="name" value={row.categoryName} placeholder="Name" onChange={e => onEditCategoryName(e, rowIdx)} />
                  <FFIconButton
                    buttonType="transparent"
                    iconName="general/line/delete-circle"
                    iconType="danger"
                    onClick={() => onDeleteCategory(row)}
                  />
                </FFAddGroup.Row>
              </FFAddGroup.Col>
            );
          }}
        />
      </SectionBox>
    </FFRow>
  );
});

const CategoryForm = () => {
  const { closeCategoryForm, openedForms, category: categoryFormProps } = useFormStore();
  const formRef = useRef<RefType>({
    onSave: () => {},
    onConfirmDeleteCategory: () => {}
  });
  const [isDeleteCategoryOpen, setIsDeleteCategoryOpen] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const onConfirmDelete = () => {
    setIsDeleteCategoryOpen(false);
    formRef.current.onConfirmDeleteCategory();
  };

  return (
    <>
      <FFSidePanel
        isOpen={isDeleteCategoryOpen}
        minWidth={600}
        maxWidth={1100}
        onClose={() => setIsDeleteCategoryOpen(false)}
        sidebarName="DeleteCategory"
        zIndex={getSidebarZIndex(openedForms, 'category') + 1}
        title="Delete Category"
        actions={
          <FFRow gap="8px">
            <FFButton onClick={onConfirmDelete}>Yes</FFButton>
            <FFButton type="gray" onClick={() => setIsDeleteCategoryOpen(false)}>
              No
            </FFButton>
          </FFRow>
        }
      >
        <FFCol>
          <p>Deleting this category will move all {categoryFormProps?.categoryType}s in it to Uncategorized.</p>
          <p>
            <strong>Are you sure you want to delete this category? (this action is not reversible).</strong>
          </p>
        </FFCol>
      </FFSidePanel>
      <FFSidePanel
        isOpen={categoryFormProps.isOpen}
        maxWidth={500}
        offsetLevel={getSidebarOffsetLevel(openedForms, 'category') + (isDeleteCategoryOpen ? 1 : 0)}
        onClose={closeCategoryForm}
        sidebarName="CategoryForm"
        zIndex={getSidebarZIndex(openedForms, 'category')}
        title="Categories"
        actions={
          <FFRow gap="8px">
            <FFButton onClick={() => formRef.current.onSave()} loading={submitLoading} disabled={submitLoading}>
              Save
            </FFButton>
            <FFButton type="tertiary" disabled={submitLoading} onClick={closeCategoryForm}>
              Cancel
            </FFButton>
          </FFRow>
        }
      >
        <Form
          closeForm={closeCategoryForm}
          setSubmitLoading={setSubmitLoading}
          openCategoryDeleteConfirm={() => setIsDeleteCategoryOpen(true)}
          submitLoading={submitLoading}
          ref={formRef}
        />
      </FFSidePanel>
    </>
  );
};

export default CategoryForm;
