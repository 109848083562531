import CodeSnippet from '@/components/CodeSnippet';
import SectionBox from '@/components/SectionBox';
import { Page } from '@/models/page';
import useSystemSettingsStore from '@/stores/systemSettings';
import { FFCol, FFField, FFRow, FFSelect } from '@/uikit';
import { useState } from 'react';

const JavascriptTracking = ({ pages = [], idFunnel }: { pages: Page[]; idFunnel: string }) => {
  const { domains, userSettings } = useSystemSettingsStore();
  const [idPage, setIdPage] = useState('');
  const [domain, setDomain] = useState(userSettings.defaultCustomDomain);

  return (
    <FFCol gap="18px" height="max-content">
      <SectionBox title="Required: Universal JS Tag">
        <FFCol gap="12px">
          <FFRow>
            <FFField label="Select a domain:" direction="row" block>
              <FFSelect
                options={domains}
                valueGetter={(opt) => opt.domain}
                labelGetter={(opt) => opt.domain}
                value={domain}
                onSelect={setDomain}
                placeholder="Select a domain"
              />
            </FFField>
          </FFRow>
          <p>
            This script is required for direct linking. Place it just before {' </head> '}. We recommend adding this to all of your pages
            regardless as it improves tracking. This code already includes a page view event.
          </p>
          <CodeSnippet codeType="genericViewFull" domain={domain} />
        </FFCol>
      </SectionBox>
      <SectionBox title="Optional: View-only Event">
        <FFCol gap="12px">
          <p>
            If you have already loaded our universal JS tag and want to fire a singular page view event, you can use the following code. See
            our{' '}
            <a href="https://help.funnelflux.pro/article/105-javascript-tracking-of-page-views" rel="noreferrer" target="_blank">
              help documentation
            </a>{' '}
            for further methods/arguments.
          </p>
          <CodeSnippet codeType="genericViewSingle" maxContent />
        </FFCol>
      </SectionBox>
      <SectionBox title="Optional: Default Tracking Parameters">
        <FFCol gap="12px">
          <p>
            This code is optional and is NOT required for tracking to work. If you would like to track organic visits to your page, it needs
            to declare some defaults. You can embed the below code <em>before</em> our global snippet, to provide the tracking with default
            parameters. See our{' '}
            <a href="https://help.funnelflux.pro/article/108-setting-javascript-page-defaults" rel="noreferrer" target="_blank">
              help documentation
            </a>{' '}
            for more info.
          </p>
          <FFSelect
            value={idPage}
            options={pages}
            valueGetter={(opt) => opt.idPage!}
            labelGetter={(opt) => opt.pageName!}
            placeholder="Select the page"
            onSelect={setIdPage}
          />
          <CodeSnippet codeType="defaultParams" idPage={idPage} idFunnel={idFunnel} maxContent />
        </FFCol>
      </SectionBox>
    </FFCol>
  );
};

export default JavascriptTracking;
