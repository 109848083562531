import useHttp from '@/hooks/http';
import { OfferSource } from '@/models/offerSource';
import { OfferSourceCategoryInfo } from '@/models/offerSourceCategoryInfo';
import { useQuery } from '@tanstack/react-query';

export function useOfferSourceQuery(idOfferSource: string) {
  const { get } = useHttp();
  return useQuery({
    queryKey: ['offersource', idOfferSource],
    queryFn: async () => {
      const { data } = await get<OfferSource>('v1/offersource/find/byId', {
        params: {
          idOfferSource,
        },
      });
      return data;
    },
    enabled: !!idOfferSource,
  });
}

export function useOfferSourceCategoryListQuery(includeChilds = true) {
  const { get } = useHttp();
  return useQuery({
    queryKey: ['offersource', 'categoryList'],
    queryFn: async () => {
      const { data } = await get<OfferSourceCategoryInfo[]>('v1/offersource/category/list/', {
        params: {
          status: 'active',
          includeChilds,
        },
      });
      return data;
    },
  });
}
