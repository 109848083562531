const Lock = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 18">
    <path
      fill="currentColor"
      stroke="currentColor"
      fillRule="evenodd"
      d="M13.45 4.386a7.158 7.158 0 0 0-1.504-2.277A6.978 6.978 0 0 0 9.724.565 6.813 6.813 0 0 0 7 0c-.943 0-1.86.19-2.725.567A6.961 6.961 0 0 0 2.054 2.11 7.2 7.2 0 0 0 0 7.167v9.436C0 17.374.613 18 1.369 18h11.26c.756 0 1.369-.626 1.367-1.397V8.276L14 7.892v-.725c0-.962-.185-1.898-.55-2.781zM4.824 1.83h.004l.027-.01A5.26 5.26 0 0 1 7 1.368a5.37 5.37 0 0 1 2.145.446c.66.288 1.257.704 1.777 1.232a5.883 5.883 0 0 1 1.668 4.12v.593H1.409v-.596c0-.781.152-1.546.45-2.27.29-.697.7-1.316 1.218-1.845A5.557 5.557 0 0 1 4.825 1.83zm7.768 7.299v7.474a.083.083 0 0 1-.004.028H1.414a.083.083 0 0 1-.005-.028V9.13h11.184zM6.348 12.21v1.795c0 .351.294.637.655.637a.647.647 0 0 0 .656-.637v-1.8c.348-.216.555-.582.555-.987 0-.65-.544-1.178-1.213-1.178s-1.213.528-1.213 1.178c0 .4.214.777.56.992z"
    ></path>
  </svg>
);

export default Lock;