const Links = () => (
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
    <g id="copy-link">
        <path id="Subtract" fill="#d7e0ff" fillRule="evenodd" d="m5.74517 3.28038 -0.00143 0.00144C4.3714 4.6557 4.37187 6.88196 5.74517 8.25526c1.37378 1.37377 3.6011 1.37377 4.97483 0L12 6.9752V12c0 0.5523 -0.4477 1 -1 1H2c-0.55229 0 -1 -0.4477 -1 -1V3c0 -0.55229 0.44772 -1 1 -1h5.02545L5.74517 3.28038Z" clipRule="evenodd" strokeWidth="1"></path>
        <path id="Vector 3859" stroke="#4147d5" strokeLinecap="round" strokeLinejoin="round" d="m9.23242 4.76782 0.99998 -1" strokeWidth="1"></path>
        <path id="Vector" stroke="#4147d5" strokeLinecap="round" strokeLinejoin="round" d="M12 8.5V12c0 0.2652 -0.1054 0.5196 -0.2929 0.7071S11.2652 13 11 13H2c-0.26522 0 -0.51957 -0.1054 -0.70711 -0.2929C1.10536 12.5196 1 12.2652 1 12V3c0 -0.26522 0.10536 -0.51957 0.29289 -0.70711C1.48043 2.10536 1.73478 2 2 2h3.5" strokeWidth="1"></path>
        <path id="Vector 3857" stroke="#4147d5" strokeLinecap="round" strokeLinejoin="round" d="m9.48242 2.01777 0.5 -0.5c0.69038 -0.690359 1.80968 -0.690359 2.49998 0 0.6904 0.69035 0.6904 1.80964 0 2.5l-0.5 0.5" strokeWidth="1"></path>
        <path id="Vector 3858" stroke="#4147d5" strokeLinecap="round" strokeLinejoin="round" d="m9.98261 6.51782 -0.5 0.5c-0.69036 0.69036 -1.80964 0.69036 -2.5 0 -0.69036 -0.69035 -0.69035 -1.80964 0 -2.5l0.5 -0.5" strokeWidth="1"></path>
    </g>
</svg>
);

export default Links;