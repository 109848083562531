import { UserStoreProps, UserStore } from '@/types/stores/user';
import { isSubscriptionExpired, isSubscriptionStandby } from '@/utils/app';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

const DEFAULT_PROPS: UserStoreProps = {
  user: {
    user_id: '',
    permissions: [],
    email: '',
    email_verified: false,
    first_name: '',
    last_name: '',
    name: '',
    nickname: '',
    picture_url: '',
    updated_at: 0,
  },
  subscriptionStatus: {
    isExpired: false,
    isStandby: false,
  },
};

const useUserStore = create<UserStore>()(
  devtools(
    persist(
      (set) => ({
        ...DEFAULT_PROPS,
        setUser: (user) =>
          set({
            user,
            subscriptionStatus: {
              isExpired: user && isSubscriptionExpired(user),
              isStandby: user && isSubscriptionStandby(user),
            },
          }),
        resetUser: () => set(DEFAULT_PROPS),
      }),
      { name: 'user' },
    ),
  ),
);

export default useUserStore;
