import { useQuery } from '@tanstack/react-query';
import useHttp from '@/hooks/http';
import { User } from '@/models/user';
import useUserStore from '@/stores/user';

export function useUserQuery() {
  const { get } = useHttp();
  const setUser = useUserStore((state) => state.setUser);

  return useQuery({
    queryKey: ['user', 'data'],
    queryFn: async () => {
      const { data } = await get<User>('v1/user');
      setUser(data);
      return data;
    },
  });
}
