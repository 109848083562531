import { Select, SelectProps } from 'antd';
import { FFSelectOption } from '@/uikit/types/select';
import { defined, definedObject } from '@/utils/define';
import clsx from 'clsx';
import './style.scss';
import className from '@/utils/className';

const { blockClassName, getClass } = className('c-ffSelect');

const getOptions = <T, P extends string | number>(
  options: T[],
  valueGetter: (opt: T) => P,
  labelGetter: (opt: T) => string | JSX.Element,
  classNameGetter: (opt: T) => string | undefined,
  categoryNameGetter: (opt: T) => string,
  groupOptions: boolean,
  sortGroup: boolean,
  checkDisabled: (value: P) => boolean,
) => {
  let categorizedOptions: { [key: string]: T[] } = {};
  let result: JSX.Element[] = [];

  categorizedOptions.Uncategorized = [];
  options.forEach((opt) => {
    if (categoryNameGetter(opt)) {
      if (!categorizedOptions[categoryNameGetter(opt)]) {
        categorizedOptions[categoryNameGetter(opt)] = [];
      }
      categorizedOptions[categoryNameGetter(opt)].push(opt);
    } else {
      categorizedOptions.Uncategorized.push(opt);
    }
  });

  const categories = Object.keys(categorizedOptions);
  if (sortGroup) {
    categories.sort();
  }
  if (groupOptions) {
    categories.forEach((category) => {
      if (categorizedOptions[category].length > 0) {
        result.push(
          <Select.OptGroup label={category} key={category}>
            {categorizedOptions[category].map((opt) => (
              <Select.Option
                key={valueGetter(opt)}
                value={valueGetter(opt)}
                label={labelGetter(opt)}
                className={classNameGetter(opt)}
                disabled={checkDisabled(valueGetter(opt))}
              >
                {labelGetter(opt)}
              </Select.Option>
            ))}
          </Select.OptGroup>,
        );
      }
    });
  } else {
    Object.values(categorizedOptions).forEach((options) => {
      options.forEach((option) => {
        result.push(
          <Select.Option
            key={valueGetter(option)}
            value={valueGetter(option)}
            label={labelGetter(option)}
            disabled={checkDisabled(valueGetter(option))}
            className={classNameGetter(option)}
          >
            {labelGetter(option)}
          </Select.Option>,
        );
      });
    });
  }
  return result;
};

const FFSelect = <T, P extends string | number>({
  className,
  options,
  valueGetter,
  labelGetter,
  classNameGetter = () => undefined,
  categoryNameGetter = (opt: T) => (opt as any).category,
  categoryValueGetter = (opt: T) => '',
  onSetSelectedCategories = () => {},
  createNewView,
  selectAll = false,
  value,
  groupOptions = false,
  maxTagCount = 'responsive',
  sortGroup = false,
  defaultValueFallback,
  optionFilterProp = 'label',
  popupMatchSelectWidth = false,
  onChange = () => {},
  checkDisabled = () => false,
  error,
  ...props
}: Omit<SelectProps, 'options' | 'onChange'> & {
  options: T[];
  valueGetter: (opt: T) => P;
  labelGetter: (opt: T) => string | JSX.Element;
  onSetSelectedCategories?: (categories: string[]) => void;
  classNameGetter?: (opt: T) => string | undefined;
  categoryValueGetter?: (opt: T) => string;
  categoryNameGetter?: (opt: T) => string;
  onChange?: (val: any) => void;
  createNewView?: FFSelectOption;
  selectAll?: boolean;
  groupOptions?: boolean;
  sortGroup?: boolean;
  groupBy?: string;
  defaultValueFallback?: {
    label: string;
    value: string;
  };
  error?: string | boolean;
  checkDisabled?: (value: P) => boolean;
}) => {
  return (
    <div
      className={clsx(blockClassName, {
        [`${className}`]: defined(className),
      })}
    >
      <Select
        {...props}
        className={clsx([getClass('select'), className], {
          [`${className}__select`]: defined(className),
          'c-ffSelect--error': !!error,
        })}
        popupMatchSelectWidth={popupMatchSelectWidth}
        value={!value ? undefined : value}
        maxTagCount={maxTagCount}
        optionFilterProp={optionFilterProp}
        onChange={(value) => {
          if (onChange) {
            if (Array.isArray(value) && value.includes('selectAll')) {
              onChange(options.map(valueGetter));
              onSetSelectedCategories([...new Set(options.map((opt) => categoryValueGetter(opt)))]);
            } else if (Array.isArray(value) && value.includes('clearAll')) {
              onChange([]);
              onSetSelectedCategories([]);
            } else {
              onChange(value);
              onSetSelectedCategories([
                ...new Set(
                  options
                    .filter((option) => (typeof value === 'number' ? value === valueGetter(option) : value.includes(valueGetter(option))))
                    .map((opt) => categoryValueGetter(opt)),
                ),
              ]);
            }
          }
        }}
      >
        {definedObject(createNewView) && (
          <Select.Option key={createNewView.value} value={createNewView.value} className={getClass('createNewView')}>
            {createNewView.label}
          </Select.Option>
        )}

        {selectAll && Array.isArray(value) && value.length !== options.length && (
          <Select.Option key="selectAll" value="selectAll" className={getClass('selectAll')}>
            Select All
          </Select.Option>
        )}

        {selectAll && Array.isArray(value) && value.length === options.length && (
          <Select.Option key="clearAll" value="clearAll" className={getClass('clearAll')}>
            Clear All
          </Select.Option>
        )}

        {definedObject(defaultValueFallback) && (
          <Select.Option key={defaultValueFallback?.value} value={defaultValueFallback?.value} className={getClass('defaultFallback')}>
            {defaultValueFallback?.label}
          </Select.Option>
        )}

        {getOptions<T, P>(options, valueGetter, labelGetter, classNameGetter, categoryNameGetter, groupOptions, sortGroup, checkDisabled)}
      </Select>
    </div>
  );
};

export default FFSelect;
