const CampaignAnalysis = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30">
    <g id="data-analytics--business-product-analysis-chart-magnifier-search">
      <path
        id="Ellipse 650"
        fill="currentColor"
        d="M0.9953571428571428 11.944285714285714a10.94892857142857 10.94892857142857 0 1 0 21.89785714285714 0 10.94892857142857 10.94892857142857 0 1 0 -21.89785714285714 0"
        strokeWidth="2.13"
      ></path>
      <path
        id="Ellipse 651"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M0.9953571428571428 11.944285714285714a10.94892857142857 10.94892857142857 0 1 0 21.89785714285714 0 10.94892857142857 10.94892857142857 0 1 0 -21.89785714285714 0"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector 1950"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m19.89762724285714 19.897647149999997 5.982056614285715 5.982036707142857"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector 2175"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m6.4214072571428575 15.716350864285713 3.246755464285714 -4.4881848 4.7433346499999995 2.005365942857143 3.0557862428571427 -5.061132278571429"
        strokeWidth="2.13"
      ></path>
    </g>
  </svg>
);

export default CampaignAnalysis;
