const UpdateCosts = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30">
    <g id="desktop-dollar--cash-desktop-display-device-notification-computer-money-currency">
      <path
        id="Vector"
        fill="currentColor"
        strokeOpacity="0"
        d="M25.879285714285714 3.9814285714285713H1.9907142857142857c-0.5497198242857143 0 -0.9953571428571428 0.4456413 -0.9953571428571428 0.9953571428571428v15.925714285714285c0 0.5496362142857143 0.4456373185714286 0.9953571428571428 0.9953571428571428 0.9953571428571428h23.888571428571428c0.5496362142857143 0 0.9953571428571428 -0.44572092857142853 0.9953571428571428 -0.9953571428571428v-15.925714285714285c0 -0.5497158428571428 -0.44572092857142853 -0.9953571428571428 -0.9953571428571428 -0.9953571428571428Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_2"
        fill="#ffffff"
        d="M17.916428571428572 26.874642857142856 15.925714285714285 21.89785714285714H11.944285714285714l-1.9907142857142857 4.976785714285715h7.962857142857143Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_3"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m11.944285714285714 21.89785714285714 -1.9907142857142857 4.976785714285715"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_4"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m15.925714285714285 21.89785714285714 1.9907142857142857 4.976785714285715"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.962857142857143 26.874642857142856h11.944285714285714"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_6"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.4491771 3.9814285714285713H1.9907142857142857c-0.26398464 0 -0.5171577107142856 0.10487082857142857 -0.7038230078571428 0.2915401071428571C1.10022399 4.45961805 0.9953571428571428 4.7127970928571425 0.9953571428571428 4.976785714285715v15.925714285714285c0 0.2639687142857143 0.10486684714285714 0.5171875714285714 0.29153413499999997 0.7039165714285714C1.473556575 21.7929465 1.7267296457142858 21.89785714285714 1.9907142857142857 21.89785714285714h23.888571428571428c0.2639687142857143 0 0.5171875714285714 -0.10491064285714284 0.7039165714285714 -0.29144057142857144 0.18652992857142858 -0.18672899999999998 0.29144057142857144 -0.4399478571428571 0.29144057142857144 -0.7039165714285714v-1.9907142857142857"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector 3"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M26.72354764285714 5.4192418714285715c-0.11645678571428572 -0.32968219285714284 -0.29621828571428566 -0.6294638571428571 -0.5247522857142857 -0.8847530571428571 -0.4859333571428571 -0.5430270428571429 -1.1922387857142858 -0.8847729642857143 -1.978371857142857 -0.8847729642857143h-2.0546162142857143c-1.3084965 0 -2.3692287 1.0607720142857142 -2.3692287 2.3693083285714285 0 1.113426407142857 0.7752637714285714 2.076633514285714 1.862990057142857 2.3145636857142855l3.128009357142857 0.6842682214285714c1.2185162142857142 0.2665566428571428 2.087064857142857 1.3463997 2.087064857142857 2.5937613642857142 0 1.4659221857142857 -1.1882573571428572 2.6552545285714286 -2.654219357142857 2.6552545285714286h-1.7695459285714286c-1.1558087142857143 0 -2.1388234285714285 -0.7386147214285714 -2.5033232142857145 -1.7695260214285713"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector 2489"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M23.335551000000002 3.649636221428571V0.9953571428571428"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector 2490"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M23.335551000000002 16.9209918V14.26671272142857"
        strokeWidth="2.13"
      ></path>
    </g>
  </svg>
);

export default UpdateCosts;