const Export = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.57 -0.57 16 16" height="16" width="16">
    <g id="download-browser--action-actions-computer-website-device-display-download-monitor-screen">
      <path
        id="Vector"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.43 7.43v6.899285714285714"
        strokeWidth="1.14"
      ></path>
      <path
        id="Vector_2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.776428571428571 11.675714285714285 7.43 14.329285714285714 10.083571428571428 11.675714285714285"
        strokeWidth="1.14"
      ></path>
      <path
        id="Vector_3"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.6535714285714285 14.329285714285714h-1.0614285714285714c-0.2815120857142857 0 -0.5514864428571428 -0.11187457142857142 -0.7505435728571429 -0.31089242857142857C0.6425432157142857 13.819375428571428 0.5307142857142857 13.549348 0.5307142857142857 13.267857142857142v-11.675714285714285c0 -0.2815120857142857 0.11182893000000001 -0.5514864428571428 0.3108849985714286 -0.7505435728571429C1.0406564142857142 0.6425432157142857 1.3106307714285714 0.5307142857142857 1.592142857142857 0.5307142857142857h11.675714285714285c0.2814908571428571 0 0.5515182857142856 0.11182893000000001 0.7505361428571428 0.3108849985714286 0.19901785714285714 0.19905713 0.31089242857142857 0.4690314871428571 0.31089242857142857 0.7505435728571429v11.675714285714285c0 0.2814908571428571 -0.11187457142857142 0.5515182857142856 -0.31089242857142857 0.7505361428571428s-0.4690452857142857 0.31089242857142857 -0.7505361428571428 0.31089242857142857h-1.0614285714285714"
        strokeWidth="1.14"
      ></path>
      <path
        id="Vector_4"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M0.5307142857142857 3.715h13.798571428571428"
        strokeWidth="1.14"
      ></path>
    </g>
  </svg>
);

export default Export;