/**
 * FunnelFlux Pro Ledger API
 * API endpoints to manage customers' assets
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface Category { 
    /**
     * ID of category
     */
    idCategory: string;
    /**
     * Category name
     */
    categoryName: string;
    /**
     * Category type
     */
    categoryType: Category.CategoryTypeEnum;
    status?: Category.StatusEnum;
    /**
     * DEPRECATED
     */
    isArchived?: boolean;
}
export namespace Category {
    export type CategoryTypeEnum = 'offer' | 'lander' | 'offersources' | 'trafficsources' | 'condition';
    export const CategoryTypeEnum = {
        Offer: 'offer' as CategoryTypeEnum,
        Lander: 'lander' as CategoryTypeEnum,
        Offersources: 'offersources' as CategoryTypeEnum,
        Trafficsources: 'trafficsources' as CategoryTypeEnum,
        Condition: 'condition' as CategoryTypeEnum
    };
    export type StatusEnum = 'active' | 'archived' | 'deleted';
    export const StatusEnum = {
        Active: 'active' as StatusEnum,
        Archived: 'archived' as StatusEnum,
        Deleted: 'deleted' as StatusEnum
    };
}
