const DesktopSync = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30">
    <g id="desktop-sync--desktop-sync-arrow-refresh-device-display-computer">
      <path
        id="Vector"
        fill="#d7e0ff"
        stroke="transparent"
        d="M25.879285714285714 3.9814285714285713H1.9907142857142857c-0.5497198242857143 0 -0.9953571428571428 0.4456413 -0.9953571428571428 0.9953571428571428v15.925714285714285c0 0.5496362142857143 0.4456373185714286 0.9953571428571428 0.9953571428571428 0.9953571428571428h23.888571428571428c0.5496362142857143 0 0.9953571428571428 -0.44572092857142853 0.9953571428571428 -0.9953571428571428v-15.925714285714285c0 -0.5497158428571428 -0.44572092857142853 -0.9953571428571428 -0.9953571428571428 -0.9953571428571428Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_2"
        fill="#ffffff"
        d="M17.916428571428572 26.874642857142856 15.925714285714285 21.89785714285714H11.944285714285714l-1.9907142857142857 4.976785714285715h7.962857142857143Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_3"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.521641657142856 3.9814285714285713H1.9907142857142857c-0.26398464 0 -0.5171597014285715 0.10487082857142857 -0.7038249985714286 0.2915401071428571C1.10022399 4.45961805 0.9953571428571428 4.7127970928571425 0.9953571428571428 4.976785714285715v15.925714285714285c0 0.2639687142857143 0.10486684714285714 0.5171875714285714 0.2915321442857143 0.7039165714285714C1.4735545842857143 21.7929465 1.7267296457142858 21.89785714285714 1.9907142857142857 21.89785714285714h23.888571428571428c0.2639687142857143 0 0.5171875714285714 -0.10491064285714284 0.7039165714285714 -0.29144057142857144 0.18652992857142858 -0.18672899999999998 0.29144057142857144 -0.4399478571428571 0.29144057142857144 -0.7039165714285714V13.43152845"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_4"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m15.925714285714285 21.89785714285714 1.9907142857142857 4.976785714285715"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_5"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m11.944285714285714 21.89785714285714 -1.9907142857142857 4.976785714285715"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_6"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.90714285714286 26.874642857142856H7.962857142857143"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_7"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m26.874841928571428 7.3617012428571424 -1.6741907142857142 1.2654174428571427 -1.2654970714285714 -1.6741907142857142"
        strokeWidth="2.13"
      ></path>
      <path
        id="Ellipse 50"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M25.107485785714285 8.500927307142858c0.27651021428571426 -0.5310031285714285 0.4640355 -1.1197369714285714 0.540678 -1.749001757142857 0.34339821428571426 -2.8148301857142854 -1.6602557142857142 -5.375006209285714 -4.475125714285714 -5.718286971428571 -0.8908446428571428 -0.10864721357142856 -1.756247957142857 0.017794995 -2.533900585714286 0.3306437078571428"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_8"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m14.223693385714284 4.97660655 1.6741907142857142 -1.2653975357142857 1.2654174428571427 1.6741907142857142"
        strokeWidth="2.13"
      ></path>
      <path
        id="Ellipse 52"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.989974542857142 3.8375596499999998c-0.2763907714285714 0.5310230357142857 -0.46393596428571426 1.1197568785714287 -0.5406580928571428 1.7490216642857142 -0.34329867857142854 2.8148301857142854 1.6603154357142857 5.3750082 4.475145621428571 5.718286971428571 0.8908446428571428 0.1086531857142857 1.7562081428571428 -0.017796985714285715 2.533980214285714 -0.3306377357142857"
        strokeWidth="2.13"
      ></path>
    </g>
  </svg>
);

export default DesktopSync;