const ArrowRight3 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.855 -0.855 24 24">
    <g id="arrow-right-3--arrow-right-keyboard">
      <path
        id="Vector"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m6.1297181571428565 0.7960714285714285 9.791678571428571 9.791678571428571c0.07613627142857143 0.07147129285714285 0.13679691428571428 0.15779727857142858 0.17835184285714284 0.25364427857142857 0.041395714285714286 0.09583107857142857 0.06288964285714285 0.19916115 0.06288964285714285 0.3036057214285714 0 0.10444457142857143 -0.02149392857142857 0.20777464285714287 -0.06288964285714285 0.3036057214285714 -0.04155492857142857 0.09584699999999999 -0.10221557142857142 0.1821729857142857 -0.17835184285714284 0.25364427857142857l-9.791678571428571 9.791678571428571"
        strokeWidth="1.71"
      ></path>
    </g>
  </svg>
);

export default ArrowRight3;