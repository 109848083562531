import { defined } from '@/utils/define';
import { getTZ, timeRangeItems } from '@/constants/time';
import { TimeRangeKeys } from '@/types/time';
import { DateTime } from 'luxon';
import dayjs, { Dayjs } from 'dayjs';

type NoUndefinedRangeValueType = [start: Dayjs, end: Dayjs];

export interface DatePickerValues {
  date: Dayjs[];
  timeZone: string;
  timeStart: string;
  timeEnd: string;
  isChangedByCalendar: boolean;
}

export const getTimeZone = () => {
  let timeZone = localStorage.getItem('timeZone') || '+00:00';

  if (!defined(timeZone)) {
    timeZone = dayjs().format('Z');
    localStorage.setItem('timeZone', timeZone);
  }
  return timeZone;
};

export const setTimezone = (timeZone: string) => {
  localStorage.setItem('timeZone', timeZone);
};

export const changeTimezoneOffset = (datetimeStr: string, newOffset: string) => {
  const regex = /(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})([+-]\d{2}:\d{2})/;
  const match = datetimeStr.match(regex);

  if (match) {
    return match[1] + newOffset;
  } else {
    return datetimeStr;
  }
};

export const getDatePickerValues = (date: NoUndefinedRangeValueType = [dayjs().startOf('day'), dayjs().endOf('day')], withTimeZone = false): DatePickerValues => {
  const timeZone = getTimeZone();
  let timeStart = date[0]!;
  let timeEnd = date[1]!;
  if (withTimeZone) {
    timeStart = timeStart.tz(getTZ(timeZone));
    timeEnd = timeEnd.tz(getTZ(timeZone));
  }

  return {
    date: [date[0]!, date[1]!],
    timeZone,
    timeStart: `${timeStart ? `${timeStart.format('YYYY-MM-DDTHH:mm:ss')}${timeZone}` : ''}`,
    timeEnd: `${timeEnd ? `${timeEnd.format('YYYY-MM-DDTHH:mm:ss')}${timeZone}` : ''}`,
    isChangedByCalendar: false,
  };
};

export const withoutTimeZone = (date: string = '') => {
  return date.trim().replace(/([+-])(\d{2}):(\d{2})$/g, '');
};

export const extractTimezone = (date: string) => {
  return date.match(/([+-])(\d{2}):(\d{2})$/g)?.[0] || '';
};

export const getDateRangeName = (date: Dayjs[]) => {
  let value: TimeRangeKeys | null = null;

  switch (true) {
    case date === timeRangeItems['Last 24 h']:
      value = 'Last 24 h';
      break;
    case date === timeRangeItems['Last 72 h']:
      value = 'Last 72 h';
      break;
    case date === timeRangeItems['Today']:
      value = 'Today';
      break;
    case date === timeRangeItems['Yesterday']:
      value = 'Yesterday';
      break;
    case date === timeRangeItems['Last 30 days']:
      value = 'Last 30 days';
      break;
    case date === timeRangeItems['Last 7 days']:
      value = 'Last 7 days';
      break;
    case date === timeRangeItems['This Year']:
      value = 'This Year';
      break;
    default:
      value = null;
  }

  return value;
};

export const convertArrayStringToMomentRangeDate = (date: [string, string]): Dayjs[] => {
  return [dayjs(date[0]), dayjs(date[1])];
};

export const dateIsExpired = (date: string) => dayjs(getDatePickerValues().timeEnd).diff(dayjs(date), 'days') >= 2;

export const canUpdateEndTime = (timeRange: TimeRangeKeys) => timeRange === 'Last 24 h' || timeRange === 'Last 72 h';

export const isCurrentTimeAfterFromEndTime = (timeEnd: string, currentDate: string, timeRange: TimeRangeKeys) => {
  if (!canUpdateEndTime(timeRange)) {
    return false;
  }
  return dayjs(currentDate).isAfter(dayjs(timeEnd));
};

export const hours24Ranges = () => {
  const hours24Range: string[] = [];
  for (let i = 0; i < 24; i++) {
    const getRangeValue = (n: number) => {
      if (n === 24) {
        return `00:00`;
      }
      return n < 10 ? `0${n}:00` : `${n}:00`;
    };
    hours24Range.push(`${getRangeValue(i)}-${getRangeValue(i + 1)}`);
  }

  return hours24Range;
};

export const compareDates = (date: number) => {
  return Date.now() > date;
};

export const convertToISO = (dateString: string, timeZone: string) => {
  const possibleFormats = [
    'dd/LL/yyyy',
    'dd-LL-yyyy',
    'LL/dd/yyyy',
    'LL-dd-yyyy',
    'yyyy/LL/dd',
    'yyyy-LL-dd',
    'dd LLL yyyy',
    'dd LLLL yyyy',
    'LLLL dd, yyyy',
    'EEE, dd LLL yyyy',
    'EEEE, dd LLLL yyyy',
    'dd.LL.yyyy',
  ];
  let date = '';
  for (const format of possibleFormats) {
    const parsed = DateTime.fromFormat(dateString, format);
    if (parsed.isValid) {
      date = parsed.toISO().replace(/([-+]\d{2}:\d{2})$/g, timeZone);
      date = date.replace(/.000/g, '');
      break;
    }
  }

  if (!date) {
    throw new Error('Invalid date format');
  }

  return {
    date: dateString,
    timeStart: date,
    timeEnd: date.replace(/T00:00:00/g, 'T23:59:59'),
  };
};

export const replaceTimezone = (date: string, timezone: string) => {
  return date.replace(/[+-](?:2[0-3]|[01][0-9]):[0-5][0-9]/g, timezone);
};
