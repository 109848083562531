import { TrafficSource } from '@/models/trafficSource';
import { FFCol, FFField, FFInput } from '@/uikit';
import { Control, Controller } from 'react-hook-form';

const MicrosoftAdsCustomScenario = ({ triggeringEvent, control }: { triggeringEvent: string; control: Control<TrafficSource> }) => {
  return (
    <FFCol gap="12px">
      <Controller
        name={`conversionTrackingSettings.${triggeringEvent}.customEventData.adAccountId`}
        control={control}
        render={(opt) => (
          <FFField label="Ad Account ID:" block direction="row">
            <FFInput
              value={opt.field.value}
              onChange={(e) => opt.field.onChange(e.target.value.trim())}
              error={opt.fieldState.error?.message}
              placeholder="Ad Account ID (no hyphens)"
              style={{ width: 280 }}
            />
          </FFField>
        )}
      />
      <Controller
        name={`conversionTrackingSettings.${triggeringEvent}.customEventData.customerId`}
        control={control}
        render={(opt) => (
          <FFField label="Your Customer ID:" block direction="row">
            <FFInput
              value={opt.field.value}
              onChange={(e) => opt.field.onChange(e.target.value.trim())}
              error={opt.fieldState.error?.message}
              placeholder="Your customer ID (no hyphens)"
              style={{ width: 280 }}
            />
          </FFField>
        )}
      />
      <Controller
        name={`conversionTrackingSettings.${triggeringEvent}.customEventData.conversionGoalId`}
        control={control}
        render={(opt) => (
          <FFField label="Conversion Goal ID:" block direction="row">
            <FFInput
              value={opt.field.value}
              onChange={(e) => opt.field.onChange(e.target.value.trim())}
              error={opt.fieldState.error?.message}
              placeholder="Your conversion goal ID"
              style={{ width: 280 }}
            />
          </FFField>
        )}
      />
      <Controller
        name={`conversionTrackingSettings.${triggeringEvent}.customEventData.refreshToken`}
        control={control}
        render={(opt) => (
          <FFField label="Refresh token:" block direction="row">
            <FFInput
              value={opt.field.value}
              onChange={(e) => opt.field.onChange(e.target.value.trim())}
              error={opt.fieldState.error?.message}
              placeholder="Your generated refresh token"
              style={{ width: 280 }}
            />
          </FFField>
        )}
      />
    </FFCol>
  );
};

export default MicrosoftAdsCustomScenario;
