/**
 * FunnelFlux Pro Ledger API
 * API endpoints to manage customers' assets
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FluxifyParams } from './fluxifyParams';
import { Meta } from './meta';
import { OfferParams } from './offerParams';
export interface Page { 
    /**
     * ID of the page
     */
    idPage: string;
    /**
     * What kind of page. If 'offer' then 'offerParams' is required
     */
    pageType: Page.PageTypeEnum;
    /**
     * Page's name.
     */
    pageName: string;
    /**
     * Redirects to this URL when page is displayed
     */
    baseURL: string;
    /**
     * Query parameters to add to the base url. Tokens can be used as values.
     */
    queryParams?: { [key: string]: string; };
    /**
     * How to redirect to the page's URL. 'umr' means 'Ultimate Meta Refresh'. 'fluxify' for cloaking the page with advanced settings. If 'fluxify' is used, then 'fluxifyParams' is required. By default, it uses the redirect type mentioned in the system settings for landers and offers.
     */
    redirectType: Page.RedirectTypeEnum;
    /**
     * Category ID. Empty if uncategorized
     */
    idCategory?: string;
    /**
     * Optional notes for this page
     */
    notes?: string;
    offerParams?: OfferParams;
    fluxifyParams?: FluxifyParams;
    disableAppendVid: boolean;
    status?: Page.StatusEnum;
    meta?: Meta;
}
export namespace Page {
    export type PageTypeEnum = 'lander' | 'offer';
    export const PageTypeEnum = {
        Lander: 'lander' as PageTypeEnum,
        Offer: 'offer' as PageTypeEnum
    };
    export type RedirectTypeEnum = '301' | '307' | 'umr' | 'fluxify' | 'meta';
    export const RedirectTypeEnum = {
        _301: '301' as RedirectTypeEnum,
        _307: '307' as RedirectTypeEnum,
        Umr: 'umr' as RedirectTypeEnum,
        Meta: 'meta' as RedirectTypeEnum,
        Fluxify: 'fluxify' as RedirectTypeEnum
    };
    export type StatusEnum = 'active' | 'archived' | 'deleted';
    export const StatusEnum = {
        Active: 'active' as StatusEnum,
        Archived: 'archived' as StatusEnum,
        Deleted: 'deleted' as StatusEnum
    };
}
