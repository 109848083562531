import { forwardRef } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Input from 'antd/lib/input/Input';
import { InputRef, Tooltip } from 'antd';
import { FFInputProps } from '@/uikit/types/input';
import className from '@/utils/className';
import clsx from 'clsx';
import './style.scss';

const { blockClassName, getClass } = className('c-ffInput');

const hasError = (error?: string | boolean) => error && typeof error === 'string' && error.length > 0;

const FFInput = forwardRef<InputRef, FFInputProps>(
  ({ disabled, value, onChange, style, className = '', placeholder, error, readOnly = false, ...props }, ref) => {
    return (
      <Input
        readOnly={readOnly}
        className={clsx([blockClassName, className], {
          [`${blockClassName}--readOnly`]: readOnly,
        })}
        disabled={disabled}
        value={value}
        onChange={onChange}
        style={style}
        status={hasError(error) ? 'error' : ''}
        placeholder={placeholder}
        ref={ref}
        suffix={
          hasError(error) ? (
            <Tooltip placement="top" title={error} className={getClass('tooltip')} overlayClassName={getClass('tooltipOverlay')}>
              <ExclamationCircleOutlined style={{ color: '#ff4d4f' }} />
            </Tooltip>
          ) : (
            <></>
          )
        }
        {...props}
      />
    );
  },
);

export default FFInput;
