import { defined } from './define';
import { TrackingField } from '@/models/trackingFields';
import { sortObjectArrayByKeyValue } from './arrs';

interface StringObject {
  [key: string]: string;
}

export const getQueryUrlSeparate = (value: string, url: string = '') => (url && url.indexOf('?') > -1 && value ? '&' : value ? '?' : '');

export const splitQueryUrl = (value: string): StringObject => {
  let result: StringObject = {};
  value.split('&').forEach((value: string) => {
    const values = value.split('=');
    result[values[0] || ''] = values[1] || '';
  });
  return result;
};

export const makeQueryUrl = (object?: StringObject, hasSeparator = false) => {
  let result = '';
  let params: string[] = [];
  if (!!object) {
    params = Object.entries(object).reduce((acc: string[], [key, value]: any) => {
      if (!key || !value) return acc;
      return (acc = [...acc, `${key}=${value}`]);
    }, []);
  }

  if (hasSeparator) {
    result = params.join('&');
  } else {
    result = params.join('');
  }

  return result;
};

export const decodeURLParams = (search?: string) => {
  if (defined(search)) {
    const hashes = search.slice(search.indexOf('?') + 1).split('&');
    return hashes.reduce((params, hash) => {
      const split = hash.indexOf('=');
      const key = hash.slice(0, split);

      if (key !== '') {
        const val = hash.slice(split + 1);
        return { ...params, ...{ [key]: decodeURIComponent(val) } };
      }

      return params;
    }, {});
  }

  return {};
};

export const getByTrackingFieldSlotsQuery = (trackingFieldSlotsValues: TrackingField[], cost: string) => {
  if (defined(trackingFieldSlotsValues) && trackingFieldSlotsValues.length) {
    const campaignNameKey = 'campaign';
    const externalNameKey = 'external';
    sortObjectArrayByKeyValue(trackingFieldSlotsValues, 'name', [externalNameKey, campaignNameKey]);

    const trackingFieldSlotsQuery = trackingFieldSlotsValues
      .reduce((acc: string[], crr: TrackingField) => {
        const shouldAdd = !!crr.value && !!crr.name && (!crr.status || crr.status === 'active');
        const costIsAdded = !!cost && acc.includes(`c=${cost}`);
        const filledValuesNameKeys = trackingFieldSlotsValues.filter((t) => !!t.value).map((r) => r.name);
        const getCostQuery = (cost: string) => `c=${cost}`;
        const hasCost = !!cost && cost !== '0';

        if (hasCost && !filledValuesNameKeys.includes(campaignNameKey) && !filledValuesNameKeys.includes(externalNameKey) && !costIsAdded) {
          acc.push(getCostQuery(cost));
        }

        if (shouldAdd) {
          acc.push(`${crr.name}=${crr.value}`);
        }

        if (hasCost && shouldAdd && !costIsAdded) {
          if (
            filledValuesNameKeys.includes(campaignNameKey) &&
            filledValuesNameKeys.includes(externalNameKey) &&
            crr.name === campaignNameKey
          ) {
            return acc;
          } else if (crr.name === 'external') {
            acc.push(getCostQuery(cost));
          } else if (crr.name === 'campaign') {
            acc.push(getCostQuery(cost));
          }
        }

        return acc;
      }, [])
      .join('&');

    return trackingFieldSlotsQuery;
  }
  return '';
};

export const addUrlSlash = (baseUrl: string) => {
  let url = baseUrl;
  if (baseUrl.indexOf('?') === -1) {
    if (baseUrl[baseUrl.length - 1] !== '/') {
      url = baseUrl;
    }
  } else {
    const splitUrl = url.split('?');
    if (splitUrl[0][splitUrl[0].length - 1] !== '/') {
      url = `${splitUrl[0]}?${splitUrl[1]}`;
    }
  }

  return url;
};

export const getHostname = (url: string) => {
  return new URL(url).hostname;
};

export function objectToQueryString(obj: Record<string, any>) {
  const queryString = Object.keys(obj)
    .map((key) => `${key}=${obj[key]}`)
    .join('&');
  return queryString;
}
