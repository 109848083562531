import {
  ConditionRuleSelectParams,
  connectionTypeParams,
  continentSelectParams,
  countrySelectParams,
  dayOfMonthParams,
  dayOfWeekParams,
  deviceBrowserLanguageParams,
  deviceOsSelectParams,
  deviceTypeSelectParams,
  monthOfYearParams,
  timezoneSelectParams,
} from '@/constants/conditions';
import { FunnelCondition } from '@/models/funnelCondition';
import { FunnelConditionRule } from '@/models/funnelConditionRule';
import { DomainEntry } from '@/models/models';
import { FFField, FFInput, FFSelect } from '@/uikit';
import { defined } from '@/utils/define';
import { sortBasedOnOrder, sortByName } from '@/utils/sort';
import { DatePicker, TimePicker } from 'antd';
import { PropsWithChildren } from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';
import { FFSelectOption } from '@/uikit/types/select';
import { getConditionRuleDate, getConditionRuleTime } from '../../utils';

interface Props {
  rule: FunnelConditionRule;
  idRoute: number;
  idGroup: number;
  idRule: number;
  trafficSources: FFSelectOption[];
  domains: DomainEntry[];
  control: Control<FunnelCondition, any>;
}

const getSelectParams = (
  attribute: FunnelConditionRule.AttributeEnum,
  trafficSources: FFSelectOption[] = [],
  domains: DomainEntry[] = [],
): ConditionRuleSelectParams => {
  switch (attribute) {
    case FunnelConditionRule.AttributeEnum.LocationContinent:
      return continentSelectParams;
    case FunnelConditionRule.AttributeEnum.LocationCountry:
      return countrySelectParams;
    case FunnelConditionRule.AttributeEnum.LocationTimezone:
      return timezoneSelectParams;
    case FunnelConditionRule.AttributeEnum.DeviceType:
      return deviceTypeSelectParams;
    case FunnelConditionRule.AttributeEnum.DeviceOS:
      return deviceOsSelectParams;
    case FunnelConditionRule.AttributeEnum.DeviceBrowserLanguage:
      return deviceBrowserLanguageParams;
    case FunnelConditionRule.AttributeEnum.ConnectionType:
      return connectionTypeParams;
    case FunnelConditionRule.AttributeEnum.TimeDayOfWeek:
      return dayOfWeekParams;
    case FunnelConditionRule.AttributeEnum.TimeDayOfMonth:
      return dayOfMonthParams;
    case FunnelConditionRule.AttributeEnum.TimeMonthOfYear:
      return monthOfYearParams;
    case FunnelConditionRule.AttributeEnum.TrafficSource:
      return {
        defaultValueFallback: {
          label: 'Organic traffic',
          value: 'organic',
        },
        options: sortByName(trafficSources, 'label'),
        valueGetter: (opt: FFSelectOption) => opt.value,
        labelGetter: (opt: FFSelectOption) => opt.label,
      };
    case FunnelConditionRule.AttributeEnum.TrackingDomain:
      return {
        options: sortBasedOnOrder(domains, 'domain'),
        valueGetter: (opt: DomainEntry) => opt.domain,
        labelGetter: (opt: DomainEntry) => opt.domain,
      };
    default:
      return {
        options: [],
        labelGetter: (opt) => opt,
        valueGetter: (opt) => opt,
      };
  }
};

const RouteGroupRule = ({ rule, idRoute, idGroup, idRule, trafficSources, domains, control, children }: PropsWithChildren<Props>) => {
  const isPlural = [
    FunnelConditionRule.TestEnum.AnyIn,
    FunnelConditionRule.TestEnum.NotIn,
    FunnelConditionRule.TestEnum.Contains,
    FunnelConditionRule.TestEnum.DoesNotContain,
  ].includes(rule.test);
  const condition = useWatch({
    control,
  });
  const attribute = condition.routes?.[idRoute]?.groups?.[idGroup]?.rules?.[idRule].attribute;

  switch (attribute) {
    case FunnelConditionRule.AttributeEnum.LocationContinent:
    case FunnelConditionRule.AttributeEnum.LocationCountry:
    case FunnelConditionRule.AttributeEnum.LocationTimezone:
    case FunnelConditionRule.AttributeEnum.DeviceType:
    case FunnelConditionRule.AttributeEnum.DeviceOS:
    case FunnelConditionRule.AttributeEnum.DeviceBrowserLanguage:
    case FunnelConditionRule.AttributeEnum.ConnectionType:
    case FunnelConditionRule.AttributeEnum.TrafficSource:
    case FunnelConditionRule.AttributeEnum.TrackingDomain:
      return (
        <>
          {children}
          <FFField block>
            <Controller
              name={`routes.${idRoute}.groups.${idGroup}.rules.${idRule}.genericParams.values`}
              control={control}
              shouldUnregister
              rules={{ required: 'Required' }}
              render={(opt) => (
                <FFSelect
                  {...getSelectParams(rule.attribute, trafficSources, domains)}
                  onChange={(values) => {
                    opt.field.onChange(Array.isArray(values) ? values : [values]);
                  }}
                  value={opt.field.value}
                  showSearch
                  autoFocus
                  placeholder="Select value(s)"
                  mode={isPlural ? 'multiple' : undefined}
                  error={opt.fieldState.error?.message}
                />
              )}
            />
          </FFField>
        </>
      );
    case FunnelConditionRule.AttributeEnum.LocationCity:
    case FunnelConditionRule.AttributeEnum.LocationRegion:
    case FunnelConditionRule.AttributeEnum.DeviceBrand:
    case FunnelConditionRule.AttributeEnum.DeviceOSVersion:
    case FunnelConditionRule.AttributeEnum.DeviceModel:
    case FunnelConditionRule.AttributeEnum.DeviceBrowser:
    case FunnelConditionRule.AttributeEnum.DeviceBrowserVersion:
    case FunnelConditionRule.AttributeEnum.ConnectionIP:
    case FunnelConditionRule.AttributeEnum.ConnectionISP:
    case FunnelConditionRule.AttributeEnum.ConnectionCarrier:
    case FunnelConditionRule.AttributeEnum.ConnectionUserAgent:
    case FunnelConditionRule.AttributeEnum.ConnectionReferrer:
    case FunnelConditionRule.AttributeEnum.ConnectionCurrentURL:
      return (
        <>
          {children}
          <FFField block>
            <Controller
              name={`routes.${idRoute}.groups.${idGroup}.rules.${idRule}.genericParams.values`}
              shouldUnregister
              control={control}
              rules={{ required: 'Required' }}
              render={(opt) => (
                <FFInput
                  error={opt.fieldState.error?.message}
                  onChange={(e) => {
                    opt.field.onChange(e.target.value.split(','));
                  }}
                  value={opt.field.value}
                  placeholder="Select value(s)"
                />
              )}
            />
          </FFField>
        </>
      );
    case FunnelConditionRule.AttributeEnum.TimeDate:
      return (
        <>
          {children}
          <FFField block>
            <Controller
              name={`routes.${idRoute}.groups.${idGroup}.rules.${idRule}.timeDateParams`}
              control={control}
              rules={{ required: 'Required' }}
              render={(opt) => (
                <DatePicker
                  status={opt.fieldState.error?.message ? 'error' : undefined}
                  onChange={(date) => {
                    if (defined(date)) {
                      opt.field.onChange({
                        day: date.date(),
                        month: date.month() + 1,
                        year: date.year(),
                      });
                    }
                  }}
                  value={getConditionRuleDate(opt.field.value?.day, opt.field.value?.month, opt.field.value?.year).date}
                  showTime={false}
                  format="DD.MM.YYYY"
                />
              )}
            />
          </FFField>
        </>
      );
    case FunnelConditionRule.AttributeEnum.TimeDayOfWeek:
      return (
        <>
          {children}
          <FFField block>
            <Controller
              name={`routes.${idRoute}.groups.${idGroup}.rules.${idRule}.timeDayOfWeekParams.day`}
              control={control}
              rules={{ required: 'Required' }}
              render={(opt) => (
                <FFSelect
                  {...getSelectParams(rule.attribute)}
                  onChange={(value) => opt.field.onChange(value)}
                  value={opt.field.value}
                  showSearch
                  autoFocus
                  placeholder="Select value(s)"
                  mode={isPlural ? 'multiple' : undefined}
                  error={opt.fieldState.error?.message}
                />
              )}
            />
          </FFField>
        </>
      );
    case FunnelConditionRule.AttributeEnum.TimeDayOfMonth:
      return (
        <>
          {children}
          <FFField block>
            <Controller
              name={`routes.${idRoute}.groups.${idGroup}.rules.${idRule}.timeDayOfMonthParams.day`}
              control={control}
              rules={{ required: 'Required' }}
              render={(opt) => (
                <FFField block>
                  <FFSelect
                    {...getSelectParams(rule.attribute)}
                    onChange={(value) => opt.field.onChange(Number(value))}
                    value={opt.field.value}
                    showSearch
                    autoFocus
                    placeholder="Select value(s)"
                    mode={isPlural ? 'multiple' : undefined}
                    error={opt.fieldState.error?.message}
                  />
                </FFField>
              )}
            />
          </FFField>
        </>
      );
    case FunnelConditionRule.AttributeEnum.TimeMonthOfYear:
      return (
        <>
          {children}
          <FFField block>
            <Controller
              name={`routes.${idRoute}.groups.${idGroup}.rules.${idRule}.timeMonthOfYearParams.month`}
              control={control}
              rules={{ required: 'Required' }}
              render={(opt) => (
                <FFField block>
                  <FFSelect
                    {...getSelectParams(rule.attribute)}
                    onChange={(value) => opt.field.onChange(value)}
                    value={opt.field.value}
                    showSearch
                    autoFocus
                    placeholder="Select value(s)"
                    mode={isPlural ? 'multiple' : undefined}
                    error={opt.fieldState.error?.message}
                  />
                </FFField>
              )}
            />
          </FFField>
        </>
      );
    case FunnelConditionRule.AttributeEnum.TimeTimeOfDay:
      return (
        <>
          {children}
          <FFField block>
            <Controller
              name={`routes.${idRoute}.groups.${idGroup}.rules.${idRule}.timeOfDayParams`}
              control={control}
              rules={{ required: 'Required' }}
              render={(opt) => (
                <TimePicker
                  value={getConditionRuleTime(opt.field.value?.hour, opt.field.value?.minutes).time}
                  format="HH:mm"
                  onChange={(time) => {
                    const value = defined(time) ? { hour: time.hour(), minutes: time.minute() } : { hour: null, minutes: null };
                    opt.field.onChange(value);
                  }}
                  placeholder="Select Time"
                />
              )}
            />
          </FFField>
        </>
      );
    case FunnelConditionRule.AttributeEnum.TrackingField:
      return (
        <>
          <Controller
            name={`routes.${idRoute}.groups.${idGroup}.rules.${idRule}.trackingFieldParams.fieldName`}
            control={control}
            rules={{ required: 'Required' }}
            render={(opt) => (
              <FFInput
                value={opt.field.value}
                error={opt.fieldState.error?.message}
                onChange={(e) => opt.field.onChange(e.target.value)}
                placeholder="Enter Field Name"
              />
            )}
          />
          {children}
          <Controller
            name={`routes.${idRoute}.groups.${idGroup}.rules.${idRule}.trackingFieldParams.fieldValues`}
            control={control}
            rules={{ required: 'Required' }}
            render={(opt) => (
              <FFInput
                value={opt.field.value}
                error={opt.fieldState.error?.message}
                onChange={(e) => opt.field.onChange(e.target.value.split(','))}
                placeholder="Enter Value(s)"
              />
            )}
          />
        </>
      );
    default:
      return <></>;
  }
};

export default RouteGroupRule;
