const CopyLink = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.855 -0.855 24 24">
    <g id="copy-link">
      <path
        id="Vector 1007"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m14.84627042142857 3.1181799428571426 1.337543292857143 -1.3376069785714286c1.214805 -1.214717432142857 3.184285714285714 -1.21471584 4.3989315 0 1.214805 1.2147253928571429 1.214805 3.184190185714286 0 4.398915578571429l-1.3375592142857142 1.337591057142857"
        strokeWidth="1.71"
      ></path>
      <path
        id="Vector 1008"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M17.10948557142857 5.254151035714286 14.46689462142857 7.896694221428571"
        strokeWidth="1.71"
      ></path>
      <path
        id="Vector 1009"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m12.388049614285713 5.576782864285714 -1.337591057142857 1.337591057142857c-1.2147253928571429 1.2147253928571429 -1.2147253928571429 3.1841742642857143 0 4.398899657142857s3.184190185714286 1.2147253928571429 4.398899657142857 0l1.3375592142857142 -1.337591057142857"
        strokeWidth="1.71"
      ></path>
      <path
        id="Vector 1010"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.394436835714285 3.8248684714285717H3.7286712C2.109047957142857 3.8248684714285717 0.7960714285714285 5.1378449999999996 0.7960714285714285 6.757484164285715V18.487962857142858c0 1.6195277142857143 1.3129765285714285 2.932567928571429 2.9325997714285714 2.932567928571429h11.730415007142856c1.619671007142857 0 2.9325520071428572 -1.3130402142857143 2.9325520071428572 -2.932567928571429V14.822133535714284"
        strokeWidth="1.71"
      ></path>
    </g>
  </svg>
);

export default CopyLink;
