import useHttp from '@/hooks/http';
import { Category } from '@/models/category';
import { OfferSource } from '@/models/offerSource';
import { ReportCategory } from '@/models/reportCategory';
import { useQuery } from '@tanstack/react-query';

const categoryUrls: { [key: string]: string } = {
  offer: 'v1/category/find/byStatus/?status=not-deleted&categoryType=offer',
  offersources: 'v1/category/find/byStatus/?status=not-deleted&categoryType=offersources',
  lander: 'v1/category/find/byStatus/?status=not-deleted&categoryType=lander',
  trafficsources: 'v1/category/find/byStatus/?status=not-deleted&categoryType=trafficsources',
  condition: 'v1/category/find/byStatus/?status=not-deleted&categoryType=condition',
};

export function useCategoriesQuery(type: ReportCategory.TypeEnum | 'condition') {
  const { get } = useHttp();
  return useQuery({
    queryKey: ['categoryForm', 'categories'],
    queryFn: async () => {
      const { data } = await get<Category[]>(categoryUrls[type]);
      return data;
    },
  });
}

export function useCategoryQuery(idCategory: string) {
  const { get } = useHttp();
  return useQuery({
    queryKey: ['category', idCategory],
    queryFn: async () => {
      const { data } = await get<OfferSource>('v1/category/find/byId', {
        params: {
          idCategory,
        },
      });
      return data;
    },
    enabled: !!idCategory,
  });
}
