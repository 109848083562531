import { AppStore, AppStoreProps } from '@/types/stores/app';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

const DEFAULT_PROPS: AppStoreProps = {
  isSidebarExpanded: true,
  quickStatsReportParams: null,
  useCompactMode: false,
  fullRowHighlighting: false,
  showColumnBorders: false,
};

const useAppStore = create<AppStore>()(
  devtools(
    persist(
      (set) => ({
        ...DEFAULT_PROPS,
        toggleSidebar: () =>
          set((state) => {
            state.isSidebarExpanded = !state.isSidebarExpanded;
            return state;
          }),
        setQuickStatsReportParams: (params) =>
          set((state) => {
            state.quickStatsReportParams = params;
            return state;
          }),
        toggleUseCompactMode: (useCompactMode: boolean) => set({ useCompactMode }),
        toggleFullRowHighlighting: (fullRowHighlighting: boolean) => set({ fullRowHighlighting }),
        toggleShowColumnBorders: (showColumnBorders: boolean) => set({ showColumnBorders }),
      }),
      {
        name: 'app',
        partialize: (state) => ({
          isSidebarExpanded: state.isSidebarExpanded,
          useCompactMode: state.useCompactMode,
          fullRowHighlighting: state.fullRowHighlighting,
          showColumnBorders: state.showColumnBorders,
        }),
      },
    ),
  ),
);

export default useAppStore;
