import React from 'react';

const TrafficSources = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30">
    <g id="ai-network-spark--globe-internet-world-artificial-intelligence-ai">
      <path
        id="Ellipse 1512"
        fill="currentColor"
        d="M1.03119 14.374947857142857a11.944285714285714 11.944285714285714 0 1 0 23.888571428571428 0 11.944285714285714 11.944285714285714 0 1 0 -23.888571428571428 0"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M24.919363285714283 14.374529807142856c0 6.5966498357142855 -5.347655785714286 11.94430562142857 -11.94430562142857 11.94430562142857 -6.5966498357142855 0 -11.944281732857142 -5.347655785714286 -11.944281732857142 -11.94430562142857 0 -6.596629928571428 5.347631897142857 -11.944285714285714 11.944281732857142 -11.944285714285714"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1.0307759314285714 14.374529807142856H14.812646207142857"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_3"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12.975157199999998 2.430244092857143c-2.759468421428571 3.3933914785714285 -4.368303985714285 7.576379871428571 -4.593971357142857 11.944285714285714 0.22566737142857143 4.36792575 1.8345029357142857 8.55093405 4.593971357142857 11.94430562142857 2.172486407142857 -2.6715385714285715 3.6317994214285716 -5.832593785714286 4.264448421428572 -9.187922807142856"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector 2136"
        fill="#ffffff"
        d="M14.727602892857142 8.387177892857142c-0.6986013642857143 -0.12153310714285714 -0.6986013642857143 -1.1243952428571429 0 -1.24592835 2.530894607142857 -0.44032609285714286 4.543884985714286 -2.3680541785714286 5.093222592857143 -4.877548607142857l0.042123514285714286 -0.19236272142857141c0.15109521428571426 -0.69045138 1.134309 -0.6947473414285714 1.2913763571428571 -0.005653628571428571l0.05116135714285714 0.22417433571428572c0.5697424285714285 2.4976695857142857 2.5831508571428574 4.409113628571428 5.1071775 4.848205478571429 0.7021249285714286 0.12215022857142857 0.7021249285714286 1.1301484071428571 0 1.2522986357142858 -2.5240266428571427 0.43909184999999995 -4.537435071428572 2.3505358928571427 -5.1071775 4.848205478571429l-0.05116135714285714 0.22417433571428572c-0.15706735714285713 0.68908575 -1.1402811428571429 0.6848057142857142 -1.2913763571428571 -0.005653628571428571l-0.042123514285714286 -0.19236272142857141C19.271487878571428 10.755232071428571 17.258497499999997 8.827503985714285 14.727602892857142 8.387177892857142Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector 2137"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.727602892857142 8.387177892857142c-0.6986013642857143 -0.12153310714285714 -0.6986013642857143 -1.1243952428571429 0 -1.24592835 2.530894607142857 -0.44032609285714286 4.543884985714286 -2.3680541785714286 5.093222592857143 -4.877548607142857l0.042123514285714286 -0.19236272142857141c0.15109521428571426 -0.69045138 1.134309 -0.6947473414285714 1.2913763571428571 -0.005653628571428571l0.05116135714285714 0.22417433571428572c0.5697424285714285 2.4976695857142857 2.5831508571428574 4.409113628571428 5.1071775 4.848205478571429 0.7021249285714286 0.12215022857142857 0.7021249285714286 1.1301484071428571 0 1.2522986357142858 -2.5240266428571427 0.43909184999999995 -4.537435071428572 2.3505358928571427 -5.1071775 4.848205478571429l-0.05116135714285714 0.22417433571428572c-0.15706735714285713 0.68908575 -1.1402811428571429 0.6848057142857142 -1.2913763571428571 -0.005653628571428571l-0.042123514285714286 -0.19236272142857141C19.271487878571428 10.755232071428571 17.258497499999997 8.827503985714285 14.727602892857142 8.387177892857142Z"
        strokeWidth="2.13"
      ></path>
    </g>
  </svg>
);

export default TrafficSources;
