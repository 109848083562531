import useAuthStore from '@/stores/auth';
import { gistUtils } from '@/widgets/gist';
import useUserStore from '@/stores/user';
import { useNavigate } from 'react-router-dom';
import { appEmitter } from '@/utils/app';
import { removeCookie } from '@/utils/storage';
import { FF_ACCESS_TOKEN, FF_REFRESH_TOKEN, FF_USER_ID } from '@/constants/auth';

export const useLogout = () => {
  const logout = useAuthStore((state) => state.logout);
  const resetUser = useUserStore((state) => state.resetUser);
  const navigate = useNavigate();

  const handleLogout = () => {
    gistUtils.close();
    gistUtils.shutdown();
    resetUser();
    logout();
    removeCookie(FF_ACCESS_TOKEN);
    removeCookie(FF_REFRESH_TOKEN);
    removeCookie(FF_USER_ID);
    navigate('/');
    appEmitter.emit('onLogout');
  };

  return {
    logout: handleLogout,
  };
};