
const Knowledgebase = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30">
    <g id="desktop-help--device-help-information-display-desktop-question-info">
      <path
        id="Vector"
        fill="currentColor"
        d="M25.879285714285714 3.9814285714285713H1.9907142857142857c-0.5497198242857143 0 -0.9953571428571428 0.4456413 -0.9953571428571428 0.9953571428571428v15.925714285714285c0 0.5496362142857143 0.4456373185714286 0.9953571428571428 0.9953571428571428 0.9953571428571428h23.888571428571428c0.5496362142857143 0 0.9953571428571428 -0.44572092857142853 0.9953571428571428 -0.9953571428571428v-15.925714285714285c0 -0.5497158428571428 -0.44572092857142853 -0.9953571428571428 -0.9953571428571428 -0.9953571428571428Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_2"
        fill="#ffffff"
        d="M17.916428571428572 26.874642857142856 15.925714285714285 21.89785714285714H11.944285714285714l-1.9907142857142857 4.976785714285715h7.962857142857143Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_3"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M26.874642857142856 13.091773242857142V20.9025c0 0.2639687142857143 -0.10491064285714284 0.5171875714285714 -0.29144057142857144 0.7039165714285714 -0.18672899999999998 0.18652992857142858 -0.4399478571428571 0.29144057142857144 -0.7039165714285714 0.29144057142857144H1.9907142857142857c-0.26398464 0 -0.5171577107142856 -0.10491064285714284 -0.7038230078571428 -0.29144057142857144C1.10022399 21.41968757142857 0.9953571428571428 21.166468714285713 0.9953571428571428 20.9025v-15.925714285714285c0 -0.26398862142857143 0.10486684714285714 -0.5171676642857144 0.29153413499999997 -0.7038170357142857C1.473556575 4.0862994 1.7267296457142858 3.9814285714285713 1.9907142857142857 3.9814285714285713h12.274983171428572"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_4"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m11.944285714285714 21.89785714285714 -1.9907142857142857 4.976785714285715"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m15.925714285714285 21.89785714285714 1.9907142857142857 4.976785714285715"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_6"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.962857142857143 26.874642857142856h11.944285714285714"
        strokeWidth="2.13"
      ></path>
      <g id="Group 623" style={{ color: 'currentColor' }}>
        <path
          id="Vector_7_outline"
          stroke="white"
          style={{ stroke: 'white' }}
          strokeOpacity="1"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M18.911785714285713 3.9814285714285713c0 -0.5905852071428571 0.17512313571428573 -1.167912257142857 0.5032525714285715 -1.65896175 0.32810952857142855 -0.4910674092857143 0.7944940714285714 -0.8737981564285714 1.3401488571428573 -1.0998079307142856 0.5456547857142857 -0.2260077835714286 1.1460542142857142 -0.2851419514285714 1.725153 -0.16992339 0.5792978571428571 0.11521856142857143 1.1114157857142857 0.399613995 1.5290676428571428 0.8172220564285715 0.4174527857142857 0.4176120428571428 0.7019258571428572 0.9496702499999999 0.8171882142857142 1.5289282928571428 0.11526235714285714 0.5792381357142857 0.05613814285714285 1.1796375642857144 -0.170007 1.7252724428571429 -0.22594607142857143 0.5456149714285714 -0.6087604285714285 1.011979607142857 -1.0996705714285715 1.3400891357142857 -0.4911092142857143 0.3281294357142857 -1.068416357142857 0.5032525714285715 -1.6590612857142857 0.5032525714285715v1.9907142857142857"
          strokeWidth="5"
        />
        <path
          id="Vector_8_outline"
          stroke="white"
          style={{ stroke: 'white' }}
          strokeOpacity="1"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M21.89785714285714 13.934999999999999c-0.2749176428571429 0 -0.4976785714285714 -0.22282065 -0.4976785714285714 -0.4976785714285714s0.22276092857142857 -0.4976785714285714 0.4976785714285714 -0.4976785714285714"
          strokeWidth="5"
        />
        <path
          id="Vector_9_outline"
          stroke="white"
          style={{ stroke: 'white' }}
          strokeOpacity="1"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M21.89785714285714 13.934999999999999c0.2749176428571429 0 0.4976785714285714 -0.22282065 0.4976785714285714 -0.4976785714285714s-0.22276092857142857 -0.4976785714285714 -0.4976785714285714 -0.4976785714285714"
          strokeWidth="5"
        />
        <path
          id="Vector_7"
          stroke="currentColor"
          strokeOpacity="1"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M18.911785714285713 3.9814285714285713c0 -0.5905852071428571 0.17512313571428573 -1.167912257142857 0.5032525714285715 -1.65896175 0.32810952857142855 -0.4910674092857143 0.7944940714285714 -0.8737981564285714 1.3401488571428573 -1.0998079307142856 0.5456547857142857 -0.2260077835714286 1.1460542142857142 -0.2851419514285714 1.725153 -0.16992339 0.5792978571428571 0.11521856142857143 1.1114157857142857 0.399613995 1.5290676428571428 0.8172220564285715 0.4174527857142857 0.4176120428571428 0.7019258571428572 0.9496702499999999 0.8171882142857142 1.5289282928571428 0.11526235714285714 0.5792381357142857 0.05613814285714285 1.1796375642857144 -0.170007 1.7252724428571429 -0.22594607142857143 0.5456149714285714 -0.6087604285714285 1.011979607142857 -1.0996705714285715 1.3400891357142857 -0.4911092142857143 0.3281294357142857 -1.068416357142857 0.5032525714285715 -1.6590612857142857 0.5032525714285715v1.9907142857142857"
          strokeWidth="2.13"
        />
        <path
          id="Vector_8"
          stroke="currentColor"
          strokeOpacity="1"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M21.89785714285714 13.934999999999999c-0.2749176428571429 0 -0.4976785714285714 -0.22282065 -0.4976785714285714 -0.4976785714285714s0.22276092857142857 -0.4976785714285714 0.4976785714285714 -0.4976785714285714"
          strokeWidth="2.13"
        />
        <path
          id="Vector_9"
          stroke="currentColor"
          strokeOpacity="1"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M21.89785714285714 13.934999999999999c0.2749176428571429 0 0.4976785714285714 -0.22282065 0.4976785714285714 -0.4976785714285714s-0.22276092857142857 -0.4976785714285714 -0.4976785714285714 -0.4976785714285714"
          strokeWidth="2.13"
        />
      </g>
    </g>
  </svg>
);

export default Knowledgebase;