import React from 'react';
import ReactDOM from 'react-dom/client';
import '@ant-design/v5-patch-for-react-19';
import './index.scss';
import 'react-loading-skeleton/dist/skeleton.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AllEnterpriseModule, LicenseManager, ModuleRegistry } from 'ag-grid-enterprise';
import { LICENSE_KEY } from './constants/table';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import packageJson from '../package.json';

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    console.log('Service worker updated');
    if (registration?.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
  },
  onSuccess: (registration) => {}
});

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    // Or, if you are using react router, use the appropriate integration
    // See docs for support for different versions of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
  // tracePropagationTargets: [
  //   /^https:\/\/api\.funnelflux\.pro/,
  //   /^https:\/\/middleware\.funnelflux\.pro/,
  //   /^https:\/\/api-staging\.funnelflux\.pro/,
  // ],
  release: 'funnelflux@' + packageJson.version || 'notSetted',
  enabled: process.env.NODE_ENV === 'production',
  environment: process.env.NODE_ENV,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

declare global {
  interface Window {
    Chargebee: any;
  }
}

if (LICENSE_KEY) {
  ModuleRegistry.registerModules([AllEnterpriseModule]);
  LicenseManager.setLicenseKey(LICENSE_KEY);
} else {
  throw Error('Provide key');
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
