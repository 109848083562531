export const RequestIdleCallback = (callback: any, timeout: IdleRequestOptions = { timeout: 100 }) => {
  if ('requestIdleCallback' in window) {
    requestIdleCallback(() => callback(), timeout!);
  } else {
    callback();
  }
};

/**
 * Interface for user device and browser information
 */
interface UserDeviceInfo {
  browser: {
    name: string;
    version: string;
    userAgent: string;
  };
  device: {
    type: 'mobile' | 'tablet' | 'desktop' | 'unknown';
    screenWidth: number;
    screenHeight: number;
    pixelRatio: number;
    colorDepth: number;
    orientation: string;
  };
  os: {
    name: string;
    version: string;
  };
  network: {
    effectiveType: string;
    downlink: number | null;
    rtt: number | null;
    saveData: boolean;
  };
  language: string;
  timezone: string;
}

/**
 * Detects the user's browser name and version
 */
const detectBrowser = (): { name: string; version: string } => {
  const userAgent = navigator.userAgent;
  let browserName = "unknown";
  let version = "unknown";
  
  // Check for common browsers
  if (userAgent.indexOf("Firefox") > -1) {
    browserName = "Firefox";
    version = userAgent.match(/Firefox\/([0-9.]+)/)?.[1] || "unknown";
  } else if (userAgent.indexOf("SamsungBrowser") > -1) {
    browserName = "Samsung Browser";
    version = userAgent.match(/SamsungBrowser\/([0-9.]+)/)?.[1] || "unknown";
  } else if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
    browserName = "Opera";
    version = (userAgent.match(/Opera\/([0-9.]+)/) || 
              userAgent.match(/OPR\/([0-9.]+)/))?.[1] || "unknown";
  } else if (userAgent.indexOf("Trident") > -1) {
    browserName = "Internet Explorer";
    version = userAgent.match(/rv:([0-9.]+)/)?.[1] || "unknown";
  } else if (userAgent.indexOf("Edge") > -1) {
    browserName = "Edge (Legacy)";
    version = userAgent.match(/Edge\/([0-9.]+)/)?.[1] || "unknown";
  } else if (userAgent.indexOf("Edg") > -1) {
    browserName = "Edge Chromium";
    version = userAgent.match(/Edg\/([0-9.]+)/)?.[1] || "unknown";
  } else if (userAgent.indexOf("Chrome") > -1) {
    browserName = "Chrome";
    version = userAgent.match(/Chrome\/([0-9.]+)/)?.[1] || "unknown";
  } else if (userAgent.indexOf("Safari") > -1) {
    browserName = "Safari";
    version = userAgent.match(/Version\/([0-9.]+)/)?.[1] || "unknown";
  }
  
  return { name: browserName, version };
};

/**
 * Detects the user's operating system
 */
const detectOS = (): { name: string; version: string } => {
  const userAgent = navigator.userAgent;
  let osName = "unknown";
  let osVersion = "unknown";

  if (userAgent.indexOf("Win") !== -1) {
    osName = "Windows";
    if (userAgent.indexOf("Windows NT 10.0") !== -1) osVersion = "10";
    else if (userAgent.indexOf("Windows NT 6.3") !== -1) osVersion = "8.1";
    else if (userAgent.indexOf("Windows NT 6.2") !== -1) osVersion = "8";
    else if (userAgent.indexOf("Windows NT 6.1") !== -1) osVersion = "7";
    else if (userAgent.indexOf("Windows NT 6.0") !== -1) osVersion = "Vista";
    else if (userAgent.indexOf("Windows NT 5.1") !== -1) osVersion = "XP";
  } else if (userAgent.indexOf("Mac") !== -1) {
    osName = "MacOS";
    osVersion = userAgent.match(/Mac OS X ([0-9_]+)/)?.[1]?.replace(/_/g, '.') || "unknown";
  } else if (userAgent.indexOf("Android") !== -1) {
    osName = "Android";
    osVersion = userAgent.match(/Android ([0-9.]+)/)?.[1] || "unknown";
  } else if (userAgent.indexOf("like Mac") !== -1) {
    osName = "iOS";
    osVersion = userAgent.match(/OS ([0-9_]+)/)?.[1]?.replace(/_/g, '.') || "unknown";
  } else if (userAgent.indexOf("Linux") !== -1) {
    osName = "Linux";
  }

  return { name: osName, version: osVersion };
};

/**
 * Detects the device type (mobile, tablet, desktop)
 */
const detectDeviceType = (): 'mobile' | 'tablet' | 'desktop' | 'unknown' => {
  const userAgent = navigator.userAgent;
  
  // Check for tablets first (some tablets report as both mobile and tablet)
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
    return 'tablet';
  }
  
  // Check for mobile devices
  if (/Mobile|iPhone|Android|IEMobile|BlackBerry|Opera Mini/i.test(userAgent)) {
    return 'mobile';
  }
  
  // If not mobile or tablet, assume desktop
  if (typeof window !== 'undefined' && window.innerWidth > 1024) {
    return 'desktop';
  }
  
  return 'unknown';
};

/**
 * Gets network information if available
 */
const getNetworkInfo = (): {
  effectiveType: string;
  downlink: number | null;
  rtt: number | null;
  saveData: boolean;
} => {
  // Type assertion for the Navigator interface to include connection property
  const nav = navigator as Navigator & {
    connection?: {
      effectiveType?: string;
      downlink?: number;
      rtt?: number;
      saveData?: boolean;
    };
  };
  
  const connection = nav.connection;
  
  return {
    effectiveType: connection?.effectiveType || 'unknown',
    downlink: connection?.downlink || null,
    rtt: connection?.rtt || null,
    saveData: connection?.saveData || false
  };
};

/**
 * Collects comprehensive information about user device and browser
 */
const getUserDeviceInfo = (): UserDeviceInfo => {
  const browser = detectBrowser();
  const os = detectOS();
  
  return {
    browser: {
      name: browser.name,
      version: browser.version,
      userAgent: navigator.userAgent,
    },
    device: {
      type: detectDeviceType(),
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
      pixelRatio: window.devicePixelRatio || 1,
      colorDepth: window.screen.colorDepth,
      orientation: window.screen.orientation ? window.screen.orientation.type : 'unknown',
    },
    os: {
      name: os.name,
      version: os.version,
    },
    network: getNetworkInfo(),
    language: navigator.language,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
};

/**
 * Measures and returns page load timing information along with user device info
 * @returns Promise resolving to object containing timing measurements and user info
 */
export const measurePageLoadTime = async (): Promise<{
  total: number;
  ttfb: number;
  domComplete: number;
  interactive: number;
  details: PerformanceNavigationTiming | null;
  userIp: string;
  deviceInfo: UserDeviceInfo;
}> => {
  // Gather performance metrics first
  let metrics: {
    total: number;
    ttfb: number;
    domComplete: number;
    interactive: number;
    details: PerformanceNavigationTiming | null;
  };

  // Use the newer Performance API if available
  const navEntry = window.performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming;
  
  if (navEntry) {
    metrics = {
      // Total time from navigation start to load complete
      total: navEntry.loadEventEnd - navEntry.startTime,
      
      // Time to First Byte - how long it takes to receive the first byte of response
      ttfb: navEntry.responseStart - navEntry.startTime,
      
      // DOM Complete - when the DOM is fully loaded
      domComplete: navEntry.domComplete - navEntry.startTime,
      
      // Time to Interactive - when the page is fully interactive
      interactive: navEntry.domInteractive - navEntry.startTime,
      
      // Full details for advanced analysis
      details: navEntry
    };
  } else {
    // Fallback for older browsers using the deprecated timing API
    const timing = window.performance.timing;
    if (timing) {
      const navigationStart = timing.navigationStart;
      
      metrics = {
        total: timing.loadEventEnd - navigationStart,
        ttfb: timing.responseStart - navigationStart,
        domComplete: timing.domComplete - navigationStart,
        interactive: timing.domInteractive - navigationStart,
        details: null
      };
    } else {
      // Return zeros if Performance API is not supported
      metrics = {
        total: 0,
        ttfb: 0,
        domComplete: 0,
        interactive: 0,
        details: null
      };
    }
  }
  
  // Fetch user IP using a public API
  let userIp = "unknown";
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    userIp = data.ip;
  } catch (error) {
    console.error('Failed to fetch user IP:', error);
  }
  
  // Get device and browser information
  const deviceInfo = getUserDeviceInfo();
  
  // Return combined metrics, IP and device information
  return {
    ...metrics,
    userIp,
    deviceInfo
  };
};

/**
 * Sends performance metrics to the API for storage
 */
export const sendPerformanceMetricsToAPI = async (metrics: any): Promise<void> => {
  try {
    await fetch('/api/save-performance-metrics', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(metrics),
    });
  } catch (error) {
    //
  }
};

/**
 * Logs page performance metrics to console and sends to API
 */
export const logPagePerformance = async (): Promise<void> => {
  // Wait until the page is fully loaded
  if (document.readyState === 'complete') {
    const metrics = await measurePageLoadTime();
    
    // Log to console for debugging
    console.group('📊 Page Load Performance');
    console.log(`⏱️ Total load time: ${metrics.total.toFixed(0)}ms`);
    console.log(`📡 Time to first byte: ${metrics.ttfb.toFixed(0)}ms`);
    console.log(`🔄 DOM complete: ${metrics.domComplete.toFixed(0)}ms`);
    console.log(`👆 Interactive: ${metrics.interactive.toFixed(0)}ms`);
    console.log(`🌐 User IP: ${metrics.userIp}`);
    console.group('📱 Device Information');
    console.log(`🖥️ Browser: ${metrics.deviceInfo.browser.name} ${metrics.deviceInfo.browser.version}`);
    console.log(`💻 OS: ${metrics.deviceInfo.os.name} ${metrics.deviceInfo.os.version}`);
    console.log(`📱 Device type: ${metrics.deviceInfo.device.type}`);
    console.log(`🖥️ Screen: ${metrics.deviceInfo.device.screenWidth}x${metrics.deviceInfo.device.screenHeight} (${metrics.deviceInfo.device.pixelRatio}x)`);
    console.log(`🌐 Language: ${metrics.deviceInfo.language}`);
    console.log(`🕒 Timezone: ${metrics.deviceInfo.timezone}`);
    console.log(`📶 Network: ${metrics.deviceInfo.network.effectiveType}`);
    console.groupEnd();
    console.groupEnd();
    
    // Send metrics to API for storage
    await sendPerformanceMetricsToAPI(metrics);
    
    return;
  }
  
  // If document is not complete yet, wait for the load event
  window.addEventListener('load', () => {
    // Add a small delay to ensure all metrics are available
    setTimeout(() => logPagePerformance(), 0);
  });
};
