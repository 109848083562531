const ThreeDRotate = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30">
    <g id="3d-rotate-2">
      <path
        id="Vector 2076"
        fill="currentColor"
        d="m9.712057971428571 12.52734602142857 2.81528805 -2.81528805c0.77743365 -0.77743365 2.037874307142857 -0.77743365 2.815307957142857 0l2.81528805 2.81528805c0.7774137428571428 0.7774137428571428 0.7774137428571428 2.037874307142857 0 2.81528805L15.342653978571429 18.15794202857143c-0.77743365 0.7774137428571428 -2.037874307142857 0.7774137428571428 -2.815307957142857 0L9.712057971428571 15.34263407142857c-0.7774137428571428 -0.7774137428571428 -0.7774137428571428 -2.037874307142857 0 -2.81528805Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector 2077"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m9.712057971428571 12.527465464285715 2.81528805 -2.81528805c0.77743365 -0.77743365 2.037874307142857 -0.77743365 2.815307957142857 0l2.81528805 2.81528805c0.7774137428571428 0.77743365 0.7774137428571428 2.037874307142857 0 2.81528805L15.342653978571429 18.15806147142857c-0.77743365 0.7774137428571428 -2.037874307142857 0.7774137428571428 -2.815307957142857 0L9.712057971428571 15.342753514285715c-0.7774137428571428 -0.7774137428571428 -0.7774137428571428 -2.0378543999999996 0 -2.81528805Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Ellipse 1524"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M26.874443785714284 13.934999999999999c0 7.146465214285715 -5.793376714285714 12.939642857142857 -12.93968267142857 12.939642857142857 -5.382791892857142 0 -9.997904635714285 -3.286669285714286 -11.94793469357143 -7.962857142857143"
        strokeWidth="2.13"
      ></path>
      <path
        id="Ellipse 1523"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M0.9953571428571428 13.934999999999999C0.9953571428571428 6.788634321428571 6.788634321428571 0.9953571428571428 13.934999999999999 0.9953571428571428c5.382791892857142 0 9.997964357142857 3.2867688214285713 11.947869 7.962857142857143"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M26.874642857142856 4.976785714285715v3.9814285714285713H21.89785714285714"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M0.9953571428571428 22.893214285714286v-3.9814285714285713h3.9814285714285713"
        strokeWidth="2.13"
      ></path>
    </g>
  </svg>
);

export default ThreeDRotate;
