import { createContext } from 'react';
import { MessageInstance } from 'antd/es/message/interface';

const AntMessageContext = createContext<{
  messageApi: MessageInstance;
  messageContextHolder: React.ReactNode;
}>({
  messageApi: null as any,
  messageContextHolder: null,
});

export default AntMessageContext;
