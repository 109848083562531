const TrackingCodes = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30">
    <g id="browser-code-2--code-browser-tags-angle-bracket">
      <path
        id="Vector"
        fill="#ffffff"
        d="M26.874642857142856 24.88392857142857v-17.916428571428572H0.9953571428571428v17.916428571428572c0 1.0994715 0.8912726464285714 1.9907142857142857 1.9907142857142857 1.9907142857142857h21.89785714285714c1.0994715 0 1.9907142857142857 -0.8912427857142856 1.9907142857142857 -1.9907142857142857Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_2"
        fill="currentColor"
        d="M26.874642857142856 2.9860714285714285v3.9814285714285713H0.9953571428571428v-3.9814285714285713c0 -1.0994396485714286 0.8912746371428572 -1.9907142857142857 1.9907142857142857 -1.9907142857142857h21.89785714285714c1.0994715 0 1.9907142857142857 0.8912726464285714 1.9907142857142857 1.9907142857142857Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_3"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M24.88392857142857 0.9953571428571428h-21.89785714285714c-1.0994416392857143 0 -1.9907142857142857 0.8912726464285714 -1.9907142857142857 1.9907142857142857v21.89785714285714c0 1.0994715 0.8912726464285714 1.9907142857142857 1.9907142857142857 1.9907142857142857h21.89785714285714c1.0994715 0 1.9907142857142857 -0.8912427857142856 1.9907142857142857 -1.9907142857142857v-21.89785714285714c0 -1.0994416392857143 -0.8912427857142856 -1.9907142857142857 -1.9907142857142857 -1.9907142857142857Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_4"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M0.9953571428571428 6.967499999999999h25.879285714285714"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m8.460535714285713 13.934999999999999 -2.9860714285714285 2.9860714285714285 2.9860714285714285 2.9860714285714285"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_6"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m19.409464285714286 13.934999999999999 2.9860714285714285 2.9860714285714285 -2.9860714285714285 2.9860714285714285"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_7"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12.441964285714285 20.9025 15.428035714285715 11.944285714285714"
        strokeWidth="2.13"
      ></path>
    </g>
  </svg>
);

export default TrackingCodes;