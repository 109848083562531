const Archive = () => (
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
    <g id="inbox-content--mail-email-outbox-drawer-content-open-inbox">
        <path id="Vector 3973" fill="currentColor" d="M1 12V2c0 -0.55228 0.44772 -1 1 -1h10c0.5523 0 1 0.44772 1 1v10c0 0.5523 -0.4477 1 -1 1H2c-0.55228 0 -1 -0.4477 -1 -1Z" strokeWidth="1"></path>
        <path id="Vector 3976" fill="transparent" d="M5 8.5H1V12c0 0.5523 0.44772 1 1 1h10c0.5523 0 1 -0.4477 1 -1V8.5H9V9c0 1.1046 -0.89543 2 -2 2s-2 -0.8954 -2 -2v-0.5Z" strokeWidth="1"></path>
        <path id="Vector 3975" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M1 12V2c0 -0.55228 0.44772 -1 1 -1h10c0.5523 0 1 0.44772 1 1v10c0 0.5523 -0.4477 1 -1 1H2c-0.55228 0 -1 -0.4477 -1 -1Z" strokeWidth="1"></path>
        <path id="Vector 3974" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M1 8.5h4V9c0 1.1046 0.89543 2 2 2s2 -0.8954 2 -2v-0.5h4" strokeWidth="1"></path>
        <path id="Vector 2381" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M4 3.5h6" strokeWidth="1"></path>
        <path id="Vector 2382" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M4 6h6" strokeWidth="1"></path>
    </g>
</svg>
);

export default Archive;
