import useHttp from '@/hooks/http';
import { TrafficSource } from '@/models/trafficSource';
import { TrafficSourceCategoryInfo } from '@/models/trafficSourceCategoryInfo';
import { TrafficSourceInfo } from '@/models/trafficSourceInfo';
import { useQuery } from '@tanstack/react-query';

export function useTrafficSourceQuery(idTrafficSource: string | undefined) {
  const { get } = useHttp();
  return useQuery({
    queryKey: ['trafficsource', idTrafficSource],
    queryFn: async () => {
      const { data } = await get<TrafficSource>('v1/trafficsource/find/byId', {
        params: {
          idTrafficSource,
        },
      });
      return data;
    },
    enabled: !!idTrafficSource,
  });
}

export function useTrafficSourcesByIdsQuery(ids: string[] = []) {
  const { get } = useHttp();
  return useQuery({
    queryKey: ['trafficsources', ids],
    queryFn: async () => {
      const { data } = await get<TrafficSource[]>('v1/trafficsource/find/byIds', {
        params: {
          idTrafficSources: ids.join(','),
        },
      });
      return data;
    },
    enabled: ids.length > 0,
  });
}

export function useTrafficSourcesCategoryListQuery() {
  const { get } = useHttp();
  return useQuery({
    queryKey: ['trafficsource', 'categoryList'],
    queryFn: async () => {
      const { data } = await get<TrafficSourceCategoryInfo[]>('v1/trafficsource/category/list', {
        params: {
          status: 'active',
          includeChilds: true,
        },
      });
      return data;
    },
  });
}

export function useTrafficSourcesListQuery() {
  const { get } = useHttp();
  return useQuery({
    queryKey: ['trafficsource', 'list'],
    queryFn: async () => {
      const { data } = await get<TrafficSourceInfo[]>('v1/trafficsource/list', {
        params: {
          status: 'active',
        },
      });
      return data;
    },
  });
}

export function useTrafficSourceCategoriesQuery() {
  const { get } = useHttp();
  return useQuery({
    queryKey: ['trafficsource', 'categories'],
    queryFn: async () => {
      const { data } = await get<TrafficSource>('v1/category/find/byStatus/', {
        params: {
          categoryType: 'trafficsources',
          status: 'active',
        },
      });
      return data;
    },
  });
}

export function useTrafficSourcesQuery(status: TrafficSource.StatusEnum | 'not-deleted') {
  const { get } = useHttp();
  return useQuery({
    queryKey: ['trafficsources', status],
    queryFn: async () => {
      const { data } = await get<TrafficSource[]>('v1/trafficsource/find/byStatus/', {
        params: {
          status: status,
        },
      });
      return data;
    },
  });
}
