const RawEvents = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30">
    <g id="table">
      <path
        id="Rectangle 53"
        fill="currentColor"
        d="M26.874642857142856 24.88392857142857v-21.89785714285714c0 -1.0994416392857143 -0.8912427857142856 -1.9907142857142857 -1.9907142857142857 -1.9907142857142857H11.944285714285714c-1.0994316857142856 0 -1.9907142857142857 0.8912726464285714 -1.9907142857142857 1.9907142857142857v21.89785714285714c0 1.0994715 0.8912826 1.9907142857142857 1.9907142857142857 1.9907142857142857h12.939642857142857c1.0994715 0 1.9907142857142857 -0.8912427857142856 1.9907142857142857 -1.9907142857142857Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Rectangle 55"
        fill="#ffffff"
        d="M0.9953571428571428 2.9860714285714285v21.89785714285714c0 1.0994715 0.8912726464285714 1.9907142857142857 1.9907142857142857 1.9907142857142857H7.962857142857143c1.0994316857142856 0 1.9907142857142857 -0.8912427857142856 1.9907142857142857 -1.9907142857142857v-21.89785714285714c0 -1.0994416392857143 -0.8912826 -1.9907142857142857 -1.9907142857142857 -1.9907142857142857H2.9860714285714285c-1.0994416392857143 0 -1.9907142857142857 0.8912726464285714 -1.9907142857142857 1.9907142857142857Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Rectangle 54"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.9860714285714285 0.9953571428571428h21.89785714285714s1.9907142857142857 0 1.9907142857142857 1.9907142857142857v21.89785714285714s0 1.9907142857142857 -1.9907142857142857 1.9907142857142857h-21.89785714285714s-1.9907142857142857 0 -1.9907142857142857 -1.9907142857142857v-21.89785714285714s0 -1.9907142857142857 1.9907142857142857 -1.9907142857142857"
        strokeWidth="2.13"
      ></path>
      <path
        id="vector 2077"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.95357142857143 0.9953571428571428v25.879086642857143"
        strokeWidth="2.13"
      ></path>
      <path
        id="vector 2078"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M26.874642857142856 9.95357142857143 0.9953651057142857 9.95357142857143"
        strokeWidth="2.13"
      ></path>
      <path
        id="vector 2079"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M26.874642857142856 17.916428571428572 0.9953571428571428 17.916428571428572"
        strokeWidth="2.13"
      ></path>
    </g>
  </svg>
);

export default RawEvents;
