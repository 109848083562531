const GeneralSettings = () => (
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
    <g id="subscription-cashflow">
        <path id="Vector 3" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M8.4892 5.44447c-0.05122 -0.14491 -0.13025 -0.27668 -0.23069 -0.38889 -0.21362 -0.23868 -0.52407 -0.38889 -0.8696 -0.38889h-0.90304c-0.57516 0 -1.04141 0.46625 -1.04141 1.04141 0 0.4894 0.34077 0.91277 0.81886 1.01735l1.37491 0.30076c0.5356 0.11716 0.91735 0.5918 0.91735 1.14007 0 0.64433 -0.52233 1.1671 -1.16667 1.1671h-0.77778c-0.50798 0 -0.94013 -0.32465 -1.10029 -0.77778" strokeWidth="1"></path>
        <path id="Vector 2489" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M7 4.66667V3.5" strokeWidth="1"></path>
        <path id="Vector 2490" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M7 10.5V9.33331" strokeWidth="1"></path>
        <path id="Ellipse 1522" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M13.5 7c0 3.5899 -2.9101 6.5 -6.49999 6.5 -2.70395 0 -5.02228 -1.651 -6.001841 -4" strokeWidth="1"></path>
        <path id="Ellipse 1523" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M0.5 7C0.5 3.41015 3.41015 0.5 7 0.5c2.70395 0 5.0223 1.65105 6.0018 4" strokeWidth="1"></path>
        <path id="Vector" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M13.5 2.5v2h-2" strokeWidth="1"></path>
        <path id="Vector_2" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M0.5 11.5v-2h2" strokeWidth="1"></path>
    </g>
</svg>
  );
  
  export default GeneralSettings;