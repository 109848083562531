const Copy = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
    <g id="copy-1">
      <path
        id="Vector 3878"
        fill="#d7e0ff"
        d="M13 12V4.5c0 -0.55228 -0.4477 -1 -1 -1H4.5c-0.55228 0 -1 0.44772 -1 1V12c0 0.5523 0.44772 1 1 1H12c0.5523 0 1 -0.4477 1 -1Z"
        strokeWidth="1"
      ></path>
      <path
        id="Vector 3884"
        stroke="#4147d5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13 12V4.5c0 -0.55228 -0.4477 -1 -1 -1H4.5c-0.55228 0 -1 0.44772 -1 1V12c0 0.5523 0.44772 1 1 1H12c0.5523 0 1 -0.4477 1 -1Z"
        strokeWidth="1"
      ></path>
      <path id="Vector 3879" stroke="#4147d5" strokeLinecap="round" strokeLinejoin="round" d="M9.5 1h-1" strokeWidth="1"></path>
      <path id="Vector 3880" stroke="#4147d5" strokeLinecap="round" strokeLinejoin="round" d="M5 1h1" strokeWidth="1"></path>
      <path
        id="Vector 3881"
        stroke="#4147d5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1 2.5V2c0 -0.55228 0.44772 -1 1 -1h0.5"
        strokeWidth="1"
      ></path>
      <path id="Vector 3882" stroke="#4147d5" strokeLinecap="round" strokeLinejoin="round" d="M1 5v1" strokeWidth="1"></path>
      <path id="Vector 3883" stroke="#4147d5" strokeLinecap="round" strokeLinejoin="round" d="M1 9.5v-1" strokeWidth="1"></path>
    </g>
  </svg>
);

export default Copy;
