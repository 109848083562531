import { FFNewIcon } from '@/uikit';
import { FFLayout } from '@/uikit/components/Layout';
import className from '@/utils/className';
import { gistUtils } from '@/widgets/gist';
import { PropsWithChildren, useState } from 'react';
import { useLogout } from '@/hooks/logout';
import CreateNewAssetModal from '../CreateNewAssetModal';
import Partners from '../Partners';
import useAppStore from '@/stores/app';
import useUserStore from '@/stores/user';
import { isJustExpired } from '@/utils/app';

const { getClass: getSidebarClass } = className('c-layoutSidebar');

const Layout = ({ children }: PropsWithChildren<{}>) => {
  const { isSidebarExpanded, toggleSidebar } = useAppStore();
  const [isNewAssetModalOpen, setIsNewAssetModalOpen] = useState(false);
  const isExpired = useUserStore((state) => isJustExpired(state.subscriptionStatus));
  const [isPartnersOpen, setIsPartnersOpen] = useState(false);
  const [unreadChatCount, setUnreadChatCount] = useState(0);
  const { logout } = useLogout();
  const [, forceUpdate] = useState({});

  const toggleSidebarAndUpdate = () => {
    forceUpdate({});
    toggleSidebar();
  };

  const onCreateNewAssetClick = () => {
    setIsNewAssetModalOpen(true);
  };

  const onCloseNewAssetModal = () => {
    setIsNewAssetModalOpen(false);
  };

  const onLogout = () => {
    logout();
  };

  const onShowPartners = () => {
    setIsPartnersOpen(true);
  };

  const onClosePartners = () => {
    setIsPartnersOpen(false);
  };

  return (
    <>
      <CreateNewAssetModal isOpen={isNewAssetModalOpen} onClose={onCloseNewAssetModal} />
      <Partners isOpen={isPartnersOpen} onClose={onClosePartners} />
      <FFLayout>
        <FFLayout.Sider collapsed={!isSidebarExpanded} onToggle={toggleSidebarAndUpdate} onCreateNewAsset={onCreateNewAssetClick}>
          <FFLayout.Menu>
            <FFLayout.FFMenuItem
              key="Dashboard"
              title="Dashboard"
              data-onboarding-id="Dashboard"
              to="/dashboard/summary"
              single
              icon={<FFNewIcon name="navigation/dashboard" size="md" type="menu" />}
              popupClassName={getSidebarClass('popup')}
              className={getSidebarClass('subMenu', ['singleMenu'])}
            />
            <FFLayout.FFMenuItem
              key="Funnels"
              title="Funnels"
              data-onboarding-id="Funnels"
              to="/funnels"
              single
              icon={<FFNewIcon name="navigation/funnels" size="md" type="menu" />}
              popupClassName={getSidebarClass('popup')}
              className={getSidebarClass('subMenu', 'singleMenu')}
            />
            <FFLayout.FFMenuItem
              key="Assets"
              title="Assets"
              data-onboarding-id="Assets"
              icon={<FFNewIcon name="navigation/assets" size="md" type="menu" />}
            >
              <FFLayout.FFMenuSubItem key="TrafficSources" data-onboarding-id="TrafficSources" to="/pages-and-resources/trafficsources">
                <FFNewIcon name="navigation/traffic-sources" size="md" type="menu" /> Traffic Sources
              </FFLayout.FFMenuSubItem>
              <FFLayout.FFMenuSubItem key="offers" data-onboarding-id="Offers" to="/pages-and-resources/offers">
                <FFNewIcon name="navigation/offers" size="md" type="menu" /> Offers
              </FFLayout.FFMenuSubItem>
              <FFLayout.FFMenuSubItem key="offersources" data-onboarding-id="OfferSources" to="/pages-and-resources/offersources">
                <FFNewIcon name="navigation/offer-sources" size="md" type="menu" /> Offer Sources
              </FFLayout.FFMenuSubItem>
              <FFLayout.FFMenuSubItem key="Landers" data-onboarding-id="Landers" to="/pages-and-resources/landers">
                <FFNewIcon name="navigation/landers" size="md" type="menu" /> Landers
              </FFLayout.FFMenuSubItem>
              <FFLayout.FFMenuSubItem key="PageGroups" data-onboarding-id="PageGroups" to="/pages-and-resources/pagegroups">
                <FFNewIcon name="navigation/page-groups" size="md" type="menu" /> Page Groups
              </FFLayout.FFMenuSubItem>
              <FFLayout.FFMenuSubItem key="Conditions" data-onboarding-id="Conditions" to="/pages-and-resources/conditions">
                <FFNewIcon name="navigation/conditions" size="md" type="menu" /> Conditions
              </FFLayout.FFMenuSubItem>
            </FFLayout.FFMenuItem>
            <FFLayout.FFMenuItem
              key="Reporting"
              title="Reporting"
              data-onboarding-id="Reporting"
              icon={<FFNewIcon name="navigation/reporting" size="md" type="menu" />}
            >
              <FFLayout.FFMenuSubItem key="Report Builder" data-onboarding-id="ReportBuilder" to="/reporting/builder">
                <FFNewIcon name="navigation/report-builder" size="md" type="menu" /> Report Builder
              </FFLayout.FFMenuSubItem>
              <FFLayout.FFMenuSubItem key="CampaignAnalysis" data-onboarding-id="CampaignAnalysis" to="/reporting/campaigns">
                <FFNewIcon name="navigation/campaign-analysis" size="md" type="menu" /> Campaign Analysis
              </FFLayout.FFMenuSubItem>
              <FFLayout.FFMenuSubItem key="RawEvents" data-onboarding-id="RawEvents" to="/reporting/events">
                <FFNewIcon name="navigation/raw-events" size="md" type="menu" /> Raw Events
              </FFLayout.FFMenuSubItem>
              <FFLayout.FFMenuItem
                key="IntegratedReporting"
                title="Integrated Reporting"
                data-onboarding-id="IntegratedReporting"
                level={2}
                icon={<FFNewIcon name="navigation/integrated-reporting" size="md" type="menu" />}
              >
                <FFLayout.FFMenuSubItem key="GoogleAds" data-onboarding-id="GoogleAds" to="/reporting/integrated/google">
                  Google Ads
                </FFLayout.FFMenuSubItem>
                <FFLayout.FFMenuSubItem key="Facebook" data-onboarding-id="Facebook" to="/reporting/integrated/facebook">
                  Facebook Ads
                </FFLayout.FFMenuSubItem>
              </FFLayout.FFMenuItem>
            </FFLayout.FFMenuItem>
            <FFLayout.FFMenuItem
              key="DataUpdates"
              title="Data Updates"
              disabled={isExpired}
              icon={<FFNewIcon name="navigation/updates" data-onboarding-id="DataUpdates" size="md" type="menu" />}
            >
              <FFLayout.FFMenuSubItem key="UpdateTrafficCosts" data-onboarding-id="UpdateTrafficCosts" to="/updates/update-traffic-costs">
                <FFNewIcon name="navigation/update-costs" size="md" type="menu" /> Update Traffic Costs
              </FFLayout.FFMenuSubItem>
              <FFLayout.FFMenuSubItem key="UpdateConversions" data-onboarding-id="UpdateConversions" to="/updates/update-conversions">
                <FFNewIcon name="navigation/update-conversions" size="md" type="menu" /> Update Conversions
              </FFLayout.FFMenuSubItem>
              <FFLayout.FFMenuSubItem key="ResetData" data-onboarding-id="ResetData" to="/updates/reset-data">
                <FFNewIcon name="navigation/reset-data" size="md" type="menu" /> Reset Data
              </FFLayout.FFMenuSubItem>
            </FFLayout.FFMenuItem>
            <FFLayout.FFMenuItem
              key="Labs"
              title="Labs"
              disabled={isExpired}
              data-onboarding-id="Labs"
              icon={<FFNewIcon name="navigation/labs" size="md" type="menu" />}
            >
              <FFLayout.FFMenuSubItem key="FunnelFluxLabs" data-onboarding-id="FunnelFluxLabs" to="/labs">
                <FFNewIcon name="navigation/labs-2" size="md" type="menu" /> FunnelFlux Labs
              </FFLayout.FFMenuSubItem>
              <FFLayout.FFMenuSubItem key="TrafficFiltering" data-onboarding-id="TrafficFiltering" to="/labs/traffic-filtering">
                <FFNewIcon name="navigation/traffic-filtering" size="md" type="menu" /> Traffic Filtering
              </FFLayout.FFMenuSubItem>
              <FFLayout.FFMenuSubItem
                key="KeywordRotationLinks"
                data-onboarding-id="KeywordRotationLinks"
                to="/labs/keyword-rotation-links"
              >
                <FFNewIcon name="navigation/keyword-links" size="md" type="menu" /> Keyword Rotation Links
              </FFLayout.FFMenuSubItem>
            </FFLayout.FFMenuItem>
            <FFLayout.FFMenuItem
              key="Settings"
              title="Settings"
              data-onboarding-id="Settings"
              icon={<FFNewIcon name="navigation/settings" size="md" type="menu" />}
            >
              <FFLayout.FFMenuSubItem key="Domains" data-onboarding-id="Domains" to="/settings/domains">
                <FFNewIcon name="navigation/domains" size="md" type="menu" /> Domains
              </FFLayout.FFMenuSubItem>
              <FFLayout.FFMenuSubItem key="TrackingCodes" data-onboarding-id="TrackingCodes" to="/settings/trackingcodes">
                <FFNewIcon name="navigation/tracking-codes" size="md" type="menu" /> Tracking Codes
              </FFLayout.FFMenuSubItem>
              <FFLayout.FFMenuItem
                key="Integrations"
                title="Integrations"
                data-onboarding-id="Integrations"
                level={2}
                icon={<FFNewIcon name="navigation/integrations" size="md" type="menu" />}
              >
                <FFLayout.FFMenuSubItem key="TrafficSources" data-onboarding-id="TrafficSources" to="/settings/integrations/trafficsources">
                  Traffic Sources
                </FFLayout.FFMenuSubItem>
                <FFLayout.FFMenuSubItem key="OfferSources" data-onboarding-id="OfferSources" to="/settings/integrations/offersources">
                  Offer Sources
                </FFLayout.FFMenuSubItem>
                <FFLayout.FFMenuSubItem
                  key="IntegratedUIConnections"
                  data-onboarding-id="IntegratedUIConnections"
                  to="/settings/integrations/integrated-uis"
                >
                  Integrated UIs
                </FFLayout.FFMenuSubItem>
                <FFLayout.FFMenuSubItem key="Others" data-onboarding-id="Others" to="/settings/integrations/others">
                  Others
                </FFLayout.FFMenuSubItem>
              </FFLayout.FFMenuItem>
              <FFLayout.FFMenuSubItem key="SubscriptionBilling" data-onboarding-id="SubscriptionBilling" to="/settings/subscriptionbilling">
                <FFNewIcon name="navigation/billing" size="md" type="menu" /> Subscription & Billing
              </FFLayout.FFMenuSubItem>
              <FFLayout.FFMenuSubItem key="SystemSettings" data-onboarding-id="SystemSettings" to="/settings/systemsettings">
                <FFNewIcon name="navigation/system-settings" size="md" type="menu" /> System Settings
              </FFLayout.FFMenuSubItem>
              <FFLayout.FFMenuSubItem key="APIAccess" data-onboarding-id="APIAccess" to="/settings/api-access">
                <FFNewIcon name="navigation/api-access" size="md" type="menu" /> API Access
              </FFLayout.FFMenuSubItem>
            </FFLayout.FFMenuItem>
            <FFLayout.FFMenuItem
              key="HelpAndSupport"
              title="Help and Support"
              data-onboarding-id="HelpAndSupport"
              icon={<FFNewIcon name="navigation/support" size="md" type="menu" />}
            >
              <FFLayout.FFMenuSubItem key="ContactSupport" data-onboarding-id="ContactSupport" onClick={gistUtils.open}>
                <FFNewIcon name="navigation/chat" size="md" type="menu" /> Contact Support
              </FFLayout.FFMenuSubItem>
              <FFLayout.FFMenuSubItem key="Knowledgebase" data-onboarding-id="Knowledgebase" href="https://help.funnelflux.pro">
                <FFNewIcon name="navigation/knowledgebase" size="md" type="menu" /> Knowledgebase
              </FFLayout.FFMenuSubItem>
              <FFLayout.FFMenuSubItem key="Release Notes" data-onboarding-id="ReleaseNotes" href="https://funnelflux.canny.io/changelog">
                <FFNewIcon name="navigation/changelog" size="md" type="menu" /> Release Notes
              </FFLayout.FFMenuSubItem>
              <FFLayout.FFMenuSubItem key="Roadmap" data-onboarding-id="Roadmap" href="https://funnelflux.canny.io/">
                <FFNewIcon name="navigation/roadmap" size="md" type="menu" /> Roadmap
              </FFLayout.FFMenuSubItem>
              <FFLayout.FFMenuSubItem
                key="RequestFeatures"
                data-onboarding-id="RequestFeatures"
                href="https://funnelflux.canny.io/requests"
              >
                <FFNewIcon name="navigation/changelog" data-onboarding-id="Changelog" size="md" type="menu" /> Request Features
              </FFLayout.FFMenuSubItem>
              <FFLayout.FFMenuSubItem key="StatusPage" data-onboarding-id="StatusPage" href="https://status.funnelflux.pro/">
                <FFNewIcon name="navigation/status-page" size="md" type="menu" /> Status Page
              </FFLayout.FFMenuSubItem>
            </FFLayout.FFMenuItem>
          </FFLayout.Menu>
          <FFLayout.MenuFooter>
            <FFLayout.MenuFooterItem onClick={onLogout} data-onboarding-id="Logout">
              <FFNewIcon name="navigation/logout" size="md" type="menu" />
            </FFLayout.MenuFooterItem>
            <FFLayout.MenuFooterItem onClick={onShowPartners} data-onboarding-id="MarketplaceFooter" visible={isSidebarExpanded}>
              <FFNewIcon name="navigation/marketplace" size="md" type="menu" />
            </FFLayout.MenuFooterItem>
            <FFLayout.MenuFooterItem data-onboarding-id="ChangelogFooter" data-canny-changelog visible={isSidebarExpanded}>
              <FFNewIcon name="navigation/changelog" size="md" type="menu" />
            </FFLayout.MenuFooterItem>
            <FFLayout.MenuFooterItem
              href="https://help.funnelflux.pro"
              data-onboarding-id="KnowledgebaseFooter"
              visible={isSidebarExpanded}
            >
              <FFNewIcon name="navigation/knowledgebase" size="md" type="menu" />
            </FFLayout.MenuFooterItem>
            <FFLayout.MenuFooterItem onClick={gistUtils.open} data-onboarding-id="ContactSupportFooter" unreadChatCount={unreadChatCount}>
              <FFNewIcon name="navigation/chat" size="md" type="menu" />
            </FFLayout.MenuFooterItem>
          </FFLayout.MenuFooter>
        </FFLayout.Sider>
        <FFLayout.Main>
          <FFLayout.Content>{children}</FFLayout.Content>
        </FFLayout.Main>
      </FFLayout>
    </>
  );
};

export default Layout;
