import { AINodeSetting } from '@/models/aINodeSetting';
import { FunnelNode } from '@/models/funnelNode';
import { FunnelNodePageParams } from '@/models/funnelNodePageParams';
import { FFSelectOption } from '@/uikit/types/select';
import { generateEntityId } from '@/utils/id';
import { ConnectionLineType } from '@xyflow/react';

export const defaultNodePos = {
  posX: (posX = 0) => window.innerWidth / 2.5 + posX,
  posY: (posY = 0) => window.innerHeight / 10 + posY,
};

export const getTrafficNode = (idNode = generateEntityId()): FunnelNode => ({
  idNode,
  nodeName: 'Traffic',
  nodeRotatorParams: { rotatorType: 'random' },
  nodeType: 'root',
  posX: defaultNodePos.posX(),
  posY: defaultNodePos.posY(),
  status: 'active',
});

export const getConditionNode = ({ idCondition = '', nodeName = '', idNode = generateEntityId(), posY = 40 }): FunnelNode => ({
  idNode,
  nodeName,
  nodeConditionParams: { idCondition },
  nodeType: 'condition',
  posX: defaultNodePos.posX(),
  posY: defaultNodePos.posY() + posY,
  status: 'active',
});

export const getRotatorNode = ({ idNode = generateEntityId(), nodeName = '', posY = 120, posX = defaultNodePos.posX() }): FunnelNode => ({
  idNode,
  nodeName,
  nodeRotatorParams: { rotatorType: 'random' },
  nodeType: 'rotator',
  posX,
  posY: defaultNodePos.posY() + posY,
  status: 'active',
});

export const getPageGroupNode = ({
  idNode = generateEntityId(),
  idPageGroup = '',
  nodeName = '',
  nodeType = '' as FunnelNode.NodeTypeEnum,
  posY = 40,
}): FunnelNode => ({
  idNode,
  nodeName,
  nodePageGroupParams: { idPageGroup },
  nodeType,
  posX: defaultNodePos.posX(),
  posY: defaultNodePos.posY() + posY,
  status: 'active',
});

export const getDefaultPageOverrides = (idPage?: string): FunnelNodePageParams => ({
  idPage: idPage!,
  redirectOverride: undefined,
  additionalTokens: undefined,
  accumulateUrlParams: false,
});

export const connectionLineTypeOptions: FFSelectOption<ConnectionLineType>[] = [
  { value: ConnectionLineType.Bezier, label: 'Bezier' },
  { value: ConnectionLineType.Straight, label: 'Straight' },
  { value: ConnectionLineType.SmoothStep, label: 'Smoothstep' },
];

export const DEFAULT_AI_NODE_SETTING: AINodeSetting = {
  funnelId: '',
  idAINodeSetting: '',
  segmentBy: 'default',
  routingGoal: 'bestNextNode',
  model: 'revenuePerView',
  eventType: 'conversions',
  lookbackWindow: 24,
  updateFrequency: 12,
  calculationParams: {
    explorationWeight: 0.05,
    minConversions: 5,
    priorStrength: 15.0,
    sensitivityFactor: 3.0,
  },
};
