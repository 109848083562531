import { FunnelNodeRotatorParams } from '@/models/funnelNodeRotatorParams';
import AssetLogos from '@/constants/nodeIcons';
import { FunnelNode } from '@/models/funnelNode';

const AssetLogo = ({
  assetType,
  size = 'lg',
  className,
  rotatorType,
}: {
  assetType: FunnelNode.NodeTypeEnum;
  rotatorType?: FunnelNodeRotatorParams.RotatorTypeEnum;
  size?: 'sm' | 'md' | 'lg' | 'xlg';
  className?: string;
}) => {
  let icon;
  const width = size === 'sm' ? 16 : size === 'md' ? 24 : size === 'lg' ? 32 : 48;
  const height = size === 'sm' ? 16 : size === 'md' ? 24 : size === 'lg' ? 32 : 48;

  if (assetType === 'root') {
    icon = AssetLogos.ROOT;
  }
  if (assetType === 'rotator') {
    if (rotatorType === 'ai') {
      icon = AssetLogos.ROTATOR_AI;
    } else {
      icon = AssetLogos.ROTATOR;
    }
  } else if (assetType === 'condition') {
    icon = AssetLogos.CONDITION;
  } else if (assetType === 'lander') {
    icon = AssetLogos.LANDER;    
  } else if (assetType === 'landerGroup') {
    icon = AssetLogos.LANDER_GROUP;
  } else if (assetType === 'offer') {
    icon = AssetLogos.OFFER;    
  } else if (assetType === 'offerGroup') {
    icon = AssetLogos.OFFER_GROUP;
  } else if (assetType === 'externalUrl') {
    icon = AssetLogos.EXTERNAL_URL;
  }
  return (
    <i style={{ width, height }} className={className}>
      {icon}
    </i>
  );
};

export default AssetLogo;
