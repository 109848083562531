const GeneralSettings = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
    <g id="layout-lists--layout-lists-design-view">
      <path
        id="Vector 4064"
        fill="currentColor"
        d="M1 5V2c0 -0.55228 0.44772 -1 1 -1h3c0.55228 0 1 0.44772 1 1v3c0 0.55228 -0.44772 1 -1 1H2c-0.55228 0 -1 -0.44772 -1 -1Z"
        strokeWidth="1"
      ></path>
      <path
        id="Vector 4065"
        fill="currentColor"
        d="M1 12V9c0 -0.55228 0.44772 -1 1 -1h3c0.55228 0 1 0.44772 1 1v3c0 0.5523 -0.44772 1 -1 1H2c-0.55228 0 -1 -0.4477 -1 -1Z"
        strokeWidth="1"
      ></path>
      <path
        id="Vector 4071"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1 5V2c0 -0.55228 0.44772 -1 1 -1h3c0.55228 0 1 0.44772 1 1v3c0 0.55228 -0.44772 1 -1 1H2c-0.55228 0 -1 -0.44772 -1 -1Z"
        strokeWidth="1"
      ></path>
      <path
        id="Vector 4072"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1 12V9c0 -0.55228 0.44772 -1 1 -1h3c0.55228 0 1 0.44772 1 1v3c0 0.5523 -0.44772 1 -1 1H2c-0.55228 0 -1 -0.4477 -1 -1Z"
        strokeWidth="1"
      ></path>
      <path id="Vector 4066" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M8.25 1.8125H13" strokeWidth="1"></path>
      <path id="Vector 4067" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M8.25 5.1875H13" strokeWidth="1"></path>
      <path id="Vector 4069" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M8.25 8.8125H13" strokeWidth="1"></path>
      <path id="Vector 4070" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M8.25 12.1875H13" strokeWidth="1"></path>
    </g>
  </svg>
);

export default GeneralSettings;
