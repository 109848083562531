const UnLock = () => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 14 18">
    <image
      width="14"
      height="18"
      x="0"
      y="0"
      href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAmCAMAAADkx9tQAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABF1BMVEUAAADMzMyq//////+i
ssKhscOgsMOgscOhscOhsMKgscOhscOiscOhscaissWhsMKhsMOis8Sms8ahscOhscKhsMKhscKg
scOhsMOgscOiucWhsMOgsMKgsMKis8O2ttuxscShs8ShscOgsMKgssSissWhscKhscOjtMajscOg
sMKhscOnucqgscOhssOjs8egsMOhscO/v/+hssSqtsKhscOhscKitMehscOhssKhscOitMWgsML/
//+hsMOhscOgsMOhscKissShscOgscOhsMKhscKis8ShscOhscSjscShsMKhscOiscKhssOhsMOn
scSgscKgscOhscOhscOhscKhscOqxsahsMOgsMKiudGgsML///8rK8kFAAAAW3RSTlMABQMCP5DP
6v785ciDMWDj00oo0PS5vdf5uhZE76xNBw1Xu98rQvvUOkjifx23njLCwARJFctcKXJ6tizSAenk
+uuOjYyLih7RbFvzYmVMURr46OHxk50J4PALKi85YgAAAAFiS0dEAxEMTPIAAAAHdElNRQfkBBsI
LzLnQb0/AAABFUlEQVQ4y63T6VLCMBQF4GDVFrWCiFZxw60K4o64Va24tLghCm7n/d/DXMdpo9Ob
0RnOr9x8bW6SaYX4R1I9nBi9ff2mlR4YHLITcDiDKNmRX5obpel8dmzcmaDRZEHVqWk5NTM7R+Pi
/IIsFpdiXXaBldW4LpWRXouqigmsF9XVChubcbEFbO+wZ90FMlX+KvaAGq/7clsGzwfAoeaaj4Bj
DXtwTzR8ijONCh+ens/rlAvK5VeuKNcyBjGf1B/YCRLjfHPInZjYVbhhmg2ebQuwbJZrtJ2bn6z0
DvLAbcD3vgPuBfu2eACaURF2mR9brYqmt6gqP4+nv9SwC/wE/zkxPtryoQ7/NbxIfq2/vSem3PkQ
4hMZ5X06pQmkGQAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMC0wNC0yN1QwODo0Nzo1MCswMzowMJ5i
Gb4AAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjAtMDQtMjdUMDg6NDc6NTArMDM6MDDvP6ECAAAAAElF
TkSuQmCC"
    ></image>
  </svg>
);

export default UnLock;
