const DnsSettings = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <g id="cloud-setting-gear">
        <path id="Subtract" fill="#d7e0ff" fillRule="evenodd" d="M11.2756 4.14929c0.0307 0.03555 0.0709 0.06157 0.1159 0.07501 0.6517 0.15353 1.2244 0.54068 1.6098 1.08815 0.3854 0.54747 0.5566 1.21724 0.4813 1.8825 -0.0753 0.66527 -0.3919 1.27983 -0.8899 1.72732 -0.2665 0.23951 -0.5751 0.42226 -0.907 0.54101 0.0173 -0.0891 0.0293 -0.1795 0.0358 -0.27072 0.0317 -0.44855 -0.0718 -0.8962 -0.297 -1.28514l-0.1966 -0.34409 -0.0049 -0.00834c-0.2232 -0.38571 -0.5561 -0.69636 -0.9563 -0.89245 -0.33281 -0.16311 -0.69996 -0.24098 -1.06851 -0.22793 -0.16898 -0.32158 -0.41407 -0.59862 -0.71545 -0.80602 -0.37296 -0.25666 -0.81475 -0.39471 -1.2675 -0.39605l-0.41525 0 -0.00519 0.00001c-0.45274 0.00134 -0.89452 0.13938 -1.26749 0.39604 -0.30151 0.20749 -0.54668 0.48469 -0.71567 0.80645 -0.37163 -0.01479 -0.74235 0.06286 -1.07833 0.2275 -0.4002 0.19609 -0.73308 0.50672 -0.95631 0.89244l-0.2014 0.35243c-0.22526 0.38894 -0.32879 0.83659 -0.29704 1.28515 0.00261 0.03691 0.00614 0.07368 0.01055 0.11029 -0.24897 -0.12224 -0.48305 -0.27735 -0.6955 -0.46306C0.954829 8.28139 0.564032 7.4921 0.507193 6.64556c-0.056839 -0.84654 0.224936 -1.68098 0.783337 -2.31977 0.27649 -0.31629 0.61258 -0.57503 0.98906 -0.76144 0.37649 -0.1864 0.786 -0.29683 1.20517 -0.32498 0.0435 0.00096 0.08636 -0.01056 0.12353 -0.03318 0.03717 -0.02263 0.06708 -0.05541 0.08622 -0.09449 0.28916 -0.83806 0.85688 -1.55166 1.60849 -2.02181C6.05462 0.619753 6.94469 0.421491 7.82479 0.528169c0.8801 0.106679 1.69703 0.511851 2.31451 1.147951 0.6176 0.6361 0.9984 1.46467 1.0789 2.34754 0.0068 0.04649 0.0266 0.09008 0.0574 0.12563Z" clipRule="evenodd" strokeWidth="1"></path>
        <path id="Vector" stroke="#4147d5" strokeLinecap="round" strokeLinejoin="round" d="M12.1783 9.22935c0.1466 -0.08852 0.2854 -0.19115 0.4144 -0.30708 0.498 -0.44749 0.8146 -1.06205 0.8899 -1.72731 0.0753 -0.66526 -0.0959 -1.33504 -0.4813 -1.88251 -0.3854 -0.54747 -0.9581 -0.93462 -1.6098 -1.08815 -0.045 -0.01344 -0.0852 -0.03946 -0.1159 -0.07501 -0.0308 -0.03555 -0.0506 -0.07914 -0.0574 -0.12563 -0.0805 -0.88287 -0.4613 -1.71144 -1.0789 -2.34754C9.52182 1.04002 8.70489 0.634848 7.82479 0.528169 6.94469 0.421491 6.05462 0.619753 5.303 1.08989c-0.75161 0.47015 -1.31933 1.18375 -1.60849 2.02181 -0.01914 0.03908 -0.04905 0.07186 -0.08622 0.09449 -0.03717 0.02262 -0.08003 0.03414 -0.12353 0.03318 -0.41917 0.02815 -0.82868 0.13858 -1.20517 0.32498 -0.37648 0.18641 -0.71257 0.44515 -0.98906 0.76144 -0.558401 0.63879 -0.840176 1.47323 -0.783337 2.31977 0.056839 0.84654 0.447636 1.63583 1.086417 2.19423 0.10038 0.08774 0.20558 0.16865 0.3149 0.2425" strokeWidth="1"></path>
        <path id="Vector_2" fill="#ffffff" d="m6.11025 7.84478 0.21615 -0.55798c0.03645 -0.09447 0.10059 -0.17573 0.18401 -0.23314 0.08342 -0.05741 0.18223 -0.08828 0.28349 -0.08858h0.4122c0.10126 0.0003 0.20007 0.03117 0.28349 0.08858 0.08342 0.05741 0.14756 0.13867 0.18401 0.23314l0.21616 0.55798 0.73392 0.42226 0.59317 -0.09049c0.09877 -0.0134 0.19929 0.00286 0.2888 0.04672 0.08951 0.04385 0.16395 0.11333 0.21388 0.1996l0.20108 0.35188c0.05152 0.08764 0.07526 0.18885 0.06808 0.29026 -0.00718 0.10141 -0.04494 0.19827 -0.1083 0.27778l-0.36696 0.46749v0.84452l0.37702 0.4675c0.06335 0.0795 0.10112 0.1764 0.10829 0.2778 0.00716 0.1014 -0.01655 0.2026 -0.06808 0.2902l-0.20107 0.3519c-0.04993 0.0863 -0.12438 0.1558 -0.21389 0.1996 -0.0895 0.0439 -0.19003 0.0601 -0.2888 0.0467l-0.59317 -0.0905 -0.73392 0.4223 -0.21616 0.558c-0.03645 0.0945 -0.10059 0.1757 -0.184 0.2331 -0.08342 0.0574 -0.18223 0.0883 -0.28349 0.0886H6.7939c-0.10126 -0.0003 -0.20007 -0.0312 -0.28349 -0.0886 -0.08342 -0.0574 -0.14756 -0.1386 -0.18401 -0.2331l-0.21615 -0.558 -0.73393 -0.4223 -0.59317 0.0905c-0.09877 0.0134 -0.19929 -0.0028 -0.2888 -0.0467 -0.08951 -0.0438 -0.16395 -0.1133 -0.21388 -0.1996l-0.20108 -0.3519c-0.05152 -0.0876 -0.07526 -0.1888 -0.06808 -0.2902 0.00718 -0.1014 0.04494 -0.1983 0.1083 -0.2778l0.36696 -0.4675v-0.84452l-0.37702 -0.46749c-0.06335 -0.07951 -0.10112 -0.17637 -0.10829 -0.27778 -0.00718 -0.10141 0.01655 -0.20262 0.06808 -0.29026l0.20107 -0.35188c0.04993 -0.08627 0.12438 -0.15575 0.21389 -0.1996 0.08951 -0.04386 0.19003 -0.06012 0.2888 -0.04672l0.59317 0.09049 0.74398 -0.42226Z" strokeWidth="1"></path>
        <path id="Vector_3" stroke="#4147d5" strokeLinecap="round" strokeLinejoin="round" d="m6.11025 7.84478 0.21615 -0.55798c0.03645 -0.09447 0.10059 -0.17573 0.18401 -0.23314 0.08342 -0.05741 0.18223 -0.08828 0.28349 -0.08858h0.4122c0.10126 0.0003 0.20007 0.03117 0.28349 0.08858 0.08342 0.05741 0.14756 0.13867 0.18401 0.23314l0.21615 0.55798 0.73393 0.42226 0.59317 -0.09049c0.09877 -0.0134 0.19929 0.00286 0.2888 0.04672 0.08951 0.04385 0.16395 0.11333 0.21388 0.1996l0.20108 0.35188c0.05152 0.08764 0.07526 0.18885 0.06808 0.29026 -0.00718 0.10141 -0.04494 0.19827 -0.1083 0.27778l-0.36696 0.46749v0.84452l0.37702 0.4675c0.06335 0.0795 0.10112 0.1764 0.10829 0.2778 0.00716 0.1014 -0.01655 0.2026 -0.06808 0.2902l-0.20107 0.3519c-0.04993 0.0863 -0.12438 0.1558 -0.21389 0.1996 -0.0895 0.0439 -0.19003 0.0601 -0.2888 0.0467l-0.59317 -0.0905 -0.73392 0.4223 -0.21616 0.558c-0.03645 0.0945 -0.10059 0.1757 -0.184 0.2331 -0.08342 0.0574 -0.18223 0.0883 -0.28349 0.0886H6.7939c-0.10126 -0.0003 -0.20007 -0.0312 -0.28349 -0.0886 -0.08342 -0.0574 -0.14756 -0.1386 -0.18401 -0.2331l-0.21615 -0.558 -0.73393 -0.4223 -0.59317 0.0905c-0.09877 0.0134 -0.19929 -0.0028 -0.2888 -0.0467 -0.08951 -0.0438 -0.16395 -0.1133 -0.21388 -0.1996l-0.20108 -0.3519c-0.05152 -0.0876 -0.07526 -0.1888 -0.06808 -0.2902 0.00718 -0.1014 0.04494 -0.1983 0.1083 -0.2778l0.36696 -0.4675v-0.84452l-0.37702 -0.46749c-0.06335 -0.07951 -0.10112 -0.17637 -0.10829 -0.27778 -0.00718 -0.10141 0.01655 -0.20262 0.06808 -0.29026l0.20107 -0.35188c0.04993 -0.08627 0.12438 -0.15575 0.21389 -0.1996 0.08951 -0.04386 0.19003 -0.06012 0.2888 -0.04672l0.59317 0.09049 0.74398 -0.42226Z" strokeWidth="1"></path>
        <path id="Ellipse 1473" stroke="#4147d5" strokeLinecap="round" strokeLinejoin="round" d="M6.5 10.232a0.5 0.5 0 1 0 1 0 0.5 0.5 0 1 0 -1 0" strokeWidth="1"></path>
      </g>
    </svg>
);

export default DnsSettings;