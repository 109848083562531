/**
 * FunnelFlux Pro Ledger API
 * API endpoints to manage customers' assets
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FunnelConditionRule } from './funnelConditionRule';


export interface FunnelConditionGroup { 
    operator: FunnelConditionGroup.OperatorEnum;
    rules: Array<FunnelConditionRule>;
}
export namespace FunnelConditionGroup {
    export type OperatorEnum = 'or' | 'and';
    export const OperatorEnum = {
        Or: 'or' as OperatorEnum,
        And: 'and' as OperatorEnum
    };
}
