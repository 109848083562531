import { useQuery } from '@tanstack/react-query';
import useHttp from '@/hooks/http';
import { CloudflaredDomain } from '@/models/models';
import useSystemSettingsStore from '@/stores/systemSettings';

export function useDomainsQuery() {
  const { get } = useHttp();
  const setDomains = useSystemSettingsStore((state) => state.setDomains);

  return useQuery({
    queryKey: ['domains', 'data'],
    queryFn: async () => {
      const { data } = await get<CloudflaredDomain[]>('v1/edge/domains/');
      setDomains(data);
      return data;
    },
  });
}
