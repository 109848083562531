import { FFButton, FFCol, FFField, FFNewIcon, FFRow, FFSelect, VisibilityWrapper } from '@/uikit';
import className from '@/utils/className';
import { useCallback } from 'react';
import { Radio } from 'antd';
import { attributeFields } from '@/constants/conditions';
import { Control, Controller, useWatch } from 'react-hook-form';
import RouteGroupRule from '../RouteGroupRule';
import { DomainEntry, FunnelConditionRule } from '@/models/models';
import clsx from 'clsx';
import { FFSelectOption } from '@/uikit/types/select';
import { SimpleFlowRule } from '@/models/simpleFlowRules';
import './style.scss';

interface Props {
  onAddGroup: () => void;
  onDeleteGroup: (idGroup: number) => void;
  onAddRule: (idGroup: number) => void;
  onDeleteRule: (idGroup: number, idRule: number) => void;
  trafficSources: FFSelectOption[];
  domains: DomainEntry[];
  control: Control<SimpleFlowRule, any>;
}

const { blockClassName, getClass } = className('c-simpleFlowRule');

const FlowRule = ({
  onAddGroup = () => {},
  onAddRule = () => {},
  onDeleteGroup = () => {},
  onDeleteRule = () => {},
  trafficSources = [],
  domains = [],
  control,
}: Props) => {
  const route = useWatch({
    control,
    name: 'rule',
  });

  const getTestOptions = useCallback((attribute: FunnelConditionRule.AttributeEnum) => {
    switch (attribute) {
      case FunnelConditionRule.AttributeEnum.LocationContinent:
      case FunnelConditionRule.AttributeEnum.LocationCountry:
      case FunnelConditionRule.AttributeEnum.LocationTimezone:
      case FunnelConditionRule.AttributeEnum.DeviceType:
      case FunnelConditionRule.AttributeEnum.DeviceOS:
      case FunnelConditionRule.AttributeEnum.DeviceBrowserLanguage:
      case FunnelConditionRule.AttributeEnum.ConnectionType:
      case FunnelConditionRule.AttributeEnum.TrafficSource:
        return ['is', 'is not', 'any in', 'not in'];
      case FunnelConditionRule.AttributeEnum.LocationCity:
      case FunnelConditionRule.AttributeEnum.LocationRegion:
      case FunnelConditionRule.AttributeEnum.DeviceBrowserVersion:
      case FunnelConditionRule.AttributeEnum.TimeDayOfWeek:
      case FunnelConditionRule.AttributeEnum.TimeDayOfMonth:
      case FunnelConditionRule.AttributeEnum.TimeMonthOfYear:
        return ['is', 'is not'];
      case FunnelConditionRule.AttributeEnum.DeviceBrand:
      case FunnelConditionRule.AttributeEnum.DeviceBrowser:
      case FunnelConditionRule.AttributeEnum.ConnectionISP:
      case FunnelConditionRule.AttributeEnum.ConnectionCarrier:
      case FunnelConditionRule.AttributeEnum.ConnectionUserAgent:
      case FunnelConditionRule.AttributeEnum.ConnectionReferrer:
      case FunnelConditionRule.AttributeEnum.ConnectionCurrentURL:
      case FunnelConditionRule.AttributeEnum.TrackingField:
      case FunnelConditionRule.AttributeEnum.TrackingDomain:
        return ['is', 'is not', 'contains', 'does not contain'];
      case FunnelConditionRule.AttributeEnum.DeviceOSVersion:
        return ['is', 'is not', '<', '>'];
      case FunnelConditionRule.AttributeEnum.TimeDate:
      case FunnelConditionRule.AttributeEnum.TimeTimeOfDay:
        return ['<', '<=', 'is', '>', '>='];
      default:
        return ['is', 'is not'];
    }
  }, []);

  return (
    <div className={clsx(blockClassName, route.groups.length > 1 && `${blockClassName}--moreThanOneGroup`)}>
      <FFCol className={getClass('groupsWrapper')}>
        <FFRow justifyContent="space-between" alignItems="center" marginBottom="18px">
          <div>
            <VisibilityWrapper visible={route.groups.length > 1}>
              <Controller
                name="rule.operator"
                control={control}
                render={(opt) => (
                  <Radio.Group
                    onChange={opt.field.onChange}
                    value={opt.field.value}
                    buttonStyle="solid"
                    className={getClass('routeOperator')}
                  >
                    <Radio.Button value="or">OR</Radio.Button>
                    <Radio.Button value="and">AND</Radio.Button>
                  </Radio.Group>
                )}
              />
            </VisibilityWrapper>
          </div>
          <FFButton iconName="general/line/add-circle" className={getClass('addGroupButton')} onClick={onAddGroup}>
            Group
          </FFButton>
        </FFRow>
        {route.groups.map((group, idGroup) => (
          <FFCol key={idGroup} className={getClass('group', group.rules.length > 1 && 'moreThanOneRule')}>
            <div className={getClass('groupInnerContent')}>
              <FFRow justifyContent="space-between" alignItems="center" marginBottom="12px">
                <div>
                  <VisibilityWrapper visible={group.rules.length > 1}>
                    <Controller
                      name={`rule.groups.${idGroup}.operator`}
                      control={control}
                      render={(opt) => (
                        <Radio.Group
                          onChange={opt.field.onChange}
                          value={opt.field.value}
                          buttonStyle="solid"
                          className={getClass('groupOperator')}
                        >
                          <Radio.Button value="or">OR</Radio.Button>
                          <Radio.Button value="and">AND</Radio.Button>
                        </Radio.Group>
                      )}
                    />
                  </VisibilityWrapper>
                </div>
                <FFRow alignItems="center" gap="12px">
                  <FFButton
                    iconName="general/line/add-circle"
                    size="small"
                    className={getClass('addGroupButton')}
                    onClick={() => onAddRule(idGroup)}
                  >
                    Rule
                  </FFButton>
                  <FFNewIcon
                    name="general/line/recycle-bin"
                    size="sm"
                    className={getClass('deleteIcon')}
                    onClick={() => onDeleteGroup(idGroup)}
                  />
                </FFRow>
              </FFRow>
              {group.rules.map((rule, idRule) => (
                <FFRow key={idRule} alignItems="center" gap="12px" marginBottom="12px">
                  <FFCol flex="1">
                    <FFRow flex="1" gap="8px" alignItems="center">
                      <Controller
                        name={`rule.groups.${idGroup}.rules.${idRule}.attribute`}
                        control={control}
                        rules={{ required: 'Required' }}
                        render={(opt) => (
                          <FFField block>
                            <FFSelect
                              placeholder="Select field"
                              popupMatchSelectWidth={false}
                              options={attributeFields}
                              valueGetter={(opt) => opt.value}
                              labelGetter={(opt) => opt.label}
                              value={rule.attribute}
                              onChange={opt.field.onChange}
                              groupOptions={true}
                              sortGroup
                              showSearch
                              autoFocus
                              error={opt.fieldState.error?.message}
                              className={getClass('selectField')}
                            />
                          </FFField>
                        )}
                      />
                      <VisibilityWrapper visible={Boolean(rule.attribute)}>
                        <>
                          <RouteGroupRule
                            rule={rule}
                            idGroup={idGroup}
                            idRule={idRule}
                            trafficSources={trafficSources}
                            domains={domains}
                            control={control}
                          >
                            <Controller
                              name={`rule.groups.${idGroup}.rules.${idRule}.test`}
                              rules={{ required: 'Required' }}
                              control={control}
                              render={(opt) => (
                                <FFSelect
                                  onChange={opt.field.onChange}
                                  value={opt.field.value}
                                  style={{ width: 130 }}
                                  placeholder="Operator"
                                  popupMatchSelectWidth={false}
                                  options={getTestOptions(rule.attribute)}
                                  valueGetter={(opt) => opt}
                                  labelGetter={(opt) => opt.toUpperCase()}
                                  sortGroup
                                  showSearch
                                  autoFocus
                                  error={opt.fieldState.error?.message}
                                  className={getClass('selectOperator')}
                                />
                              )}
                            />
                          </RouteGroupRule>
                        </>
                      </VisibilityWrapper>
                    </FFRow>
                  </FFCol>
                  <FFCol flexGrow={0}>
                    <FFNewIcon
                      name="general/line/recycle-bin"
                      size="sm"
                      className={getClass('deleteIcon')}
                      onClick={() => onDeleteRule(idGroup, idRule)}
                    />
                  </FFCol>
                </FFRow>
              ))}
            </div>
          </FFCol>
        ))}
      </FFCol>
    </div>
  );
};

export default FlowRule;
