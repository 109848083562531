/**
 * FunnelFlux Pro Ledger API
 * API endpoints to manage customers' assets
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface FunnelNodeExternalUrlParams { 
    /**
     * URL to redirect to
     */
    url: string;
    /**
     * What http code should be used to redirect to the external URL.
     */
    redirectType?: FunnelNodeExternalUrlParams.RedirectTypeEnum;
}
export namespace FunnelNodeExternalUrlParams {
    export type RedirectTypeEnum = '301' | '307';
    export const RedirectTypeEnum = {
        _301: '301' as RedirectTypeEnum,
        _307: '307' as RedirectTypeEnum
    };
}
