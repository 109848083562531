import {
  FacebookCustomScenarioProps,
  GoogleAdsCustomScenarioProps,
  KwaiCustomScenarioProps,
  MicrosoftAdsCustomScenarioProps,
  RedditCustomScenarioProps,
  SnapchatCustomScenarioProps,
  TikTokCustomScenarioProps,
  TwitterCustomScenarioProps,
} from '@/types/forms/trafficSource';

export const getFacebookPostbackUrl = ({
  accessToken = 'ACCESS_TOKEN_HERE',
  pixelID = 'PIXEL_ID_HERE',
  eventName = 'EVENT_HERE' as any,
  sendTestEventCode = false,
  sendOverridingEventURL = false,
  testEventCode,
  eventURLToUse = '{page-url}',
  customEventName,
}: FacebookCustomScenarioProps) =>
  `https://functions.funnelflux.pro/facebook-s2s-api?fbclid={external}&funnel_id={funnel-id}&hit_id={hit}&vid={visitor}&revenue={payout}&currency=USD${
    sendOverridingEventURL ? `&page_url=${eventURLToUse}` : '&page_url={page-url}'
  }&ip={ip}&user_agent={user-agent}&entrance_timestamp={entrance-timestamp}&conversion_timestamp={conversion-time}&txid={txid}&offer_id=&offer_cat=&access_token=${accessToken}&pixel_id=${pixelID}&event_name=${eventName === 'custom-manual-value' ? customEventName : eventName}${
    sendTestEventCode && testEventCode ? `&test_event_code=${testEventCode}` : ''
  }`;

export const getTwitterPostbackUrl = ({
  userId = 'REFRESH_TOKEN_HERE',
  pixelId = 'EVENT_HERE',
  pixelEventId = 'EVENT_ID_HERE',
}: TwitterCustomScenarioProps) =>
  `https://functions.funnelflux.pro/twitter-s2s-api?user_id=${userId}&pixel_id=${pixelId}&event_id=tw-${pixelId}-${pixelEventId}&conversion_time={timestamp}&twclid={external}&conversion_id={hit}-{txid}&conversion_value={payout}`;

export const getGooglePostbackUrl = ({ refreshToken, accountId, conversionActionId, managerAccountId }: GoogleAdsCustomScenarioProps) =>
  `https://functions.funnelflux.pro/google-s2s-ingest?refresh_token=${refreshToken}&ad_account_id=${accountId}&conversion_timestamp={conversion-time}&entrance_timestamp={entrance-timestamp}&gclid={external}&wbraid={data-wbraid}&gbraid={data-gbraid}&value={payout}&currency_code=USD&conversion_action_id=${conversionActionId}&order_id={visitor}-{txid}${
    managerAccountId ? `&manager_account_id=${managerAccountId}` : ''
  }`;

export const getKwaiPostbackUrl = ({
  accessToken = 'ACCESS_TOKEN_HERE',
  pixelID = 'PIXEL_ID_HERE',
  eventName = 'EVENT_HERE',
  sendTestEventCode = false,
}: KwaiCustomScenarioProps) =>
  `https://functions.funnelflux.pro/kwai-s2s-api?access_token=${accessToken}&pixel_id=${pixelID}&event_name=${eventName}&click_id={external}&revenue={payout}${
    sendTestEventCode ? `&test_event=true` : ''
  }`;

export const getReditPostbackUrl = ({ refreshToken, eventName }: RedditCustomScenarioProps) =>
  `https://functions.funnelflux.pro/reddit-s2s-api?click_id={external}&conversion_timestamp={timestamp}&value={payout}&transaction_id={txid}&ip={ip}&user_agent={user-agent}&account_id={data-account_id}&tracking_type=${eventName}&refresh_token=${refreshToken}`;

export const getMicrosoftAdsPostbackUrl = ({ refreshToken, adAccountId, customerId, conversionGoalId }: MicrosoftAdsCustomScenarioProps) =>
  `https://functions.funnelflux.pro/msads-s2s-ingest?refresh_token=${refreshToken}&ad_account_id=${adAccountId}&customer_id=${customerId}&conversion_timestamp={conversion-time}&click_timestamp={entrance-timestamp}&msclkid={external}&revenue={payout}&currency=USD&conversion_goal_id=${conversionGoalId}`;

export const getSnapchatPostbackUrl = ({
  accessToken = 'ACCESS_TOKEN_HERE',
  pixelID = 'PIXEL_ID_HERE',
  eventType = 'EVENT_HERE',
  sendOverridingEventURL = false,
  eventURLToUse = '{page-url}',
}: SnapchatCustomScenarioProps) =>
  `https://functions.funnelflux.pro/snapchat-s2s-api?click_id={external}${
    sendOverridingEventURL ? `&page_url=${eventURLToUse}` : '&page_url={page-url}'
  }&transaction_id={hit}-{txid}&revenue={payout}&currency=USD&ip={ip}&user_agent={user-agent}&conversion_timestamp={conversion-time}&access_token=${accessToken}&pixel_id=${pixelID}&event_type=${eventType}&event_tag={funnel-id}&item_id={page-id}&item_category=`;

export const getTikTokPostbackUrl = ({
  accessToken = 'ACCESS_TOKEN_HERE',
  pixelID = 'PIXEL_ID_HERE',
  eventName = 'EVENT_HERE',
  sendTestEventCode = false,
  testEventCode,
}: TikTokCustomScenarioProps) =>
  `https://functions.funnelflux.pro/tiktok-s2s-api?access_token=${accessToken}&pixel_id=${pixelID}&event_name=${eventName}&vid={visitor}&ttclid={external}&currency=USD&revenue={payout}&ip={ip}&user_agent={user-agent}&page_url={page-url}&conversion_timestamp={conversion-time}${
    sendTestEventCode && testEventCode ? `&test_event_code=${testEventCode}` : ''
  }`;
