const Expand = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.57 -0.57 16 16">
    <g id="hierarchy-2--node-organization-links-structure-link-nodes-network-hierarchy">
      <path
        id="Vector"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.1228571428571428 10.614285714285714V8.491428571428571c0 -0.2815120857142857 0.11183211428571428 -0.5514864428571428 0.31088181428571426 -0.7505467571428571C2.6327992714285715 7.5418321142857145 2.9027736285714285 7.43 3.184285714285714 7.43h8.491428571428571c0.2814908571428571 0 0.5515182857142856 0.11183211428571428 0.7505361428571428 0.31088181428571426C12.625268285714286 7.9399421285714284 12.737142857142857 8.209916485714285 12.737142857142857 8.491428571428571v2.1228571428571428"
        strokeWidth="1.14"
      ></path>
      <path
        id="Vector_2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.43 4.2457142857142856v6.368571428571428"
        strokeWidth="1.14"
      ></path>
      <path
        id="Vector 2521"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5.837857142857143 2.1228571428571428v1.0614285714285714c0 0.5862057714285714 0.4752228 1.0614285714285714 1.0614285714285714 1.0614285714285714h1.0614285714285714c0.5862057714285714 0 1.0614285714285714 -0.4752228 1.0614285714285714 -1.0614285714285714V2.1228571428571428c0 -0.5862057714285714 -0.4752228 -1.0614285714285714 -1.0614285714285714 -1.0614285714285714h-1.0614285714285714c-0.5862057714285714 0 -1.0614285714285714 0.4752228 -1.0614285714285714 1.0614285714285714Z"
        strokeWidth="1.14"
      ></path>
      <path
        id="Vector 2522"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5.837857142857143 11.675714285714285v1.0614285714285714c0 0.5862269999999999 0.4752228 1.0614285714285714 1.0614285714285714 1.0614285714285714h1.0614285714285714c0.5862057714285714 0 1.0614285714285714 -0.4752015714285714 1.0614285714285714 -1.0614285714285714v-1.0614285714285714c0 -0.5862269999999999 -0.4752228 -1.0614285714285714 -1.0614285714285714 -1.0614285714285714h-1.0614285714285714c-0.5862057714285714 0 -1.0614285714285714 0.4752015714285714 -1.0614285714285714 1.0614285714285714Z"
        strokeWidth="1.14"
      ></path>
      <path
        id="Vector 2523"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M0.5307142857142857 11.675714285714285v1.0614285714285714c0 0.5862269999999999 0.4752174928571428 1.0614285714285714 1.0614285714285714 1.0614285714285714h1.0614285714285714c0.5862057714285714 0 1.0614285714285714 -0.4752015714285714 1.0614285714285714 -1.0614285714285714v-1.0614285714285714c0 -0.5862269999999999 -0.4752228 -1.0614285714285714 -1.0614285714285714 -1.0614285714285714h-1.0614285714285714c-0.5862110785714286 0 -1.0614285714285714 0.4752015714285714 -1.0614285714285714 1.0614285714285714Z"
        strokeWidth="1.14"
      ></path>
      <path
        id="Vector 2524"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.145 11.675714285714285v1.0614285714285714c0 0.5862269999999999 0.4752015714285714 1.0614285714285714 1.0614285714285714 1.0614285714285714h1.0614285714285714c0.5862269999999999 0 1.0614285714285714 -0.4752015714285714 1.0614285714285714 -1.0614285714285714v-1.0614285714285714c0 -0.5862269999999999 -0.4752015714285714 -1.0614285714285714 -1.0614285714285714 -1.0614285714285714h-1.0614285714285714c-0.5862269999999999 0 -1.0614285714285714 0.4752015714285714 -1.0614285714285714 1.0614285714285714Z"
        strokeWidth="1.14"
      ></path>
    </g>
  </svg>
);

export default Expand;