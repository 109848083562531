const Updates = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30">
    <g id="database-upload--database-upload-raid-storage-code-disk-programming-array-hard-disc">
      <path
        id="Vector"
        fill="currentColor"
        strokeOpacity="0"
        d="M11.944285714285714 9.4362246c6.046933992857143 0 10.94892857142857 -1.8895063714285714 10.94892857142857 -4.220314285714286v13.457208664285714c0 2.3291158071428573 -4.857342857142857 4.2003872357142855 -10.94892857142857 4.220294378571428 -6.091585714285714 -0.019907142857142856 -10.94892857142857 -1.8911785714285714 -10.94892857142857 -4.220294378571428V5.215910314285714c0 2.3308079142857143 4.901994578571428 4.220314285714286 10.94892857142857 4.220314285714286Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_2"
        fill="#ffffff"
        d="M11.944285714285714 9.435985714285714c6.046933992857143 0 10.94892857142857 -1.8895063714285714 10.94892857142857 -4.220314285714286S17.991219707142857 0.9953571428571428 11.944285714285714 0.9953571428571428 0.9953571428571428 2.884863514285714 0.9953571428571428 5.2156714285714285 5.897351721428571 9.435985714285714 11.944285714285714 9.435985714285714Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_3"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.944285714285714 9.435985714285714c6.046933992857143 0 10.94892857142857 -1.8895063714285714 10.94892857142857 -4.220314285714286S17.991219707142857 0.9953571428571428 11.944285714285714 0.9953571428571428 0.9953571428571428 2.884863514285714 0.9953571428571428 5.2156714285714285 5.897351721428571 9.435985714285714 11.944285714285714 9.435985714285714Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_4"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m18.911785714285713 20.9025 3.9814285714285713 -3.9814285714285713 3.9814285714285713 3.9814285714285713"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M22.893214285714286 16.921071428571427v9.95357142857143"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_6"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M0.9953571428571428 5.215910314285714v13.45722857142857C0.9953571428571428 21.002234785714286 5.972142857142857 22.893413357142855 11.944285714285714 22.893413357142855c0.7777720714285714 0 1.5386429785714284 -0.032050499999999996 2.2738535785714284 -0.09296635714285714"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_7"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M0.9953571428571428 11.944285714285714C0.9953571428571428 14.273421428571428 5.972142857142857 16.164599999999997 11.944285714285714 16.164599999999997s10.94892857142857 -1.8911785714285714 10.94892857142857 -4.220314285714286"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_8"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M22.893214285714286 11.9445246v-6.728614285714285"
        strokeWidth="2.13"
      ></path>
    </g>
  </svg>
);

export default Updates;
