import React from 'react';

const FunnelFlux = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 163.6516 142">
    <g id="b">
      <g id="c">
        <path
          style={{ fill: '#fff' }}
          d="M160.3713,36.7524c1.9936-2.4646,3.1435-5.504,3.2803-8.671V.3656l-51.5139,18.6374c-17.9479,6.5189-25.8458,17.4673-25.8458,47.6068l-.6268,74.842c7.7163-24.2442,19.7495-46.8949,35.5197-66.8605"
        />
        <path
          style={{ fill: '#fff' }}
          d="M3.2803,36.3868c-1.9936-2.4646-3.1435-5.504-3.2803-8.671V0L51.514,18.6374c17.9374,6.5189,25.7935,17.4673,25.7935,47.6068,.2089,24.9473,.4214,49.8982,.6373,74.8525-7.7208-24.2424-19.7535-46.8923-35.5197-66.8605"
        />
      </g>
    </g>
  </svg>
);

export default FunnelFlux;
