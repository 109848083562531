const SimpleFlow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30">
    <g id="hierarchy-4--node-organization-links-structure-link-nodes-network-hierarchy">
      <path
        id="Vector 2519"
        fill="#d7e0ff"
        d="M9.95357142857143 0.9953571428571428H5.972142857142857c-1.0994316857142856 0 -1.9907142857142857 0.8912726464285714 -1.9907142857142857 1.9907142857142857v3.9814285714285713c0 1.0994316857142856 0.8912826 1.9907142857142857 1.9907142857142857 1.9907142857142857h3.9814285714285713c1.0994316857142856 0 1.9907142857142857 -0.8912826 1.9907142857142857 -1.9907142857142857v-3.9814285714285713c0 -1.0994416392857143 -0.8912826 -1.9907142857142857 -1.9907142857142857 -1.9907142857142857Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector 2520"
        fill="#d7e0ff"
        d="M9.95357142857143 18.911785714285713H5.972142857142857c-1.0994316857142856 0 -1.9907142857142857 0.8912826 -1.9907142857142857 1.9907142857142857v3.9814285714285713c0 1.0994715 0.8912826 1.9907142857142857 1.9907142857142857 1.9907142857142857h3.9814285714285713c1.0994316857142856 0 1.9907142857142857 -0.8912427857142856 1.9907142857142857 -1.9907142857142857v-3.9814285714285713c0 -1.0994316857142856 -0.8912826 -1.9907142857142857 -1.9907142857142857 -1.9907142857142857Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector 2521"
        fill="#d7e0ff"
        d="M21.89785714285714 10.94892857142857H17.916428571428572c-1.0994316857142856 0 -1.9907142857142857 0.8912826 -1.9907142857142857 1.9907142857142857v3.9814285714285713c0 1.0994316857142856 0.8912826 1.9907142857142857 1.9907142857142857 1.9907142857142857h3.9814285714285713c1.0994715 0 1.9907142857142857 -0.8912826 1.9907142857142857 -1.9907142857142857v-3.9814285714285713c0 -1.0994316857142856 -0.8912427857142856 -1.9907142857142857 -1.9907142857142857 -1.9907142857142857Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector 2522"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.95357142857143 0.9953571428571428H5.972142857142857c-1.0994316857142856 0 -1.9907142857142857 0.8912726464285714 -1.9907142857142857 1.9907142857142857v3.9814285714285713c0 1.0994316857142856 0.8912826 1.9907142857142857 1.9907142857142857 1.9907142857142857h3.9814285714285713c1.0994316857142856 0 1.9907142857142857 -0.8912826 1.9907142857142857 -1.9907142857142857v-3.9814285714285713c0 -1.0994416392857143 -0.8912826 -1.9907142857142857 -1.9907142857142857 -1.9907142857142857Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector 2523"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.95357142857143 18.911785714285713H5.972142857142857c-1.0994316857142856 0 -1.9907142857142857 0.8912826 -1.9907142857142857 1.9907142857142857v3.9814285714285713c0 1.0994715 0.8912826 1.9907142857142857 1.9907142857142857 1.9907142857142857h3.9814285714285713c1.0994316857142856 0 1.9907142857142857 -0.8912427857142856 1.9907142857142857 -1.9907142857142857v-3.9814285714285713c0 -1.0994316857142856 -0.8912826 -1.9907142857142857 -1.9907142857142857 -1.9907142857142857Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector 2524"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M21.89785714285714 10.94892857142857H17.916428571428572c-1.0994316857142856 0 -1.9907142857142857 0.8912826 -1.9907142857142857 1.9907142857142857v3.9814285714285713c0 1.0994316857142856 0.8912826 1.9907142857142857 1.9907142857142857 1.9907142857142857h3.9814285714285713c1.0994715 0 1.9907142857142857 -0.8912826 1.9907142857142857 -1.9907142857142857v-3.9814285714285713c0 -1.0994316857142856 -0.8912427857142856 -1.9907142857142857 -1.9907142857142857 -1.9907142857142857Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.962857142857143 8.958214285714286v9.95357142857143"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_2"
        stroke="#48508b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.925714285714285 14.930357142857142H13.934999999999999c-1.5839118214285712 0 -3.1029462642857144 -0.6292050642857143 -4.2229420285714285 -1.7492008285714284C8.592062207142856 12.061160549999999 7.962857142857143 10.542126107142858 7.962857142857143 8.958214285714286"
        strokeWidth="2.13"
      ></path>
    </g>
  </svg>
);

export default SimpleFlow;