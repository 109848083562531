const ArrowDown = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <g id="arrow-down-3">
      <path 
      id="Vector" 
      d="M20.775,7.7465l-8.3025,8.3025c-.0606.0646-.1338.116-.2151.1512-.0813.0351-.1689.0533-.2574.0533s-.1762-.0182-.2574-.0533c-.0813-.0352-.1545-.0867-.2151-.1512L3.225,7.7465" 
      fill="none"
      stroke="#333"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2" />
    </g>
  </svg>
);

export default ArrowDown;
