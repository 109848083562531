const RecycleBin = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.855 -0.855 24 24">
    <g id="recycle-bin-2--remove-delete-empty-bin-trash-garbage">
      <path
        id="Vector"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1.592142857142857 5.5725h19.105714285714285"
        strokeWidth="1.71"
      ></path>
      <path
        id="Vector_2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.9803571428571427 5.5725h14.329285714285714v14.329285714285714c0 0.4222362857142857 -0.16781185714285712 0.8272774285714285 -0.4663386428571428 1.125804214285714s-0.7035679285714286 0.4663386428571428 -1.125804214285714 0.4663386428571428h-11.145c-0.4222681285714286 0 -0.8272296642857142 -0.16781185714285712 -1.1258201357142856 -0.4663386428571428C4.148105314285714 20.729063142857143 3.9803571428571427 20.324022 3.9803571428571427 19.901785714285715v-14.329285714285714Z"
        strokeWidth="1.71"
      ></path>
      <path
        id="Vector_3"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.164642857142857 5.5725V4.776428571428571c0 -1.0556543999999999 0.41935450714285716 -2.068082121428571 1.1658147642857144 -2.8145423785714287C9.076917878571427 1.2154291199999998 10.0893456 0.7960714285714285 11.145 0.7960714285714285c1.0556543999999999 0 2.068082121428571 0.41935769142857143 2.8145423785714287 1.1658147642857144C14.706002635714286 2.70834645 15.125357142857142 3.7207741714285714 15.125357142857142 4.776428571428571v0.7960714285714285"
        strokeWidth="1.71"
      ></path>
      <path
        id="Vector_4"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.756785714285714 10.3512531V16.72227642857143"
        strokeWidth="1.71"
      ></path>
      <path
        id="Vector_5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.533214285714285 10.3512531V16.72227642857143"
        strokeWidth="1.71"
      ></path>
    </g>
  </svg>
);

export default RecycleBin;