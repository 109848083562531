/**
 * FunnelFlux Pro Ledger API
 * API endpoints to manage customers' assets
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface TrackingField { 
    name: string;
    previousName?: string;
    value: string;
    status?: TrackingField.StatusEnum;
}
export namespace TrackingField {
    export type StatusEnum = 'active' | 'archived' | 'deleted';
    export const StatusEnum = {
        Active: 'active' as StatusEnum,
        Archived: 'archived' as StatusEnum,
        Deleted: 'deleted' as StatusEnum
    };
}
