import React from 'react';
import { DatePicker } from 'antd';
import { getTimeRanges, timeZoneOptions } from '@/constants/time';
import VisibilityWrapper from '../VisibilityWrapper';
import { RangePickerProps } from 'antd/es/date-picker';
import { defined } from '@/utils/define';
import { DatePickerValues, getDatePickerValues } from '@/utils/time-helper';
import { TimeRangeKeys } from '@/types/time';
import { Dayjs } from 'dayjs';
import FFSelect from '../Select';
import className from '@/utils/className';
import clsx from 'clsx';
import './style.scss';

type NoUndefinedRangeValueType = [start: Dayjs, end: Dayjs];

let timeRangeItems = getTimeRanges();

const { blockClassName, getClass } = className('c-ffDatepicker');

type onDatePickerChange = (value: DatePickerValues) => void | Promise<void>;
type SetRangeName = (rangeKey: TimeRangeKeys, isChangedByCalendar: boolean) => void;
type Ranges = {
  short: {
    name: TimeRangeKeys;
    value: NoUndefinedRangeValueType;
  }[];
  long: {
    name: TimeRangeKeys;
    value: NoUndefinedRangeValueType;
  }[];
};
type onOpenChange = (isOpen: boolean, isChanged?: boolean) => void;

const getRanges = (): Ranges => ({
  short: [
    {
      name: 'Today',
      value: timeRangeItems.Today,
    },
    {
      name: 'Yesterday',
      value: timeRangeItems.Yesterday,
    },
    {
      name: 'Last 24 h',
      value: timeRangeItems['Last 24 h'],
    },
    {
      name: 'Last 72 h',
      value: timeRangeItems['Last 72 h'],
    },
    {
      name: 'Last 7 days',
      value: timeRangeItems['Last 7 days'],
    },
    {
      name: 'Last 14 days',
      value: timeRangeItems['Last 14 days'],
    },
  ],
  long: [
    {
      name: 'Last 30 days',
      value: timeRangeItems['Last 30 days'],
    },
    {
      name: 'This Month',
      value: timeRangeItems['This Month'],
    },
    {
      name: 'Last Month',
      value: timeRangeItems['Last Month'],
    },
    {
      name: '90 Days',
      value: timeRangeItems['90 Days'],
    },
    {
      name: 'This Year',
      value: timeRangeItems['This Year'],
    },
    {
      name: 'Last Year',
      value: timeRangeItems['Last Year'],
    },
  ],
});

const onDateTimeSelect = () => {
  const okBtn = document.querySelector('.ant-picker-dropdown .ant-picker-ok button') as HTMLButtonElement;
  requestAnimationFrame(() => {
    if (defined(okBtn)) {
      okBtn.click();
    }
  });
};

const ExtraFooter = ({
  onChange,
  setOpen,
  onOpenChange,
  setRangeName,
  ranges,
}: {
  onChange: onDatePickerChange;
  onOpenChange: onOpenChange;
  setOpen: (isOpen: boolean) => void;
  setRangeName: SetRangeName;
  ranges: Ranges;
}) => {
  const onChangeAndClose = async (value: NoUndefinedRangeValueType, dateRangeName: TimeRangeKeys) => {
    await setRangeName(dateRangeName, false);
    await onChange(getDatePickerValues(value, true));
    setOpen(false);
    onOpenChange!(false, true);
  };

  return (
    <div className={getClass('footer')}>
      <div className={getClass('footerRow')}>
        <span className={getClass('footerRowTitle')}>SHORT:</span>
        {ranges.short.map((timeRange) => (
          <button key={timeRange.name} className={getClass('footerRowBtn')} onClick={() => onChangeAndClose(timeRange.value, timeRange.name)}>
            {timeRange.name}
          </button>
        ))}
      </div>
      <div className={getClass('footerRow')}>
        <span className={getClass('footerRowTitle')}>LONG:</span>
        {ranges.long.map((timeRange) => (
          <button key={timeRange.name} className={getClass('footerRowBtn')} onClick={() => onChangeAndClose(timeRange.value, timeRange.name)}>
            {timeRange.name}
          </button>
        ))}
      </div>
    </div>
  );
};

const FFDatePicker = ({
  onChange = () => {},
  format = 'DD/MM/YYYY HH:mm',
  onCalendarChange,
  onOpenChange = () => {},
  className,
  value,
  showTimeZone = true,
  timeZone = { onTimeZoneChange: () => {} },
  setRangeName = () => {},
  disabled = false,
  calendarDisabled = false,
  showTime = true,
}: Omit<RangePickerProps, 'picker' | 'onChange' | 'onCalendarChange' | 'value' | 'onOpenChange'> & {
  showTimeZone?: boolean;
  showTime?: boolean;
  onChange: onDatePickerChange;
  onCalendarChange?: onDatePickerChange;
  setRangeName?: SetRangeName;
  onOpenChange?: onOpenChange;
  timeZone?: {
    placeholder?: string;
    changeDropDownPosition?: boolean;
    disabled?: boolean;
    onTimeZoneChange?: (timeZone: string) => void;
  };
  value: DatePickerValues;
  disabled?: boolean;
  calendarDisabled?: boolean;
}) => {
  const [open, setOpen] = React.useState(false);
  const [ranges, setRanges] = React.useState<Ranges>(getRanges());

  return (
    <>
      <DatePicker.RangePicker
        disabled={disabled || calendarDisabled}
        className={clsx(blockClassName, {
          [`${className}`]: Boolean(className),
        })}
        dateRender={(currentDate) => (
          <div className="ant-picker-cell-inner" onClick={onDateTimeSelect}>
            {currentDate.date()}
          </div>
        )}
        showTime={
          showTime && {
            format: 'HH:mm',
          }
        }
        open={open}
        onOpenChange={(isOpen) => {
          if (isOpen) {
            timeRangeItems = getTimeRanges();
            setRanges(getRanges());
          }
          setOpen(isOpen);
        }}
        value={value?.date as any}
        format={format}
        onChange={(value) => {
          if (!value || value.length !== 2) {
            return;
          }
          if (!showTime && value.length > 1) {
            value[1]!.hour(23).minute(59).second(59);
          }
          onChange({
            ...getDatePickerValues(value as NoUndefinedRangeValueType),
            isChangedByCalendar: true,
          });
        }}
        onCalendarChange={async (value, f, info) => {
          if (info.range === 'start') return;
          if (onCalendarChange) {
            await onCalendarChange({
              ...getDatePickerValues(value as NoUndefinedRangeValueType),
              isChangedByCalendar: true,
            });
          } else {
            await onChange({
              ...getDatePickerValues(value as NoUndefinedRangeValueType),
              isChangedByCalendar: true,
            });
          }
          await setRangeName('' as any, false);
          if (info.range === 'end') {
            await onOpenChange(false, true);
          }
        }}
        renderExtraFooter={() => (
          <ExtraFooter onChange={onChange} setOpen={setOpen} onOpenChange={onOpenChange} setRangeName={setRangeName} ranges={ranges} />
        )}
      />
      <VisibilityWrapper visible={showTimeZone}>
        <FFSelect
          value={value?.timeZone}
          onChange={async (val: string) => {
            localStorage.setItem('timeZone', val);
            timeRangeItems = getTimeRanges();
            await onChange(getDatePickerValues([value.date[0]!, value.date[1]!], false));
            await setRanges(getRanges());
            await timeZone.onTimeZoneChange!(val);
            if (defined(onOpenChange)) {
              await onOpenChange(false, true);
            }
          }}
          options={timeZoneOptions}
          placeholder={timeZone?.placeholder}
          className={getClass('timezone')}
          valueGetter={(option) => option.value}
          labelGetter={(option) => option.label}
          showSearch={true}
          filterOption={true}
          data-testid="timezone"
          popupMatchSelectWidth={false}
          disabled={timeZone?.disabled || disabled}
        />
      </VisibilityWrapper>
    </>
  );
};

export default FFDatePicker;
