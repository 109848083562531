const Duplicate = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
    <g id="merge-PDF--essential-files-folder-merge-PDF">
      <path
        id="Vector 3894"
        fill="#d7e0ff"
        d="M4 12.5V4c0 -0.55228 0.44772 -1 1 -1h5l2.5 2.5v7c0 0.5523 -0.4477 1 -1 1H5c-0.55228 0 -1 -0.4477 -1 -1Z"
        strokeWidth="1"
      ></path>
      <path
        id="Vector 3898"
        stroke="#4147d5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4 12.5V4c0 -0.55228 0.44772 -1 1 -1h5l2.5 2.5v7c0 0.5523 -0.4477 1 -1 1H5c-0.55228 0 -1 -0.4477 -1 -1Z"
        strokeWidth="1"
      ></path>
      <path
        id="Vector 3895"
        stroke="#4147d5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1.5 10V1.5c0 -0.552285 0.44772 -1 1 -1h5"
        strokeWidth="1"
      ></path>
      <path id="Vector 3896" stroke="#4147d5" strokeLinecap="round" strokeLinejoin="round" d="M6.25 8.5h4" strokeWidth="1"></path>
      <path id="Vector 3897" stroke="#4147d5" strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.5v4" strokeWidth="1"></path>
    </g>
  </svg>
);

export default Duplicate;
