const Dashboard = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 29.95 29.95">
    <g id="dashboard-square--app-application-dashboard-home-layout-square">
      <path
        id="Vector"
        fill="currentColor"
        d="M8.942142857142857 0.9935714285714285h-5.961428571428572c-1.097469192857143 0 -1.987142857142857 0.8896736642857143 -1.987142857142857 1.987142857142857v5.961428571428572c0 1.0974592571428572 0.8896736642857143 1.987142857142857 1.987142857142857 1.987142857142857h5.961428571428572c1.0974592571428572 0 1.987142857142857 -0.8896836 1.987142857142857 -1.987142857142857v-5.961428571428572c0 -1.097469192857143 -0.8896836 -1.987142857142857 -1.987142857142857 -1.987142857142857Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_2"
        fill="currentColor"
        d="M24.839285714285715 0.9935714285714285h-5.961428571428572c-1.0974592571428572 0 -1.987142857142857 0.8896736642857143 -1.987142857142857 1.987142857142857v5.961428571428572c0 1.0974592571428572 0.8896836 1.987142857142857 1.987142857142857 1.987142857142857h5.961428571428572c1.097499 0 1.987142857142857 -0.8896836 1.987142857142857 -1.987142857142857v-5.961428571428572c0 -1.097469192857143 -0.8896438571428571 -1.987142857142857 -1.987142857142857 -1.987142857142857Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_3"
        fill="currentColor"
        d="M8.942142857142857 16.890714285714285h-5.961428571428572c-1.097469192857143 0 -1.987142857142857 0.8896836 -1.987142857142857 1.987142857142857v5.961428571428572c0 1.097499 0.8896736642857143 1.987142857142857 1.987142857142857 1.987142857142857h5.961428571428572c1.0974592571428572 0 1.987142857142857 -0.8896438571428571 1.987142857142857 -1.987142857142857v-5.961428571428572c0 -1.0974592571428572 -0.8896836 -1.987142857142857 -1.987142857142857 -1.987142857142857Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_4"
        fill="currentColor"
        d="M24.839285714285715 16.890714285714285h-5.961428571428572c-1.0974592571428572 0 -1.987142857142857 0.8896836 -1.987142857142857 1.987142857142857v5.961428571428572c0 1.097499 0.8896836 1.987142857142857 1.987142857142857 1.987142857142857h5.961428571428572c1.097499 0 1.987142857142857 -0.8896438571428571 1.987142857142857 -1.987142857142857v-5.961428571428572c0 -1.0974592571428572 -0.8896438571428571 -1.987142857142857 -1.987142857142857 -1.987142857142857Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.942142857142857 0.9935714285714285h-5.961428571428572c-1.097469192857143 0 -1.987142857142857 0.8896736642857143 -1.987142857142857 1.987142857142857v5.961428571428572c0 1.0974592571428572 0.8896736642857143 1.987142857142857 1.987142857142857 1.987142857142857h5.961428571428572c1.0974592571428572 0 1.987142857142857 -0.8896836 1.987142857142857 -1.987142857142857v-5.961428571428572c0 -1.097469192857143 -0.8896836 -1.987142857142857 -1.987142857142857 -1.987142857142857Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_6"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M24.839285714285715 0.9935714285714285h-5.961428571428572c-1.0974592571428572 0 -1.987142857142857 0.8896736642857143 -1.987142857142857 1.987142857142857v5.961428571428572c0 1.0974592571428572 0.8896836 1.987142857142857 1.987142857142857 1.987142857142857h5.961428571428572c1.097499 0 1.987142857142857 -0.8896836 1.987142857142857 -1.987142857142857v-5.961428571428572c0 -1.097469192857143 -0.8896438571428571 -1.987142857142857 -1.987142857142857 -1.987142857142857Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_7"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.942142857142857 16.890714285714285h-5.961428571428572c-1.097469192857143 0 -1.987142857142857 0.8896836 -1.987142857142857 1.987142857142857v5.961428571428572c0 1.097499 0.8896736642857143 1.987142857142857 1.987142857142857 1.987142857142857h5.961428571428572c1.0974592571428572 0 1.987142857142857 -0.8896438571428571 1.987142857142857 -1.987142857142857v-5.961428571428572c0 -1.0974592571428572 -0.8896836 -1.987142857142857 -1.987142857142857 -1.987142857142857Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_8"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M24.839285714285715 16.890714285714285h-5.961428571428572c-1.0974592571428572 0 -1.987142857142857 0.8896836 -1.987142857142857 1.987142857142857v5.961428571428572c0 1.097499 0.8896836 1.987142857142857 1.987142857142857 1.987142857142857h5.961428571428572c1.097499 0 1.987142857142857 -0.8896438571428571 1.987142857142857 -1.987142857142857v-5.961428571428572c0 -1.0974592571428572 -0.8896438571428571 -1.987142857142857 -1.987142857142857 -1.987142857142857Z"
        strokeWidth="2.13"
      ></path>
    </g>
  </svg>
);

export default Dashboard;