import React, { PropsWithChildren } from 'react';
import { Checkbox, CheckboxProps } from 'antd';
import clsx from 'clsx';
import './style.scss';

const FFCheckbox = ({
  onChange,
  children,
  className = '',
  checked = false,
  ...props
}: PropsWithChildren<CheckboxProps>) => {
  return <Checkbox
  {...props}
  checked={checked}
  className={clsx(['c-ffCheckbox', className], {
    [`${className}--checked`]: checked && !!className,
    [`${className}--notChecked`]: !checked && !!className
  })}
  onChange={onChange}
>
  {children}
</Checkbox>
};

export default FFCheckbox;
