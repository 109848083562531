import { User } from '@/models/user';

declare global {
  interface Window {
    gist: any;
    gistIsLoaded: boolean;
    gistUnreadCount: number;
    Canny: any;
  }
}

const waitForGistLoad = () =>
  new Promise((res) => {
    if (window.gistIsLoaded) {
      res(true);
    } else {
      const intv = setInterval(() => {
        if (window.gistIsLoaded) {
          res(true);
          clearInterval(intv);
        }
      }, 700);
    }
  });

export const gistUtils = {
  openArticle: async (articleId: string) => {
    await waitForGistLoad();
    window.gist.chat('article', articleId);
    //requestAnimationFrame(gistUtils.setStyle);
  },
  open: async () => {
    await waitForGistLoad();
    window.gist.chat('sidebar');
    window.gist.chat('open');
  },
  contactUsRequest: async () => {
    await waitForGistLoad();
    window.gist.chat('sidebar');
    window.gist.chat('open');
    window.gist.chat("navigate", "newConversation", "Hi, I'm considering cancelling and would like to speak to someone about it.");
  },
  shutdown: async () => {
    await waitForGistLoad();
    setTimeout(() => {
      if (window?.gist?.chat) {
        window.gist.chat('shutdown');
      }
    }, 500);
  },
  trackPageView: async () => {
    await waitForGistLoad();
    setTimeout(() => window.gist.trackPageView(), 500);
  },
  identify: async (user: User) => {
    await waitForGistLoad();
    window.gist.identify(user.user_id, {
      email: user.email,
    });
  },
  hideLauncher: async () => {
    await waitForGistLoad();
    window.gist.chat('hideLauncher');
  },
  sidebar: async () => {
    await waitForGistLoad();
    window.gist.chat('sidebar');
  },
  close: async () => {
    await waitForGistLoad();
    window.gist.chat('close');
  },
  setStyle: () => {
    window.gist.on('open', () => {
      const iframe: any = document.querySelector('.gist-article-sidebar-iframe iframe');
      if (iframe?.contentDocument?.querySelector('.gist-close-icon')) {
        iframe.contentDocument!.querySelector('.gist-close-icon')!.style.display = 'none';
      }
    });
  },
};

export const loadGistScript = () => {
  if (document.querySelector('[src="https://widget.getgist.com"]')) {
    return;
  }

  (function (d, h, w) {
    let gist = (w.gist = w.gist || []);
    gist.methods = ['trackPageView', 'identify', 'track', 'setAppId'];
    gist.factory = function (t: any) {
      return function () {
        var e = Array.prototype.slice.call(arguments);
        e.unshift(t);
        gist.push(e);
        return gist;
      };
    };
    for (var i = 0; i < gist.methods.length; i++) {
      var c = gist.methods[i];
      gist[c] = gist.factory(c);
    }
    let s, e;
    s = d.createElement('script');
    s.src = 'https://widget.getgist.com';
    s.async = !0;
    e = d.getElementsByTagName(h)[0];
    e.appendChild(s);
    s.addEventListener(
      'load',
      function (e) {
        window.gistIsLoaded = true;
      },
      !1,
    );
    gist.setAppId('rsrn2hwa');
  })(document, 'head', window);
  document.addEventListener('gistReady', function () {
    gistUtils.hideLauncher();
  });
  document.addEventListener('gistChatReady', function () {
    gistUtils.sidebar();
  });
  document.addEventListener(
    'messenger:closed',
    function () {
      gistUtils.close();
      gistUtils.hideLauncher();
    },
    false,
  );
};

export const gistArticleIds = {
  trafficSources: '4',
  offerSources: '5',
  offers: '6',
  landers: '7',
  pageGroups: '8',
  updateTrafficCosts: '29',
  updateConversion: '28',
  resetData: '97',
  systemSettings: '98',
  domains: '122',
  funnelBuilder: '99',
  funnels: '100',
  conditions: '101',
  reporting: '102',
  rawEvents: '103',
  campaignAnalysis: '104',
  pageRedirectModes: '10',
  ipAnonymisation: '113',
  keywordRotationLinks: '136',
};
