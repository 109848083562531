import className from '@/utils/className';
import { FFCol, FFIconButton, FFInput, FFNewIcon, FFRow, FFSlider } from '@/uikit';
import { useMemo, useState } from 'react';
import useFormStore from '@/stores/forms';
import { PageGroup } from '@/models/pageGroup';
import AssetLogo from '@/components/AssetLogo';
import Skeleton from 'react-loading-skeleton';
import { Page } from '@/models/page';
import clsx from 'clsx';
import './style.scss';

const { blockClassName, getClass } = className('c-entrySlider');

const EntrySlider = <T extends { weight: number }>({
  sliderEntry,
  totalWeight,
  pageType,
  status = 'active',
  loading = false,
  isFlow = false,
  onChange,
  onDelete,
  getId,
  getName,
}: {
  sliderEntry: T;
  isFlow?: boolean;
  pageType?: PageGroup.PageTypeEnum;
  status?: Page.StatusEnum;
  totalWeight: number;
  loading?: boolean;
  onChange: (id: string, weight: number) => void;
  onDelete: (id: string) => void;
  getId: (sliderEntry: T) => string;
  getName: (sliderEntry: T) => string;
}) => {
  const { openLanderForm, openOfferForm, setLanderData, setOfferData } = useFormStore();

  const [weight, setWeight] = useState(sliderEntry.weight * 100);

  const sliderName = useMemo(() => getName(sliderEntry), [sliderEntry, getName]);

  const percent = useMemo(() => {
    if (sliderEntry.weight === 0 && totalWeight === 0) return '0.00%';
    return ((sliderEntry.weight / totalWeight) * 100).toFixed(2);
  }, [totalWeight, sliderEntry]);

  const isDeleted = useMemo(() => {
    return status === 'deleted';
  }, [status]);

  const onWeightChange = (value: number) => {
    setWeight(value);
    onChange(getId(sliderEntry), value / 100);
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = Number(e.target.value.replace(/[^0-9]/g, '') || 0);
    if (val < 0) val = 0;
    if (val > 100) val = 100;
    setWeight(val);
    onChange(getId(sliderEntry), val / 100);
  };

  const onEditPage = () => {
    if (pageType === PageGroup.PageTypeEnum.Lander) {
      setLanderData({ id: getId(sliderEntry), value: getName(sliderEntry) }, false);
      openLanderForm();
    } else {
      setOfferData({ id: getId(sliderEntry), value: getName(sliderEntry) }, false);
      openOfferForm();
    }
  };

  const onDeletePage = () => {
    onDelete(getId(sliderEntry));
  };

  const showSkeleton = useMemo(() => (loading && !sliderName ? true : false), [loading, sliderName]);

  return (
    <FFCol gap="8px" className={clsx(blockClassName, { [`${blockClassName}--deleted`]: isDeleted })}>
      <FFRow gap="8px" alignItems="center" className={getClass('header')}>
        {isFlow ? (
          <FFNewIcon name="general/duotone/simple-flow" />
        ) : (
          <AssetLogo assetType={pageType === 'lander' ? 'landerGroup' : 'offerGroup'} size="md" />
        )}
        <span className={getClass('entryName')}>
          {showSkeleton ? <Skeleton width={100} /> : sliderName} {isDeleted ? `(Deleted)` : ''}
        </span>
        {!isFlow && <FFNewIcon className={getClass('editIcon')} onClick={onEditPage} name="table/edit" size="sm" />}
      </FFRow>
      <FFRow gap="8px" alignItems="center">
        <FFSlider value={weight} step={10} className={getClass('slider')} onChange={onWeightChange} />
        <FFInput className={getClass('input')} value={weight} onChange={onInputChange} max={100} min={0} />
        <span>{percent}%</span>
        <FFIconButton buttonType="transparent" iconType="danger" iconName="general/line/recycle-bin" onClick={onDeletePage} />
      </FFRow>
    </FFCol>
  );
};

export default EntrySlider;
