import dayjs, { Dayjs } from 'dayjs';
import { TimeRangeKeys } from '@/types/time';
import { FFSelectOption } from '@/uikit/types/select';
import { getDatePickerValues, DatePickerValues, getTimeZone } from '@/utils/time-helper';
import { defined } from '../utils/define';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

type NoUndefinedRangeValueType = [start: Dayjs, end: Dayjs];

dayjs.extend(utc);
dayjs.extend(timezone);

export const DEFAULT_TIME_RANGE: TimeRangeKeys = 'Today';

export const timeZoneOptions: FFSelectOption[] = [
  { value: '-12:00', label: 'UTC-12:00 (USA, Baker Island, AoE)' },
  { value: '-11:00', label: 'UTC-11:00 (Niue, Alofi, NUT)' },
  { value: '-10:00', label: 'UTC-10:00 (USA, Honolulu, HST)' },
  { value: '-09:00', label: 'UTC-09:00 (USA, ADAK, HDT)' },
  { value: '-08:00', label: 'UTC-08:00 (USA, Alaska, AKDT)' },
  { value: '-07:00', label: 'UTC-07:00 (USA, Los Angeles, PDT)' },
  { value: '-06:00', label: 'UTC-06:00 (USA, Denver, MDT)' },
  { value: '-05:00', label: 'UTC-05:00 (USA, Chicago, CDT)' },
  { value: '-04:00', label: 'UTC-04:00 (USA, Atlanta, EDT)' },
  { value: '-03:00', label: 'UTC-03:00 (Brazil, Rio de Janeiro, BRT)' },
  { value: '-02:00', label: 'UTC-02:00 (Denmark, Nuuk, WGST)' },
  { value: '-01:00', label: 'UTC-01:00 (Cabo Verde, Praia, CVT)' },
  { value: '+00:00', label: "UTC+00:00 (Greenwich Mean Time, GMT)" },
  { value: '+01:00', label: 'UTC+01:00 (United Kingdom, London, BST)' },
  { value: '+02:00', label: 'UTC+02:00 (Germany, Berlin, CEST)' },
  { value: '+03:00', label: 'UTC+03:00 (Russia, Moscow, MSK)' },
  { value: '+03:30', label: 'UTC+03:30 (Iran, Tehran, IRST)' },
  { value: '+04:00', label: 'UTC+04:00 (UAE, Dubai, GST)' },
  { value: '+04:30', label: 'UTC+04:30 (Afghanistan, Kabul, IRDT)' },
  { value: '+05:00', label: 'UTC+05:00 (Russia, Yekaterinburg, YEKT)' },
  { value: '+05:30', label: 'UTC+05:30 (India, Sri Lanka, IST)' },
  { value: '+06:00', label: 'UTC+06:00 (Kazakhstan, Almaty, ALMT)' },
  { value: '+06:30', label: 'UTC+06:30 (Myanmar, Yangon, MMT)' },
  { value: '+07:00', label: 'UTC+07:00 (Thailand, Bangkok, ICT)' },
  { value: '+08:00', label: 'UTC+08:00 (China, Hong Kong, HKT)' },
  { value: '+09:00', label: 'UTC+09:00 (Japan, Tokyo, JST)' },
  { value: '+09:30', label: 'UTC+09:30 (Australia, Central)' },
  { value: '+10:00', label: 'UTC+10:00 (Australia, Sydney, AEST)' },
  { value: '+11:00', label: 'UTC+11:00 (Russia, Magadan, MAGT)' },
  { value: '+12:00', label: 'UTC+12:00 (Fiji, Suva, FJT)' },
  { value: '+13:00', label: 'UTC+13:00 (Samoa, Apia, WSST)' },
  { value: '+14:00', label: 'UTC+14:00 (Kiribati, Tabwakea Village, LINT)' },
];

export const timeZones: { [key: string]: string } = {
  '-12:00': 'Etc/GMT+12',
  '-11:00': 'Etc/GMT+11',
  '-10:00': 'Etc/GMT+10',
  '-09:00': 'Etc/GMT+9',
  '-08:00': 'Etc/GMT+8',
  '-07:00': 'Etc/GMT+7',
  '-06:00': 'Etc/GMT+6',
  '-05:00': 'Etc/GMT+5',
  '-04:00': 'Etc/GMT+4',
  '-03:00': 'Etc/GMT+3',
  '-02:00': 'Etc/GMT+2',
  '-01:00': 'Etc/GMT+1',
  '+00:00': 'Etc/GMT+0',
  '+01:00': 'Etc/GMT-1',
  '+02:00': 'Etc/GMT-2',
  '+03:00': 'Etc/GMT-3',
  '+03:30': 'Asia/Tehran',
  '+04:00': 'Etc/GMT-4',
  '+04:30': 'Asia/Kabul',
  '+05:00': 'Etc/GMT-5',
  '+05:30': 'Asia/Calcutta',
  '+06:00': 'Etc/GMT-6',
  '+06:30': 'Asia/Yangon',
  '+07:00': 'Etc/GMT-7',
  '+08:00': 'Etc/GMT-8',
  '+09:00': 'Etc/GMT-9',
  '+09:30': 'Australia/Adelaide',
  '+10:00': 'Etc/GMT-10',
  '+11:00': 'Etc/GMT-11',
  '+12:00': 'Etc/GMT-12',
  '+13:00': 'Etc/GMT-13',
  '+14:00': 'Etc/GMT-14',
};

export const getTZ = (val: string): string => {
  if (defined(timeZones[val])) {
    return timeZones[val];
  }

  // using only full timezone
  val = val.replace(':30', ':00');
  if (defined(timeZones[val])) {
    return timeZones[val];
  }

  return '+00:00';
};

export const getTimeRanges = (timezone = getTimeZone()): { [key: string]: NoUndefinedRangeValueType } => {
  if (timezone === '+00:00') {
    return {
      Today: [dayjs().utc().startOf('day'), dayjs().utc().endOf('day')],
      Yesterday: [dayjs().utc().startOf('day').subtract(1, 'days'), dayjs().utc().endOf('day').subtract(1, 'days')],
      'Last 24 h': [dayjs().utc().subtract(24, 'hours'), dayjs().utc()],
      'Last 72 h': [dayjs().utc().subtract(72, 'hours'), dayjs().utc()],
      'Last 7 days': [dayjs().utc().startOf('day').subtract(6, 'days'), dayjs().utc().endOf('day')],
      'Last 14 days': [dayjs().utc().startOf('day').subtract(13, 'days'), dayjs().utc().endOf('day')],
      'Last 30 days': [dayjs().utc().startOf('day').subtract(29, 'days'), dayjs().utc().endOf('day')],
      'This Month': [dayjs().utc().startOf('month'), dayjs().utc().endOf('day')],
      'Last Month': [dayjs().utc().startOf('month').subtract(1, 'month'), dayjs().utc().endOf('month').subtract(1, 'month')],
      '90 Days': [dayjs().utc().startOf('day').subtract(89, 'days'), dayjs().utc().endOf('day')],
      'This Year': [dayjs().utc().startOf('year'), dayjs().utc().endOf('day')],
      'Last Year': [dayjs().utc().startOf('year').subtract(1, 'years'), dayjs().utc().endOf('year').subtract(1, 'years')],
    }
  }
  return {
    //short
    Today: [dayjs().tz(getTZ(timezone)).startOf('day'), dayjs().tz(getTZ(timezone)).endOf('day')],
    Yesterday: [dayjs().tz(getTZ(timezone)).startOf('day').subtract(1, 'days'), dayjs().tz(getTZ(timezone)).endOf('day').subtract(1, 'days')],
    'Last 24 h': [dayjs().tz(getTZ(timezone)).subtract(24, 'hours'), dayjs().tz(getTZ(timezone))],
    'Last 72 h': [dayjs().tz(getTZ(timezone)).subtract(72, 'hours'), dayjs().tz(getTZ(timezone))],
  
    'Last 7 days': [dayjs().tz(getTZ(timezone)).startOf('day').subtract(6, 'days'), dayjs().tz(getTZ(timezone)).endOf('day')],
    'Last 14 days': [dayjs().tz(getTZ(timezone)).startOf('day').subtract(13, 'days'), dayjs().tz(getTZ(timezone)).endOf('day')],
    // long
    'Last 30 days': [dayjs().tz(getTZ(timezone)).startOf('day').subtract(29, 'days'), dayjs().tz(getTZ(timezone)).endOf('day')],
    'This Month': [dayjs().tz(getTZ(timezone)).startOf('month'), dayjs().tz(getTZ(timezone)).endOf('day')],
    'Last Month': [
      dayjs().tz(getTZ(timezone)).subtract(1, 'month').startOf('month'),
      dayjs().tz(getTZ(timezone)).subtract(1, 'month').endOf('month'),
    ],
    '90 Days': [dayjs().tz(getTZ(timezone)).startOf('day').subtract(89, 'days'), dayjs().tz(getTZ(timezone)).endOf('day')],
    'This Year': [dayjs().tz(getTZ(timezone)).startOf('year'), dayjs().tz(getTZ(timezone)).endOf('day')],
    'Last Year': [
      dayjs().tz(getTZ(timezone)).startOf('year').subtract(1, 'years'),
      dayjs().tz(getTZ(timezone)).endOf('year').subtract(1, 'years'),
    ],
  }
};

export const timeRangeItems = getTimeRanges();

export const setCorrectManualDates = (date: any) => {
  date[0].set({ hour: 0, minute: 0, second: 0 });
  date[1].set({ hour: 23, minute: 59, second: 59 });
  return [date[0], date[1]];
};

export const getDefaultInitialTimeRange = (range: TimeRangeKeys): DatePickerValues => {
  const currentTime = getTimeRanges();
  const params = getDatePickerValues(currentTime[range]);
  return {
    timeStart: params.timeStart,
    timeEnd: params.timeEnd,
    date: params.date,
    timeZone: params.timeZone,
    isChangedByCalendar: false,
  };
};
