const UploadBox = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30">
    <g id="upload-box-2--arrow-button-download-internet-network-server-up-upload">
      <path
        id="Vector"
        fill="transparent"
        d="M22.893214285714286 9.95357142857143c0.2639687142857143 0 0.5171875714285714 0.10487082857142857 0.7039165714285714 0.2915401071428571 0.18652992857142858 0.1866493714285714 0.29144057142857144 0.4398284142857143 0.29144057142857144 0.7038170357142857V25.879285714285714c0 0.2639687142857143 -0.10491064285714284 0.5171875714285714 -0.29144057142857144 0.7039165714285714 -0.18672899999999998 0.18652992857142858 -0.4399478571428571 0.29144057142857144 -0.7039165714285714 0.29144057142857144h-17.916428571428572c-0.26398862142857143 0 -0.5171676642857144 -0.10491064285714284 -0.7038170357142857 -0.29144057142857144C4.0862994 26.396473285714286 3.9814285714285713 26.143254428571428 3.9814285714285713 25.879285714285714V10.94892857142857c0 -0.26398862142857143 0.10487082857142857 -0.5171676642857144 0.2915401071428571 -0.7038170357142857C4.45961805 10.058442257142856 4.7127970928571425 9.95357142857143 4.976785714285715 9.95357142857143h17.916428571428572Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M20.9025 9.95357142857143h1.9907142857142857c0.2639687142857143 0 0.5171875714285714 0.10487082857142857 0.7039165714285714 0.2915401071428571 0.18652992857142858 0.1866493714285714 0.29144057142857144 0.4398284142857143 0.29144057142857144 0.7038170357142857V25.879285714285714c0 0.2639687142857143 -0.10491064285714284 0.5171875714285714 -0.29144057142857144 0.7039165714285714 -0.18672899999999998 0.18652992857142858 -0.4399478571428571 0.29144057142857144 -0.7039165714285714 0.29144057142857144h-17.916428571428572c-0.26398862142857143 0 -0.5171676642857144 -0.10491064285714284 -0.7038170357142857 -0.29144057142857144C4.0862994 26.396473285714286 3.9814285714285713 26.143254428571428 3.9814285714285713 25.879285714285714V10.94892857142857c0 -0.26398862142857143 0.10487082857142857 -0.5171676642857144 0.2915401071428571 -0.7038170357142857C4.45961805 10.058442257142856 4.7127970928571425 9.95357142857143 4.976785714285715 9.95357142857143h1.9907142857142857"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_3"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.934999999999999 14.930357142857142v-13.934999999999999"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_4"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m9.95357142857143 4.976785714285715 3.9814285714285713 -3.9814285714285713 3.9814285714285713 3.9814285714285713"
        strokeWidth="2.13"
      ></path>
    </g>
  </svg>
);

export default UploadBox;
