const Pencil = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.855 -0.855 24 24">
    <g id="pencil--change-edit-modify-pencil-write-writing">
      <path
        id="Vector"
        stroke="#8991a3"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m7.960714285714285 19.487669357142856 -7.164642857142857 2.0061 2.0061 -7.164642857142857L15.92142857142857 1.2735534792857144c0.14822849999999999 -0.15164523857142856 0.325434 -0.27213701785714284 0.5207899285714286 -0.354399855 0.19551514285714286 -0.082261245 0.4055187857142857 -0.1246377192857143 0.6175922142857143 -0.1246377192857143 0.21207342857142858 0 0.4220770714285714 0.04237647428571428 0.6175922142857143 0.1246377192857143 0.19535592857142858 0.08226283714285713 0.3725614285714286 0.2027546164285714 0.5207899285714286 0.354399855L21.01628571428571 4.107569357142856c0.14918378571428573 0.1480056 0.2676392142857143 0.32409659999999996 0.34852007142857144 0.5181151285714285 0.08088085714285714 0.19401852857142857 0.1224357857142857 0.40212752142857144 0.1224357857142857 0.6123063 0 0.21017877857142855 -0.04155492857142857 0.41828777142857143 -0.1224357857142857 0.6123063 -0.08088085714285714 0.19400260714285714 -0.19933628571428572 0.37010952857142854 -0.34852007142857144 0.5181151285714285l-13.055571428571428 13.119257142857142Z"
        strokeWidth="1.71"
      ></path>
    </g>
  </svg>
);

export default Pencil;