const ArrowRightDouble = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.855 -0.855 24 24">
    <g id="arrow-right-double--arrow-arrows-double-right">
      <path
        id="Vector"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.285226935714286 0.7960714285714285 20.061 10.58775c0.07610442857142857 0.07147129285714285 0.13676507142857142 0.15779727857142858 0.17832 0.25364427857142857 0.04155492857142857 0.09583107857142857 0.06288964285714285 0.19916115 0.06288964285714285 0.3036057214285714 0 0.10444457142857143 -0.021334714285714287 0.20777464285714287 -0.06288964285714285 0.3036057214285714 -0.04155492857142857 0.09584699999999999 -0.10221557142857142 0.1821729857142857 -0.17832 0.25364427857142857L10.285226935714286 21.49392857142857"
        strokeWidth="1.71"
      ></path>
      <path
        id="Vector_2"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1.9901785714285714 0.7960714285714285 11.781857142857143 10.58775c0.14585620714285713 0.14880167142857142 0.22756497857142857 0.34887034285714286 0.22756497857142857 0.5572499999999999 0 0.20837965714285714 -0.08170877142857143 0.40844832857142854 -0.22756497857142857 0.5572499999999999L1.9901785714285714 21.49392857142857"
        strokeWidth="1.71"
      ></path>
    </g>
  </svg>
);

export default ArrowRightDouble;