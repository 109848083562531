/**
 * FunnelFlux Ledger API
 * FunnelFlux PRO consumer API endpoints for management of assets (so-called 'ledger' API)
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@funnelflux.pro
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FunnelNodeConditionParams } from './funnelNodeConditionParams';
import { FunnelNodeExternalUrlParams } from './funnelNodeExternalUrlParams';
import { FunnelNodeLabelParams } from './funnelNodeLabelParams';
import { FunnelNodePageGroupParams } from './funnelNodePageGroupParams';
import { FunnelNodePageParams } from './funnelNodePageParams';
import { FunnelNodeRotatorParams } from './funnelNodeRotatorParams';
import { FunnelNodeVisitorTagParams } from './funnelNodeVisitorTagParams';
import { Status } from './status';


export interface FunnelNode { 
    /**
     * ID of the node
     */
    idNode: string;
    nodeName: string;
    /**
     * The type of this node. Depending on the value, different parameters may be required. <ul>   <li>If nodeType is 'root' or 'rotator' then the property 'nodeRotatorParams' is optional</li>   <li>If nodeType is 'lander' or 'offer' then the property 'nodePageParams' is required</li>   <li>If nodeType is 'externalUrl' then the property 'nodeExternalUrlParams' is required</li>   <li>If nodeType is 'condition' then the property 'nodeConditionParams' is required</li>   <li>If nodeType is 'landerGroup' or 'offerGroup' then the property 'nodePageGroupParams' is required</li> </ul>
     */
    nodeType: FunnelNode.NodeTypeEnum;
    nodeRotatorParams?: FunnelNodeRotatorParams;
    nodePageParams?: FunnelNodePageParams;
    nodeExternalUrlParams?: FunnelNodeExternalUrlParams;
    nodeConditionParams?: FunnelNodeConditionParams;
    nodeVisitorTagParams?: FunnelNodeVisitorTagParams;
    nodePageGroupParams?: FunnelNodePageGroupParams;
    nodeLabelParams?: FunnelNodeLabelParams;
    /**
     * Node's X position in the funnel canvas.
     */
    posX?: number;
    /**
     * Node's Y position in the funnel canvas.
     */
    posY?: number;
    status?: Status;
}
export namespace FunnelNode {
    export type NodeTypeEnum = 'root' | 'rotator' | 'lander' | 'offer' | 'externalUrl' | 'condition' | 'visitorTag' | 'landerGroup' | 'offerGroup' | 'actionProxy' | 'label';
    export const NodeTypeEnum = {
        Root: 'root' as NodeTypeEnum,
        Rotator: 'rotator' as NodeTypeEnum,
        Lander: 'lander' as NodeTypeEnum,
        Offer: 'offer' as NodeTypeEnum,
        ExternalUrl: 'externalUrl' as NodeTypeEnum,
        Condition: 'condition' as NodeTypeEnum,
        VisitorTag: 'visitorTag' as NodeTypeEnum,
        LanderGroup: 'landerGroup' as NodeTypeEnum,
        OfferGroup: 'offerGroup' as NodeTypeEnum,
        ActionProxy: 'actionProxy' as NodeTypeEnum,
        Label: 'label' as NodeTypeEnum
    };
    export type StatusEnum = Status;
}
