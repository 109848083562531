import { useLanderCreateMutation, useLanderDuplicateMutation, useLanderUpdateMutation } from '@/api/mutations/lander';
import { useLanderCategoriesListQuery, useLanderQuery } from '@/api/queries/lander';
import CodeSnippet from '@/components/CodeSnippet';
import CopyButton from '@/components/CopyButton';
import DataPassing from '@/components/DataPassing';
import SectionBox from '@/components/SectionBox';
import { actionNumbers } from '@/constants';
import { redirects } from '@/constants/form';
import useHttp from '@/hooks/http';
import useMitt from '@/hooks/mitt';
import { Page } from '@/models/page';
import useFormStore from '@/stores/forms';
import Skeleton from 'react-loading-skeleton';
import useSystemSettingsStore from '@/stores/systemSettings';
import {
  FFButton,
  FFIconButton,
  FFCol,
  FFField,
  FFInput,
  FFRow,
  FFSelect,
  FFSidePanel,
  FFSwitch,
  FFTextArea,
  VisibilityWrapper,
  FFNewIcon,
} from '@/uikit';
import { SidebarCopyItems, SidebarTab } from '@/uikit/types/sidebar';
import { generateEntityId } from '@/utils/id';
import { getPageActionLink } from '@/utils/linkJs';
import { withIncrementedVersion } from '@/utils/model';
import { getSidebarOffsetLevel, getSidebarZIndex } from '@/utils/sidebar';
import { addOrIncrementCopySuffix, trimStringPropertiesInObject } from '@/utils/string';
import { addUrlSlash, getQueryUrlSeparate } from '@/utils/url';
import { validateUrl } from '@/utils/validation';
import { Ref, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { categoriesWithoutUncategorized } from '@/utils/array';

const DEFAULT_LANDER: Page = {
  pageName: '',
  idPage: '',
  pageType: 'lander',
  queryParams: {},
  baseURL: '',
  notes: '',
  redirectType: '301',
  disableAppendVid: false,
};

type TabId = 'general' | 'data-passing' | 'page-action-links' | 'js-tracking' | 'conversion-tracking' | 'help';

interface RefType {
  onSave: () => void;
}

const tabs: SidebarTab<TabId>[] = [
  {
    title: 'General Settings',
    tabId: 'general',
    icon: <FFNewIcon name="sidebar-tabs/general-settings" size="md" display="inline-block" type="sidebartab" />,
  },
  {
    title: 'Configure Data Passing',
    tabId: 'data-passing',
    icon: <FFNewIcon name="sidebar-tabs/configure-data-passing" size="md" display="inline-block" type="sidebartab" />,
  },
  {
    title: 'Page Action Links',
    tabId: 'page-action-links',
    icon: <FFNewIcon name="sidebar-tabs/page-action-link" size="md" display="inline-block" type="sidebartab" />,
  },
  {
    title: 'Javascript Tracking',
    tabId: 'js-tracking',
    icon: <FFNewIcon name="sidebar-tabs/javascript-tracking" size="md" display="inline-block" type="sidebartab" />,
  },
  {
    title: 'Help',
    tabId: 'help',
    icon: <FFNewIcon name="sidebar-tabs/help" size="md" display="inline-block" type="sidebartab" />,
  },
];

interface FormProps {
  closeForm: () => void;
  currentTabId: TabId;
  defaultFormValues: Page;
  submitLoading: boolean;
  setSubmitLoading: (loading: boolean) => void;
}

const Form = forwardRef(({ currentTabId, closeForm, setSubmitLoading, submitLoading, defaultFormValues }: FormProps, ref: Ref<RefType>) => {
  const { get: httpGet } = useHttp();
  const emitter = useMitt();
  const { data: categories = [], refetch: refetchCategories } = useLanderCategoriesListQuery();
  const { mutateAsync: createLander } = useLanderCreateMutation();
  const { mutateAsync: duplicateLander } = useLanderDuplicateMutation(defaultFormValues?.idPage || '');
  const { mutateAsync: updateLander } = useLanderUpdateMutation();

  const { domains, userSettings } = useSystemSettingsStore();
  const isDuplication = useFormStore((state) => state.lander.isDuplication);
  const openCategoryForm = useFormStore((state) => state.openCategoryForm);
  const openVersioningForm = useFormStore((state) => state.openVersioningForm);
  const setVersioningType = useFormStore((state) => state.setVersioningType);

  const [domain, setDomain] = useState<string>(userSettings.defaultCustomDomain);
  const [actionNumber, setActionNumber] = useState<string>('');
  const [dataPassingHasError, setDataPassingHasError] = useState(false);
  const [latestVersionOfLander, setLatestVersionOfLander] = useState<Page>();

  const {
    handleSubmit,
    control,
    formState: { isSubmitted },
    setValue,
    watch,
  } = useForm<Page>({
    defaultValues: defaultFormValues,
  });

  const queryParams = watch('queryParams');
  const baseUrl = watch('baseURL') || '';
  const idPage = watch('idPage');

  useEffect(() => {
    emitter.on('onVersioningConfirm', () => onSave(false, true));
    emitter.on('onCategorySave', () => refetchCategories());
  }, []);

  const resultingLanderUrl = useMemo(() => {
    const pairs = Object.entries({
      ...(queryParams || {}),
    })
      .reduce((acc: string[], [key, value]: [string, string]) => (!!key && !!value ? [...acc, `${key}=${value}`] : acc), [])
      .join('&');

    return !!baseUrl ? `${addUrlSlash(baseUrl)}${getQueryUrlSeparate(pairs, baseUrl)}${pairs}` : '';
  }, [queryParams, baseUrl]);

  const onClose = () => {
    closeForm();
  };

  const onSaveAndCreate = () => {
    onSave(true);
  };

  const onSave = (isSaveAndCreate = false, isConfirmingVersioning = false) =>
    handleSubmit(async (data) => {
      if (!dataPassingHasError) {
        setSubmitLoading(true);
        const onSaveSuccessFn = (lander: Page) => {
          emitter.emit('onLanderSave', lander);
          onClose();
        };

        try {
          const newID = generateEntityId();
          const model = trimStringPropertiesInObject<Page>(data, ['pageName']);
          const updateModel = withIncrementedVersion(model);
          const duplicateModel: Page = { ...model, meta: { version: 1 } };
          const createModel: Page = { ...model, idPage: newID, meta: { version: 1 } };
          const category = categories.find((category) => category.idCategory === model.idCategory)!;

          if (isConfirmingVersioning) {
            try {
              let versionedUpdate = withIncrementedVersion(updateModel, latestVersionOfLander?.meta?.version);
              await updateLander(versionedUpdate);
              emitter.emit('onLanderUpdate', versionedUpdate);
              onSaveSuccessFn(versionedUpdate);
            } catch (e) {
              //
            } finally {
              setSubmitLoading(false);
            }
            return;
          } else if (isDuplication) {
            const duplicatedLander = await duplicateLander(duplicateModel);
            emitter.emit('onLanderCreate', { data: duplicatedLander, category });
          } else if (model.idPage) {
            const lander = await httpGet<Page>('v1/page/find/byId', {
              params: { idPage: model.idPage },
            });
            setLatestVersionOfLander(lander.data);
            if (model.meta?.version !== lander.data.meta?.version) {
              setVersioningType('lander');
              openVersioningForm();
              return;
            } else {
              await updateLander(updateModel);
              emitter.emit('onLanderUpdate', updateModel);
            }
          } else {
            await createLander(createModel);
            emitter.emit('onLanderCreate', { data: createModel, category });
            setValue('idPage', '');
          }

          if (isSaveAndCreate) {
            setValue('pageName', addOrIncrementCopySuffix(data.pageName));
          } else {
            onClose();
          }
        } catch (e) {
          //
        } finally {
          setSubmitLoading(false);
        }
      }
    })();

  useImperativeHandle(ref, () => ({ onSave }));

  return (
    <>
      <VisibilityWrapper visible={currentTabId === 'general'} beRenderedAlways>
        <SectionBox title="General Settings">
          <FFCol gap="18px" height="max-content" flex="1">
            <FFRow gap="8px">
              <Controller
                name="pageName"
                control={control}
                rules={{ required: 'Lander Name is required' }}
                render={(opt) => (
                  <FFField label="Lander Name" block>
                    <FFInput
                      value={opt.field.value}
                      onChange={opt.field.onChange}
                      error={opt.fieldState.error?.message}
                      placeholder="Lander Name"
                    />
                  </FFField>
                )}
              />
              <Controller
                name="idCategory"
                control={control}
                render={(opt) => (
                  <FFField label="Category">
                    <FFSelect
                      value={opt.field.value}
                      defaultValue=""
                      onSelect={opt.field.onChange}
                      options={categoriesWithoutUncategorized(categories)}
                      valueGetter={(opt) => opt.idCategory}
                      labelGetter={(opt) => opt.categoryName}
                      defaultValueFallback={{
                        label: 'Uncategorized',
                        value: '',
                      }}
                      showSearch
                      placeholder="Category"
                      style={{ width: 170 }}
                    />
                    <FFIconButton
                      buttonType="blue"
                      iconName="general/line/add-circle"
                      onClick={() => openCategoryForm('lander')}
                    />
                  </FFField>
                )}
              />
            </FFRow>
            <FFRow gap="8px">
              <Controller
                name="baseURL"
                control={control}
                rules={{ required: 'Base Lander URL is required', validate: validateUrl }}
                render={(opt) => (
                  <FFField
                    label="Base Lander URL"
                    block
                    tooltipContent={
                      <>
                        Put your lander page URL here. You can configure appending of extra data (query string) in the{' '}
                        <b>Append Extra Data</b> section. You can still use tokens in this base URL.
                      </>
                    }
                  >
                    <FFInput
                      value={opt.field.value}
                      onChange={opt.field.onChange}
                      error={opt.fieldState.error?.message}
                      placeholder="Lander URL"
                    />
                    <CopyButton value={opt.field.value} />
                  </FFField>
                )}
              />
            </FFRow>
            <FFRow gap="8px">
              <Controller
                name="redirectType"
                control={control}
                render={(opt) => (
                  <FFField label="Redirect Type">
                    <FFSelect
                      value={opt.field.value}
                      onChange={opt.field.onChange}
                      options={redirects}
                      valueGetter={(opt) => opt.value}
                      labelGetter={(opt) => opt.label}
                      placeholder="Redirect Type"
                    />
                  </FFField>
                )}
              />
            </FFRow>
            <FFRow gap="8px">
              <Controller
                name="notes"
                control={control}
                render={(opt) => (
                  <FFField label="Notes" block>
                    <FFTextArea value={opt.field.value} onChange={opt.field.onChange} rows={4} placeholder="Notes" />
                  </FFField>
                )}
              />
            </FFRow>
            <FFButton type="primary" onClick={onSaveAndCreate} loading={submitLoading}>
              Save & Create New
            </FFButton>
          </FFCol>
        </SectionBox>
      </VisibilityWrapper>
      <VisibilityWrapper visible={currentTabId === 'data-passing'} beRenderedAlways>
        <FFCol gap="18px" height="max-content" flex="1">
          <SectionBox title="Configure Data Passing">
            <FFCol gap="12px">
              <p>Here you can configure additional data that gets appended to your base page URL.</p>
              <DataPassing
                queryParams={queryParams || {}}
                onChange={(queryParams) => setValue('queryParams', queryParams)}
                pageName="Data Passing Landers"
                baseURL={baseUrl}
                showErrors={isSubmitted}
                setError={setDataPassingHasError}
              />
            </FFCol>
          </SectionBox>
          <SectionBox>
            <FFField
              label="Resulting Lander URL"
              block
              tooltipContent={
                <>
                  This is the resulting URL from your base URL + query string data configured in the <b>Append Extra Data</b> section. This
                  is the URL FunnelFlux Pro will use when redirecting users to your page.
                </>
              }
            >
              <CodeSnippet data-testid="finalUrl" placeholder="Resulting Lander URL" code={resultingLanderUrl} />
            </FFField>
          </SectionBox>
          <SectionBox title="Advanced Settings">
            <FFCol gap="8px">
              <p>Here you can configure advanced settings for data passing</p>
              <Controller
                name="disableAppendVid"
                control={control}
                render={(opt) => (
                  <FFField block>
                    <FFSwitch checked={opt.field.value} onClick={opt.field.onChange}>
                      Disable automatic appending of visitor ID on redirect
                    </FFSwitch>
                  </FFField>
                )}
              />
            </FFCol>
          </SectionBox>
        </FFCol>
      </VisibilityWrapper>
      <VisibilityWrapper visible={currentTabId === 'page-action-links'}>
        <SectionBox title="Page Action Links">
          <FFCol gap="12px">
            <p>
              Action links are universal click-through URLs that you should use in your pages to send visitors to the next node. You can
              right-click action connections in the funnel builder to get URLs with additional default parameters.
            </p>
            <FFRow gap="8px">
              <FFField label="Select a domain" block>
                <FFSelect
                  options={domains}
                  valueGetter={(opt) => opt.domain}
                  labelGetter={(opt) => opt.domain}
                  value={domain}
                  onSelect={setDomain}
                  placeholder="Select a domain"
                />
              </FFField>
              <FFField label="Action number" block>
                <FFSelect
                  options={actionNumbers}
                  valueGetter={(opt) => opt.value}
                  labelGetter={(opt) => opt.label}
                  value={actionNumber}
                  onSelect={setActionNumber}
                  placeholder="Action Number"
                />
              </FFField>
            </FFRow>
            <FFField label="Action URL" block>
              <CodeSnippet
                data-testid="actionURL"
                placeholder="Action URL"
                className="width-full"
                code={getPageActionLink(domain, actionNumber)}
              />
            </FFField>
          </FFCol>
        </SectionBox>
      </VisibilityWrapper>
      <VisibilityWrapper visible={currentTabId === 'js-tracking'}>
        <FFCol gap="18px" height="max-content">
          <SectionBox title="Required: Universal JS Tag">
            <FFCol gap="12px">
              <FFRow>
                <FFField label="Select a domain:" direction="row" block>
                  <FFSelect
                    options={domains}
                    valueGetter={(opt) => opt.domain}
                    labelGetter={(opt) => opt.domain}
                    value={domain}
                    onSelect={setDomain}
                    placeholder="Select a domain"
                  />
                </FFField>
              </FFRow>
              <p>
                This script is required for direct linking. Place it just before {' </head> '}. We recommend adding this to all of your
                pages regardless as it improves tracking. This code already includes a page view event.
              </p>
              <CodeSnippet codeType="genericViewFull" domain={domain} />
            </FFCol>
          </SectionBox>
          <SectionBox title="Optional: View-only Event">
            <FFCol gap="12px">
              <p>
                If you have already loaded our universal JS tag and want to fire a singular page view event, you can use the following code.
                See our{' '}
                <a href="https://help.funnelflux.pro/article/105-javascript-tracking-of-page-views" target="_blank" rel="noreferrer">
                  help documentation
                </a>{' '}
                for further methods/arguments.
              </p>
              <CodeSnippet codeType="genericViewSingle" />
            </FFCol>
          </SectionBox>
          <SectionBox title="Optional: Default Tracking Parameters">
            <FFCol gap="12px">
              <p>
                This code is optional and is NOT required for tracking to work. If you would like to track organic visits to your page, it
                needs to declare some defaults. You can embed the below code <em>before</em> our global snippet, to provide the tracking
                with default parameters. See our{' '}
                <a href="https://help.funnelflux.pro/article/108-setting-javascript-page-defaults" target="_blank" rel="noreferrer">
                  help documentation
                </a>{' '}
                for more info.
              </p>
              <CodeSnippet codeType="defaultParams" idPage={idPage} />
            </FFCol>
          </SectionBox>
        </FFCol>
      </VisibilityWrapper>
      <VisibilityWrapper visible={currentTabId === 'help'}>
        <SectionBox title="Help">
          <FFCol gap="12px">
            <>
              <p>
                Landers are pages you send users to within your funnels. These pages cannot convert and create revenue - for that you need
                to use offer pages.
              </p>

              <p>
                When creating landers you only need to provide a few settings such as URL and redirect type. You can also append extra data
                using our query builder, or put it in the base URL directly (its up to you, though we always recommend using the field/value
                form to do this, as it helps avoid mistakes).
              </p>
              <p>
                For more help on landers, see our documentation{' '}
                <a href="https://help.funnelflux.pro/article/7-introduction-to-landers" target="_blank" rel="noopener noreferrer">
                  here
                </a>
                .
              </p>
              <p>
                For further information on using our Javascript tracking, see our documentation{' '}
                <a href="https://help.funnelflux.pro/collection/6-javascript-tracking" target="_blank" rel="noopener noreferrer">
                  here
                </a>
                .
              </p>
            </>
          </FFCol>
        </SectionBox>
      </VisibilityWrapper>
    </>
  );
});

const LanderForm = () => {
  const isOpen = useFormStore((state) => state.lander.isOpen);
  const openedForms = useFormStore((state) => state.openedForms);
  const isDuplication = useFormStore((state) => state.lander.isDuplication);
  const idLander = useFormStore((state) => state.lander.data?.id);
  const closeForm = useFormStore((state) => state.closeLanderForm);
  const [currentTabId, setCurrentTabId] = useState<TabId>('general');
  const { data: lander = DEFAULT_LANDER, isFetching } = useLanderQuery(idLander!);
  const formRef = useRef<RefType>({
    onSave: () => {},
  });
  const [submitLoading, setSubmitLoading] = useState(false);

  const defaultFormValues = useMemo(() => {
    if (isDuplication) {
      return {
        ...lander,
        pageName: addOrIncrementCopySuffix(lander.pageName),
      };
    }
    return lander;
  }, [lander, isDuplication]);

  const copyItems: SidebarCopyItems[] = useMemo(() => {
    if (idLander) {
      return [{ title: 'Lander ID', value: idLander }];
    }
    return [];
  }, [idLander]);

  const onClose = () => {
    setCurrentTabId('general');
    closeForm();
  };

  const sidebarTitle = useMemo(() => {
    if (isDuplication) {
      return 'Duplicate Lander';
    } else if (idLander) {
      return 'Edit Lander';
    } else {
      return 'Create Lander';
    }
  }, [isDuplication, idLander]);

  return (
    <FFSidePanel
      isOpen={isOpen}
      minWidth={600}
      maxWidth={1100}
      tabs={tabs}
      copyItems={copyItems}
      onClose={onClose}
      sidebarName="LanderForm"
      currentTabId={currentTabId}
      offsetLevel={getSidebarOffsetLevel(openedForms, 'lander')}
      zIndex={getSidebarZIndex(openedForms, 'lander')}
      title={sidebarTitle}
      setCurrentTabId={(tabId) => setCurrentTabId(tabId as TabId)}
      actions={
        <FFRow gap="8px">
          <FFButton onClick={() => formRef.current.onSave()} loading={submitLoading} disabled={submitLoading}>
            Save
          </FFButton>
          <FFButton type="tertiary" disabled={submitLoading} onClick={onClose}>
            Cancel
          </FFButton>
        </FFRow>
      }
    >
      {isFetching ? (
        <Skeleton width="100%" height="400px" />
      ) : (
        <Form
          defaultFormValues={defaultFormValues}
          currentTabId={currentTabId}
          closeForm={onClose}
          setSubmitLoading={setSubmitLoading}
          submitLoading={submitLoading}
          ref={formRef}
        />
      )}
    </FFSidePanel>
  );
};

export default LanderForm;
