const Filter = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.57 -0.57 16 16">
    <g>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.329285714285714 0.5307142857142857H0.5307142857142857l5.307142857142857 7.43v6.368571428571428l3.184285714285714 -2.1228571428571428v-4.2457142857142856l5.307142857142857 -7.43Z"
        strokeWidth="1.14"
      ></path>
    </g>
  </svg>
);

export default Filter;
