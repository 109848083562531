const DataUpdates = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30">
    <g id="cloud-upload--cloud-server-internet-upload-up-arrow-network">
      <path
        id="Vector"
        fill="currentColor"
        d="M22.659504428571427 8.488405714285715c-0.08938307142857144 -0.02667557142857143 -0.1694097857142857 -0.07839432857142857 -0.23032564285714285 -0.14900496428571428 -0.06091585714285714 -0.07063054285714285 -0.10053107142857143 -0.15722661428571427 -0.11386885714285715 -0.24957585000000002 -0.16005342857142857 -1.75399845 -0.916524857142857 -3.4001200928571427 -2.1434020714285715 -4.663845428571428C18.945070457142855 2.162254135714286 17.32210082142857 1.357302842142857 15.57361665 1.1453654271428573c-1.7484841714285713 -0.211937415 -3.516795857142857 0.18194730428571426 -5.010010735714285 1.1159665585714287 -1.4932148785714285 0.9340232357142857 -2.6211137785714285 2.351730321428571 -3.1955742 4.016704028571429 -0.03802264285714285 0.07763785714285715 -0.09744546428571428 0.14277402857142857 -0.17128105714285716 0.18770445 -0.0738555 0.04495032857142857 -0.15899834999999998 0.06782363571428572 -0.24543516428571427 0.06593245714285713 -0.8327356928571429 0.055919164285714286 -1.6463207142857141 0.2753157857142857 -2.3942718857142857 0.6456483642857143 -0.7479710785714285 0.37033257857142854 -1.41565665 0.8843549142857143 -1.9649743499999999 1.5127437857142858 -1.1093613685714285 1.26906045 -1.6691601792857143 2.926847678571429 -1.556238902142857 4.608642921428571 0.11292127714285713 1.6818151499999998 0.8893137835714285 3.2498808857142856 2.1583702521428574 4.359246235714285 1.269080357142857 1.10936535 2.926847678571429 1.6691741142857142 4.608662828571428 1.5562408928571427h13.642703421428571c1.3301952857142858 -0.007962857142857143 2.611219928571429 -0.5033521071428572 3.600405857142857 -1.3923851999999999 0.989385 -0.8890330928571428 1.618450714285714 -2.1099580714285713 1.7679533571428572 -3.4316330999999995 0.1497017142857143 -1.3216551214285714 -0.19051135714285714 -2.6522883642857145 -0.9561400714285714 -3.7399350214285714 -0.7656287142857142 -1.0876665642857144 -1.903521 -1.8568188428571428 -3.1982815714285713 -2.1618360857142855Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.658744085714284 11.808817607142856v11.944186178571428"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_3"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m10.677315514285713 15.790246178571428 3.9814285714285713 -3.9814285714285713 3.9814285714285713 3.9814285714285713"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_4"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.802863435714285 19.214195121428574c-1.6818151499999998 0.11293322142857143 -3.3395824714285713 -0.44687554285714287 -4.608662828571428 -1.5562408928571427C1.9251441385714285 16.548588878571426 1.148751632142857 14.980523142857143 1.0358303549999999 13.298707992857143c-0.11292127714285713 -1.6817952428571428 0.44687753357142856 -3.3395824714285713 1.556238902142857 -4.608642921428571 0.5493177 -0.6283888714285714 1.2170032714285715 -1.1424112071428572 1.9649743499999999 -1.5127437857142858 0.7479511714285714 -0.37033257857142854 1.561536192857143 -0.5897292 2.3942718857142857 -0.6456483642857143 0.08643681428571429 0.0018911785714285714 0.17157966428571428 -0.020982128571428574 0.24543516428571427 -0.06593245714285713 0.07383559285714285 -0.044930421428571427 0.1332783214285714 -0.11006659285714285 0.17128105714285716 -0.18770445 0.5744604214285713 -1.664973707142857 1.7023593214285713 -3.082680792857143 3.1955742 -4.016704028571429C12.056820792857144 1.3273127314285713 13.82513247857143 0.9334280121428571 15.57361665 1.1453654271428573c1.7484841714285713 0.211937415 3.371453807142857 1.0168887085714287 4.598291207142857 2.280614044285714 1.2268772142857143 1.2637253357142857 1.9833486428571427 2.9098469785714287 2.1434020714285715 4.663845428571428 0.013337785714285715 0.09234923571428572 0.05295299999999999 0.17894530714285714 0.11386885714285715 0.24957585000000002 0.06091585714285714 0.07061063571428572 0.14094257142857142 0.12232939285714285 0.23032564285714285 0.14900496428571428 1.2947605714285713 0.3050172428571428 2.432652857142857 1.0741695214285714 3.1982815714285713 2.1618360857142855 0.7656287142857142 1.0876466571428571 1.1058417857142857 2.4182799 0.9561400714285714 3.7399350214285714 -0.14950264285714285 1.3216750285714285 -0.7785683571428571 2.542619914285714 -1.7679533571428572 3.4316330999999995 -0.9891859285714285 0.8890330928571428 -2.2702105714285716 1.3844223428571427 -3.600405857142857 1.3923851999999999"
        strokeWidth="2.13"
      ></path>
    </g>
  </svg>
);

export default DataUpdates;