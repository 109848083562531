import className from '@/utils/className';
import { FFNewIcon } from '@/uikit';
import './style.scss';

const { blockClassName, getClass } = className('c-stepCheck');

interface Props {
  showCheckMark?: boolean;
  step: number;
}

const StepCheck = ({ showCheckMark, step }: Props) => {
  return (
    <div className={`${blockClassName} ${blockClassName}--${showCheckMark ? 'checked' : 'notChecked'}`}>
      {showCheckMark ? <FFNewIcon name="general/line/check" size="sm" /> : <div className={getClass('step')}>{step}</div>}
    </div>
  );
};

export default StepCheck;
