import React, { PropsWithChildren, CSSProperties } from 'react';
import clsx from 'clsx';
import './style.scss';

const Col = ({
  children,
  className,
  style,
  draggable,
  onClick,
  ...rest
}: PropsWithChildren &
  CSSProperties &
  React.DOMAttributes<HTMLDivElement> &
  React.HTMLAttributes<HTMLDivElement> & { className?: string }) => (
  <div className={clsx('c-col', className)} style={rest} draggable={draggable} onClick={onClick}>
    {children}
  </div>
);

export default Col;
