import { useQuery } from '@tanstack/react-query';
import useHttp from '@/hooks/http';
import { FunnelGroupInfo } from '@/models/funnelGroupInfo';
import { Status } from '@/models/status';
import { FunnelGroup } from '@/models/funnelGroup';
import { Funnel } from '@/models/funnel'

export function useFunnelGroupListQuery(status: Status = 'active', includeChilds = false) {
  const { get } = useHttp();

  return useQuery({
    queryKey: ['funnelGroup', 'list', status, includeChilds ? 'with-childs' : 'without-childs'],
    queryFn: async () => {
      const { data } = await get<FunnelGroupInfo[]>('v1/campaign/list/', {
        params: {
          status,
          includeChilds,
        },
      });
      return data;
    },
  });
}

export function useFunnelGroupQuery(idCampaign: string) {
  const { get } = useHttp();

  return useQuery({
    queryKey: ['funnelGroup', idCampaign],
    queryFn: async () => {
      const { data } = await get<FunnelGroup>('v1/campaign/find/byId/', {
        params: {
          idCampaign,
        },
      });
      return data;
    },
    enabled: !!idCampaign,
  });
}

export function useFunnelQuery(idFunnel: string) {
  const { get } = useHttp();

  return useQuery({
    queryKey: ['funnel', idFunnel],
    queryFn: async () => {
      const { data } = await get<Funnel>('v1/campaign/funnel/find/byId/', {
        params: {
          idFunnel,
        },
      });
      return data;
    },
    enabled: !!idFunnel,
  });
}
