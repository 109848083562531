import React, { PropsWithChildren, useMemo } from 'react';
import Button from 'antd/lib/button';
import NewIcon from '../NewIcon';
import { NewIconType, NewIconName, NewIconSize } from '../../types/icon';
import className from '@/utils/className';
import clsx from 'clsx';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { LoadingOutlined } from '@ant-design/icons';
import './style.scss';

const { blockClassName, getClass } = className('c-ffButton');

const FFButton = ({
  className = '',
  onClick = () => {},
  onMouseDown,
  onMouseUp,
  onMouseLeave,
  children,
  iconName,
  size = 'middle',
  iconSize = 'md',
  loading = false,
  disabled = false,
  active,
  block = false,
  type = 'primary',
}: PropsWithChildren<{
  className?: string;
  active?: boolean;
  iconSize?: NewIconSize;
  size?: SizeType;
  onClick?: React.MouseEventHandler<HTMLElement>;
  onMouseDown?: React.MouseEventHandler<any>;
  onMouseUp?: React.MouseEventHandler<any>;
  onMouseLeave?: React.MouseEventHandler<any>;
  iconName?: NewIconName;
  block?: boolean;
  type?: 'primary' | 'primaryDanger' | 'primaryWarning' | 'tertiary' | 'tertiaryDanger' | 'gray' | 'ghost' | 'brandRed' | 'dangerSkeleton',
  loading?: boolean;
  disabled?: boolean;
}>) => {
  const onButtonClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onClick(e);
  };

  const iconType: NewIconType = useMemo(() => {
    if (type === 'primary' || type === 'primaryDanger' || type === 'primaryWarning') return 'primary';
    if (type === 'tertiaryDanger') return 'danger';
    return 'tertiary';
  }, [type]);

  const showSkeleton = type === 'dangerSkeleton' && loading;

  return (
    <Button
      className={clsx([
        blockClassName,
        `${blockClassName}--${type}`,
        `${blockClassName}--${block ? 'block' : 'minimum'}`,
        disabled && `${blockClassName}--disabled`,
        active && `${blockClassName}--active`,
        loading && `${blockClassName}--loading`,
        className,
      ])}
      data-type={type}
      size={size}
      block={block}
      aria-disabled={disabled}
      onClick={onButtonClick}
      onMouseDown={onMouseDown}
      onMouseLeave={onMouseLeave}
      onMouseUp={onMouseUp}
      disabled={disabled}
    >
      {iconName && <NewIcon type={iconType} name={iconName} size={iconSize} className={getClass('icon')} />}
      <span className={getClass('text')}>{children}</span>
      {loading && !showSkeleton && <div className={getClass('loading')}><LoadingOutlined /></div>}
      {showSkeleton && <div className={getClass('skeleton')} />}
    </Button>
  );
};

export default FFButton;
