/**
 * FunnelFlux Pro Ledger API
 * API endpoints to manage customers' assets
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { RuleCapOfferConversionParams } from './ruleCapOfferConversionParams';
import { RuleCapVisitorConversionParams } from './ruleCapVisitorConversionParams';
import { RuleGenericParams } from './ruleGenericParams';
import { RuleTimeDateParams } from './ruleTimeDateParams';
import { RuleTimeDayOfMonthParams } from './ruleTimeDayOfMonthParams';
import { RuleTimeDayOfWeekParams } from './ruleTimeDayOfWeekParams';
import { RuleTimeMonthOfYearParams } from './ruleTimeMonthOfYearParams';
import { RuleTimeOfDayParams } from './ruleTimeOfDayParams';
import { RuleTrackingFieldParams } from './ruleTrackingFieldParams';

export interface FunnelConditionRule {
  /**
   * Depending on the selected attribute, some other properties may become required. <ul> <li>For 'Time: Date', timeDateParams is required</li> <li>For 'Time: Time of Day', timeOfDayParams is required</li> <li>For 'Time: Day of Week', timeDayOfWeekParams is required</li> <li>For 'Time: Day of Month', timeDayOfMonthParams is required</li> <li>For 'Time: Month of Year', timeMonthOfYearParams is required</li> <li>For 'Conversion Cap: Current Visitor', capVisitorConversionParams is required</li> <li>For 'Conversion Cap: Globally on Offer', capOfferConversionParams is required</li> <li>For 'Tracking Field', trackingFieldParams is required</li> <li>For all other attributes, genericParams is required</li> </ul>
   */
  attribute: FunnelConditionRule.AttributeEnum;
  /**
   * Test operator
   */
  test: FunnelConditionRule.TestEnum;
  genericParams?: RuleGenericParams;
  timeDateParams?: RuleTimeDateParams;
  timeOfDayParams?: RuleTimeOfDayParams;
  timeDayOfWeekParams?: RuleTimeDayOfWeekParams;
  timeDayOfMonthParams?: RuleTimeDayOfMonthParams;
  timeMonthOfYearParams?: RuleTimeMonthOfYearParams;
  capVisitorConversionParams?: RuleCapVisitorConversionParams;
  capOfferConversionParams?: RuleCapOfferConversionParams;
  trackingFieldParams?: RuleTrackingFieldParams;
}
export namespace FunnelConditionRule {
  export type AttributeEnum =
    | 'Location: Continent'
    | 'Location: Country'
    | 'Location: City'
    | 'Location: Region'
    | 'Location: Timezone'
    | 'Device: Type'
    | 'Device: Brand'
    | 'Device: Model'
    | 'Device: OS'
    | 'Device: OS Version'
    | 'Device: Browser'
    | 'Device: Browser Language'
    | 'Device: Browser Version'
    | 'Connection: IP'
    | 'Connection: ISP'
    | 'Connection: Mobile Carrier'
    | 'Connection: Connection Type'
    | 'Connection: User Agent'
    | 'Connection: Referrer'
    | 'Connection: Current URL'
    | 'Conversion Cap: Current Visitor'
    | 'Conversion Cap: Globally on Offer'
    | 'Time: Date'
    | 'Time: Day of Week'
    | 'Time: Day of Month'
    | 'Time: Month of Year'
    | 'Time: Time of Day'
    // | 'Number of Total Visitors'
    // | 'Number of Visitors Today'
    | 'Traffic Source'
    | 'Tracking Field'
    | 'Tracking Domain';
  export const AttributeEnum = {
    LocationContinent: 'Location: Continent' as AttributeEnum,
    LocationCountry: 'Location: Country' as AttributeEnum,
    LocationCity: 'Location: City' as AttributeEnum,
    LocationRegion: 'Location: Region' as AttributeEnum,
    LocationTimezone: 'Location: Timezone' as AttributeEnum,
    DeviceType: 'Device: Type' as AttributeEnum,
    DeviceBrand: 'Device: Brand' as AttributeEnum,
    DeviceModel: 'Device: Model' as AttributeEnum,
    DeviceOS: 'Device: OS' as AttributeEnum,
    DeviceOSVersion: 'Device: OS Version' as AttributeEnum,
    DeviceBrowser: 'Device: Browser' as AttributeEnum,
    DeviceBrowserLanguage: 'Device: Browser Language' as AttributeEnum,
    DeviceBrowserVersion: 'Device: Browser Version' as AttributeEnum,
    ConnectionIP: 'Connection: IP' as AttributeEnum,
    ConnectionISP: 'Connection: ISP' as AttributeEnum,
    ConnectionCarrier: 'Connection: Mobile Carrier' as AttributeEnum,
    ConnectionType: 'Connection: Connection Type' as AttributeEnum,
    ConnectionUserAgent: 'Connection: User Agent' as AttributeEnum,
    ConnectionReferrer: 'Connection: Referrer' as AttributeEnum,
    ConnectionCurrentURL: 'Connection: Current URL' as AttributeEnum,
    // ConversionCapCurrentVisitor: 'Conversion Cap: Current Visitor' as AttributeEnum,
    // ConversionCapGloballyOnOffer: 'Conversion Cap: Globally on Offer' as AttributeEnum,
    TimeDate: 'Time: Date' as AttributeEnum,
    TimeDayOfWeek: 'Time: Day of Week' as AttributeEnum,
    TimeDayOfMonth: 'Time: Day of Month' as AttributeEnum,
    TimeMonthOfYear: 'Time: Month of Year' as AttributeEnum,
    TimeTimeOfDay: 'Time: Time of Day' as AttributeEnum,
    // NumberOfTotalVisitors: 'Number of Total Visitors' as AttributeEnum,
    // NumberOfVisitorsToday: 'Number of Visitors Today' as AttributeEnum,
    TrafficSource: 'Traffic Source' as AttributeEnum,
    TrackingField: 'Tracking Field' as AttributeEnum,
    TrackingDomain: 'Tracking Domain' as AttributeEnum
  };
  export type TestEnum =
    | 'is'
    | 'is not'
    | 'contains'
    | 'does not contain'
    | 'any in'
    | 'not in'
    | '>'
    | '>='
    | '<'
    | '<=';
  export const TestEnum = {
    Is: 'is' as TestEnum,
    IsNot: 'is not' as TestEnum,
    Contains: 'contains' as TestEnum,
    DoesNotContain: 'does not contain' as TestEnum,
    AnyIn: 'any in' as TestEnum,
    NotIn: 'not in' as TestEnum,
    GreaterThan: '>' as TestEnum,
    GreaterThanOrEqualTo: '>=' as TestEnum,
    LessThan: '<' as TestEnum,
    LessThanOrEqualTo: '<=' as TestEnum
  };
}
