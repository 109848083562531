import { TrafficSource } from '@/models/models';
import { FFCol, FFField, FFInput, FFSelect, FFSwitch } from '@/uikit';
import { FFSelectOption } from '@/uikit/types/select';
import { Control, Controller } from 'react-hook-form';

const EVENT_NAMES: Array<FFSelectOption> = [
  {
    value: 'EVENT_ADD_TO_CART',
    label: 'Add to Cart'
  },
  {
    value: 'EVENT_ADD_PAYMENT_INFO',
    label: 'Add Payment Info'
  },
  {
    value: 'EVENT_ADD_TO_WISHLIST',
    label: 'Add to Wishlist'
  },
  {
    value: 'EVENT_BUTTON_CLICK',
    label: 'Button Click'
  },
  {
    value: 'EVENT_COMPLETE_REGISTRATION',
    label: 'Complete Registration'
  },
  {
    value: 'EVENT_CONTACT',
    label: 'Contact'
  },
  {
    value: 'EVENT_CONTENT_VIEW',
    label: 'Content View'
  },
  {
    value: 'EVENT_DOWNLOAD',
    label: 'Download'
  },
  {
    value: 'EVENT_FORM_SUBMIT',
    label: 'Form Submit'
  },
  {
    value: 'EVENT_INITIATED_CHECKOUT',
    label: 'Initiate Checkout'
  },
  {
    value: 'EVENT_PLACE_ORDER',
    label: 'Place an Order'
  },
  {
    value: 'EVENT_PURCHASE',
    label: 'Purchase'
  },
  {
    value: 'EVENT_SEARCH',
    label: 'Search'
  },
  {
    value: 'EVENT_SUBSCRIBE',
    label: 'Subscribe'
  },
  {
    value: '{txid}',
    label: 'Custom – Transaction ID Value'
  }
];

const KwaiCustomScenario = ({ triggeringEvent, control }: { triggeringEvent: string, control: Control<TrafficSource> }) => {
  return (
    <FFCol gap="12px">
      <Controller
        name={`conversionTrackingSettings.${triggeringEvent}.customEventData.accessToken`}
        control={control}
        render={opt => (
          <FFField label="Access Token:" block direction="row">
            <FFInput
              value={opt.field.value}
              onChange={(e) => opt.field.onChange(e.target.value.trim())}
              error={opt.fieldState.error?.message}
              placeholder="Access Token"
              style={{ width: 280 }}
            />
          </FFField>
        )}
      />
      <Controller
        name={`conversionTrackingSettings.${triggeringEvent}.customEventData.pixelID`}
        control={control}
        render={opt => (
          <FFField label="Pixel ID:" block direction="row">
            <FFInput
              value={opt.field.value}
              onChange={(e) => opt.field.onChange(e.target.value.trim())}
              error={opt.fieldState.error?.message}
              placeholder="Pixel ID"
              style={{ width: 280 }}
            />
          </FFField>
        )}
      />
      <Controller
        name={`conversionTrackingSettings.${triggeringEvent}.customEventData.eventName`}
        control={control}
        render={opt => (
          <FFField label="Event name:" block direction="row">
            <FFSelect
              options={EVENT_NAMES}
              valueGetter={opt => opt.value}
              labelGetter={opt => opt.label}
              value={opt.field.value}
              onChange={opt.field.onChange}
              error={opt.fieldState.error?.message}
              placeholder="Event name"
              style={{ width: 280 }}
            />
          </FFField>
        )}
      />
      <Controller
        name={`conversionTrackingSettings.${triggeringEvent}.customEventData.sendTestEventCode`}
        control={control}
        render={opt => (
          <FFSwitch checked={opt.field.value} onClick={opt.field.onChange}>
            Turn on test mode (only use for testing)
          </FFSwitch>
        )}
      />
    </FFCol>
  );
};

export default KwaiCustomScenario;
