import { useMutation } from '@tanstack/react-query';
import useHttp from '@/hooks/http';
import { AxiosError, AxiosResponse } from 'axios';
import { AuthenticateResponse } from '@/models/authenticate';
import { LoginDto } from '../../types/apis/login';
import useHttpNotificationNew from '@/hooks/useHttpNotification';
import { ModelError } from '@/models/modelError';

export function useLoginMutation() {
  const { post } = useHttp();
  const { showNotification } = useHttpNotificationNew('Login');
  return useMutation<AxiosResponse<AuthenticateResponse>, AxiosError<ModelError>, LoginDto>({
    mutationKey: ['authenticate', 'login'],
    mutationFn: (params: LoginDto) => post<LoginDto, AuthenticateResponse>('v1/auth/login', params),
    onError: (error, params) => {
      showNotification({
        params,
        error,
      });
    },
  });
}

export function useResetPasswordMutation() {
  const { post } = useHttp();
  const { showNotification } = useHttpNotificationNew('ResetPassword');
  return useMutation<any, AxiosError<ModelError>, string>({
    mutationKey: ['authenticate', 'resetPassword'],
    mutationFn: (email: string) => post('v1/auth/reset_password', { email }),
    onSuccess: (_, email) => {
      showNotification({
        params: {
          email,
        },
      });
    },
    onError: (error, email) => {
      showNotification({
        params: {
          email,
        },
        error,
      });
    },
  });
}
