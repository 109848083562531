const ArrowLeft = () => (
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 18 18">
    <g id="arrow-up-3--arrow-up-keyboard">
      <path
        id="Vector"
        style={{
          fill: 'none',
          stroke: 'currentColor',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '1.3px',
        }}
        d="M12.8,16.8l-7.3-7.3c0,0-.1-.1-.1-.2,0,0,0-.1,0-.2s0-.2,0-.2c0,0,0-.1.1-.2L12.8,1.2"
      />
    </g>
  </svg>
);

export default ArrowLeft;
