/**
 * FunnelFlux Pro Ledger API
 * API endpoints to manage customers' assets
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * The day is tested against the visitor's timezone
 */
export interface RuleTimeDayOfWeekParams { 
    day: RuleTimeDayOfWeekParams.DayEnum;
}
export namespace RuleTimeDayOfWeekParams {
    export type DayEnum = 'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday';
    export const DayEnum = {
        Sunday: 'sunday' as DayEnum,
        Monday: 'monday' as DayEnum,
        Tuesday: 'tuesday' as DayEnum,
        Wednesday: 'wednesday' as DayEnum,
        Thursday: 'thursday' as DayEnum,
        Friday: 'friday' as DayEnum,
        Saturday: 'saturday' as DayEnum
    };
}
