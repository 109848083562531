import SectionBox from '@/components/SectionBox';
import { redirects } from '@/constants/form';
import { Page } from '@/models/page';
import { PageGroupEntry } from '@/models/pageGroupEntry';
import { FunnelNodeExtendedPageParams } from '@/types/forms/pageGroup';
import { FFCol, FFInput, FFSelect, FFSwitch } from '@/uikit';
import { FFSelectOption } from '@/uikit/types/select';
import { useMemo, useState } from 'react';
import { Control, Controller, useController, useWatch } from 'react-hook-form';

const validateExtraUrlParams = (value: string | undefined) => {
  if (!value) return 'Query string is required';
  const queryString = value.startsWith('?') ? value : `?${value}`;
  return /(^\?|)(([\d\w]*=[\d\w-]*)|(#[\d\w]*))(&|)/gm.test(queryString) ? undefined : 'Invalid URL parameters';
};

const AdditionalSettings = ({
  pages = [],
  selectedPages = [],
  control,
}: {
  control: Control<{ pageOverrides: FunnelNodeExtendedPageParams[] }>;
  pages: Page[];
  selectedPages: PageGroupEntry[];
}) => {
  const { field } = useController({
    name: 'pageOverrides',
    control,
  });
  const pageOverrides = useWatch({
    control,
    name: 'pageOverrides',
  });
  const [idPage, setIdPage] = useState(pages[0]?.idPage || '');
  const idPageIndex = useMemo(() => {
    const index = pageOverrides.findIndex((pageOverride) => pageOverride.idPage === idPage);
    return index === -1 ? pageOverrides.length - 1 : index;
  }, [idPage, pageOverrides]);
  const appendExtraUrlParams = pageOverrides?.[idPageIndex]?.appendExtraUrlParams;
  const overrideRedirectMode = pageOverrides?.[idPageIndex]?.overrideRedirectMode;

  const onPageSelect = (idPage: string) => {
    setIdPage('');
    const pageIndex = field.value.findIndex((pageOverride) => pageOverride.idPage === idPage);
    if (pageIndex === -1) {
      field.onChange([
        ...field.value,
        {
          idPage,
          accumulateUrlParams: false,
          appendExtraUrlParams: false,
          extraUrlParams: '',
          overrideRedirectMode: false,
          redirectOverride: '',
        },
      ]);
    }
    requestAnimationFrame(() => {
      setIdPage(idPage);
    });
  };

  const pageOptions = useMemo(() => {
    const options: FFSelectOption[] = [];
    selectedPages.forEach((page) => {
      const pageData = pages.find((p) => p.idPage === page.idPage);
      if (pageData) {
        options.push({
          value: page.idPage,
          label: pageData.pageName,
        });
      }
    });
    return options;
  }, [selectedPages, pages]);

  return (
    <SectionBox title="Additional Settings">
      <FFCol gap="12px">
        <FFSelect
          value={idPage}
          options={pageOptions}
          valueGetter={(opt) => opt.value!}
          labelGetter={(opt) => opt.label!}
          placeholder="Select the page"
          onSelect={onPageSelect}
        />
        {idPage && (
          <>
            <Controller
              name={`pageOverrides.${idPageIndex}.accumulateUrlParams`}
              control={control}
              defaultValue={false}
              render={(opt) => (
                <FFSwitch checked={opt.field.value || false} onClick={opt.field.onChange}>
                  Receive Accumulated URL Parameters
                </FFSwitch>
              )}
            />
            <Controller
              name={`pageOverrides.${idPageIndex}.appendExtraUrlParams`}
              control={control}
              defaultValue={false}
              render={(opt) => (
                <FFSwitch checked={opt.field.value} onClick={opt.field.onChange}>
                  Append Extra URL Parameters
                </FFSwitch>
              )}
            />
            {appendExtraUrlParams && (
              <Controller
                name={`pageOverrides.${idPageIndex}.extraUrlParams`}
                control={control}
                rules={{ required: true, validate: validateExtraUrlParams }}
                render={(opt) => (
                  <FFInput
                    error={opt.fieldState.error?.message}
                    value={opt.field.value}
                    onChange={opt.field.onChange}
                    placeholder="Extra URL Parameters"
                  />
                )}
              />
            )}
            <Controller
              name={`pageOverrides.${idPageIndex}.overrideRedirectMode`}
              control={control}
              defaultValue={false}
              render={(opt) => (
                <FFSwitch checked={opt.field.value} onClick={opt.field.onChange}>
                  Override redirect mode
                </FFSwitch>
              )}
            />
            <Controller
              name={`pageOverrides.${idPageIndex}.redirectOverride`}
              control={control}
              render={(opt) => (
                <FFSelect
                  value={opt.field.value}
                  options={redirects}
                  valueGetter={(opt) => opt.value}
                  labelGetter={(opt) => opt.label}
                  onSelect={opt.field.onChange}
                  placeholder="Select a Redirect"
                  disabled={!overrideRedirectMode}
                />
              )}
            />
          </>
        )}
      </FFCol>
    </SectionBox>
  );
};

export default AdditionalSettings;
