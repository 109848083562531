const Roadmap = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1.065 -1.065 30 30">
    <g id="ai-map-generating-spark--navigation-direction-travel-artificial-intelligence-ai">
      <path
        id="Vector"
        fill="currentColor"
        d="m8.016566614285715 5.551763721428571 -5.523674742857143 1.5781984714285715c-0.8546235964285714 0.2441810142857143 -1.4438292385714284 1.0252974857142858 -1.4438292385714284 1.914131507142857V24.157317857142857c0 1.3226305714285713 1.2658991957142856 2.2773771428571425 2.5376072957142854 1.9140717857142857l4.429896685714286 -1.265696142857143 6.967499999999999 1.9907142857142857 5.523674742857143 -1.5782382857142856c0.8546136428571428 -0.24406157142857143 1.4438650714285712 -1.0252178571428572 1.4438650714285712 -1.9140717857142857V8.190913371428572c0 -1.322590757142857 -1.2658952142857143 -2.277456771428571 -2.5376431285714283 -1.9141116l-4.429896685714286 1.2656762357142857 -6.967499999999999 -1.9907142857142857Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector_2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M21.951606428571427 17.83821340714286v5.465884307142858c0 0.8888539285714285 -0.5892514285714285 1.6700102142857143 -1.4438650714285712 1.9140717857142857l-5.523674742857143 1.5782382857142856 -6.967499999999999 -1.9907142857142857 -4.429896685714286 1.265696142857143c-1.2717081000000001 0.3633053571428571 -2.5376072957142854 -0.5914412142857142 -2.5376072957142854 -1.9140717857142857V9.038260907142858c0 -0.8888340214285714 0.5892056421428571 -1.669950492857143 1.4438292385714284 -1.914131507142857l5.523674742857143 -1.5781984714285715 2.7806894357142857 0.7944940714285714"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector 2681"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.984066614285714 26.796407785714283V12.861427692857141"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector 2682"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.016566614285715 24.805693499999997V5.545930928571428"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector 2136"
        fill="#ffffff"
        d="M14.709248507142858 7.9095856285714286c-0.6986013642857143 -0.12153310714285714 -0.6986013642857143 -1.1244151500000001 0 -1.245948257142857 2.530914514285714 -0.4403061857142857 4.543884985714286 -2.3680342714285714 5.0932425 -4.877542634999999l0.04210360714285714 -0.19236272142857141c0.15113502857142858 -0.6904454078571428 1.1341497428571428 -0.6947413692857143 1.2914161714285712 -0.005643675l0.05116135714285714 0.22417433571428572c0.5695433571428572 2.49765366 2.5831508571428574 4.40911761 5.106978428571428 4.84820946 0.702324 0.12215022857142857 0.702324 1.1301284999999999 0 1.2522986357142858 -2.5238275714285714 0.43907194285714285 -4.537435071428572 2.3505358928571427 -5.106978428571428 4.848185571428571l-0.05116135714285714 0.22417433571428572c-0.15726642857142856 0.6891056571428572 -1.1402811428571429 0.6848057142857142 -1.2914161714285712 -0.005633721428571429l-0.04210360714285714 -0.19236272142857141c-0.5493575142857142 -2.5094944285714282 -2.5623279857142855 -4.4372424214285715 -5.0932425 -4.877548607142857Z"
        strokeWidth="2.13"
      ></path>
      <path
        id="Vector 2137"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.709248507142858 7.9095856285714286c-0.6986013642857143 -0.12153310714285714 -0.6986013642857143 -1.1244151500000001 0 -1.245948257142857 2.530914514285714 -0.4403061857142857 4.543884985714286 -2.3680342714285714 5.0932425 -4.877542634999999l0.04210360714285714 -0.19236272142857141c0.15113502857142858 -0.6904454078571428 1.1341497428571428 -0.6947413692857143 1.2914161714285712 -0.005643675l0.05116135714285714 0.22417433571428572c0.5695433571428572 2.49765366 2.5831508571428574 4.40911761 5.106978428571428 4.84820946 0.702324 0.12215022857142857 0.702324 1.1301284999999999 0 1.2522986357142858 -2.5238275714285714 0.43907194285714285 -4.537435071428572 2.3505358928571427 -5.106978428571428 4.848185571428571l-0.05116135714285714 0.22417433571428572c-0.15726642857142856 0.6891056571428572 -1.1402811428571429 0.6848057142857142 -1.2914161714285712 -0.005633721428571429l-0.04210360714285714 -0.19236272142857141c-0.5493575142857142 -2.5094944285714282 -2.5623279857142855 -4.4372424214285715 -5.0932425 -4.877548607142857Z"
        strokeWidth="2.13"
      ></path>
    </g>
  </svg>
);

export default Roadmap;