import { PageGroupEntry } from '@/models/pageGroupEntry';
import className from '@/utils/className';
import { FFCol, FFIconButton, FFInput, FFRow, FFSlider, FFTag, VisibilityWrapper } from '@/uikit';
import { useMemo, useState } from 'react';
import useFormStore from '@/stores/forms';
import { PageGroup } from '@/models/pageGroup';
import AssetLogo from '@/components/AssetLogo';
import Skeleton from 'react-loading-skeleton';
import { Page } from '@/models/page';
import clsx from 'clsx';
import './style.scss';

const { blockClassName, getClass } = className('c-pageSlider');

const PageSlider = ({
  pageGroupEntry,
  totalWeight,
  pageType,
  page,
  showSkeleton,
  index,
  totalItemsCount,
  routing,
  onChange,
  onDelete,
  onMoveUp,
  onMoveDown,
}: {
  pageGroupEntry: PageGroupEntry;
  pageType: PageGroup.PageTypeEnum;
  page: Page | null;
  totalWeight: number;
  showSkeleton: boolean;
  index: number;
  totalItemsCount: number;
  routing: PageGroup.RoutingEnum;
  onChange: (idPage: string, weight: number) => void;
  onDelete: (idPage: string) => void;
  onMoveUp: (index: number) => void;
  onMoveDown: (index: number) => void;
}) => {
  const { openLanderForm, openOfferForm, setLanderData, setOfferData } = useFormStore();
  const [weight, setWeight] = useState(pageGroupEntry.weight * 100);

  const percent = useMemo(() => {
    if (pageGroupEntry.weight === 0 && totalWeight === 0) return '0.00%';
    return ((pageGroupEntry.weight / totalWeight) * 100).toFixed(2);
  }, [totalWeight, pageGroupEntry]);

  const isDeleted = useMemo(() => {
    return page?.status === 'deleted';
  }, [page]);

  const onWeightChange = (value: number) => {
    setWeight(value);
    onChange(pageGroupEntry.idPage, value / 100);
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = Number(e.target.value.replace(/[^0-9]/g, '') || 0);
    if (val < 0) val = 0;
    if (val > 100) val = 100;
    setWeight(val);
    onChange(pageGroupEntry.idPage, val / 100);
  };

  const onEditPage = () => {
    if (pageType === PageGroup.PageTypeEnum.Lander) {
      setLanderData({ id: pageGroupEntry.idPage, value: page?.pageName }, false);
      openLanderForm();
    } else {
      setOfferData({ id: pageGroupEntry.idPage, value: page?.pageName }, false);
      openOfferForm();
    }
  };

  const onDeletePage = () => {
    onDelete(pageGroupEntry.idPage);
  };

  return (
    <FFCol gap="8px" className={clsx(blockClassName, { [`${blockClassName}--deleted`]: isDeleted })}>
      <FFRow justifyContent="space-between" alignItems="center">
        <FFRow gap="8px" alignItems="center" className={getClass('header')}>
          <VisibilityWrapper visible={routing === 'actionMapped'}>
            <FFTag>{index + 1}</FFTag>
        </VisibilityWrapper>
        <AssetLogo assetType={pageType === 'lander' ? 'landerGroup' : 'offerGroup'} size="md" />
        <span className={getClass('pageName')}>
          {showSkeleton ? <Skeleton width={100} /> : page?.pageName} {isDeleted ? `(Deleted)` : ''}
        </span>
          <FFIconButton buttonType="transparent" onClick={onEditPage} iconName="table/edit" iconType="tableaction" iconSize="sm" />
        </FFRow>
        <FFRow>
          <FFIconButton
            buttonType="transparent"
            onClick={() => onMoveUp(index)}
            disabled={index === 0}
            iconName="general/line/arrow-up-circle"
            iconType="purple"
            iconSize="md"
          />
          <FFIconButton
            buttonType="transparent"
            onClick={() => onMoveDown(index)}
            disabled={index === totalItemsCount - 1}
            iconName="general/line/arrow-down-circle"
            iconType="purple"
            iconSize="md"
          />
      </FFRow>
      </FFRow>
      <VisibilityWrapper visible={routing === 'rotator'}>
        <FFRow gap="8px" alignItems="center">
          <FFSlider value={weight} step={1} className={getClass('slider')} onChange={onWeightChange} />
          <FFInput className={getClass('input')} value={weight} onChange={onInputChange} max={100} min={0} />
          <span>{percent}%</span>
          <FFIconButton buttonType="transparent" iconName="general/line/recycle-bin" iconType="danger" onClick={onDeletePage} />
        </FFRow>
      </VisibilityWrapper>
    </FFCol>
  );
};

export default PageSlider;
