import { daysOfWeek, monthOfYearParams } from '@/constants/conditions';
import { countries } from '@/constants/countries';
import { languages } from '@/constants/languages';
import { FunnelConditionRoute } from '@/models/funnelConditionRoute';
import { FunnelConditionRule } from '@/models/funnelConditionRule';
import { FFSelectOption } from '@/uikit/types/select';
import { defined } from '@/utils/define';
import * as dayjs from 'dayjs';

const convertedTestEnum = (test: FunnelConditionRule.TestEnum) => {
  switch (test) {
    case 'is':
      return '=';
    case 'is not':
      return '!=';
    case 'not in':
      return '!=';
    case 'does not contain':
      return '!=';
    default:
      return test;
  }
};

const nowYear = new Date().getFullYear();
const nowMonth = new Date().getMonth() + 1;
const nowDay = new Date().getDate();
const nowHour = new Date().getHours();
const nowMinute = new Date().getMinutes();

export const getConditionRuleDate = (day = nowDay, month = nowMonth, year = nowYear) => {
  const d = day >= 10 ? day : `0${day}`;
  const m = month >= 10 ? month : `0${month}`;
  const y = year >= 10 ? year : `0${year}`;
  return { day: d, month: m, year: y, date: dayjs.default(`${d}.${m}.${y}`, 'DD.MM.YYYY') };
};

export const getConditionRuleTime = (hour = nowHour, minute = nowMinute) => {
  const h = hour >= 10 ? hour : `0${hour}`;
  const m = minute >= 10 ? minute : `0${minute}`;
  return { hour: h, minute: m, time: dayjs.default(`${h}:${m}`, 'HH:mm') };
};

export const getRouteNameByParam = (rule: FunnelConditionRule, trafficSources: FFSelectOption[]) => {
  if (rule.attribute === 'Traffic Source') {
    return (rule.genericParams?.values || [])
      .map((item) => {
        const trafficSource = trafficSources.find((trafficSource) => trafficSource.value === item);
        return trafficSource?.label || '';
      })
      .join(', ');
  } else if (rule.attribute === 'Location: Country') {
    return (rule.genericParams?.values || [])
      .map((item) => {
        const country = countries.find((country) => country.value === item);
        return country?.label || '';
      })
      .join(', ');
  } else if (rule.attribute === FunnelConditionRule.AttributeEnum.DeviceBrowserLanguage) {
    return (rule.genericParams?.values || [])
      .map((item) => {
        const language = languages.find((language) => language.value === item);
        return language?.label || '';
      })
      .join(', ');
  } else if (defined(rule.timeDateParams)) {
    const { day, month, year } = getConditionRuleDate(rule.timeDateParams!.day, rule.timeDateParams!.month, rule.timeDateParams!.year);
    return `${day}.${month}.${year}`;
  } else if (defined(rule.timeOfDayParams)) {
    const { hour, minute } = getConditionRuleTime(rule.timeOfDayParams!.hour, rule.timeOfDayParams!.minutes);
    return `${hour}:${minute}`;
  } else if (defined(rule.timeDayOfWeekParams)) {
    return daysOfWeek.find((item) => item.value === rule.timeDayOfWeekParams!.day)?.label;
  } else if (defined(rule.timeDayOfMonthParams)) {
    return `Day ${rule.timeDayOfMonthParams!.day} Of Month`;
  } else if (defined(rule.timeMonthOfYearParams)) {
    return monthOfYearParams.labelGetter(monthOfYearParams.options.find((item) => Number(item) === rule.timeMonthOfYearParams!.month)!);
  } else if (defined(rule.trackingFieldParams)) {
    return ` ${rule.trackingFieldParams!.fieldName} ${rule.test || ''} ${rule.trackingFieldParams?.fieldValues || []}`;
  } else {
    return (rule.genericParams?.values || []).join(', ');
  }
};

export const getRouteName = (route: FunnelConditionRoute, idRoute: number, trafficSources: FFSelectOption[]) => {
  if (!route) {
    return `Route ${idRoute}`;
  }
  const groupIsEmpty = !defined(route.groups) || !route.groups.length;
  const ruleIsEmpty =
    !defined(route.groups?.[0]?.rules) ||
    !route.groups?.[0]?.rules.length ||
    !getRouteNameByParam(route.groups?.[0]?.rules?.[0], trafficSources);

  if (groupIsEmpty || ruleIsEmpty) {
    return route.routeName || `Route ${idRoute}`;
  }
  if (route.groups.length > 1) {
    return 'Multiple rule group';
  }
  if (route.groups[0].rules.length > 2) {
    return 'Complex rule set';
  }

  return route.groups[0].rules.slice(0, 3).reduce((acc, rule, idRule) => {
    if (idRule === 2) {
      acc += '...';
    } else {
      acc += rule.attribute.includes(':') ? rule.attribute.split(':')?.[1] : rule.attribute;
      acc += rule.attribute === FunnelConditionRule.AttributeEnum.TrackingField ? '' : ` ${convertedTestEnum(rule.test) || ''} `;
      acc += getRouteNameByParam(rule, trafficSources);
      if (idRule < route.groups[0].rules.length - 1) {
        acc += route.groups[0].operator === 'or' ? ' OR ' : ' AND ';
      }
    }
    return `[${acc.trim()}]`;
  }, '');
};

export const isRouteNameAutoGenerated = (routeName: string) => {
  return /Route\s[0-9]{1,}$/g.test(routeName) || (routeName.startsWith('[') && routeName.endsWith(']'));
};
