import { TrafficSource } from '@/models/trafficSource';
import { FacebookEventName } from '@/types/forms/trafficSource';
import { FFCol, FFField, FFInput, FFSelect, FFSwitch } from '@/uikit';
import { FFSelectOption } from '@/uikit/types/select';
import { useMemo } from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';

const customEventNameValidation = (value: string) => {
  if (!value || value.length === 0) return 'Required';
  if (!/^[a-zA-Z0-9_\-\.]+$/.test(value)) return 'Only letters, numbers, underscores, hyphens and dots are allowed';
  return true;
};

const eventNameOptions: FFSelectOption<FacebookEventName>[] = [
  {
    value: 'AddPaymentInfo',
    label: 'Add Payment Info',
  },
  {
    value: 'AddToCart',
    label: 'Add to Cart',
  },
  {
    value: 'AddToWishlist',
    label: 'Add to Wishlist',
  },
  {
    value: 'ClickButton',
    label: 'Click Button',
  },
  {
    value: 'CompleteRegistration',
    label: 'Complete Registration',
  },
  {
    value: 'Contact',
    label: 'Contact',
  },
  {
    value: 'CustomizeProduct',
    label: 'Customize Product',
  },
  {
    value: 'Donate',
    label: 'Donate',
  },
  {
    value: 'FindLocation',
    label: 'Find Location',
  },
  {
    value: 'InitiateCheckout',
    label: 'Initiate Checkout',
  },
  {
    value: 'Lead',
    label: 'Lead',
  },
  {
    value: 'Purchase',
    label: 'Purchase',
  },
  {
    value: 'Schedule',
    label: 'Schedule',
  },
  {
    value: 'Search',
    label: 'Search',
  },
  {
    value: 'StartTrial',
    label: 'Start Trial',
  },
  {
    value: 'SubmitApplication',
    label: 'Submit Application',
  },
  {
    value: 'Subscribe',
    label: 'Subscribe',
  },
  {
    value: 'ViewContent',
    label: 'View Content',
  },
  {
    value: '{txid}',
    label: 'Custom - Transaction ID Value',
  },
  {
    value: 'custom-manual-value',
    label: 'Custom - Manual Value',
  },
];

const FacebookCustomScenario = ({
  triggeringEvent,
  control,
}: {
  triggeringEvent: string;
  control: Control<TrafficSource>;
}) => {
  const sendTestEventCode = useWatch({
    control,
    name: `conversionTrackingSettings.${triggeringEvent}.customEventData.sendTestEventCode`,
  });
  const sendOverridingEventURL = useWatch({
    control,
    name: `conversionTrackingSettings.${triggeringEvent}.customEventData.sendOverridingEventURL`,
  });
  const eventName = useWatch({
    control,
    name: `conversionTrackingSettings.${triggeringEvent}.customEventData.eventName`,
  });
  const isCustomManualValue = useMemo(() => eventName === 'custom-manual-value', [eventName]);

  return (
    <FFCol gap="12px">
      <Controller
        name={`conversionTrackingSettings.${triggeringEvent}.customEventData.accessToken`}
        control={control}
        render={(opt) => (
          <FFField label="Access Token:" block direction="row">
            <FFInput
              value={opt.field.value}
              onChange={(e) => opt.field.onChange(e.target.value.trim())}
              error={opt.fieldState.error?.message}
              placeholder="Access Token"
              style={{ width: 280 }}
            />
          </FFField>
        )}
      />
      <Controller
        name={`conversionTrackingSettings.${triggeringEvent}.customEventData.pixelID`}
        control={control}
        render={(opt) => (
          <FFField label="Pixel ID:" block direction="row">
            <FFInput
              value={opt.field.value}
              onChange={(e) => opt.field.onChange(e.target.value.trim())}
              error={opt.fieldState.error?.message}
              placeholder="Pixel ID"
              style={{ width: 280 }}
            />
          </FFField>
        )}
      />
      <Controller
        name={`conversionTrackingSettings.${triggeringEvent}.customEventData.eventName`}
        control={control}
        render={(opt) => (
          <FFField label="Event name:" block direction="row">
            <FFSelect
              value={opt.field.value}
              onChange={opt.field.onChange}
              options={eventNameOptions}
              valueGetter={(opt) => opt.value}
              labelGetter={(opt) => opt.label}
              error={opt.fieldState.error?.message}
              placeholder="Event name"
              style={{ width: 280 }}
            />
          </FFField>
        )}
      />
      {isCustomManualValue && (
        <Controller
          name={`conversionTrackingSettings.${triggeringEvent}.customEventData.customEventName`}
          control={control}
          rules={{
            required: 'Required',
            validate: customEventNameValidation,
          }}
          render={(opt) => (
            <FFField label="Custom event name:" block direction="row">
              <FFInput
                value={opt.field.value}
                onChange={(e) => opt.field.onChange(e.target.value.trim())}
                error={opt.fieldState.error?.message}
                placeholder="Custom event name"
                style={{ width: 280 }}
              />
            </FFField>
          )}
        />
      )}
      <Controller
        name={`conversionTrackingSettings.${triggeringEvent}.customEventData.sendTestEventCode`}
        control={control}
        render={(opt) => (
          <FFSwitch checked={opt.field.value} onClick={opt.field.onChange}>
            Send an optional test event code (use for testing only)
          </FFSwitch>
        )}
      />
      {sendTestEventCode && (
        <Controller
          name={`conversionTrackingSettings.${triggeringEvent}.customEventData.testEventCode`}
          control={control}
          rules={{ required: 'Required' }}
          render={(opt) => (
            <FFField label="Test Event Code:" block direction="row">
              <FFInput
                value={opt.field.value}
                onChange={(e) => opt.field.onChange(e.target.value.trim())}
                error={opt.fieldState.error?.message}
                placeholder="Test Event Code"
                style={{ width: 280 }}
              />
            </FFField>
          )}
        />
      )}
      <Controller
        name={`conversionTrackingSettings.${triggeringEvent}.customEventData.sendOverridingEventURL`}
        control={control}
        render={(opt) => (
          <FFSwitch checked={opt.field.value} onClick={opt.field.onChange}>
            Send overriding event URL for each conversion
          </FFSwitch>
        )}
      />
      {sendOverridingEventURL && (
        <Controller
          name={`conversionTrackingSettings.${triggeringEvent}.customEventData.eventURLToUse`}
          control={control}
          rules={{ required: 'Required' }}
          render={(opt) => (
            <FFField label="Event URL to use:" block direction="row">
              <FFInput
                value={opt.field.value}
                onChange={(e) => opt.field.onChange(e.target.value.trim())}
                error={opt.fieldState.error?.message}
                placeholder="Event URL"
                style={{ width: 280 }}
              />
            </FFField>
          )}
        />
      )}
    </FFCol>
  );
};

export default FacebookCustomScenario;
