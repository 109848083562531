const ArrowRight2 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.855 -0.855 24 24">
    <g>
      <path
        id="Vector"
        stroke="#8991a3"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m10.348928571428571 6.368571428571428 4.776428571428571 4.776428571428571 -4.776428571428571 4.776428571428571"
        strokeWidth="1.71"
      ></path>
      <path
        id="Vector_2"
        stroke="#8991a3"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m0.7960714285714285 11.145 14.329285714285714 0"
        strokeWidth="1.71"
      ></path>
      <path
        id="Vector_3"
        stroke="#8991a3"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m21.49392857142857 5.5725 0 11.145"
        strokeWidth="1.71"
      ></path>
    </g>
  </svg>
);

export default ArrowRight2;