/**
 * FunnelFlux Pro Ledger API
 * API endpoints to manage customers' assets
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Meta } from './meta';
import { PageGroupEntry } from './pageGroupEntry';
export interface PageGroup { 
    idPageGroup: string;
    /**
     * Page group's name.
     */
    pageGroupName: string;
    /**
     * What kind of pages in this group.
     */
    pageType: PageGroup.PageTypeEnum;
    routing: PageGroup.RoutingEnum;
    /**
     * List of page entries
     */
    pages: Array<PageGroupEntry>;
    /**
     * If empty, then page group is global and can be used by multiple funnels, otherwise, page group is local and can be used only by the funnel with id specified in this field.
     */
    restrictToFunnelId?: string;
    status?: PageGroup.StatusEnum;
    /**
     * DEPRECATED
     */
    isArchived?: boolean;
    meta?: Meta;
}
export namespace PageGroup {
    export type PageTypeEnum = 'lander' | 'offer';
    export const PageTypeEnum = {
        Lander: 'lander' as PageTypeEnum,
        Offer: 'offer' as PageTypeEnum
    };
    export type RoutingEnum = 'rotator' | 'actionMapped';
    export const RoutingEnum = {
        Rotator: 'rotator' as RoutingEnum,
        ActionMapped: 'actionMapped' as RoutingEnum
    };
    export type StatusEnum = 'active' | 'archived' | 'deleted';
    export const StatusEnum = {
        Active: 'active' as StatusEnum,
        Archived: 'archived' as StatusEnum,
        Deleted: 'deleted' as StatusEnum
    };
}
