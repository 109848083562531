import { CSSProperties } from 'react';
import { NewIconName, NewIconSize, NewIconType } from '../../types/icon';
import clsx from 'clsx';
import className from '@/utils/className';
import './style.scss';

const { getClass } = className('c-newIcon');

const images = import.meta.glob('../../../assets/icons/**/*.tsx', { eager: true });

const NewIcon = ({
  name,
  size = 'lg',
  className,
  display,
  width,
  height,
  type = 'primary',
  onClick = () => {},
}: {
  name: NewIconName;
  size?: NewIconSize;
  className?: string;
  width?: number;
  height?: number;
  onClick?: () => void;
  type?: NewIconType;
  display?: CSSProperties['display'];
}) => {
  try {
    //@ts-ignore
    const Icon = images[`../../../assets/icons/${name}.tsx`]?.default;
    if (!Icon) {
      console.warn(`Icon ${name} not found`);
      return null;
    }

    const w = width || (size === 'xlg' ? 38 : size === 'lg' ? 32 : size === 'md' ? 22 : size === 'sm' ? 16 : 12);
    const h = height || (size === 'xlg' ? 38 : size === 'lg' ? 32 : size === 'md' ? 22 : size === 'sm' ? 16 : 12);

    return (
      <i
        className={clsx(className, getClass(type))}
        style={{
          width: w,
          height: h,
          display: display || size === 'md' ? 'flex' : 'unset',
        }}
        data-icon={name}
        onClick={onClick}
      >
        <Icon />
      </i>
    );
  } catch (e) {
    return null;
  }
};

export default NewIcon;
