/**
 * FunnelFlux Ledger API
 * FunnelFlux PRO consumer API endpoints for management of assets (so-called \"ledger\" API)
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@funnelflux.pro
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CustomEventPostback } from './customEventPostback';
import { ConversionTrackingSettings } from './conversionTrackingSettings';
import { Meta } from './meta';
import { Postback } from './postback';
import { TrackingField } from './trackingField';

export interface TrafficSource {
  /**
   * ID of the traffic source
   */
  idTrafficSource: string;
  /**
   * Traffic source's name.
   */
  trafficSourceName: string;
  costType: TrafficSource.CostTypeEnum;
  defaultCost?: string;
  /**
   * Allowed keys are campaign, external and c1 to c20 - see example
   */
  trackingFieldSlots?: { [key: string]: TrackingField };
  postback?: Postback;
  customEventPostback?: CustomEventPostback;
  status?: TrafficSource.StatusEnum;
  /**
   * Incoming cost modifier in percent. This value is used to adjust the incoming cost of a traffic source. For example, if the incoming cost is $1 and the incoming cost modifier is 50, the incoming cost will be adjusted to $0.5. This number should be between 0 and 500.
   */
  incomingCostModifier?: number;
  /**
   * Outgoing revenue modifier in percent. This value is used to adjust the outgoing revenue of a traffic source. For example, if the outgoing revenue is $1 and the outgoing revenue modifier is 50, the outgoing revenue sent in postbacks will be $0.5. This number should be between 0 and 500.
   */
  outgoingRevenueModifier?: number;
  /**
   * If true, then zero revenue postbacks will not be sent for this traffic source. This is useful for filtering events of zero revenue which may not be desirable to send to the traffic source.
   */
  disableZeroRevenuePostbacks?: boolean;
  /**
   * Outgoing postback probability in percent. This value is used to determine the probability of sending a postback to the traffic source. For example, if the outgoing postback probability is 50, then there is a 50% chance that a postback will be sent to the traffic source. This number should be between 0 and 100.
   */
  outgoingPostbackProbability?: number;
  /**
   * DEPRECATED
   */
  isArchived?: boolean;
  /**
   * Category ID. Empty if uncategorized
   */
  idCategory?: string;

  /**
   * DEPRECATED
   */
  customScenarioData?: {
    isCustomScenario: boolean;
    type: TrafficSource.CustomScenarioTypeEnum;
    data: any;
  };
  conversionTrackingSettings?: { [key: string]: ConversionTrackingSettings };
  meta?: Meta;
}

export namespace TrafficSource {
  export type CostTypeEnum = 'cpe' | 'cpa';
  export const CostTypeEnum = {
    Cpe: 'cpe' as CostTypeEnum,
    Cpa: 'cpa' as CostTypeEnum
  };
  export type StatusEnum = 'active' | 'archived' | 'deleted';
  export const StatusEnum = {
    Active: 'active' as StatusEnum,
    Archived: 'archived' as StatusEnum,
    Deleted: 'deleted' as StatusEnum
  };
  const trafficSources = [
    'Facebook',
    'TikTok',
    'GoogleAds',
    'MicrosoftAds',
    'Snapchat',
    'Kwai',
    'Reddit',
    'Twitter',
    'Bigo',
  ];
  
  export type CustomScenarioTypeEnum = typeof trafficSources[number];
  
  export const CustomScenarioTypeEnum = Object.fromEntries(
    trafficSources.map((source) => [source, source as CustomScenarioTypeEnum])
  );
}


