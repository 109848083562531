import { FFSelectOption } from '@/uikit/types/select';
import { ColorMode } from '@xyflow/react';

export const NO_OFFER_SOURCE = 'No Offer Source';
export const UNCATEGORIZED = 'Uncategorized';
export const UNKNOWN = 'Unknown';

export const disabledIPs: FFSelectOption = { value: 'disabled', label: 'Disable Anonymization' };
export const IPs: FFSelectOption[] = [{ value: 'EU IPs', label: 'EU-based IPs' }, { value: 'All IPs', label: 'All IPs' }, disabledIPs];

export const actionNumbers: FFSelectOption<number>[] = Array.from(Array(255).keys())
  .map((i) => i + 1)
  .map((i) => ({ label: String(i), value: i }));

export const colorModes: FFSelectOption<ColorMode>[] = [
  {
    value: 'light',
    label: 'Light',
  },
  {
    value: 'dark',
    label: 'Dark',
  },
  {
    value: 'system',
    label: 'System',
  },
];

export const customEventTriggerOptions = (customEventAliases: {
  [key: number]: { alias: string; shortAlias: string };
}): FFSelectOption<number>[] => {
  const customEvents = [
    {
      label: 'Custom Event 1',
      value: 1,
    },
    {
      label: 'Custom Event 2',
      value: 2,
    },
    {
      label: 'Custom Event 3',
      value: 3,
    },
    {
      label: 'Custom Event 4',
      value: 4,
    },
    {
      label: 'Custom Event 5',
      value: 5,
    },
    {
      label: 'Custom Event 6',
      value: 6,
    },
    {
      label: 'Custom Event 7',
      value: 7,
    },
    {
      label: 'Custom Event 8',
      value: 8,
    },
    {
      label: 'Custom Event 9',
      value: 9,
    },
    {
      label: 'Custom Event 10',
      value: 10,
    },
  ];

  return customEvents.map((customEvent) => ({
    label: customEventAliases[customEvent.value]?.alias
      ? `${customEvent.label} (${customEventAliases[customEvent.value]?.alias})`
      : customEvent.label,
    value: customEvent.value,
  }));
};
