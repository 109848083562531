import useHttp from '@/hooks/http';
import { FunnelCondition } from '@/models/funnelCondition';
import { FunnelConditionCategoryInfo } from '@/models/funnelConditionCategoryInfo';
import { FunnelConditionInfo } from '@/models/funnelConditionInfo';
import { useQuery } from '@tanstack/react-query';

export function useConditionCategoriesListQuery() {
  const { get } = useHttp();
  return useQuery({
    queryKey: ['conditon', 'categoriesList'],
    queryFn: async () => {
      const { data } = await get<FunnelConditionCategoryInfo[]>(
        'v1/campaign/funnel/condition/category/list/?status=not-deleted&includeChilds=true',
      );
      return data;
    },
  });
}

export function useConditionListQuery(status: FunnelCondition.StatusEnum = 'active') {
  const { get } = useHttp();
  return useQuery({
    queryKey: ['conditon', 'list'],
    queryFn: async () => {
      const { data } = await get<FunnelConditionInfo[]>('v1/campaign/funnel/condition/list/', {
        params: {
          status,
        },
      });
      return data;
    },
  });
}

export function useConditionQuery(idCondition: string) {
  const { get } = useHttp();
  return useQuery({
    queryKey: ['condition', idCondition],
    queryFn: async () => {
      const { data } = await get<FunnelCondition>('v1/campaign/funnel/condition/find/byId', {
        params: {
          idCondition,
        },
      });
      return data;
    },
    enabled: !!idCondition,
  });
}
