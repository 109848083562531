/**
 * FunnelFlux Pro Ledger API
 * API endpoints to manage customers' assets
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Meta } from "./meta";


export interface FunnelGroup { 
    /**
     * ID of the funnelGroup
     */
    idCampaign: string;
    /**
     * Campaign's name.
     */
    campaignName: string;
    /**
     * Query parameters to add to the tracking url for all the funnels of this campaign, unless a specific funnel overwrites them
     */
    accumulatedUrlParams?: { [key: string]: string; };
    /**
     * Custom tokens usable in conditions, JS nodes and PHP nodes for all the funnels of this campaign
     */
    customTokens?: { [key: string]: string; };
    defaultCost?: string;
    status?: Campaign.StatusEnum;
    /**
     * DEPRECATED
     */
    isArchived?: boolean;
    meta?: Meta;
}
export namespace Campaign {
    export type StatusEnum = 'active' | 'archived' | 'deleted';
    export const StatusEnum = {
        Active: 'active' as StatusEnum,
        Archived: 'archived' as StatusEnum,
        Deleted: 'deleted' as StatusEnum
    };
}
